import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";
import ResetPasswordReducer from "./auth/resetpwd/reducer";

// User
import UserReducer from "./user/reducer";

// Role
import RoleReducer from "./auth/role/reducer";

// Permission
import PermissionReducer from "./auth/permission/reducer";

//TicketsList
import TicketsReducer from "./tickets/reducer";

//Invoice
import InvoiceReducer from "./invoice/reducer";

// Dashboard Ecommerce
import DashboardEcommerceReducer from "./dashboardEcommerce/reducer";

// API Key
import APIKeyReducer from "./apiKey/reducer";

// CRM Customer
import CustomerReducer from "./customer/reducer";

// CRM Contact
import ContactReducer from "./contact/reducer";

// CRM Site
import SiteReducer from "./site/reducer";

// CRM Transactions
import TransactionReducer from "./transaction/reducer";

// CRM Attribute
import AttributeReducer from "./attribute/reducer";

// CRM Service
import ServiceReducer from "./service/reducer";

// CRM Stripe
import StripeReducer from "./stripe/reducer";

// CRM Communication
import CommunicationReducer from "./communication/reducer";

// CRM Inventory
import InventoryReducer from "./inventory/reducer";

// CRM Provisioning
import ProvisioningReducer from "./provisioning/reducer";

// CRM Cell Broadcast
import BroadcastReducer from "./broadcast/reducer";

// CRM OAM
import OAMReducer from "./oam/reducer";

// CRM Product
import ProductReducer from "./product/reducer";

// CRM Activity
import ActivityReducer from "./activity/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    Account: AccountReducer,
    ForgetPassword: ForgetPasswordReducer,
    ResetPassword: ResetPasswordReducer,
    Profile: ProfileReducer,
    Tickets: TicketsReducer,
    Invoice: InvoiceReducer,
    DashboardEcommerce: DashboardEcommerceReducer,
    APIKey: APIKeyReducer,
    Customer: CustomerReducer,
    Contact: ContactReducer,
    Site: SiteReducer,
    Transaction: TransactionReducer,
    Attribute: AttributeReducer,
    Service: ServiceReducer,
    Stripe: StripeReducer,
    Communication: CommunicationReducer,
    Inventory: InventoryReducer,
    Provisioning: ProvisioningReducer,
    Broadcast: BroadcastReducer,
    OAM: OAMReducer,
    Product: ProductReducer,
    Activity: ActivityReducer,
    User: UserReducer,
    Role: RoleReducer,
    Permission: PermissionReducer,
});

export default rootReducer;