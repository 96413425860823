import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import ParticlesAuth from "../ParticlesAuth";
import { useDispatch } from 'react-redux';
import { loginUser } from '../../../slices/thunks';
import { useNavigate } from 'react-router-dom';

// Import images
import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import defaultLogoLight from "../../../assets/images/omnitouch/DefaultLogoLight.png";

// Function to conditionally import user-uploaded images
const tryImport = (filename) => {
    try {
        return require(`../../../assets/images/omnitouch/${filename}`);
    } catch (err) {
        return null;
    }
};
  
// Attempt to import user-uploaded images
const userLogoLight = tryImport("logoLight.png");

const getLogo = (userLogo, defaultLogo) => userLogo || defaultLogo;

const BasicLockScreen = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userEmail, setUserEmail] = useState(null);

    useEffect(() => {
        if (sessionStorage.getItem("authUser")) {
            const obj = JSON.parse(sessionStorage.getItem("authUser"));
            setUserEmail(obj.data.email);
        }
    }, []);

    const login = (e) => {
        e.preventDefault();
        dispatch(loginUser({
            email: e.target.useremail.value,
            password: e.target.userpassword.value
        }, navigate));
    };

    document.title = "Lock Screen | Velzon - React Admin & Dashboard Template";
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-0 text-white-50">
                                    <div>
                                        <Link to="/dashboard" className="d-inline-block auth-logo">
                                            <img src={getLogo(userLogoLight, defaultLogoLight)} alt="" height="170" />
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Lock Screen</h5>
                                            <p className="text-muted">Enter your password to unlock the screen!</p>
                                        </div>
                                        <div className="user-thumb text-center">
                                            <img src={avatar1} className="rounded-circle img-thumbnail avatar-lg" alt="thumbnail" />
                                            <h5 className="font-size-15 mt-3">{userEmail}</h5>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <form onSubmit={login}>
                                                <div className="mb-3" hidden>
                                                    <label className="form-label" htmlFor="useremail">Password</label>
                                                    <input type="email" className="form-control" id="useremail" name="useremail" value={userEmail} disabled />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="userpassword">Password</label>
                                                    <input type="password" className="form-control" id="userpassword" name="userpassword" placeholder="Enter password" required />
                                                </div>
                                                <div className="mb-2 mt-4">
                                                    <Button color="success" className="w-100" type="submit">Unlock</Button>
                                                </div>
                                            </form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">Not you? Return <Link to="/login" className="fw-semibold text-primary text-decoration-underline">Signin</Link></p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default BasicLockScreen;
