import React, { useEffect, useState } from "react";
import { Container } from 'reactstrap';
import Section from './Section';

import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { toast } from 'react-toastify';
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { LoadScript } from '@react-google-maps/api';
import Loader from "../../../Components/Common/Loader";
import { withTranslation } from "react-i18next";

import {
    getCustomerById as onGetCustomerById,
    getCustomers as onGetCustomers,
    resetCustomer as onResetCustomer
} from "../../../slices/thunks";

const CustomerOverview = (props) => {
    const dispatch = useDispatch();
    const {id} = useParams();

    const [fetchedCustomer, setFetchedCustomer] = useState(null);

    // get customer by id from url params
    const selectCustomerState = (state) => state.Customer;
    const selectCustomerProperties = createSelector(
        selectCustomerState,
        (state) => ({
            customer: state.customer,
            isCustomerSuccess: state.isCustomerSuccess,
            error: state.error,
        })
    );

    const {
        customer, isCustomerSuccess, error
    } = useSelector(selectCustomerProperties);

    useEffect(() => {
        setFetchedCustomer(customer);
    }, [customer]);

    useEffect(() => {
        if (id) {
            dispatch(onGetCustomerById(id))
                .catch((error) => {
                    toast.error('Error: Customer not found',
                        {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                    console.error(error);
                });
        }
    }, [dispatch, id]);

    // reset customer state on unmount
    useEffect(() => {
        return () => {
            dispatch(onResetCustomer());
        };
    }, [dispatch]);

    useEffect(() => {
        if (isCustomerSuccess) {
            if (customer) {
                document.title = `${customer.customer_name} | Omnitouch CRM`;
            }

            if (customer?.[0]?.result === 'Failed'){
                toast.error('Error: Customer not found',
                    {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );

                dispatch(onResetCustomer());

                if (props.closeModal) {
                    props.closeModal();
                }
            }
        }
    }, [customer, isCustomerSuccess]);

    useEffect(() => {
        return () => {
            dispatch(onGetCustomers());
            document.title = "Customers | Omnitouch CRM";
        };
    }, [dispatch]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={props.t("Customer Overview")} pageTitle={props.t("Customers")} path="/customers"/>
                    {fetchedCustomer === null  || Object.keys(fetchedCustomer).length === 0? 
                        <Loader/> :
                        <Section customer={fetchedCustomer}/>
                    }
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(CustomerOverview);