import React from "react";
import { Card, CardBody, CardHeader, Row, Col, Label, Input } from "reactstrap";

const DataSelection = ({ days = 1, setDays = null, price = 10, total = 0 }) => {
    const onDaySelectionChange = (e) => {
        if (setDays) setDays(Number(e.target.value))
    };

    return (
        <React.Fragment>
            <Card className="rounded-5 pb-2 equalize-height-col-item">
                <CardHeader className="pb-0 pt-3 rounded-5">
                    <h5>Data Selection</h5>
                    <p className="text-muted">
                    </p>
                </CardHeader>

                <CardBody className="pt-0 pb-2">
                    <div className="my-3">
                        <div className="form-check form-check-inline" style={{width: "100%"}}>
                            <Label
                                    className="form-check-label"
                                    htmlFor="credit"
                                >
                                    Select Number of Days ( {days} ):
                            </Label>
                            <Input
                                id="day"
                                type="range"
                                min="1"
                                max="30"
                                value={days}
                                onChange={(e) => onDaySelectionChange(e)}
                            />
                            
                        </div>
                    </div>

                    <Row className="gy-3">
                        <Col md={12}>
                            <ul className="list-group mb-3">
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">Days of Data</h6>
                                        <small className="text-muted">
                                        </small>
                                    </div>
                                    <span className="text-muted">{days}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">Price Per Day</h6>
                                        <small className="text-muted">
                                        </small>
                                    </div>
                                    <span className="text-muted">${price}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between">
                                    <span>Total (AUD)</span>
                                    <strong>${total}</strong>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default DataSelection;