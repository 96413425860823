import React, { useState, useEffect } from 'react';
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from 'reactstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Mousewheel } from "swiper/modules";
import { useDispatch } from 'react-redux';

import { getTransactionStats } from '../../../slices/thunks';

// get Currency Symbol and Code from the environment
const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL || "$";
const currencyCode = process.env.REACT_APP_CURRENCY_CODE || "USD";

const Widgets = () => {
    const dispatch = useDispatch();
    const [transactionStats, setTransactionStats] = useState({});
    const [transactionWidgets, setTransactionWidgets] = useState([]);

    const shortenValueInteger = (value) => {
        if (value >= 1000 && value < 1000000) {
            return Math.floor(value / 1000);
        } else if (value >= 1000000 && value < 1000000000) {
            return Math.floor(value / 1000000);
        } else if (value >= 1000000000) {
            return Math.floor(value / 1000000000);
        } else {
            return value;
        }
    };

    const getShortenedDecimalValueWithSuffix = (value) => {
        if (value >= 1000 && value < 1000000) {
            const decimalPart = (value / 1000).toFixed(2).split('.')[1];
            return "." + decimalPart + "k"; // Thousands, return decimal part with "k" suffix
        } else if (value >= 1000000 && value < 1000000000) {
            const decimalPart = (value / 1000000).toFixed(2).split('.')[1];
            return "." + decimalPart + "M"; // Millions, return decimal part with "M" suffix
        } else if (value >= 1000000000) {
            const decimalPart = (value / 1000000000).toFixed(2).split('.')[1];
            return "." + decimalPart + "B"; // Billions, return decimal part with "B" suffix
        } else {
            return ".00"; // Values below 1000, default to .00
        }
    };
    const generateTransactionWidgets = (transactionStats) => {
        return [
            {
                id: 1,
                label: "Total Transactions",
                value: shortenValueInteger(transactionStats?.total_cost_of_transactions),
                decimal: getShortenedDecimalValueWithSuffix(transactionStats?.total_cost_of_transactions),
                lastValue: null,
            },
            {
                id: 2,
                label: "Total Uninvoiced Transactions",
                value: shortenValueInteger(transactionStats?.total_cost_of_uninvoiced_transactions),
                decimal: getShortenedDecimalValueWithSuffix(transactionStats?.total_cost_of_uninvoiced_transactions),
                lastValue: null,
            },
            {
                id: 3,
                label: "Total Transactions This Month",
                value: shortenValueInteger(transactionStats?.total_cost_of_transactions_this_month),
                decimal: getShortenedDecimalValueWithSuffix(transactionStats?.total_cost_of_transactions_this_month),
                lastValue: null,
            },
            {
                id: 4,
                label: "Total Transactions Last Month",
                value: shortenValueInteger(transactionStats?.total_cost_of_transactions_last_month),
                decimal: getShortenedDecimalValueWithSuffix(transactionStats?.total_cost_of_transactions_last_month),
                lastValue: null,
            },
        ];
    };

    // Fetch transaction stats
    useEffect(() => {
        dispatch(getTransactionStats())
            .then(response => {
                setTransactionStats(response.payload);
                setTransactionWidgets(generateTransactionWidgets(response.payload));
            })
            .catch(error => {
                console.error("Error fetching transaction stats: ", error);
            });
    }, [dispatch]);


    return (
        <React.Fragment>
            {transactionWidgets.map((transactionWidget, key) => (
                <Col xxl={3} md={6} key={key}>
                    <Card className="card-animate">
                        <CardBody>
                            <h3 className="mb-2">
                                <span className="counter-value" data-target={transactionWidget.value}>
                                    <CountUp start={0} end={transactionWidget.value} separator="," prefix={currencySymbol} duration={3} />
                                </span>
                                <small className="text-muted fs-13">{transactionWidget.decimal}</small></h3>
                            <h6 className="text-muted mb-0">{transactionWidget.label}</h6>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </React.Fragment>
    );
};

export default Widgets;