import { createSlice } from "@reduxjs/toolkit";

import {
  getRoles,
  getRoleById,
  addRole,
  updateRole,
  deleteRole,
} from "./thunk";

export const initialState = {
  roles: [],
  role: {},
  error: "", // for error message
  loading: false,
  errorMsg: false, // for error
};

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRoles.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.loading = false;
      state.roles = action.payload;
    });
    builder.addCase(getRoles.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || null;
    });

    builder.addCase(getRoleById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getRoleById.fulfilled, (state, action) => {
      state.loading = false;
      state.role = action.payload;
    });
    builder.addCase(getRoleById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || null;
    });

    builder.addCase(addRole.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addRole.fulfilled, (state, action) => {
      state.loading = false;
      state.role = action.payload;
    });
    builder.addCase(addRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || null;
    });

    builder.addCase(updateRole.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateRole.fulfilled, (state, action) => {
      state.loading = false;
      state.role = action.payload;
    });
    builder.addCase(updateRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || null;
    });

    builder.addCase(deleteRole.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteRole.fulfilled, (state, action) => {
      state.loading = false;
      state.role = action.payload;
    });
    builder.addCase(deleteRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || null;
    });
  }
});

export default roleSlice.reducer;