import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                             © Omnitouch. Updated 2024-09-18 16:03:27 under Commit ID: 027c936 Merge pull request #226 from Omnitouch/enhancement-208-billing-totals

Enhancement #208 billing totals by jannrocas
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
