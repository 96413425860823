import { createSlice } from "@reduxjs/toolkit";

import {
  changeUserPassword,
} from "./thunk";

export const initialState = {
  error: "",
  success: "",
  user: {},
  changePasswordSuccess: null,
  changePasswordError: null,
};

const ProfileSlice = createSlice({
  name: "Profile",
  initialState,
  reducers: { 
    profileSuccess(state, action) {
      state.success = action.payload.status;
      state.user = action.payload.data
    },
    profileError(state, action) {
        state.error = action.payload
    },
    editProfileChange(state){
      state = { ...state };
    },
    resetProfileFlagChange(state){
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(changeUserPassword.fulfilled, (state, action) => {
      state.changePasswordSuccess = action.payload.message || null;
    });
    builder.addCase(changeUserPassword.rejected, (state, action) => {
      state.changePasswordError = action.error.message || null;
    });
  }
});

export const {
    profileSuccess,
    profileError,
    editProfileChange,
    resetProfileFlagChange
} = ProfileSlice.actions

export default ProfileSlice.reducer;