import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getRoles as getRolesApi,
  addRole as createRoleApi,
  updateRole as updateRoleApi,
  deleteRole as deleteRoleApi,
  getRoleById as getRoleByIdApi,
} from "../../../helpers/backend_helper";

export const getRoles = createAsyncThunk("role/getRoles", async ({searchTerm = '', page = 1, perPage = 10, sort = 'role_id', sortOrder = 'desc', filters = {}} = {}) => {
  try {
    const response = await getRolesApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const addRole = createAsyncThunk("role/addRole", async (role) => {
  try {
    const response = await createRoleApi(role);
    return response;
  } catch (error) {
    return error;
  }
});

export const updateRole = createAsyncThunk("role/updateRole", async (role) => {
  try {
    const response = await updateRoleApi(role);
    return response;
  } catch (error) {
    return error;
  }
});

export const deleteRole = createAsyncThunk("role/deleteRole", async (roleId) => {
  try {
    const response = await deleteRoleApi(roleId);
    return {response, roleId};
  } catch (error) {
    return error;
  }
});

export const getRoleById = createAsyncThunk("role/getRoleById", async (roleId) => {
  try {
    const response = await getRoleByIdApi(roleId);
    return response;
  } catch (error) {
    return error;
  }
});

export const resetRole = createAsyncThunk("role/resetRole", async () => {
  return {};
});


