import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, FormGroup, Label, Input, Row, Col, Container, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardHeader, CardBody } from 'reactstrap';
import {
    addNewService,
    getCustomers,
    getAllProducts,
    createNewProvisioningJob,
    getProvisioningStatus,
    getInventoryByItemName,
    updateInventory
} from '../../slices/thunks';
import Carousel from '../../Components/Common/Carousel';
import ProvisioningStatus from '../Provisioning/ProvisioningStatus';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import { toast } from 'react-toastify';

const AddServiceToCustomer = () => {
    const dispatch = useDispatch();
    const { customers } = useSelector(state => state.Customer);
    const { products } = useSelector(state => state.Product);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectCustomerValidation, setSelectCustomerValidation] = useState(false);
    const [categorizedProducts, setCategorizedProducts] = useState({
      addons: {
          Individual: [],
          Business: []
      },
      service_plans: {
          Individual: [],
          Business: []
      }
    });
    const [renderedContent, setRenderedContent] = useState(null);
    const [confirmServicePlanModal, setConfirmServicePlanModal] = useState(false);
    const [additionalFields, setAdditionalFields] = useState({});
    const [additionalFieldsOptions, setAdditionalFieldsOptions] = useState({});
    const [selectedAdditionalFields, setSelectedAdditionalFields] = useState({});
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [provisioningModal, setProvisioningModal] = useState(false);
    const [provisioningJob, setProvisioningJob] = useState(null);
    const [provisioningId, setProvisioningId] = useState(null);

    const toggleProvisioningModal = () => {
        setProvisioningModal(!provisioningModal);
    };

    const handleProvisioningModalClose = () => {
        setProvisioningModal(false);
    };

    const toggleConfirmServicePlanModal = () => {
        setConfirmServicePlanModal(!confirmServicePlanModal);
    };

    const handleConfirmServicePlanModalClose = () => {
        setConfirmServicePlanModal(false);
        clearAddToCustomerForm();
    };

    const clearAddToCustomerForm = (() => {
        setSelectedProduct(null);
        setAdditionalFields({});
        setAdditionalFieldsOptions({});
        setSelectedAdditionalFields({});
    });

    const handleSoldInventory = (() => {
        Object.keys(selectedAdditionalFields).map((key, _) => {
            const date = moment().format('YYYY-MM-DD HH:mm:ss');
    
            dispatch(updateInventory({
                inventory_id: selectedAdditionalFields[key].value,
                customer_id: selectedCustomer.customer_id,
                sold_date: date
            }))
        });
    });

    useEffect(() => {
        dispatch(getCustomers());
        dispatch(getAllProducts());
    }, [dispatch]);

    useEffect(() => {
        if (products) {
            let categorized = {
                addons: {
                    Individual: [],
                    Business: []
                },
                service_plans: {
                    Individual: [],
                    Business: []
                }
            };

            products.forEach(product => {
                let category = product.category === 'addon' ? 'addons' : 'service_plans';

                if (product.business === false) {
                    categorized[category].Individual.push(product);
                } else if (product.business === true) {
                    categorized[category].Business.push(product);
                }
            });

            setCategorizedProducts(categorized);
        }
    }, [products]);

    const customerOptions = customers?.data?.map(customer => ({
        value: customer.customer_id,
        label: customer.customer_name
    }));

    const handleCustomerChange = selectedOption => {
        const selectedCustomer = customers.find(customer => customer.customer_id === selectedOption.value);
        setSelectedCustomer(selectedCustomer);
    };

    const handleAddService = (product) => {
        dispatch(addNewService({ customer_id: selectedCustomer.customer_id, product_id: product.id }));
    }

    const handleAdditionalFieldSelection = ((selectedOption, key) => {
        // Update selectedAdditionalFields
        setSelectedAdditionalFields(prevFields => ({
            ...prevFields,
            [key]: selectedOption
        }));
    });

    const handleCardAction = ((cardData) => {
        setSelectedProduct(cardData);
        if(cardData?.originalData?.inventory_items_list) {
            const itemsData = cardData.originalData.inventory_items_list;
            const dataArray = typeof itemsData === 'string' ? JSON.parse(itemsData.replace(/'/g, '"')) : itemsData;
            dataArray.forEach((item) => {
                const itemKey = item;
                dispatch(getInventoryByItemName(item))
                    .then((response) => {
                        // Update additionalFields
                        setAdditionalFields(prevFields => ({
                            ...prevFields,
                            [itemKey]: response.payload
                        }));

                        // Update additionalFieldsOptions
                        setAdditionalFieldsOptions(prevOptions => ({
                            ...prevOptions,
                            [itemKey]: response.payload.map((item) => ({value: item.inventory_id, label: item.serial1}))
                        }));
                    })
            })
        }

        toggleConfirmServicePlanModal();
    });

    const handleAddtoCustomerConfirm = (() => {
        let provisioningVars = {};
        Object.keys(selectedAdditionalFields).forEach((key) => {
            provisioningVars[key] = selectedAdditionalFields[key].value;
        });

        const provisioningData = {
            customer_id: selectedCustomer.customer_id,
            product_id: selectedProduct.originalData.product_id,
            provisioning_json_vars: JSON.stringify(provisioningVars) ?? selectedProduct.originalData.provisioning_json_vars ?? "",
            provisioning_play: selectedProduct.originalData.provisioning_play,
            site_id: null,
        };

        setProvisioningJob(provisioningData);

        handleSoldInventory();

        dispatch(createNewProvisioningJob(provisioningData))
            .then((response) => {
                if (!response.payload.provision_id) {
                    console.log('Provisioning Job Error', response.payload.error);
                    toast.error('Provisioning failed. Please contact the administrator for more information.', {autoClose: 3000});
                } else {
                    setProvisioningId(response.payload.provision_id);
                    toggleProvisioningModal();
                }

                clearAddToCustomerForm();
            })
            .catch((error) => {
                console.log('Provisioning Job Error', error);
                toast.error('Provisioning failed. Please contact the administrator for more information.', {autoClose: 3000});
            });

        handleConfirmServicePlanModalClose();
    });

    const prepareData = ((data) => {
        return data.map((item, index) => {
            return {
                title: item.product_name,
                avatar: item.avatar,
                component: prepareBody(item.features_list ?? null),
                description: item.description,
                index: index,
                originalData: item
            }
        });
    });

    const prepareBody = ((dataBody) => {
        if (!dataBody) {
            return null;
        }
    
        // Replace single quotes with double quotes and parse dataBody if it's a string representation of an array
        const dataArray = typeof dataBody === 'string' ? JSON.parse(dataBody.replace(/'/g, '"')) : dataBody;
    
        return (
            <div className='mt-auto'>
                <ul>
                    {dataArray.map((feature, index) => {
                        return (
                            <li key={index}>{feature}</li>
                        );
                    })}
                </ul>
            </div>
        );
    });

    const renderCarousels = (servicePlans = [], addOns = [], carouselType = 'Individual', theme = 'card-primary', darken = false) => {
        return (
            <>
                <Card>
                    <CardHeader>
                        <h4 className="card-title mb-1 text-center">{carouselType === 'Individual' ? 'Individual' : 'Business' } Service Plans</h4>
                        <p className='text-center text-muted mb-4'>Service plans with inclusions</p>
                    </CardHeader>
                    <CardBody>
                        <Carousel
                            title={null}
                            action={handleCardAction}
                            data={prepareData(servicePlans)}
                            carouselType="plans"
                            theme={theme}
                            darken={darken}
                            footerText='Add to Customer'
                        />
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>
                        <h4 className="card-title mb-1 text-center">{carouselType === 'Individual' ? 'Individual' : 'Business' } Addon Packs</h4>
                        <p className='text-center text-muted mb-4'>Addon packs with inclusions</p>
                    </CardHeader>
                    <CardBody>
                        <Carousel
                            title={null}
                            action={handleCardAction}
                            data={prepareData(addOns)}
                            carouselType="plans"
                            theme={theme}
                            darken={darken}
                            footerText='Add to Customer'
                        />
                    </CardBody>
                </Card>
            </>
        );
    };

    const loadOptions = (inputValue, callback) => {
        dispatch(getCustomers({searchTerm: inputValue}))
            .then((response) => {
                const options = response.payload.data.map(customer => ({ value: customer.customer_id, label: customer.customer_name }));
                callback(options);
            });
    };

    useEffect(() => {
        console.log('selectedCustomer', selectedCustomer);
        if (selectedCustomer && selectedCustomer.tax_identifier) {
          setRenderedContent(
            renderCarousels(
                categorizedProducts.service_plans.Business,
                categorizedProducts.addons.Business,
                'Business',
                'card-success',
                true
            )
        );
        } else {
          setRenderedContent(
            renderCarousels(
                categorizedProducts.service_plans.Individual,
                categorizedProducts.addons.Individual,
                'Individual'
            )
        );
        }
      }, [categorizedProducts, selectedCustomer]);

    document.title = 'Add Service | Omnitouch CRM';
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Form>
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Col md={6}>
                            <FormGroup>
                                <AsyncSelect
                                    loadOptions={loadOptions}
                                    onChange={(selectedOption) => {
                                        setSelectedCustomer(selectedOption);
                                        setSelectCustomerValidation(selectedOption.value !== null);
                                    }}
                                    styles={{
                                        menu: provided => ({ ...provided, zIndex: 2 })
                                    }}
                                />
                                {!selectCustomerValidation && <div className="alert alert-danger">Please select a customer</div>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            { selectedCustomer && renderedContent}
                        </Col>
                    </Row>
                    </Form>
                </Container>
                <Modal isOpen={confirmServicePlanModal} toggle={handleConfirmServicePlanModalClose} centered>
                    <ModalHeader toggle={handleConfirmServicePlanModalClose}>Confirm Service Plan</ModalHeader>
                    <ModalBody>
                        <p>Are you sure you want to add this service plan to the customer?</p>

                        {/* Additional Fields */}
                        {Object.keys(additionalFieldsOptions).map((key, index) => {
                                return (
                                    <>
                                    <Label key={key+index} className='mb-2'>{key}</Label>
                                    <Select className='mb-3' key={index} placeholder={key} onChange={(selectedOption) => handleAdditionalFieldSelection(selectedOption, key)} options={additionalFieldsOptions[key]} />
                                    </>
                                );
                            })}
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-danger" onClick={handleConfirmServicePlanModalClose}>Cancel</button>
                        <button type="button" className="btn btn-success" onClick={handleAddtoCustomerConfirm}>Confirm</button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={provisioningModal} toggle={toggleProvisioningModal} size='lg' centered>
                    <ModalHeader toggle={handleProvisioningModalClose}>Provisioning Status</ModalHeader>
                    <ModalBody>
                        <ProvisioningStatus provision_id={provisioningId} newProvisioning={true}/>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-danger" onClick={handleProvisioningModalClose}>Close</button>
                    </ModalFooter>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default AddServiceToCustomer;