// SelfCare/components/Layout.js

import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';

import HeaderNav from './HeaderNav';

import {
    getCustomerById,
    resetCustomer
} from '../../../slices/thunks';

const Layout = ({ children }) => {
    const dispatch = useDispatch();

    const [customer, setCustomer] = useState(null);
    // get customer id from session storage authUser.data.customerId
    const authUser = JSON.parse(sessionStorage.getItem('authUser'));
    const customerId = authUser?.data?.customer_id ?? null;

    useEffect(() => {
        if (customerId) {
            dispatch(getCustomerById(customerId))
                .then((response) => {
                    const customer = response.payload;
                    setCustomer(customer);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [customerId]);
    
    useEffect(() => {
        return () => {
            dispatch(resetCustomer());
        };
    }, []);

    const childrenWithProps = React.Children.map(children, child => {
        return React.cloneElement(child, { customer });
    });

    return (
        <div className="page-content">
            <HeaderNav customer={customer}/>
            <Container fluid className="self-care-content">
                {childrenWithProps}
            </Container>
            <footer className="self-care-footer">
                <Row>
                    <Col lg={12}>
                        <div className="text-center">
                            <p className="mb-0 text-muted">&copy; {new Date().getFullYear()} <a href='https://omnitouch.com.au' target='_blank'>Omnitouch Network Service Pty Ltd</a></p>
                            </div>
                    </Col>
                </Row>
            </footer>
        </div>
    );
};

export default Layout;
