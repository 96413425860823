import React, { useState, useEffect, useRef, useCallback } from 'react';
import { GoogleMap, Polygon, Circle, DrawingManager } from '@react-google-maps/api';
import data from './data.json';
import { Container, Row, Col, Table, Button, Card, CardBody, CardHeader } from 'reactstrap';
import { set } from 'lodash';

const mapContainerStyle = {
  width: '100%',
  height: '600px',
};

const calculateSectorPoints = (lat, lng, direction, distance = 0.001) => {
  const radian = (direction * Math.PI) / 180;
  const newLat = lat + distance * Math.cos(radian);
  const newLng = lng + distance * Math.sin(radian);
  return { lat: newLat, lng: newLng };
};

const formatPolygonData = (paths, area) => {
  const formattedPaths = paths.map(coord => ({ lat: coord.lat, lng: coord.lng }));
  return {
    area: area,
    polygon: [
      {
        coord: formattedPaths
      }
    ]
  };
};

const createArrowIcon = (sector) => ({
  path: 'M0,-1 L1,1 L-1,1 Z',
  fillColor: 'red',
  fillOpacity: 1,
  scale: 5,
  strokeColor: 'black',
  strokeWeight: 1,
  rotation: sector + 180,
});

const cellTowerIcon = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iY3VycmVudENvbG9yIj48cGF0aCBkPSJNNi4xMTYyOSAyMC4wODY4QzMuNjIxMzcgMTguMjY4NCAyIDE1LjMyMzYgMiAxMkMyIDYuNDc3MTUgNi40NzcxNSAyIDEyIDJDMTcuNTIyOCAyIDIyIDYuNDc3MTUgMjIgMTJDMjIgMTUuMzIzNiAyMC4zNzg2IDE4LjI2ODQgMTcuODgzNyAyMC4wODY4TDE2Ljg2OTIgMTguMzQ4QzE4Ljc3MjkgMTYuODg1NiAyMCAxNC41ODYxIDIwIDEyQzIwIDcuNTgxNzIgMTYuNDE4MyA0IDEyIDRDNy41ODE3MiA0IDQgNy41ODE3MiA0IDEyQzQgMTQuNTg2MSA1LjIyNzEgMTYuODg1NiA3LjEzMDggMTguMzQ4TDYuMTE2MjkgMjAuMDg2OFpNOC4xNDk2NSAxNi42MDE4QzYuODM1NjIgMTUuNTAxMiA2IDEzLjg0ODIgNiAxMkM2IDguNjg2MjkgOC42ODYyOSA2IDEyIDZDMTUuMzEzNyA2IDE4IDguNjg2MjkgMTggMTJDMTggMTMuODQ4MiAxNy4xNjQ0IDE1LjUwMTIgMTUuODUwMyAxNi42MDE4TDE0LjgyMDMgMTQuODM2NUMxNS41NDkgMTQuMTEyIDE2IDEzLjEwODcgMTYgMTJDMTYgOS43OTA4NiAxNC4yMDkxIDggMTIgOEM5Ljc5MDg2IDggOCA5Ljc5MDg2IDggMTJDOCAxMy4xMDg3IDguNDUxMDUgMTQuMTEyIDkuMTc5NjUgMTQuODM2NUw4LjE0OTY1IDE2LjYwMThaTTExIDEzSDEzTDE0IDIySDEwTDExIDEzWiI+PC9wYXRoPjwvc3ZnPg==';

const GoogleMapComponent = () => {
    const [isLoaded, setIsLoaded] = useState(false); 
    const loadError = false;

  const [map, setMap] = useState(null);
  const [polygon, setPolygon] = useState([]);
  const [circles, setCircles] = useState([]);
  const [rectangles, setRectangles] = useState([]);
  const [polygonData, setPolygonData] = useState(data);
  const [zoneIndex, setZoneIndex] = useState(null);
  const [addNewZone, setAddNewZone] = useState(false);
  const [cellSites, setCellSites] = useState([]);
  const [insidePolygonSites, setInsidePolygonSites] = useState([]);
  const [formattedPolygonData, setFormattedPolygonData] = useState(null);
  const [drawingMode, setDrawingMode] = useState(null);
  const polygonRef = useRef([]);
  const drawingManagerRef = useRef(null);

  useEffect(() => {
    setIsLoaded(false);

    fetch('/cellSites.txt')
      .then(response => response.text())
      .then(data => {
        const sites = data.split('\n').map(line => {
          const [lat, lng, name, ...sectors] = line.split(',');
          return { lat: Number(lat), lng: Number(lng), name, sectors: sectors.map(Number) };
        }).filter(site => !isNaN(site.lat) && !isNaN(site.lng));
        setCellSites(sites);
        setIsLoaded(true);

      })
      .catch(error => console.error('Error fetching cell sites:', error));
  }, []);

  const handleMapLoad = useCallback((map) => {
    setMap(map);
    if (cellSites.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      cellSites.forEach(site => {
        bounds.extend(new window.google.maps.LatLng(site.lat, site.lng));
      });
      map.fitBounds(bounds);
    }
  }, [cellSites]);

  const zoneBtnClicked = (zone, index) => {
        setZoneIndex(index);

        if (zone?.polygon) {
            setCircles([]); // Clear circles
            // Handle polygon
            setPolygon(zone.polygon);

            const formattedData = formatPolygonData(zone.polygon[0].coord, zone.area);
            setFormattedPolygonData(formattedData);

            checkCellSitesInsidePolygons(zone.polygon);
        } else if (zone?.circle) {
            // Handle circle
            setPolygon([]); // Clear polygons
            const formattedData = {
            area: zone.area,
            circle: zone.circle,
            };
            setFormattedPolygonData(formattedData);

            // Check cell sites inside the circle
            const sitesInside = cellSites.filter(site => {
            const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
                new window.google.maps.LatLng(site.lat, site.lng),
                new window.google.maps.LatLng(zone.circle.center.lat, zone.circle.center.lng)
            );
            return distance <= zone.circle.radius;
            });
            setInsidePolygonSites(sitesInside);
            setCircles([formattedData.circle]);
        } else {
            // If zone is not defined or doesn't have polygon or circle
            setPolygon([]);
            setCircles([]);
            setFormattedPolygonData(null);
            setInsidePolygonSites([]);
        }
    };

  const newZoneBtnClicked = () => {
    setAddNewZone(true);
    setDrawingMode('polygon');
  };

  const clearPolygons = () => {
    setPolygon([]);
    setCircles([]);
    setRectangles([]);
    setInsidePolygonSites([]);
    setFormattedPolygonData(null);
    if (drawingManagerRef.current) {
      drawingManagerRef.current.setMap(null);
    }
  };

  const onPolygonComplete = (polygon) => {
    const paths = polygon.getPath().getArray().map(coord => ({
      lat: coord.lat(),
      lng: coord.lng()
    }));

    const newPolygon = [{ coord: paths }];
    setPolygon(newPolygon);
    setAddNewZone(false);

    checkCellSitesInsidePolygons(newPolygon);

    const formattedData = formatPolygonData(paths, `Zone ${polygonData.length + 1}`);
    setFormattedPolygonData(formattedData);

    setPolygonData([...polygonData, formattedData]);
    polygon.setMap(null); // Remove the temporary polygon drawn
  };

  const onCircleComplete = (circle) => {
    const center = circle.getCenter();
    const radius = circle.getRadius();

    const formattedData = {
      area: `Zone ${polygonData.length + 1}`,
      circle: {
        center: { lat: center.lat(), lng: center.lng() },
        radius
      }
    };


    setPolygonData([...polygonData, formattedData]);
    setFormattedPolygonData(formattedData);
    setAddNewZone(false);

    const sitesInside = cellSites.filter(site => {
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng(site.lat, site.lng),
        center
      );
      return distance <= radius;
    });
    setInsidePolygonSites(sitesInside);
    circle.setMap(null); // Remove the temporary circle drawn
    setCircles([...circles, formattedData.circle]);
  };

  const onRectangleComplete = (rectangle) => {
    const bounds = rectangle.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const rectanglePaths = [
      { lat: ne.lat(), lng: sw.lng() },
      { lat: ne.lat(), lng: ne.lng() },
      { lat: sw.lat(), lng: ne.lng() },
      { lat: sw.lat(), lng: sw.lng() }
    ];

    const newRectangle = [{ coord: rectanglePaths }];
    setPolygon(newRectangle);
    setAddNewZone(false);

    checkCellSitesInsidePolygons(newRectangle);

    const formattedData = formatPolygonData(rectanglePaths, `Zone ${polygonData.length + 1}`);
    setFormattedPolygonData(formattedData);

    setPolygonData([...polygonData, formattedData]);
    rectangle.setMap(null); // Remove the temporary rectangle drawn
  };

  const checkCellSitesInsidePolygons = (polygons) => {
    if (!window.google || !window.google.maps || !window.google.maps.geometry) {
      console.error('Google Maps API not fully loaded.');
      return;
    }

    const allSitesInside = [];
    polygons.forEach(polygon => {
      const polygonBounds = new window.google.maps.Polygon({
        paths: polygon.coord.map(coord => new window.google.maps.LatLng(coord.lat, coord.lng))
      });
      const sitesInside = cellSites.filter(site => {
        const siteLatLng = new window.google.maps.LatLng(site.lat, site.lng);
        return window.google.maps.geometry.poly.containsLocation(siteLatLng, polygonBounds);
      });
      allSitesInside.push(...sitesInside);
    });

    setInsidePolygonSites(allSitesInside);
  };

  const onMousedown = (ref) => {
    if (!window.google || !window.google.maps) {
      console.error('Google Maps API not fully loaded.');
      return;
    }
    const polygon = polygonRef.current[ref];
    if (polygon && polygon.getPath) {
      const path = polygon.getPath();
      window.google.maps.event.addListener(path, 'set_at', () => {
        getPolygonNewPaths(path, ref);
      });
      window.google.maps.event.addListener(path, 'insert_at', () => {
        getPolygonNewPaths(path, ref);
      });
      window.google.maps.event.addListener(path, 'remove_at', () => {
        getPolygonNewPaths(path, ref);
      });
    }
  };

  const getPolygonNewPaths = (polygon, ref) => {
    let polygonPaths = [];
    polygon.getArray().forEach((path) => {
      const line = {
        lat: path.lat(),
        lng: path.lng()
      };
      polygonPaths.push(line);
    });
    const newPolygon = [...polygonData];
    newPolygon[zoneIndex].polygon[ref].coord = polygonPaths;
    setPolygonData(newPolygon);

    const formattedData = formatPolygonData(polygonPaths, `Zone ${zoneIndex + 1}`);
    setFormattedPolygonData(formattedData);
  };

  const polyRef = (ref) => {
    polygonRef.current.push(ref);
  };

  const totalSites = cellSites.length;
  const totalSectors = cellSites.reduce((acc, site) => acc + site.sectors.length, 0); // Count sectors correctly

  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-content">
        <Container fluid>
            <h3>Cell Broadcast Map</h3>
            <Row className="my-3">
                <Col>
                {polygonData.map((zone, index) => (
                    <Button
                    key={index}
                    color="primary"
                    className="me-2"
                    onClick={() => zoneBtnClicked(zone, index)}
                    >
                    {zone.area}
                    </Button>
                ))}
                <Button color="success" onClick={newZoneBtnClicked}>Add New Zone</Button>
                <Button color="danger" className="ms-2" onClick={clearPolygons}>Clear</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    onLoad={handleMapLoad}
                    options={{ 
                    mapId: 'bac3aaeb5067a638' // Use your actual Map ID
                    }}
                >
                    {isLoaded && window.google && cellSites.map((site, index) => {
                    const sitePosition = { lat: site.lat, lng: site.lng };
                    
                    // Create the HTML element for the marker content
                    const markerContent = document.createElement('div');
                    markerContent.style.backgroundColor = 'white';
                    markerContent.style.padding = '5px';
                    markerContent.style.border = '1px solid black';
                    markerContent.innerText = site.name;
                    
                    // Create AdvancedMarkerElement for the main point
                    new window.google.maps.marker.AdvancedMarkerElement({
                        map: map,
                        position: sitePosition,
                        title: site.name,
                        content: markerContent,
                        zIndex: 2, // Ensure label is on top
                    });

                    if (site.sectors.length === 0) {
                        // Use a larger circle icon for points with no sectors
                        const circleContent = document.createElement('div');
                        circleContent.style.width = '30px';
                        circleContent.style.height = '30px';
                        circleContent.style.backgroundColor = 'red';
                        circleContent.style.borderRadius = '50%';
                        circleContent.style.position = 'absolute';
                        circleContent.style.transform = 'translate(-50%, -50%)';
                        circleContent.style.zIndex = 1; // Ensure circle is behind the label

                        // Create AdvancedMarkerElement for the circle icon
                        new window.google.maps.marker.AdvancedMarkerElement({
                        map: map,
                        position: sitePosition,
                        title: site.name,
                        content: circleContent,
                        zIndex: 1, // Ensure circle is behind the label
                        });

                        return null;
                    } else {
                        // Create markers for each sector
                        return site.sectors.map((sector, sectorIndex) => {
                        const arrowPosition = calculateSectorPoints(site.lat, site.lng, sector, 0.0005);

                        // Create SVG icon for the arrow
                        const arrowIcon = createArrowIcon(sector);

                        // Create Marker for the arrow
                        new window.google.maps.Marker({
                            map: map,
                            position: arrowPosition,
                            icon: {
                                url: cellTowerIcon,
                                scaledSize: new window.google.maps.Size(30, 30),
                                anchor: new window.google.maps.Point(15, 15),
                              },
                            title: `Arrow for ${site.name} sector ${sectorIndex + 1}`,
                        });

                        return null;
                        });
                    }
                    })}

                    {polygon.map((poly, index) => (
                    <Polygon
                        key={index}
                        ref={ref => polyRef(ref)}
                        paths={poly.coord}
                        onMouseDown={() => onMousedown(index)}
                        editable
                    />
                    ))}

                    {circles.map((circle) => (
                        <Circle
                            key={circle.id}
                            center={circle.center}
                            radius={circle.radius}
                            options={{
                            editable: circle.editable,
                            draggable: circle.draggable,
                            }}
                            onRightClick={() => handleCircleRightClick(circle.id)}
                        />
                    ))}

                    {addNewZone && (
                    <DrawingManager
                        ref={drawingManagerRef}
                        drawingMode={drawingMode}
                        options={{
                        drawingControl: true,
                        drawingControlOptions: {
                            position: window.google.maps.ControlPosition.TOP_CENTER,
                            drawingModes: ['polygon', 'circle', 'rectangle']
                        },
                        polygonOptions: { editable: true, draggable: true },
                        circleOptions: { editable: true, draggable: true },
                        rectangleOptions: { editable: true, draggable: true }
                        }}
                        onPolygonComplete={onPolygonComplete}
                        onCircleComplete={onCircleComplete}
                        onRectangleComplete={onRectangleComplete}
                    />
                    )}
                </GoogleMap>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                <h3>Summary</h3>
                <p>Total Sites: {totalSites}</p>
                <p>Total Sectors: {totalSectors}</p>
                </Col>
            </Row>
            {insidePolygonSites?.length > 0 && (
                <Row>
                <Col>
                    <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>Name</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                        <th># of Sectors</th>
                        </tr>
                    </thead>
                    <tbody>
                        {insidePolygonSites?.map((site, index) => (
                        <tr key={index}>
                            <td>{site?.name}</td>
                            <td>{site?.lat}</td>
                            <td>{site?.lng}</td>
                            <td>{site?.sectors?.length}</td>
                        </tr>
                        ))}
                    </tbody>
                    </Table>
                </Col>
                </Row>
            )}
            {formattedPolygonData && (
                <Row>
                <Col>
                    <Card>
                    <CardHeader>Formatted Polygon Data</CardHeader>
                    <CardBody>
                        <pre>{JSON.stringify(formattedPolygonData, null, 2)}</pre>
                    </CardBody>
                    </Card>
                </Col>
                </Row>
            )}
        </Container>
    </div>
  );
};

export default GoogleMapComponent;
