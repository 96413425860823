import { createSlice } from "@reduxjs/toolkit";
import {
    getServices,
    addNewService,
    deleteService,
    getServicesByCustomerId,
    updateService,
    getServiceById,
    getSimpleServiceById,
    deleteActionPlanFromServiceUUID,
    getServicesByMSISDN,
    deactivateServiceByUUID,
} from './thunk';

export const initialState = {
    services: [],
    service: {},
    isServiceCreated: false,
    error: null,
};

const ServiceSlice = createSlice({
    name: 'ServiceSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getServices.fulfilled, (state, { payload }) => {
            state.services = payload;
        });
        builder.addCase(getServices.rejected, (state, { payload }) => {
            state.error = payload;
        });
        builder.addCase(addNewService.fulfilled, (state, { payload }) => {
            state.createdService = payload;
            state.isServiceCreated = true;
        });
        builder.addCase(addNewService.rejected, (state, { payload }) => {
            state.error = payload;
        });
        builder.addCase(deleteService.fulfilled, (state, { payload }) => {
            state.deletedService = payload;
        });
        builder.addCase(deleteService.rejected, (state, { payload }) => {
            state.error = payload;
        });
        builder.addCase(getServicesByCustomerId.fulfilled, (state, { payload }) => {
            state.services = payload;
        });
        builder.addCase(getServicesByCustomerId.rejected, (state, { payload }) => {
            state.error = payload;
        });
        builder.addCase(updateService.fulfilled, (state, { payload }) => {
            state.service = payload;
        });
        builder.addCase(updateService.rejected, (state, { payload }) => {
            state.error = payload;
        });
        builder.addCase(getServiceById.fulfilled, (state, { payload }) => {
            state.service = payload;
        });
        builder.addCase(getSimpleServiceById.fulfilled, (state, { payload }) => {
            state.service = payload;
        });
        builder.addCase(getSimpleServiceById.rejected, (state, { payload }) => {
            state.error = payload;
        });
        builder.addCase(getServiceById.rejected, (state, { payload }) => {
            state.error = payload;
        });
        builder.addCase(deleteActionPlanFromServiceUUID.fulfilled, (state, { payload }) => {
            state.service = payload;
        });
        builder.addCase(deleteActionPlanFromServiceUUID.rejected, (state, { payload }) => {
            state.error = payload;
        });
        builder.addCase(getServicesByMSISDN.fulfilled, (state, { payload }) => {
            state.service = payload;
        });
        builder.addCase(getServicesByMSISDN.rejected, (state, { payload }) => {
            state.error = payload;
        });
        builder.addCase(deactivateServiceByUUID.fulfilled, (state, { payload }) => {
            state.service = payload;
        });
        builder.addCase(deactivateServiceByUUID.rejected, (state, { payload }) => {
            state.error = payload;
        });
    }
});

export default ServiceSlice.reducer;