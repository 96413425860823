import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    getAllInventoryItems,
    deleteInventory,
    updateInventory,
    getCustomers,
    getAllInventoryTemplates,
    getSimpleServiceById,
} from '../../slices/thunks';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, FormGroup, Label, Button } from 'reactstrap';
import TableCommon from '../../Components/Common/TableCommon';
import DeleteModal from '../../Components/Common/DeleteModal';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import InventoryCreate from './InventoryCreate';
import AsyncSelect from 'react-select/async';
import * as Icons from 'react-icons/fa';
import ServiceView from "../Services/components/ServiceView";


const InventoryList = () => {
    const dispatch = useDispatch();

    const [inventoryItems, setInventoryItems] = React.useState([]);
    const [formattedInventoryItems, setFormattedInventoryItems] = React.useState([]);
    const [pagination, setPagination] = React.useState({
        currentPage: 1,
        itemsPerPage: 10,
        totalRecords: 0,
        totalPages: 0,
    });
    const [sortConfig, setSortConfig] = React.useState({ key: 'inventory_id', direction: 'desc' });
    const sortableColumns = [ 'customer_id', 'inventory_id', 'service_id', 'item', 'item_location', 'retail_cost', 'Serial 1', 'Serial 2', 'wholesale_cost' ];

    const [deleteModal, setDeleteModal] = React.useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = React.useState(false);
    const [selectedIds, setSelectedIds] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [addModal, setAddModal] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [editModal, setEditModal] = React.useState(false);
    const [assignModal, setAssignModal] = React.useState(false);
    const [selectedCustomer, setSelectedCustomer] = React.useState(null);
    const [selectCustomerValidation, setSelectCustomerValidation] = React.useState(false);
    const [templates, setTemplates] = React.useState([]);
    const [filterOptions, setFilterOptions] = React.useState({});
    const [filters, setFilters] = React.useState({});
    const [selectedService, setSelectedService] = React.useState(null);
    const [serviceModal, setServiceModal] = React.useState(false);

    const onViewService = (serviceId) => {
        dispatch(getSimpleServiceById(serviceId))
            .then((response) => {
                setSelectedService(response.payload);
                setServiceModal(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const toggleAddModal = () => setAddModal(!addModal);
    const toggleEditModal = () => setEditModal(!editModal);
    const toggleAssignModal = () => setAssignModal(!assignModal);

    const handleAssignModalClose = () => {
        setAssignModal(false);
        setSelectedCustomer(null);
        setSelectCustomerValidation(false);
    };

    const handleEditInventory = (inventory) => {
        setSelectedItem(inventory);
        toggleEditModal();
    };

    const handleEditInventoryRow = (inventoryId) => {
        setSelectedItem(inventoryItems.find(inventory => inventory.inventory_id === inventoryId));
        toggleEditModal();
    };

    const handleSaveInventory = () => {
        dispatch(updateInventory(selectedItem))
            .then((response) => {
                toast.success('Inventory item updated successfully');
                fetchInventory();
            })
            .catch((error) => {
                console.log('Error', error);
            });
        toggleEditModal();
    };

    const handleDeleteInventory = () => {
        dispatch(deleteInventory(selectedItem.inventory_id))
            .then((response) => {
                toast.success('Inventory item deleted successfully');
                fetchInventory();
            })
            .catch((error) => {
                console.log('Error', error);
            });
        setDeleteModal(false);
    };

    const handleDeleteMultiple = () => {
        if (selectedIds.length === 0) {
            toast.error('Please select at least one record to delete');
            return;
        }
        Promise.all(selectedIds.map((id) => dispatch(deleteInventory(id))))
            .then(() => {
                toast.success('Inventory item deleted successfully');
                fetchInventory();
            })
            .catch((error) => {
                console.log('Error', error);
            });
    };

    const handleDeleteMultipleInventory = (ids) => {
        setSelectedIds(ids);
        setDeleteModalMulti(true);
    };

    const handleFilterChange = (field, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [field]: [value],
        }));
    };

    const applyFilters = () => {
        fetchInventory();
    };

    const resetFilters = () => {
        setFilters({});
    };

    // for use in reset filters to refetch inventory
    useEffect(() => {
        if (Object.keys(filters).length === 0) {
            fetchInventory();
        }
    }, [filters]);

    const handleView = (item) => {
        // redirect to customer detail page
        window.location.href = `/customers/${item.customer_id}#8`;
    };

    const handleAssign = (item) => {
        setSelectedItem(item);
        setAssignModal(true);
    };

    const onAssignToCustomerConfirm = () => {
        if (selectedCustomer && selectedCustomer.value) {
            dispatch(updateInventory({ ...selectedItem, customer_id: selectedCustomer.value }))
                .then(() => {
                    toast.success('Inventory Item Assigned Successfully');
                    fetchInventory();
                    setAssignModal(false);
                })
                .catch((error) => {
                    toast.error('Inventory Item Assign Failed');
                    console.error(error);
                });
        }
    };

    const loadOptions = (inputValue, callback) => {
        dispatch(getCustomers({searchTerm: inputValue}))
            .then((response) => {
                const options = response.payload.data.map(customer => ({ value: customer.customer_id, label: customer.customer_name }));
                callback(options);
            });
    };

    const iconClassToComponentName = (iconClass) => {
        if (!iconClass) return null;
        const parts = iconClass.split(' ');
        if (parts.length < 1) return null;
        const iconName = parts[parts.length - 1].slice(3); // remove 'fa-' prefix
        const finalIconName = iconName
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join('');
        return `Fa${finalIconName}`;
    
    };

    const fetchInventory = (currentPage = pagination.currentPage, itemsPerPage = pagination.itemsPerPage, key = sortConfig.key, direction = sortConfig.direction, search = searchTerm) => {
        // map key to actual field names
        if (key === 'Serial 1') {
            key = 'serial1';
        } else if (key === 'Serial 2') {
            key = 'serial2';
        }
        
        dispatch(getAllInventoryItems({ currentPage, itemsPerPage, key, direction, searchTerm: search, filters: filters}))
            .then((response) => {
                setInventoryItems(response.payload.data);
                if (response.payload.pagination) {
                    setPagination({
                        currentPage: response.payload.pagination.page,
                        itemsPerPage: response.payload.pagination.per_page,
                        totalRecords: response.payload.pagination.total_records,
                        totalPages: response.payload.pagination.total_pages,
                    });
                }
                setSortConfig({ key, direction });
                setSearchTerm(search);
            })
            .catch((error) => {
                console.log('Error', error);
            });
    };

    const fetchTemplates = () => {
        dispatch(getAllInventoryTemplates())
            .then((response) => {
                setTemplates(response.payload);

                // set filter options
                const options = response.payload.map(template => ({ value: template.inventory_template_id, label: template.item }));
                setFilterOptions(prevOptions => ({ ...prevOptions, inventory_template_id: { options } }));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        fetchInventory();
        fetchTemplates();
    }, []);

    useEffect(() => {
        const formattedInventoryItems = inventoryItems.map((item) => {
            const template = templates.find(template => template.inventory_template_id === item.inventory_template_id);
            const iconComponentName = iconClassToComponentName(template?.icon);
            const IconComponent = Icons[iconComponentName];
            const icon = IconComponent ? <IconComponent /> : null;
            return {
                icon: icon,
                'id': item.inventory_id,
                customer_id: item.customer_id,
                service_id: item.service_id,
                'item': item.item,
                'item_location': item.item_location,
                'retail_cost': item.retail_cost,
                'Serial 1': item.serial1,
                'Serial 2': item.serial2,
                'wholesale_cost': item.wholesale_cost,
                actions: (
                    <ul className="list-inline hstack gap-2 mb-0">
                        <li className="list-inline-item edit">
                            <Link
                                title='Edit Inventory'
                                className="text-primary d-inline-block edit-item-btn"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleEditInventory(item);
                                }}
                            >
                                <i className="ri-pencil-fill fs-16"></i>
                            </Link>
                        </li>
                        <li className="list-inline-item">
                            <Link
                                title='Delete Inventory'
                                className="text-danger d-inline-block remove-item-btn"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedItem(item);
                                    setDeleteModal(true);
                                }}
                            >
                                <i className="ri-delete-bin-5-fill fs-16"></i>
                            </Link>
                        </li>
                        { item.customer_id ? (
                            <li className="list-inline-item">
                                <Link
                                    className="text-primary d-inline-block"
                                    title='View'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleView(item);
                                    }}
                                >
                                <i className="ri-eye-fill fs-16"></i>
                                </Link>
                            </li>
                        ) : (
                            <li className="list-inline-item">
                                <Link
                                    title='Assign to Customer'
                                    className="text-success d-inline-block remove-item-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleAssign(item);
                                    }}
                                >
                                <i className="ri-user-add-fill fs-16"></i>
                                </Link>
                            </li>
                        )}
                        { item.service_id && (
                            <li className="list-inline-item">
                                <Link
                                    className='text-primary d-inline-block'
                                    title='View Service'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onViewService(item.service_id);
                                    }
                                }>
                                    <i className="ri-tools-fill fs-16"></i>
                                </Link>
                            </li>
                        )}
                    </ul>
                ),
            };
        });
        setFormattedInventoryItems(formattedInventoryItems);
    }, [inventoryItems]);

    return (
        <div className='page-content'>
            <Container fluid>
                <BreadCrumb title='Inventory List' pageTitle={'Inventory'}/>
                {/* Assign Modal React Select */}
                <Modal isOpen={assignModal} toggle={toggleAssignModal} size='lg' centered>
                    <ModalHeader toggle={toggleAssignModal}>Assign Inventory Item to Customer</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="customer_id">Select Customer:</Label>
                                    <AsyncSelect
                                        loadOptions={loadOptions}
                                        onChange={(selectedOption) => {
                                            setSelectedCustomer(selectedOption);
                                            setSelectCustomerValidation(selectedOption.value !== null);
                                        }}
                                    />
                                </FormGroup>
                                {!selectCustomerValidation && <div className="alert alert-danger">Please select a customer</div>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-end gap-2">
                                <Button type="submit" className='btn-success mr-2' onClick={onAssignToCustomerConfirm}>Assign</Button>
                                <Button type="submit" className='btn-danger' onClick={handleAssignModalClose}>Cancel</Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Modal isOpen={addModal} toggle={toggleAddModal} centered>
                    <ModalBody>
                        <InventoryCreate page={false}/>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-primary" onClick={() => {
                            toggleAddModal();
                            fetchInventory();
                        }}>
                            Close
                        </button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={editModal} toggle={toggleEditModal}>
                    <ModalHeader toggle={toggleEditModal}>Edit Inventory Item</ModalHeader>
                    <ModalBody>
                        <form>
                            <div className="form-group">
                                <label>Item</label>
                                <input type="text" className="form-control" value={selectedItem?.item} onChange={(e) => setSelectedItem({...selectedItem, item: e.target.value})} />
                            </div>
                            <div className="form-group">
                                <label>Item Location</label>
                                <input type="text" className="form-control" value={selectedItem?.item_location} onChange={(e) => setSelectedItem({...selectedItem, item_location: e.target.value})} />
                            </div>
                            <div className="form-group">
                                <label>Serial 1</label>
                                <input type="text" className="form-control" value={selectedItem?.serial1} onChange={(e) => setSelectedItem({...selectedItem, serial1: e.target.value})} />
                            </div>
                            <div className="form-group">
                                <label>Serial 2</label>
                                <input type="text" className="form-control" value={selectedItem?.serial2} onChange={(e) => setSelectedItem({...selectedItem, serial2: e.target.value})} />
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-secondary" onClick={toggleEditModal}>
                            Close
                        </button>
                        <button type="button" className="btn btn-success" onClick={handleSaveInventory}>
                            Save
                        </button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={serviceModal} toggle={() => setServiceModal(false)} size='lg'>
                    <ModalHeader toggle={() => setServiceModal(false)}>Service Details</ModalHeader>
                    <ModalBody>
                        <ServiceView service={selectedService} onClose={() => { setSelectedService(null); setServiceModal(false); }}/>
                    </ModalBody>
                </Modal>

                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteInventory}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => {
                        handleDeleteMultiple();
                        setDeleteModalMulti(false);
                    }}
                    onCloseClick={() => setDeleteModalMulti(false)}
                />
                <TableCommon
                    title={'Inventory Items'}
                    data={formattedInventoryItems}
                    pagination={pagination}
                    sortConfig={sortConfig}
                    sortableColumns={sortableColumns}
                    fetchRecords={fetchInventory}
                    add={toggleAddModal}
                    multiDelete={handleDeleteMultipleInventory}
                    onRowClick={handleEditInventoryRow}
                    filters={{
                        filterOptions: filterOptions,
                        handleFilterChange: handleFilterChange,
                        applyFilters: applyFilters,
                        resetFilters: resetFilters
                    }}
                />
            </Container>
        </div>
    );
};

export default InventoryList;