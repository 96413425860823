import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
  getSitesByCustomerId as getSitesByCustomerIdApi,
  addNewSite as addNewSiteApi,
  updateSite as updateSiteApi,
  deleteSite as deleteSiteApi,
  getSiteById as getSiteByIdApi
} from "../../helpers/backend_helper";

export const getSitesByCustomerId = createAsyncThunk("site/getSitesByCustomerId", async (customerId) => {
  try {
    const response = await getSitesByCustomerIdApi(customerId);
    return response;
  } catch (error) {
    return error;
  }
});

export const addNewSite = createAsyncThunk("site/addNewSite", async (site) => {
  try {
    const response = await addNewSiteApi(site);
    return response;
  } catch (error) {
    return error;
  }
});

export const updateSite = createAsyncThunk("site/updateSite", async (site) => {
  try {
    const response = await updateSiteApi(site);
    return response;
  } catch (error) {
    return error;
  }
});

export const deleteSite = createAsyncThunk("site/deleteSite", async (siteId) => {
  try {
    const response = await deleteSiteApi(siteId);
    return {response, siteId};
  } catch (error) {
    return error;
  }
});

export const getSiteById = createAsyncThunk("site/getSiteById", async (siteId) => {
  try {
    const response = await getSiteByIdApi(siteId);
    return response;
  } catch (error) {
    return error;
  }
});