import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';

const TCsModal = ({ isOpen, toggle, terms, productTitle }) => {
    const renderTerms = () => {
        if (Array.isArray(terms)) {
            return terms.map((term, index) => (
            <Row key={index} style={{ marginBottom: '1em' }}>
                <Col xs="auto" style={{ paddingRight: '0em' }}>
                    <i class="ri-checkbox-circle-fill text-success"></i>
                </Col>
                <Col>
                    {term}
                </Col>
            </Row>
            ));
        } else {
            let termsArray = terms.split('. ');
            return termsArray.map((term, index) => (
            <Row key={index} style={{ marginBottom: '1em' }}>
                <Col xs="auto" style={{ paddingRight: '0em' }}>
                    <i class="ri-checkbox-circle-fill text-success"></i>
                </Col>
                <Col>
                    {term + (index !== termsArray.length - 1 ? '.' : '')}
                </Col>
            </Row>
            ));
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} scrollable={true} centered>
            <div className='modal-header position-relative'>
                <div className='d-flex flex-column align-items-center justify-content-center w-100'>
                    <h5 className="modal-title text-success text-center">{productTitle ?? ''}</h5>
                    <h5 className="modal-title text-success text-center">Terms and Conditions</h5>
                </div>
                <i className="btn btn-close close" onClick={toggle} style={{ position: 'absolute', top: '1.5em', right: '1.5em' }}></i>
            </div>
            <ModalBody>
                <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                {renderTerms()}
                </ul>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary" onClick={toggle}>Close</button>
            </ModalFooter>
        </Modal>
    );
};

export default TCsModal;