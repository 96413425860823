import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
  getContacts as getContactsApi,
  addNewContact as addNewContactApi,
  updateContact as updateContactApi,
  deleteContact as deleteContactApi,
  getContactById as getContactByIdApi,
  sendAuthMessageToContact as sendAuthMessageToContactApi,
  getContactsByCustomerId as getContactsByCustomerIdApi
} from "../../helpers/backend_helper";

export const getContacts = createAsyncThunk("contact/getContacts", async () => {
  try {
    const response = await getContactsApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const addNewContact = createAsyncThunk("customer/addNewContact", async (contact) => {
  try {
    const response = await addNewContactApi(contact);
    return response;
  } catch (error) {
    return error;
  }
});

export const updateContact = createAsyncThunk("contact/updateContact", async (contact) => {
  try {
    const response = await updateContactApi(contact);
    return response;
  } catch (error) {
    return error;
  }
});

export const deleteContact = createAsyncThunk("contact/deleteContact", async (contactId) => {
  try {
    const response = await deleteContactApi(contactId);
    return {response, contactId};
  } catch (error) {
    return error;
  }
});

export const getContactById = createAsyncThunk("contact/getContactById", async (contactId) => {
  try {
    const response = await getContactByIdApi(contactId);
    return response;
  } catch (error) {
    return error;
  }
});

export const sendAuthMessageToContact = createAsyncThunk("contact/sendAuthMessageToContact", async (contactId) => {
  try {
    const response = await sendAuthMessageToContactApi(contactId);
    return response;
  } catch (error) {
    return error;
  }
});

export const getContactsByCustomerId = createAsyncThunk("contact/getContactsByCustomerId", async ({customerId, searchTerm = '', page = 1, perPage = 10, sort = 'contact_id', sortOrder = 'desc'}) => {
  try {
    const response = await getContactsByCustomerIdApi(customerId, searchTerm, page, perPage, sort, sortOrder);
    return response;
  } catch (error) {
    return error;
  }
});

export const resetContacts = createAsyncThunk("contact/resetContacts", async () => {
  return {};
});