import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

//Import actions
import {
    addNewCustomer as onAddNewCustomer,
    updateCustomer as onUpdateCustomer
} from "../../../slices/thunks";

import {
    Col,
    Container,
    Row,
    Label,
    Input,
    Form,
    ButtonGroup,
} from 'reactstrap';

import { toast } from 'react-toastify';

const CustomerInfo = (props) => {
    const dispatch = useDispatch();

    const [customerInfo, setCustomerInfo] = useState({
        "customer_name": "",
        "customer_enabled": true,
        "customer_default_payment_method": "stripe",
        "tax_identifier": "",
        "promo_code": "",
        "customer_payment_type": "postpaid",
        "lead_source": "inStore",
    });

    const [customerType, setCustomerType] = useState("Individual");

    const [savedCustomerInfo, setSavedCustomerInfo] = useState(false);

    const handleCustomerSubmit = (event) => {
        event.preventDefault();

        if (customerInfo.customer_id) {
            dispatch(onUpdateCustomer(customerInfo));

            toast.success('Customer information updated successfully!', {autoClose: 3000});
        } else {
            dispatch(onAddNewCustomer(customerInfo))
                .then((response) => {
                    if (response.payload && response.payload.customer_id) {
                        toast.success('Customer information saved successfully!', {autoClose: 3000});
                        setSavedCustomerInfo(true);

                        // only call next pane if passed on as props
                        if (props.onNextStep) {
                            props.onNextStep();
                        }
                    }
                })
                .catch((error) => {
                    toast.error('Error saving customer information: ' + error, {autoClose: 3000});
                });
        }
    };

    const handleInputChange = (event) => {
        setCustomerInfo({ ...customerInfo, [event.target.name]: event.target.value });
    };

    const handleClearCustomerInfo = () => {
        setCustomerInfo({
            "customer_name": "",
            "customer_enabled": true,
            "customer_default_payment_method": "stripe",
            "tax_identifier": "",
            "promo_code": "",
            "customer_payment_type": "postpaid",
            "lead_source": "inStore",
        });
    };

    useEffect(() => {
        return () => {
          handleClearCustomerInfo();
        };
    }, []);

    useEffect(() => {
        setCustomerInfo({
            ...customerInfo,
            customer_id: props.customer.customer_id,
        });
    }, [props.customer]);

    document.title = "Customer Info | Omnitouch CRM";
    return (
        <React.Fragment>
                <div>
                    <Container>
                        <Form>
                            <Row>
                                <ButtonGroup>
                                    <input type="radio" className="btn-check" name="customerType" id="customerType1" autoComplete="off" defaultChecked onChange={() => setCustomerType('Individual')} />
                                    <label className="btn btn-primary" htmlFor="customerType1">Individual</label>

                                    <input type="radio" className="btn-check" name="customerType" id="customerType2" autoComplete="off" onChange={() => setCustomerType('Business')} />
                                    <label className="btn btn-primary" htmlFor="customerType2">Business</label>
                                </ButtonGroup>
                            </Row>
                            {customerType === 'Business' && (
                                <>
                                    <Row>
                                        <Col lg={12}>
                                            <Label className="form-label" htmlFor="customer_name">Business Name</Label>
                                            <Input type="text" id="customer_name" name="customer_name" placeholder="Business Name" onChange={(event) => handleInputChange(event, "customerInfo")}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <Label className="form-label" htmlFor="taxIdentifier">Tax Identifier</Label>
                                            <Input type="text" id="taxIdentifier" name="tax_identifier" placeholder="Tax Identifier" onChange={(event) => handleInputChange(event, "customerInfo")}/>
                                        </Col>
                                    </Row>
                                </>
                            )}
                          <Row>
                            <Col lg={12}>
                                <Label
                                className="form-label"
                                htmlFor="leadSource"
                                >
                                    Lead Source
                                </Label>
                                <select className="form-select mb-3" aria-label="In store" name="lead_source" id="leadSource" defaultValue="inStore" onChange={(event) => handleInputChange(event, "customerInfo")}>
                                    <option defaultValue="inStore">In store</option>
                                    <option defaultValue="referral">Referral</option>
                                    <option defaultValue="website">Website</option>
                                    <option defaultValue="outreach">Outreach</option>
                                </select>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12}>
                                <Label
                                className="form-label"
                                htmlFor="promoCode"
                                >
                                    Promo Code
                                </Label>
                                <select className="form-select mb-3" aria-label="No Promo" name="promo_code" id="promoCode" defaultValue="" onChange={(event) => handleInputChange(event, "customerInfo")}>
                                    <option defaultValue="">No Promo</option>
                                    <option defaultValue="freemodem">Free Modem</option>
                                    <option defaultValue="firstmonthfree">First Month Free</option>
                                    <option defaultValue="zeroinstall">$0 Install</option>
                                </select>
                            </Col>
                          </Row>
                            <div className="d-flex align-items-start gap-3 mt-4">
                                <button
                                    type="button"
                                    className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                    onClick={(event) => {
                                    handleCustomerSubmit(event);
                                    }}
                                >
                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                    {customerInfo.customer_id ? "Update" : "Save"} & Next
                                </button>
                            </div>
                        </Form>
                    </Container>                    
                </div>
        </React.Fragment>
    );
}

export default CustomerInfo;