import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { getUsage as fetchUsage } from '../../../slices/thunks';
import NorfoneData from '../../../assets/images/omnitouch/NorfoneData.png';
import UsageProgress from '../../Services/components/UsageProgress';
import "../style.scss";

const getDummyService = () => {
    return {
        service_name: "Dummy Service",
        cgrates: {
            BalanceMap: {
                DATA: [
                    {
                        ID: "data_1",
                        custom_Percentage: 30,
                        custom_Value_hr: 10,
                        Value: 10,
                        custom_Bucket: 30,
                        custom_Units: "GB",
                        custom_Description_String: "Dummy Data 1 Description",
                        custom_Expiration: "2022-12-31"
                    },
                    {
                        ID: "data_2",
                        custom_Percentage: 70,
                        custom_Value_hr: 21,
                        Value: 21,
                        custom_Bucket: 30,
                        custom_Units: "GB",
                        custom_Description_String: "Dummy Data 2 Description",
                        custom_Expiration: "2022-12-31"
                    }
                ]
            }
        }
    };
};

const Usage = () => {
    const dispatch = useDispatch();
    const [usage, setUsage] = useState(null);
    const [service, setService] = useState(getDummyService());
    const [custom_Expiration, setCustomExpiration] = useState(null);

    useEffect(() => {
        dispatch(fetchUsage())
            .then((response) => {
                setUsage(response?.payload);
                setService(response?.payload?.service || getDummyService());
            });
    }, [dispatch]);

    useEffect(() => {
        if (service?.cgrates?.BalanceMap?.DATA) {
            const expiration = service?.cgrates?.BalanceMap?.DATA[0]?.custom_Expiration;
            setCustomExpiration(expiration);
        }
    }, [service]);

    const calculateDataUsage = (data) => {
        if (!data || data.length === 0) return 0;
        const total = data.reduce((acc, item) => acc + parseFloat(item.Value || 0), 0);
        return total;
    };

    const calculateTotalData = (data) => {
        if (!data || data.length === 0) return 0;
        const total = data.reduce((acc, item) => acc + parseFloat(item.custom_Bucket || 0), 0);
        return total;
    };

    const dataUsage = service?.cgrates?.BalanceMap?.DATA || [];
    const totalUsage = calculateDataUsage(dataUsage);
    const totalData = calculateTotalData(dataUsage);
    const dataRemaining = totalData - totalUsage;
    const percentageUsed = (totalUsage / totalData) * 100;
    const percentageRemaining = 100 - percentageUsed;

    const renderPineTree = (percentage) => {
        return (
            <div style={{ position: 'relative', width: '200px', height: '200px', margin: '0 auto' }}>

                {/* Displaying the logo over the filled gradient */}
                <img src={NorfoneData} alt="Pine Tree" style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }} />

                {/* Gradient fill based on percentage */}
                <div style={{
                    width: '100%',
                    height: '100%',
                    background: `linear-gradient(to top, #1F646D ${percentage}%, transparent ${percentage}%)`,
                    maskImage: `url(${NorfoneData})`,
                    maskSize: 'contain',
                    maskRepeat: 'no-repeat',
                    maskPosition: 'center',
                    WebkitMaskImage: `url(${NorfoneData})`, // For Safari
                    WebkitMaskSize: 'contain',
                    WebkitMaskRepeat: 'no-repeat',
                    WebkitMaskPosition: 'center',
                }}>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <Container fluid>
                <Card className="rounded-5 pb-4 p-4 equalize-height-col-item usage mt-1" style={{ backgroundColor: '#c7c6c6' }}>
                    <Row>
                        <Col md={6} className="text-center">
                            <h4>Data Usage for {service?.service_name}</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="text-center">
                            <div style={{ height: '200px', position: 'relative' }}>
                                {renderPineTree(percentageUsed)}
                            </div>
                            <Card className="mt-2 mb-0 fs-12">
                                <CardBody className="text-center d-flex align-items-center justify-content-center">
                                    <Row className="justify-content-center w-100">
                                        <Col xs={4} className="border-end d-flex flex-column align-items-center justify-content-center">
                                            <p className="mb-0 fw-bold">Remaining</p>
                                            <h5 className="mb-0 fw-bold">{dataRemaining.toFixed(2)} GB</h5>
                                        </Col>
                                        <Col xs={4} className="border-end d-flex flex-column align-items-center justify-content-center">
                                            <p className="mb-0 fw-bold">Data Used</p>
                                            <h5 className="mb-0 fw-bold">{totalUsage.toFixed(2)} GB</h5>
                                        </Col>
                                        <Col xs={4} className="d-flex flex-column align-items-center justify-content-center">
                                            <p className="mb-0 fw-bold">Expires</p>
                                            <h5 className="mb-0 fw-bold">{custom_Expiration}</h5>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} className="mt-2 mt-md-2">
                            {dataUsage.map((item, index) => (
                                <UsageProgress
                                    key={index}
                                    data={item}
                                    dataType="*data_dongle"
                                    isOpen={true}
                                    simple={false}
                                />
                            ))}
                        </Col>
                    </Row>
                </Card>
            </Container>
        </React.Fragment>
    );
};

export default Usage;
