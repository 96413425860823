import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getPermissions as getPermissionsAPI,
  getPermissionById as getPermissionByIdAPI,
  addPermission as addPermissionAPI,
  updatePermission as updatePermissionAPI,
  deletePermission as deletePermissionAPI,
} from '../../../helpers/backend_helper';

export const getPermissions = createAsyncThunk('permission/getPermissions', async ({ searchTerm = '', page = 1, perPage = 10, sort = 'permission_id', sortOrder = 'desc', filters = {} } = {}) => {
  try {
    const response = await getPermissionsAPI(searchTerm, page, perPage, sort, sortOrder, filters);
    return response;
  } catch (error) {
    return error;
  }
});

export const getPermissionById = createAsyncThunk('permission/getPermissionById', async (permissionId) => {
  try {
    const response = await getPermissionByIdAPI(permissionId);
    return response;
  } catch (error) {
    return error;
  }
});

export const addPermission = createAsyncThunk('permission/addPermission', async (permission) => {
  try {
    const response = await addPermissionAPI(permission);
    return response;
  } catch (error) {
    return error;
  }
});

export const updatePermission = createAsyncThunk('permission/updatePermission', async (permission) => {
  try {
    const response = await updatePermissionAPI(permission);
    return response;
  } catch (error) {
    return error;
  }
});

export const deletePermission = createAsyncThunk('permission/deletePermission', async (permissionId) => {
  try {
    const response = await deletePermissionAPI(permissionId);
    return { response, permissionId };
  } catch (error) {
    return error;
  }
});

export const resetPermission = createAsyncThunk('permission/resetPermission', async () => {
  return {};
});