import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Card, CardBody, CardHeader, CardFooter, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import SiteEdit from '../components/SiteEdit';
import Site from '../components/Site';

//Import actions
import {
    deleteSite as onDeleteSite,
    getSitesByCustomerId as onGetSitesByCustomerId
} from "../../../slices/thunks";
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import DeleteModal from '../../../Components/Common/DeleteModal';
import { toast } from 'react-toastify';
import { set } from 'lodash';

const containerStyle = {
    width: '100%',
    height: '400px'
};

const SiteListPage = (props) => {
    const dispatch = useDispatch();

    const selectLayoutState = (state) => state.Site;
    const selectSiteProperties = createSelector(
        selectLayoutState,
        (state) => ({
        siteList: state.sites,
        isSiteSuccess: state.isSiteSuccess,
        error: state.error,
        })
    );

    const {
        siteList, isSiteSuccess, error
    } = useSelector(selectSiteProperties);

    const [sites, setSites] = useState([]);
    const [modal, setModal] = useState(false);
    const [currentSite, setCurrentSite] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [addModal, setAddModal] = useState(false);

    const toggleAddModal = () => setAddModal(!addModal);
    const toggle = () => setModal(!modal);
    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    const [isScriptLoaded, setIsScriptLoaded] = useState(false);

    const handleScriptLoad = () => {
        setIsScriptLoaded(true);
    }

    const handleEdit = (site) => {
        setCurrentSite(site);
        toggle();
    }

    const handleDelete = (site) => {
        setCurrentSite(site);
        toggleDeleteModal();
    }

    const handleConfirmDelete = () => {
        dispatch(onDeleteSite(currentSite.site_id));

        // Filter out the deleted site
        const newSites = sites.filter(site => site.site_id !== currentSite.site_id);
        setSites(newSites);

        toast.success('Site deleted successfully',
            {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        );

        // Reset the current site
        setCurrentSite(null);

        // Close the delete modal
        toggleDeleteModal();
    }

    useEffect(() => {
        if (siteList && siteList.length > 0) {
            setSites(siteList);
        }
    }, [siteList]);

    useEffect(() => {
        refetchSites();
    }, [props.customer]);

    const refetchSites = () => {
        if (props.customer) {
            dispatch(onGetSitesByCustomerId(props.customer?.customer_id));
        }
    };

    document.title = "Customer Sites | Omnitouch CRM";
    return (
        <React.Fragment>
            <Row className="g-4 mb-3">
                <Col className="col-sm-auto">
                    <div>
                        <Button color="success" className="add-btn me-1" onClick={() => toggleAddModal()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add New</Button>
                    </div>
                </Col>
                {/* <Col className="col-sm">
                    <div className="d-flex justify-content-sm-end">
                        <div className="search-box ms-4">
                        <Input
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                        </div>
                    </div>
                </Col> */}
            </Row>
            <Row>
                {/* <LoadScript
                    googleMapsApiKey="AIzaSyCAmkCXAT55LiHh7ZBVNhMqYaXIevCSCBI"
                    onLoad={handleScriptLoad}
                > */}
                    {sites?.map((site, index) => (
                        <Col sm="12" md="6" key={index}>
                            <Card>
                                <CardHeader>{site.site_name}</CardHeader>
                                <CardBody>
                                    <p>{site.address_line_1}, {site.address_line_2}, {site.city}, {site.state}, {site.zip_code}, {site.country}</p>
                                    <p>{site.site_notes}</p>
                                    
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={{ lat: parseFloat(site.latitude), lng: parseFloat(site.longitude) }}
                                        zoom={10}
                                    >
                                        <Marker position={{ lat: parseFloat(site.latitude), lng: parseFloat(site.longitude) }} />
                                    </GoogleMap>
                                </CardBody>
                                <CardFooter style={{ textAlign: 'right' }}>
                                    <Button className='btn-success' onClick={() => handleEdit(site)}>Edit</Button>{' '}
                                    <Button color="danger" onClick={() => handleDelete(site)} disabled={sites.length < 2}>Delete</Button>
                                </CardFooter>
                            </Card>
                        </Col>
                    ))}
                {/* </LoadScript> */}
            </Row>
            <Modal isOpen={modal} toggle={toggle} centered size='xl'>
                <ModalHeader toggle={toggle}>Edit Site</ModalHeader>
                <ModalBody>
                    <SiteEdit siteData={currentSite} onNextStep={toggle} onCancel={toggle}/>
                </ModalBody>
            </Modal>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleConfirmDelete}
                onCloseClick={() => setDeleteModal(false)}
            />
            <Modal isOpen={addModal} toggle={toggleAddModal} centered size='xl'>
                <ModalHeader toggle={toggleAddModal}>Add Site</ModalHeader>
                <ModalBody>
                    <Site standalone loadScript={false} customer={props.customer} customerId={props.customer?.customer_id} onNextStep={toggleAddModal} refetchSites={refetchSites} onCancel={toggleAddModal}/>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default SiteListPage;