import { createSlice } from "@reduxjs/toolkit";

import {
  getPermissions,
  getPermissionById,
  addPermission,
  updatePermission,
  deletePermission,
} from "./thunk";

export const initialState = {
  permissions: [],
  permission: {},
  error: "", // for error message
  loading: false,
  errorMsg: false, // for error
};

const permissionSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPermissions.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPermissions.fulfilled, (state, action) => {
      state.loading = false;
      state.permissions = action.payload;
    });
    builder.addCase(getPermissions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || null;
    });

    builder.addCase(getPermissionById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPermissionById.fulfilled, (state, action) => {
      state.loading = false;
      state.permission = action.payload;
    });
    builder.addCase(getPermissionById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || null;
    });

    builder.addCase(addPermission.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addPermission.fulfilled, (state, action) => {
      state.loading = false;
      state.permission = action.payload;
    });
    builder.addCase(addPermission.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || null;
    });

    builder.addCase(updatePermission.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updatePermission.fulfilled, (state, action) => {
      state.loading = false;
      state.permission = action.payload;
    });
    builder.addCase(updatePermission.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || null;
    });

    builder.addCase(deletePermission.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deletePermission.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deletePermission.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || null;
    });
  }
});


export default permissionSlice.reducer;