module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: "/",
    REFRESH_TOKEN_URL: "/crm/auth/refresh",
  }
};
