import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, Input, Label } from 'reactstrap';

const CommunicationDetail = ({ communication }) => {
    return (
        <React.Fragment>
                    {/* <Row>
                        <h5>Basic Info</h5>
                        <Col xs={12} md={6}>
                            <Label><strong>Communication ID:</strong></Label>
                            <Input readOnly value={communication.communication_id} />
                            <Label><strong>Contact ID:</strong></Label>
                            <Input readOnly value={communication.contact_id} />
                            <Label><strong>Customer ID:</strong></Label>
                            <Input readOnly value={communication.customer_id} />
                        </Col>
                        <Col xs={12} md={6}>
                            <Label><strong>Created:</strong></Label>
                            <Input readOnly value={communication.created} />
                            <Label><strong>Last Modified:</strong></Label>
                            <Input readOnly value={communication.last_modified} />
                        </Col>
                    </Row> */}
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <h5>Email Info</h5>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Label><strong>Email Send Time:</strong></Label>
                                            <Input readOnly value={communication.email_send_time} />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Label><strong>Send Email:</strong></Label>
                                            <Input readOnly value={communication.send_email ? 'Yes' : 'No'} />
                                        </Col>   
                                    </Row>
                                    <Label><strong>Email Subject:</strong></Label>
                                    <Input readOnly value={communication.email_subject} />
                                    <Label><strong>Email Body:</strong></Label>
                                    <Input type='textarea' readOnly value={communication.email_body} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <h5>SMS Info</h5>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Label><strong>SMS Send Time:</strong></Label>
                                            <Input readOnly value={communication.sms_send_time} />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Label><strong>Send SMS:</strong></Label>
                                            <Input readOnly value={communication.send_sms ? 'Yes' : 'No'} />
                                        </Col>   
                                    </Row>
                                    <Label><strong>SMS Body:</strong></Label>
                                    <Input type='textarea' readOnly value={communication.sms_body} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
        </React.Fragment>
    );
};

export default CommunicationDetail;