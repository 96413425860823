import React from "react";
import {
    Container,
} from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import CustomerForm from "./components/CustomerForm";

const CustomerCreate = () => {
  document.title = "Create Customer | Omnitouch CRM";

  return (
    <React.Fragment>
      <div className="page-content">        
        <Container fluid>
          <BreadCrumb title="Create" pageTitle="Customers" path="/customers"/>
          <CustomerForm />          
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CustomerCreate;
