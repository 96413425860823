import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
  getCustomers as getCustomersApi,
  addNewCustomer as addNewCustomerApi,
  updateCustomer as updateCustomerApi,
  deleteCustomer as deleteCustomerApi,
  getCustomerById as getCustomerByIdApi
} from "../../helpers/backend_helper";

export const getCustomers = createAsyncThunk("customer/getCustomers", async ({searchTerm = '', page = 1, perPage = 10, sort = 'customer_id', sortOrder = 'desc', filters = {}} = {}) => {
  try {
    const response = await getCustomersApi(searchTerm, page, perPage, sort, sortOrder, filters);
    return response;
  } catch (error) {
    return error;
  }
});

export const addNewCustomer = createAsyncThunk("customer/addNewCustomer", async (customer) => {
  try {
    const response = await addNewCustomerApi(customer);
    return response;
  } catch (error) {
    return error;
  }
});

export const updateCustomer = createAsyncThunk("customer/updateCustomer", async (customer) => {
  try {
    const response = await updateCustomerApi(customer);
    return response;
  } catch (error) {
    return error;
  }
});

export const deleteCustomer = createAsyncThunk("customer/deleteCustomer", async (customerId) => {
  try {
    const response = await deleteCustomerApi(customerId);
    return {response, customerId};
  } catch (error) {
    return error;
  }
});

export const getCustomerById = createAsyncThunk("customer/getCustomerById", async (customerId) => {
  try {
    const response = await getCustomerByIdApi(customerId);
    return response;
  } catch (error) {
    return error;
  }
});

export const resetCustomer = createAsyncThunk("customer/resetCustomer", async () => {
  return {};
});