import { createSlice } from "@reduxjs/toolkit";
import {
  createCbcMessage,
  getAllCbcItems,
  deleteCbcMessage,
} from './thunk';

export const initialState = {
  cbcItems: [],
  cbcMessage: {},
  error: {},
};

const CbcSlice = createSlice({
  name: 'CbcSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(createCbcMessage.fulfilled, (state, { payload }) => {
      state.cbcMessage = payload;
    });
    builder.addCase(createCbcMessage.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(getAllCbcItems.fulfilled, (state, { payload }) => {
      state.cbcItems = payload;
    });
    builder.addCase(getAllCbcItems.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(deleteCbcMessage.fulfilled, (state, { payload }) => {
      state.cbcItems = state.cbcItems.filter(item => item.id !== payload.id);
    });
    builder.addCase(deleteCbcMessage.rejected, (state, { payload }) => {
      state.error = payload;
    });
  }
});

export default CbcSlice.reducer;