import React, { useState } from 'react';
import { Label, Row, Col, Button } from 'reactstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

function Filter({ filterOptions, onApplyFilters, onFilterChange, onResetFilters }) {
    const [filters, setFilters] = useState({});
    const [filterValues, setFilterValues] = useState({});

    const formatLabel = (label) => {
        return label.replace('_id', '').split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };

    const getSelectedOption = (field) => {
        return filters[field] || '';
    }

    const handleChange = (selectedOption, action) => {
        setFilters(prevFilters => ({ ...prevFilters, [action.name]: selectedOption }));
        setFilterValues(prevValues => ({ ...prevValues, [action.name]: selectedOption.value }));
        if (onFilterChange) {
            onFilterChange(action.name, selectedOption.value);
        }
    };

    const handleApplyFilters = () => {
        onApplyFilters(filterValues);
    };

    const handleResetFilters = () => {
        setFilters({});
        setFilterValues({});
        if (onResetFilters) {
            onResetFilters();
        }
    };

    return (
        <React.Fragment>
            <Row>
                {Object.entries(filterOptions).map(([field, values]) => (
                    <Col key={field} xs={12} sm={6} md={4} lg={3}>
                        <Row>
                            <Label className='col col-md-12'>{formatLabel(field)}</Label>
                            {values.config?.type === 'search' ? (
                                <AsyncSelect
                                    className='col-md-12'
                                    key={field}
                                    value={getSelectedOption(field)}
                                    isMulti={false}
                                    name={field}
                                    onChange={handleChange}
                                    loadOptions={values.config.loadOptions}
                                    id={'filter-'+field}
                                    closeMenuOnSelect={true}
                                />
                            ) : (
                                <Select
                                    className='col-md-12'
                                    key={field}
                                    value={getSelectedOption(field)}
                                    isMulti={false}
                                    name={field}
                                    onChange={handleChange}
                                    options={values.options}
                                    id={'filter-'+field}
                                    closeMenuOnSelect={true}
                                    styles={{
                                        multiValueLabel: (styles) => ({
                                            ...styles,
                                            color: 'white',
                                        }),
                                    }}
                                />
                            )}
                        </Row>
                    </Col>
                ))}
            </Row>
            <Row>
                <Col xs={12} className='d-flex justify-content-end align-items-end gap-2'>
                    <Button color='primary-muted' onClick={handleResetFilters}>Reset Filters</Button>
                    <Button color="primary" onClick={handleApplyFilters}>Apply Filters</Button>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default Filter;