import React, { useState, useEffect } from 'react';
import { Col, Container, Modal, Row, ModalBody, ModalFooter, ModalHeader, Button, Input } from 'reactstrap';
import TableCommon from '../../Components/Common/TableCommon';
import DeleteModal from '../../Components/Common/DeleteModal';
import RoleForm from './components/RoleForm';
import Tags from '../../Components/Common/Tags';
import {
    deleteRole as onDeleteRole,
    updateRole as onUpdateRole,
    getRoles as onGetRoles,
    resetRole as onResetRole,
} from "../../slices/thunks";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const ManageRoles = () => {
    const dispatch = useDispatch();
    const { roles } = useSelector(state => state.Role);
    const [roleList, setRoleList] = useState([]);
    const [currentRole, setCurrentRole] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [isRolesFetched, setIsRolesFetched] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        itemsPerPage: 10,
        totalRecords: 0,
        totalPages: 0,
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'role_id', direction: 'desc' });

    const toggleAddModal = () => setAddModal(!addModal);
    const toggleDeleteModal = () => setDeleteModal(!deleteModal);
    const toggleUpdateModal = () => setUpdateModal(!updateModal);

    useEffect(() => {
        if (!isRolesFetched) {
            fetchRoles();
        }
    }, [dispatch, isRolesFetched]);

    const formatName = (name) => {
        // replace underscores with spaces and capitalize first letter of each word
        return name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };

    useEffect(() => {
        if (roles && roles.data && Array.isArray(roles.data)) {
            setRoleList(roles.data.map(role => ({
                id: role.id,
                name: formatName(role.name),
                description: role.description,
                // "permissions": (
                //     <Tags
                //         tags={
                //             typeof role.role_permissions === 'string' && role.role_permissions.includes(',')
                //                 ? role.role_permissions.split(',').filter(permission => permission !== '')
                //                 : typeof role.role_permissions === 'array' ? role.role_permissions : [role.role_permissions]
                //         }
                //     />
                // ),
                actions: (
                    <ul className="list-inline hstack gap-2 mb-0">
                        <li className="list-inline-item edit">
                            <Link
                                title={'Edit'}
                                className="text-primary d-inline-block edit-item-btn"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleRoleView(role.role_id);
                                }}
                            >
                                <i className="ri-pencil-fill fs-16"></i>
                            </Link>
                        </li>
                        <li className="list-inline-item">
                            <Link
                                title={'Delete'}
                                className="text-danger d-inline-block remove-item-btn"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleRoleDelete(role.role_id);
                                }}
                            >
                                <i className="ri-delete-bin-5-fill fs-16"></i>
                            </Link>
                        </li>
                    </ul>
                )
            })));
        }

        if (roles && roles.pagination) {
            setPagination({
                currentPage: roles.pagination.page,
                itemsPerPage: roles.pagination.per_page,
                totalRecords: roles.pagination.total_records,
                totalPages: roles.pagination.total_pages,
            });
        }
    }, [roles]);

    const handleRoleView = (id) => {
        setCurrentRole(roles.data.find(role => role.role_id === id));
        toggleUpdateModal();
    };

    const handleRoleDelete = (id) => {
        setCurrentRole(roles.data.find(role => role.role_id === id));
        toggleDeleteModal();
    };

    const handleRoleConfirmDelete = () => {
        dispatch(onDeleteRole(currentRole.role_id))
            .then(() => {
                toast.success('Role deleted successfully', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(error => {
                console.log(error);
                toast.error('Failed to delete role', { autoClose: 3000 });
            })
            .finally(() => fetchRoles(pagination.currentPage, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm));

        setCurrentRole(null);permission_description
        setDeleteModal(false);
    };

    const fetchRoles = (page = 1, perPage = 10, sort = 'role_id', sortOrder = 'desc', searchTerm = '') => {
        dispatch(onGetRoles({ searchTerm, page, perPage, sort, sortOrder }))
            .then(() => {
                setIsRolesFetched(true);
                setSearchTerm(searchTerm);
                setSortConfig({ key: sort, direction: sortOrder });
            })
            .catch(error => {
                console.log(error);
                toast.error('Failed to fetch roles', { autoClose: 3000 });
            });
    };

    useEffect(() => {
        fetchRoles();
        return () => {
            dispatch(onResetRole());
        };
    }, []);

    document.title = "Role List | OmniCRM";
    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <TableCommon
                            title="Roles"
                            data={roleList}
                            edit={handleRoleView}
                            delete={handleRoleDelete}
                            add={toggleAddModal}
                            pagination={pagination}
                            sortConfig={sortConfig}
                            fetchRecords={fetchRoles}
                            onRowClick={handleRoleView}
                        />
                    </Col>
                </Row>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleRoleConfirmDelete}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <Modal isOpen={updateModal} toggle={toggleUpdateModal} centered size='xl'>
                    <ModalHeader toggle={toggleUpdateModal}>Update Role</ModalHeader>
                    <ModalBody>
                        <RoleForm initialRole={currentRole} onNextStep={() => { toggleUpdateModal(); fetchRoles(); }} onCancel={toggleUpdateModal} />
                    </ModalBody>
                </Modal>
                <Modal isOpen={addModal} toggle={toggleAddModal} centered size='l'>
                    <ModalHeader toggle={toggleAddModal}>Add Role</ModalHeader>
                    <ModalBody>
                        <RoleForm onNextStep={() => { toggleAddModal(); fetchRoles(); }} onCancel={toggleAddModal} />
                    </ModalBody>
                </Modal>
            </Container>
        </React.Fragment>
    );
}

export default ManageRoles;
