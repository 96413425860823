import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Carousel from '../../Components/Common/Carousel';
import {
    Container,
    ButtonGroup,
    Label,
    Input,
    Row,
    Card,
    CardHeader,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import Loader from '../../Components/Common/Loader';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import ProvisioningStatus from '../Provisioning/ProvisioningStatus';
import { toast } from 'react-toastify';
import * as Icons from 'react-icons/fa6';

import {
    getAllProducts as onGetAllProducts,
    getCustomers as onGetCustomers,
    getInventoryByItemName as onGetInventoryByItemName,
    createNewProvisioningJob as onCreateNewProvisioningJob,
    updateInventory as onUpdateInventory,
} from '../../slices/thunks';

const PlanList = ({ customerId, refresh, page, simple = false }) => {
    const dispatch = useDispatch();

    const {
        products
    } = useSelector(state => state.Product);

    const {
        customers
    } = useSelector(state => state.Customer);

    const [filters, setFilters] = useState({
        category: ['service_plans'],
    });
    const [customerTypePlans, setCustomerTypePlans] = React.useState('Individual');
    const [customerTypeAddons, setCustomerTypeAddons] = React.useState('Individual');
    const [isProductsFetched, setIsProductsFetched] = React.useState(false);
    const [categorizedProducts, setCategorizedProducts] = React.useState({
        addons: {
            Individual: [],
            Business: []
        },
        service_plans: {
            Individual: [],
            Business: []
        }
    });

    const [addToCustomerModal, setAddToCustomerModal] = useState(false);
    const toggleAddToCustomerModal = () => setAddToCustomerModal(!addToCustomerModal);
    const [confirmServicePlanModal, setConfirmServicePlanModal] = useState(false);
    const toggleConfirmServicePlanModal = () => setConfirmServicePlanModal(!confirmServicePlanModal);

    const handleConfirmServicePlanModalClose = (() => {
        setConfirmServicePlanModal(false);
    });

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectCustomerValidation, setSelectCustomerValidation] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [additionalFields, setAdditionalFields] = useState({});
    const [additionalFieldsOptions, setAdditionalFieldsOptions] = useState({});
    const [selectedAdditionalFields, setSelectedAdditionalFields] = useState({});
    const [provisioningJob, setProvisioningJob] = useState({});
    const [provisioningId, setProvisioningId] = useState(null);
    const [provisioningModal, setProvisioningModal] = useState(false);

    const toggleProvisioningModal = (() => {
        setProvisioningModal(!provisioningModal);
        if (refresh && provisioningId) {
            refresh();
            setProvisioningId(null);
        }
    });

    const handleProvisioningModalClose = (() => {
        setProvisioningModal(false);
        if (refresh) {
            refresh();
        }
        setProvisioningId(null);
    });

    const loadOptions = (inputValue, callback) => {
        dispatch(onGetCustomers({searchTerm: inputValue}))
            .then((response) => {
                const options = response.payload.data.map(customer => ({ value: customer.customer_id, label: customer.customer_name }));
                callback(options);
            });
    };

    const prepareBody = ((dataBody) => {
        if (!dataBody) {
            return null;
        }
    
        // Replace single quotes with double quotes and parse dataBody if it's a string representation of an array
        const dataArray = typeof dataBody === 'string' ? JSON.parse(dataBody.replace(/'/g, '"')) : dataBody;
    
        return (
            <div className='mt-auto'>
                <ul>
                    {dataArray.map((feature, index) => {
                        return (
                            <li key={index}>{feature}</li>
                        );
                    })}
                </ul>
            </div>
        );
    });

    const iconClassToComponentName = (iconClass) => {
        if (!iconClass) return null;
        const parts = iconClass.split(' ');
        if (parts.length < 1) return null;
        const iconName = parts[parts.length - 1].slice(3); // remove 'fa-' prefix
        const finalIconName = iconName
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join('');
        return `Fa${finalIconName}`;
    
    };

    const prepareData = ((data) => {
        // Calculate the maximum height based on the length of the longest features_list
        let maxHeight = Math.max(...data.map(item => item.features_list ? item.features_list.split('. ').length : 0)) * 12;

        return data.map((item, index) => {
            const iconComponentName = iconClassToComponentName(item?.icon);
            const IconComponent = Icons[iconComponentName];
            const icon = IconComponent ? <IconComponent /> : null;
            return {
                title: item.product_name,
                avatar: item.avatar,
                component: prepareBody(item.features_list ?? null),
                description: item.description,
                index: index,
                retail_cost: item.retail_cost,
                retail_setup_cost: item.retail_setup_cost,
                icon: icon,
                terms: item.terms,
                maxHeight: maxHeight,
                originalData: item
            }
        });
    });

    // categorize products based on business, if true then show business products else show individual products
    // then categorize further based on category, if standalone, bundle or addon
    const categorizeProducts = ((products) => {
        if (products) {
            let categorizedProducts = {
                addons: {
                    Individual: [],
                    Business: []
                },
                service_plans: {
                    Individual: [],
                    Business: []
                }
            };
    
            products.forEach(product => {
                let category = product.category === 'addon' ? 'addons' : 'service_plans';
    
                if (product.residential === true) {
                    categorizedProducts[category].Individual.push(product);
                }

                if (product.business === true) {
                    categorizedProducts[category].Business.push(product);
                }
            });
    
            setCategorizedProducts(categorizedProducts);
            setIsProductsFetched(true);
        }
    });

    // clear categorized products
    const clearCategorizedProducts = (() => {
        setCategorizedProducts({
            addons: {
                Individual: [],
                Business: []
            },
            service_plans: {
                Individual: [],
                Business: []
            }
        });
    });

    useEffect(() => {
        dispatch(onGetAllProducts({filters}));
        if (!customerId) dispatch(onGetCustomers());
    }, [dispatch]);

    useEffect(() => {
        categorizeProducts(products.data);
    }, [JSON.stringify(products)]);

    const renderServicePlans = (() => {
        return (
            <Card>
                <CardHeader>
                    <h4 className="card-title mb-1 text-center">{customerTypePlans === 'Individual' ? 'Individual' : 'Business' } Service Plans</h4>
                    <p className='text-center text-muted mb-4'>Service plans with inclusions</p>
                    {/* Radio Button Selection Individual/Business */}
                    <Row>
                        <ButtonGroup>
                            <Input type="radio" className="btn-check" name="customerTypePlans" id="customerTypePlan1" autoComplete="off" defaultChecked onChange={() => setCustomerTypePlans('Individual')} />
                            <Label className="btn btn-primary" htmlFor="customerTypePlan1">Individual</Label>

                            <Input type="radio" className="btn-check" name="customerTypePlans" id="customerTypePlan2" autoComplete="off" onChange={() => setCustomerTypePlans('Business')} />
                            <Label className="btn btn-success" htmlFor="customerTypePlan2">Business</Label>
                        </ButtonGroup>
                    </Row>
                </CardHeader>
                <CardBody>
                    { customerTypePlans === 'Individual' ? (
                        (renderIndividual(categorizedProducts.service_plans.Individual))
                    ) : (
                        (renderBusiness(categorizedProducts.service_plans.Business))
                    )}
                </CardBody>
            </Card>
        );
    });

    const renderAddons = (() => {
        return (
            <Card>
                <CardHeader>
                    <h4 className="card-title mb-1 text-center">{customerTypeAddons === 'Individual' ? 'Individual' : 'Business' } Addon Packs</h4>
                    <p className='text-center text-muted mb-4'>Addon packs with inclusions</p>
                    {/* Radio Button Selection Individual/Business */}
                    <Row>
                        <ButtonGroup>
                            <Input type="radio" className="btn-check" name="customerTypeAddon" id="customerTypeAddon1" autoComplete="off" defaultChecked onChange={() => setCustomerTypeAddons('Individual')} />
                            <Label className="btn btn-primary" htmlFor="customerTypeAddon1">Individual</Label>
    
                            <Input type="radio" className="btn-check" name="customerTypeAddon" id="customerTypeAddon2" autoComplete="off" onChange={() => setCustomerTypeAddons('Business')} />
                            <Label className="btn btn-success" htmlFor="customerTypeAddon2">Business</Label>
                        </ButtonGroup>
                    </Row>
                </CardHeader>
                <CardBody>
                    { customerTypeAddons === 'Individual' ? (
                        (renderIndividual(categorizedProducts.addons.Individual))
                    ) : (
                        (renderBusiness(categorizedProducts.addons.Business))
                    )}
                </CardBody>
            </Card>
        );
    });

    const renderIndividual = (( data = [] ) => {
        return (
            <Carousel title={null} action={handleCardAction} data={prepareData(data)} carouselType="plans" footerText='Add to Customer' card={false} />
        );
    });

    const renderBusiness = (( data = [] ) => {
        return (
            <Carousel title={null} action={handleCardAction} data={prepareData(data)} carouselType="plans" theme='card-success' darken={true} footerText='Add to Customer' card={false} />
        );
    });

    const clearAddToCustomerForm = (() => {
        setSelectedCustomer(null);
        setSelectedProduct(null);
        setAdditionalFields({});
        setAdditionalFieldsOptions({});
        setSelectedAdditionalFields({});
    });

    const handleAddToCustomerModalClose = (() => {
        setAddToCustomerModal(false);
        setConfirmServicePlanModal(false);
        clearAddToCustomerForm();
    });

    const handleSoldInventory = (() => {
        Object.keys(selectedAdditionalFields).map((key, _) => {
            const date = moment().format('YYYY-MM-DD HH:mm:ss');
    
            dispatch(onUpdateInventory({
                inventory_id: selectedAdditionalFields[key].value,
                customer_id: customerId ? customerId : selectedCustomer.value,
                sold_date: date
            }))
        });
    });

    const handleAddtoCustomerConfirm = (() => {
        let provisioningVars = {};
        Object.keys(selectedAdditionalFields).forEach((key) => {
            provisioningVars[key] = selectedAdditionalFields[key].value;
        });

        const provisioningData = {
            customer_id: customerId ? customerId : selectedCustomer.value,
            product_id: selectedProduct.originalData.product_id,
            provisioning_json_vars: JSON.stringify(provisioningVars) ?? selectedProduct.originalData.provisioning_json_vars ?? "",
            provisioning_play: selectedProduct.originalData.provisioning_play,
            site_id: null,
        };

        setProvisioningJob(provisioningData);

        handleSoldInventory();

        dispatch(onCreateNewProvisioningJob(provisioningData))
            .then((response) => {
                if (!response.payload.provision_id) {
                    console.log('Provisioning Job Error', response.payload.error);
                    toast.error('Provisioning failed. Please contact the administrator for more information.', {autoClose: 3000});
                } else {
                    setProvisioningId(response.payload.provision_id);
                    toggleProvisioningModal();
                }
            });

        handleAddToCustomerModalClose();
    });

    const handleCardAction = ((cardData) => {
        setSelectedProduct(cardData);
        if(cardData?.originalData?.inventory_items_list) {
            const itemsData = cardData.originalData.inventory_items_list;
            const dataArray = typeof itemsData === 'string' ? JSON.parse(itemsData.replace(/'/g, '"')) : itemsData;
            dataArray.forEach((item) => {
                const itemKey = item;
                dispatch(onGetInventoryByItemName(item))
                    .then((response) => {
                        // Update additionalFields
                        setAdditionalFields(prevFields => ({
                            ...prevFields,
                            [itemKey]: response.payload
                        }));

                        // Update additionalFieldsOptions
                        setAdditionalFieldsOptions(prevOptions => ({
                            ...prevOptions,
                            [itemKey]: response.payload.map((item) => ({value: item.inventory_id, label: item.serial1}))
                        }));
                    })
            })
        }

        if (customerId) {
            toggleConfirmServicePlanModal();
        } else {
            toggleAddToCustomerModal();
        }
    });

    const handleAdditionalFieldSelection = ((selectedOption, key) => {
        // Update selectedAdditionalFields
        setSelectedAdditionalFields(prevFields => ({
            ...prevFields,
            [key]: selectedOption
        }));
    });

    document.title = 'Plans | Omnitouch CRM';

    if (!isProductsFetched) {
        return (
            <Loader />
        );
    }

    return (
        <React.Fragment>
            <div className={page ? 'page-content' : ''}>
                <Container fluid>
                    {renderServicePlans()}

                    {/* {renderAddons()} */}
                </Container>
            </div>
            <Modal isOpen={addToCustomerModal} toggle={handleAddToCustomerModalClose} centered>
                <ModalHeader toggle={handleAddToCustomerModalClose}>Add to Customer</ModalHeader>
                <ModalBody>
                    {/* SearchBox for customers */}
                    <Label className='mb-2'>Search Customers</Label>
                    <AsyncSelect
                        loadOptions={loadOptions}
                        onChange={(selectedOption) => {
                            setSelectedCustomer(selectedOption);
                            setSelectCustomerValidation(selectedOption.value !== null);
                        }}
                        styles={{
                            menu: provided => ({ ...provided, zIndex: 2 })
                        }}
                    />
                    {!selectCustomerValidation && <div className="alert alert-danger">Please select a customer</div>}

                    {/* Additional Fields */}
                        {Object.keys(additionalFieldsOptions).map((key, index) => {
                            return (
                                <>
                                <Label key={key+index} className='mb-2'>{key}</Label>
                                <Select className='mb-3' key={index} placeholder={key} onChange={(selectedOption) => handleAdditionalFieldSelection(selectedOption, key)} options={additionalFieldsOptions[key]} />
                                </>
                            );
                        })}
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-danger" onClick={handleAddToCustomerModalClose}>Cancel</button>
                    <button type="button" className="btn btn-success" onClick={handleAddtoCustomerConfirm}>Add</button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={provisioningModal} toggle={toggleProvisioningModal} size='lg' centered>
                <ModalHeader toggle={handleProvisioningModalClose}>Provisioning Status</ModalHeader>
                <ModalBody>
                    <ProvisioningStatus provision_id={provisioningId} newProvisioning={true} refresh={refresh} simple={simple} />
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-danger" onClick={handleProvisioningModalClose}>Close</button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={confirmServicePlanModal} toggle={handleConfirmServicePlanModalClose} centered>
                <ModalHeader toggle={handleConfirmServicePlanModalClose}>Confirm Service Plan</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to add this service plan to the customer?</p>

                    {/* Additional Fields */}
                    {Object.keys(additionalFieldsOptions).map((key, index) => {
                            return (
                                <>
                                <Label key={key+index} className='mb-2'>{key}</Label>
                                <Select className='mb-3' key={index} placeholder={key} onChange={(selectedOption) => handleAdditionalFieldSelection(selectedOption, key)} options={additionalFieldsOptions[key]} />
                                </>
                            );
                        })}
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-danger" onClick={handleConfirmServicePlanModalClose}>Cancel</button>
                    <button type="button" className="btn btn-success" onClick={handleAddtoCustomerConfirm}>Confirm</button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default PlanList;