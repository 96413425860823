import React, { useEffect, useState, useRef } from 'react';
import { Card, CardBody, CardHeader, Collapse, Button, Row, Col, Input, CardFooter, Pagination, PaginationItem, PaginationLink, Nav, NavItem, NavLink } from 'reactstrap';
import {
    getActivitiesByCustomerId,
    getCustomers,
} from '../../../slices/thunks';
import userDummyImg from '../../../assets/images/users/user-dummy-img.jpg';
import Tags from '../../../Components/Common/Tags';
import { getClosestBootstrapColor } from '../../../helpers/utils';
import Filter from '../../../Components/Common/Filter';
import Select from 'react-select';
import classnames from "classnames";

import { useDispatch } from 'react-redux';

const ActivitiesTab = ({ customerId, type = null}) => {
    const dispatch = useDispatch();
    const [activities, setActivities] = useState([]);
    const [filterOptions, setFilterOptions] = useState({
        activity_type: { 
            options: [
                {
                    value: 'Create',
                    label: 'Create',
                },
                {
                    value: 'Update',
                    label: 'Update',
                },
                {
                    value: 'Delete',
                    label: 'Delete',
                }
            ]
        },
        activity_visible_to_customer: { 
            options: [
                {
                    value: true,
                    label: 'True',
                },
                {
                    value: false,
                    label: 'False'
                }
            ]
        },
        customer_id: {
            config: {
                type: 'search',
                loadOptions: (inputValue, callback) => {
                    dispatch(getCustomers({searchTerm: inputValue}))
                        .then((response) => {
                            const options = response.payload.data.map(customer => ({ value: customer.customer_id, label: customer.customer_name }));
                            callback(options);
                        });
                }
            },
        },
    });
    const [filters, setFilters] = useState({});
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const debounceTimeout = useRef(null);

    const [pagination, setPagination] = useState({
        currentPage: 1,
        itemsPerPage: 10,
        totalRecords: 0,
        totalPages: 0,
    });

    const [sortConfig, setSortConfig] = useState({
        key: 'activity_id',
        direction: 'desc',
    });

    const sortOptions = [
        { value: 'desc', label: 'Newest' },
        { value: 'asc', label: 'Oldest' },
    ];

    const [activeTab, setActiveTab] = useState("1");

    const toggleTab = (tab, type) => {
        if (activeTab !== tab) {
            setActiveTab(tab);

            // set filters based on tab
            let filtersData = filters;
            if (['Communication', 'Customer_Transaction', 'Customer_Support', 'Other'].includes(type)) {
                filtersData = {
                    ...filtersData,
                    tag_text: [type],
                };
            } else {
                delete filtersData.tag_text;
            }

            setFilters(filtersData);

          fetchActivities(1, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm, filtersData);
        }
      };

    const toggleFilter = () => setIsFilterOpen(!isFilterOpen);

    const handleFilterChange = (field, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [field]: [value],
        }));
    };

    const handlePageChange = (pageNumber) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: pageNumber,
        }));
        fetchActivities(pageNumber);
    };

    const handleSortChange = (selectedOption) => {
        setSortConfig({
            key: 'created',
            direction: selectedOption.value,
            label: selectedOption.label,
        });
        fetchActivities();
    };

    const applyFilters = () => {
        fetchActivities();
    };

    const resetFilters = () => {
        // reset filters except for tag_text
        // check if tag_text is present in filters
        let filtersData = {};
        if (filters.tag_text) {
            filtersData = {
                tag_text: filters.tag_text,
            };
        }

        setFilters(filtersData);
        fetchActivities(1, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm, filtersData);
    };

    const fetchActivities = async (currentPage = pagination.currentPage, itemsPerPage = pagination.itemsPerPage, key = sortConfig.key,  direction = sortConfig.direction, search = searchTerm, filtersData = filters) => {
        dispatch(getActivitiesByCustomerId({customerId, page: currentPage, perPage: itemsPerPage, sort:key, sortOrder:direction, search, filters: filtersData}))
            .then((response) => {
                setActivities(response.payload.data);
                if ( response?.payload?.pagination ) {
                    const paginationData = response.payload.pagination;
                    setPagination({
                        currentPage: paginationData.page,
                        itemsPerPage: paginationData.per_page,
                        totalRecords: paginationData.total_records,
                        totalPages: paginationData.total_pages,
                    });
                }

            })
            .catch((error) => {
                console.log(error);
            });
    };

    // Fetch records from server when searchTerm changes
    useEffect(() => {
        // Clear the previous timeout if it exists
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        if (type === 'self-care') {
            // remove the filter option for customer_id and activity_visible_to_customer
            const filterOptionsCopy = {...filterOptions};
            delete filterOptionsCopy.customer_id;
            delete filterOptionsCopy.activity_visible_to_customer;

            setFilterOptions(filterOptionsCopy);
        }

        // Set a new timeout
        debounceTimeout.current = setTimeout(() => {
            fetchActivities(pagination.currentPage, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm);
        }, 500); // 500ms delay
    }, [searchTerm]);

    useEffect(() => {
        fetchActivities();

        return () => {
            setActivities([]);
        };
    }, [customerId]);

    document.title = "Customer Activities | Omnitouch CRM";
    return (
        <React.Fragment>
            <Row className="col-md-auto ms-auto">
                <h4 className="fw-semibold col-md-auto">Activities</h4>
            </Row>
            <Row className="col-md-auto ms-auto">
            <Nav
                    className="nav-tabs nav-tabs-custom nav-success border-bottom border-grey"
                    role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1", 'text-success': activeTab === "1", 'text-dark': activeTab !== "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "All");
                        }}
                        href="#"
                      >
                        <i className="ri-list-unordered me-1 align-bottom"></i>{" "}
                        All Activities
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "2", 'text-success': activeTab === "2", 'text-dark': activeTab !== "2" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("2", "Customer_Transaction");
                        }}
                        href="#"
                      >
                        <i className="ri-money-dollar-circle-line me-1 align-bottom"></i>{" "}
                        Transactions
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "3", 'text-success': activeTab === "3", 'text-dark': activeTab !== "3" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("3", "Customer_Support");
                        }}
                        href="#"
                      >
                        <i className="ri-lifebuoy-line me-1 align-bottom"></i>{" "}
                        Support
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "4", 'text-success': activeTab === "4", 'text-dark': activeTab !== "4" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("4", "Communication");
                        }}
                        href="#"
                      >
                        <i className="ri-chat-3-line me-1 align-bottom"></i>{" "}
                        Communications
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "5", 'text-success': activeTab === "5", 'text-dark': activeTab !== "5" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("5", "Other");
                        }}
                        href="#"
                      >
                        <i className="ri-question-line me-1 align-bottom"></i>{" "}
                        Other
                      </NavLink>
                    </NavItem>
                  </Nav>
            </Row>
            <Row className="col-md-auto ms-auto d-flex">
                <span className=' d-flex ms-auto mb-2 mt-4 gap-2 ps-0'>
                    <div className="search-box flex-grow-1">
                        {/* Search input */}
                        <Input
                            type="text"
                            placeholder={ "Search..."}
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                        <i className="ri-search-line search-icon"></i>
                    </div>
                    <Select
                        value={sortOptions.find(option => option.value === sortConfig.direction)}
                        onChange={selectedOption => handleSortChange(selectedOption)}
                        options={sortOptions}
                    />
                    <Button color="primary" onClick={toggleFilter} className='span py-1 pb-2'>
                        <i className="ri-filter-2-line fs-16" style={{ marginRight: '5px' }}></i> Filter
                    </Button>
                </span>
            </Row>
            <Collapse isOpen={isFilterOpen}>
                <Card>
                    <CardHeader>Filters</CardHeader>
                    <CardBody>
                        <Filter filterOptions={filterOptions} onFilterChange={handleFilterChange} onApplyFilters={applyFilters} onResetFilters={resetFilters}/>
                    </CardBody>
                </Card>
            </Collapse>
            {activities.length === 0 && (
                <div className='d-flex justify-content-center align-items-center'>
                    <span>No records found</span>
                </div>
            )}
            {activities.map((activity) => (
                <Card
                    key={activity.activity_id}
                    color={getClosestBootstrapColor(activity.tag_hex_color)}
                    style={{borderStyle: 'solid', borderWidth: '1px'}}
                    outline={true}
                >
                    <CardHeader className='fw-semibold pb-2'>
                        <h5 className="card-title">
                            {activity.activity_title}
                            <Tags
                                tags={
                                    [
                                        {
                                            value: activity.tag_text,
                                            color: getClosestBootstrapColor(activity.tag_hex_color),
                                            link: activity.tag_link
                                        },
                                    ]
                                }
                            />
                        </h5>
                    </CardHeader>
                    <CardBody className='fw-semibold py-2'>
                        <div className="acitivity-timeline py-3">
                            <div className="acitivity-item d-flex">
                                <div className="flex-shrink-0">
                                    <img src={activity.user_image ?? userDummyImg} alt="" className="avatar-sm rounded-circle activity-avatar" />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <h6 className="mb-1">{activity.user_name}</h6>
                                    <p className="text-muted mb-2">{activity.activity_body}</p>
                                    <small className="mb-0 text-muted">{activity.created_human}</small>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            ))}
            <div className='d-flex justify-content-between align-items-center'>
                <span>
                    {pagination.totalRecords === 0 
                        ? "Showing 0 - 0 of 0 records" 
                        : `Showing ${(pagination.currentPage - 1) * pagination.itemsPerPage + 1} - 
                            ${Math.min(pagination.currentPage * pagination.itemsPerPage, pagination.totalRecords)} 
                            of ${pagination.totalRecords} records`
                    }
                </span>
                <Pagination>
                    <PaginationItem disabled={pagination.currentPage <= 1}>
                        <PaginationLink previous onClick={() => handlePageChange(pagination.currentPage - 1)} />
                    </PaginationItem>
                    {[...Array(pagination.totalPages)].map((page, i) => {
                        // Always show the first, last, and two pages around the current page
                        if (i === 0 || i === pagination.totalPages - 1 || Math.abs(i + 1 - pagination.currentPage) <= 1) {
                            return (
                                <PaginationItem active={i + 1 === pagination.currentPage} key={i}>
                                    <PaginationLink onClick={() => handlePageChange(i + 1)}>
                                        {i + 1}
                                    </PaginationLink>
                                </PaginationItem>
                            );
                        }
                        // Replace other page numbers with ellipsis (but only once)
                        else if (i === 1 || i === pagination.totalPages - 2) {
                            return <PaginationItem disabled key={i}><PaginationLink>...</PaginationLink></PaginationItem>;
                        }
                        // Don't render other page numbers
                        else {
                            return null;
                        }
                    })}
                    <PaginationItem disabled={pagination.currentPage >= pagination.totalPages}>
                        <PaginationLink next onClick={() => handlePageChange(pagination.currentPage + 1)} />
                    </PaginationItem>
                </Pagination>
            </div>
        </React.Fragment>
    );
};

export default ActivitiesTab;