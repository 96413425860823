import {
  login,
  logout,
  postSocialLogin
} from "../../../helpers/backend_helper";
import { profileSuccess } from "../profile/reducer";

import { loginSuccess, logoutUserSuccess, apiError, reset_login_flag } from './reducer';

export const loginUser = (user, history) => async (dispatch) => {
  try {
    let response;
    // Use the actual login endpoint
    response = login({
      email: user.email,
      password: user.password
    });

    const data = await response;

    if (data) {
      sessionStorage.setItem("authUser", JSON.stringify(data));
      dispatch(loginSuccess(data));
      dispatch(profileSuccess(data));
      if (data?.data?.roles?.includes("customer")) {
        history('/self-care');
      } else {
        history('/dashboard');
      }
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};


export const logoutUser = () => async (dispatch) => {
  try {
    // Use the actual logout endpoint
    // await logout();
    sessionStorage.removeItem("authUser");
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const socialLogin = (type, history) => async (dispatch) => {
  try {
    let response;
    // Assuming postSocialLogin is the correct method for social login
    response = postSocialLogin(type);
      
    const socialdata = await response;
    if (socialdata) {
      sessionStorage.setItem("authUser", JSON.stringify(socialdata));
      dispatch(loginSuccess(socialdata));
      history('/dashboard');
    }

  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};