import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";

import CustomerInfo from "./CustomerInfo";
import Site from "./Site";
import ContactForm from "../../Contacts/components/ContactForm";

import classnames from "classnames";

import "./styles.css";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useNavigate } from "react-router-dom";

import {
  resetCustomer as onResetCustomer,
  getCustomers as onGetCustomers
} from "../../../slices/thunks";

const libraries = ["places"];

const CustomerForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // start customer
  const selectLayoutState = (state) => state.Customer;
  const selectcustomerProperties = createSelector(
      selectLayoutState,
      (state) => ({
      customer: state.customer,
      isCustomerSuccess: state.isCustomerSuccess,
      error: state.error,
      })
  );

  const {
      customer, isCustomerSuccess, error
  } = useSelector(selectcustomerProperties);
  // end customer

  const [formCompletionStatus, setFormCompletionStatus] = useState({
    customerInfo: false,
    contacts: false,
    site: false,
  });

  const [activeArrowTab, setactiveArrowTab] = useState(4);
  const [passedarrowSteps, setPassedarrowSteps] = useState([1]);

  function toggleArrowTab(tab) {
    if (activeArrowTab !== tab) {
      var modifiedSteps = [...passedarrowSteps, tab];

      if (tab >= 4 && tab <= 8) {
        setactiveArrowTab(tab);
        setPassedarrowSteps(modifiedSteps);
      }
    }
  }

  document.title = "Customer Details | Omnitouch CRM";
  return (
    <React.Fragment>
      <div>
        <Container>
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Create New Customer</h4>
                </CardHeader>
                <CardBody>
                  {/* <Form className="form-steps"> */}
                    <div className="step-arrow-nav mb-4">
                      <Nav
                        className="nav-pills custom-nav nav-justified"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                          href="#"
                            id="steparrow-gen-info-tab"
                            className={classnames({
                              active: activeArrowTab === 4,
                              done: activeArrowTab <= 6 && activeArrowTab > 3,
                            })}
                            onClick={() => {
                              toggleArrowTab(4);
                            }}
                          >
                            Customer Info
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                          href="#"
                            id="steparrow-gen-info-tab"
                            className={classnames({
                              active: activeArrowTab === 5,
                              done: activeArrowTab <= 6 && activeArrowTab > 4,
                              disabled: !formCompletionStatus.customerInfo,
                            })}
                            onClick={() => {
                              if (formCompletionStatus.customerInfo) {
                                toggleArrowTab(5);
                              }
                            }}
                          >
                            Contacts
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                          href="#"
                            id="steparrow-contacts-info-tab"
                            className={classnames({
                              active: activeArrowTab === 6,
                              done: activeArrowTab <= 7 && activeArrowTab > 5,
                              disabled: !formCompletionStatus.contacts,
                            })}
                            onClick={() => {
                              if (formCompletionStatus.contacts) {
                                toggleArrowTab(6);
                              }
                            }}
                          >
                            Site
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                          href="#"
                            id="steparrow-site-info-tab"
                            className={classnames({
                              active: activeArrowTab === 7,
                              done: activeArrowTab <= 7 && activeArrowTab > 6,
                              disabled: !formCompletionStatus.site,
                            })}
                            onClick={() => {
                              if (formCompletionStatus.site) {
                                toggleArrowTab(7);
                              }
                            }}
                          >
                            Finish
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>

                    <TabContent activeTab={activeArrowTab}>
                      <TabPane id="steparrow-gen-info" tabId={4}>
                        <CustomerInfo
                          customer={customer}
                          onNextStep={() => {
                            setFormCompletionStatus(prevState => ({ ...prevState, customerInfo: true }));
                            toggleArrowTab(activeArrowTab + 1);
                          }}
                        />
                      </TabPane>

                      { activeArrowTab === 5 && (
                        <TabPane id="steparrow-contacts-info" tabId={5}>
                            <ContactForm
                              customerId={customer.customer_id}
                              customer={customer}
                              onNextStep={() => {
                                setFormCompletionStatus(prevState => ({ ...prevState, contacts: true }));
                                toggleArrowTab(activeArrowTab + 1);
                              }} 
                            />
                        </TabPane>
                      )}

                      { activeArrowTab === 6 && (
                        <TabPane id="steparrow-site-info" tabId={6}>
                              <Site
                                customer={customer}
                                loadScript={false}
                                onNextStep={() => {
                                  setFormCompletionStatus(prevState => ({ ...prevState, site: true }));
                                  toggleArrowTab(activeArrowTab + 1);
                                  dispatch(onResetCustomer());
                                  setTimeout(() => {
                                    navigate(`/customers/${customer.customer_id}`);
                                    if (props.closeModal) {
                                      props.closeModal();
                                    }
                                  }, 1000);
                              }} />
                        </TabPane>
                      )}

                      <TabPane id="pills-experience" tabId={7}>
                        <div className="text-center">
                          <div className="avatar-md mt-5 mb-4 mx-auto">
                            <div className="avatar-title bg-light text-success display-4 rounded-circle">
                              <i className="ri-checkbox-circle-fill"></i>
                            </div>
                          </div>
                          <h5>Well Done !</h5>
                          <p className="text-muted">
                            You have Successfully Added a Customer
                          </p>
                        </div>
                      </TabPane>
                    </TabContent>
                  {/* </Form> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CustomerForm;
