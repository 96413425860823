import { createSlice } from "@reduxjs/toolkit";

import {
  resetPassword,
} from "./thunk";

export const initialState = {
  resetSuccessMsg: null,
  resetError: null,
};

const resetPasswordSlice = createSlice({
  name: "resetpwd",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.resetSuccessMsg = action.payload.message || null;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.resetError = action.error.message || null;
    });
  }
});

export default resetPasswordSlice.reducer;
