import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import CarouselItem from './CarouselItem';

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import './common.css';

const placeholderItem = {
    title: 'Title',
    subtitle: 'Subtitle',
}

const placeholderItems = Array(7).fill(placeholderItem);

const Carousel = ({ data = [], theme = 'card-primary', darken = false, carouselType, title = 'Carousel', footerText = 'Connect Now', action = null, card = true}) => {
    const renderWithCard = () => {
        return (
            <Card className={`align-self-center carousel ${carouselType}`}>
                { title && (
                    <CardHeader>
                        <h5 className="card-title">{title}</h5>
                    </CardHeader>
                )}
                <CardBody>
                    <Row className='justify-content-center'>
                        <Col md={'auto'} className='align-self-center  custom-prev-btn'>
                            <FaChevronLeft className='carousel-arrow' />
                        </Col>
                        <Col md={'11'}>
                            {/* Swiper */}
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={50}
                                slidesPerView={3}
                                navigation={{
                                    nextEl: '.custom-next-btn',
                                    prevEl: '.custom-prev-btn'
                                }}
                                pagination={{ clickable: true }}
                                loop={false}
                                a11y={false}
                                breakpoints={{
                                    // when window width is >= 320px
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 10
                                    },
                                    // when window width is >= 768px
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 20
                                    },
                                    1500: {
                                        slidesPerView: 4,
                                        spaceBetween: 30
                                    }
                                }}
                            >
                                {data.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <CarouselItem 
                                            item={item}
                                            theme={theme}
                                            darken={darken}
                                            index={index}
                                            footerText={footerText}
                                            action={action}
                                            totalItems={data.length}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Col>
                        <Col md={'auto'} className='align-self-center custom-next-btn'>
                            <FaChevronRight className='carousel-arrow' />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    };

    const renderWithoutCard = () => {
        return (
            <Row className='justify-content-center'>
                <Col md={'auto'} className='align-self-center  custom-prev-btn'>
                    <FaChevronLeft className='carousel-arrow' />
                </Col>
                <Col md={'11'}>
                    {/* Swiper */}
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={50}
                        slidesPerView={3}
                        navigation={{
                            nextEl: '.custom-next-btn',
                            prevEl: '.custom-prev-btn'
                        }}
                        pagination={{ clickable: true }}
                        loop={false}
                        a11y={false}
                        breakpoints={{
                            // when window width is >= 320px
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10
                            },
                            // when window width is >= 768px
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            1500: {
                                slidesPerView: 4,
                                spaceBetween: 30
                            }
                        }}
                    >
                        {data.map((item, index) => (
                            <SwiperSlide key={index}>
                                <CarouselItem 
                                    item={item}
                                    theme={theme}
                                    darken={darken}
                                    index={index}
                                    footerText={footerText}
                                    action={action}
                                    totalItems={data.length}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Col>
                <Col md={'auto'} className='align-self-center custom-next-btn'>
                    <FaChevronRight className='carousel-arrow' />
                </Col>
            </Row>
        );
    };

    return (
        <React.Fragment>
            { card ? renderWithCard() : renderWithoutCard() }
        </React.Fragment>
    );
};

export default Carousel;