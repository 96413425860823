import React from 'react';
import Chart from 'react-apexcharts';

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: 'basic-line-chart',
        },
        xaxis: {
          categories: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        },
      },
      series: [
        {
          name: 'Customer ARPU',
          data: [12, 19, 3, 5, 2, 3],
        },
        {
          name: 'Average ARPU',
          data: [5, 10, 4, 8, 3, 7],
        },
      ],
    };
  }

  render() {
    return (
      <div className='line-chart'>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type='line'
          height='320'
        />
      </div>
    );
  }
}

export default LineChart;