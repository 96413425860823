import React, { useState, useRef } from 'react';
import { Button, Form, FormGroup, Label, Input, Col, Row, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import {
    createInventoryItem,
    getCustomers,
    addNewTransaction,
} from '../../../slices/thunks';

const InventoryAdd = ({ inventoryTemplate, customerId = null }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        customer_id: customerId,
        inventory_template_id: inventoryTemplate.inventory_template_id,
        item: inventoryTemplate.item,
        item_location: '',
        retail_cost: inventoryTemplate.retail_cost,
        serial1: '',
        serial2: '',
        service_id: null,
        wholesale_cost: inventoryTemplate.wholesale_cost
    });

    const [validation, setValidation] = useState({
        retail_cost: true,
        serial1: false,
        serial2: false,
        wholesale_cost: true,
    });

    const [selectCustomerValidation, setSelectCustomerValidation] = useState(false);

    const [customers, setCustomers] = useState([]);
    const [assignModal, setAssignModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);

    const toggleAssignModal = () => {
        setAssignModal(!assignModal);
    };

    const toggleCreateModal = () => {
        setCreateModal(!createModal);
    };

    const handleAssignModalClose = () => {
        setAssignModal(false);
        clearState();
    };

    const handleCreateModalClose = () => {
        setCreateModal(false);
        clearState();
    };

    const handleAddTransaction = () => {
        const transactionData = {
            customer_id: formData.customer_id,
            description: `Purchase from Inventory`,
            retail_cost: formData.retail_cost,
            title: formData.item,
            wholesale_cost: formData.wholesale_cost,
        };
        dispatch(addNewTransaction(transactionData));
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        onChangeValidation(e.target.name, e.target.value);
    };

    //for change tooltip display propery
    const onChangeValidation = (fieldName, value) => {
        const modifiedV  = { ...validation };
        if (value !== "") {
            modifiedV[fieldName] = true;
        } else {
            modifiedV[fieldName] = false;
        }
        setValidation(modifiedV);
    };

    const handleAddNewToInventory = (e) => {
        if (!e.target.form.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        e.preventDefault();
        toggleCreateModal();
    };

    const onAddNewToInventoryConfirm = () => {
        dispatch(createInventoryItem(formData))
            .then(() => {
                toast.success('Inventory Item Created Successfully');
                toggleCreateModal();
                clearState();
            });
        };

    const handleAssignToCustomer = (e) => {
        if (!e.target.form.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        e.preventDefault();
        dispatch(getCustomers())
            .then((response) => {
                setCustomers(response.payload.data);
                toggleAssignModal();
            });
    };

    const onAssignToCustomerConfirm = () => {
        if (formData.customer_id === null) {
            setSelectCustomerValidation(false);
            return;
        }

        dispatch(createInventoryItem(formData))
            .then(() => {
                toast.success('Inventory Item Created Successfully');
                return handleAddTransaction();
            })
            .then(() => {
                setAssignModal(false);
                clearState();
            })
            .catch((error) => {
                toast.error('Inventory Item Creation Failed');
                console.error('Error posting transaction: ' + error);
            });
    };

    const clearState = () => {
        setFormData({
            customer_id: customerId,
            inventory_template_id: inventoryTemplate.inventory_template_id,
            item: inventoryTemplate.item,
            item_location: '',
            retail_cost: inventoryTemplate.retail_cost,
            serial1: '',
            serial2: '',
            service_id: null,
            wholesale_cost: inventoryTemplate.wholesale_cost
        });
        setValidation({
            retail_cost: true,
            serial1: false,
            serial2: false,
            wholesale_cost: true,
        });
    };
        
    const debounceTimeout = useRef(null);

    const onSearchInputChange = (e) => {
        e.preventDefault();
        const search = e.target.value;

        // Clear the previous timeout if it exists
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // Set a new timeout
        debounceTimeout.current = setTimeout(() => {
            dispatch(getCustomers({searchTerm: search}))
                .then((response) => {
                    setCustomers(response.payload.data);
                });
        }, 500); // 500ms delay
    };

    const loadOptions = (inputValue, callback) => {
        dispatch(getCustomers({searchTerm: inputValue}))
            .then((response) => {
                const options = response.payload.data.map(customer => ({ value: customer.customer_id, label: customer.customer_name }));
                callback(options);
            });
    };

    return (
        <>
            <Form>
                <Row>
                    <Col md={6}>
                    <FormGroup>
                        <Label for="item">{inventoryTemplate.item}</Label>
                        <Input type="text" name="item" id="item" value={formData.item} readOnly onChange={handleChange} />
                    </FormGroup>
                    </Col>
                    <Col md={6}>
                    <FormGroup>
                        <Label for="serial1">{inventoryTemplate.serial1_label}</Label>
                        <Input
                            type="text"
                            name="serial1"
                            id="serial1"
                            placeholder={inventoryTemplate.serial1_label}
                            value={formData.serial1}
                            onChange={handleChange}
                            required
                            valid={validation["serial1"] === true}
                            invalid={
                                validation["serial1"] !== true &&
                                validation["serial1"] !== null
                            }
                        />
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                    <FormGroup>
                        <Label for="serial2">{inventoryTemplate.serial2_label}</Label>
                        <Input
                            type="text"
                            name="serial2"
                            id="serial2"
                            placeholder={inventoryTemplate.serial2_label}
                            value={formData.serial2}
                            onChange={handleChange}
                            required
                            valid={validation["serial2"] === true}
                            invalid={
                                validation["serial2"] !== true &&
                                validation["serial2"] !== null
                            }
                        />
                    </FormGroup>
                    </Col>
                    <Col md={6}>
                    <FormGroup>
                        <Label for="retail_cost">Retail Cost:</Label>
                        <Input
                            type="number"
                            name="retail_cost"
                            id="retail_cost"
                            value={formData.retail_cost}
                            onChange={handleChange}
                            required
                            valid={validation["retail_cost"] === true}
                            invalid={
                                validation["retail_cost"] !== true &&
                                validation["retail_cost"] !== null
                            }
                        />
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="wholesale_cost">Wholesale Cost:</Label>
                            <Input
                                type="number"
                                name="wholesale_cost"
                                id="wholesale_cost"
                                value={formData.wholesale_cost}
                                onChange={handleChange}
                                required
                                valid={validation["wholesale_cost"] === true}
                                invalid={
                                    validation["wholesale_cost"] !== true &&
                                    validation["wholesale_cost"] !== null
                                }
                            />
                        </FormGroup>
                    </Col>
                    {/* Item Location */}
                    <Col md={6}>
                        <FormGroup>
                            <Label for="item_location">Item Location:</Label>
                            <Input type="text" name="item_location" id="item_location" value={formData.item_location} onChange={handleChange} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    {customerId === null ? (
                        <Col className="d-flex justify-content-end gap-2">
                            <Button type="submit" className='btn-success mr-2' onClick={handleAddNewToInventory}>Create New {formData.item} in Inventory pool</Button>
                            <Button type="submit" className='btn-primary' onClick={handleAssignToCustomer} >Assign New {formData.item} to Customer</Button>
                        </Col>
                    ) : (
                        <Col className="d-flex justify-content-end gap-2">
                            <Button type="submit" className='btn-success mr-2' onClick={handleAddNewToInventory}>Assign New {formData.item} to Customer: {customerId}</Button>
                        </Col>
                    )}
                </Row>
            </Form>
            {/* Assign Modal React Select */}
            <Modal isOpen={assignModal} toggle={toggleAssignModal} size='lg' centered>
                <ModalHeader toggle={toggleAssignModal}>Assign Inventory Item to Customer</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="customer_id">Select Customer:</Label>
                                <AsyncSelect
                                    loadOptions={loadOptions}
                                    onChange={(selectedOption) => {
                                        setFormData({ ...formData, customer_id: selectedOption.value });
                                        setSelectCustomerValidation(selectedOption.value !== null);
                                    }}
                                />
                            </FormGroup>
                            {!selectCustomerValidation && <div className="alert alert-danger">Please select a customer</div>}
                        </Col>
                    </Row>
                    
                </ModalBody>
                <ModalFooter>
                        <Button type="submit" className='btn-success mr-2' onClick={onAssignToCustomerConfirm}>Assign</Button>
                        <Button type="submit" className='btn-danger' onClick={handleAssignModalClose}>Cancel</Button>
                </ModalFooter>
            </Modal>
            {/* Create Modal Confirm Modal */}
            <Modal isOpen={createModal} toggle={toggleCreateModal} size='lg' centered>
                {customerId ? (
                    <>
                        <ModalHeader toggle={toggleCreateModal}>Assign Inventory Item to Customer: {customerId}</ModalHeader>
                        <ModalBody>
                            <p>Are you sure you want to create and assign this inventory item to the customer?</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="submit" className='btn-success mr-2' onClick={onAddNewToInventoryConfirm}>Create & Assign</Button>
                            <Button type="submit" className='btn-danger' onClick={handleCreateModalClose}>Cancel</Button>
                        </ModalFooter>
                    </>
                ) : (
                    <>
                        <ModalHeader toggle={toggleCreateModal}>Create New Inventory Item</ModalHeader>
                        <ModalBody>
                            <p>Are you sure you want to create this inventory item?</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="submit" className='btn-success mr-2' onClick={onAddNewToInventoryConfirm}>Create</Button>
                            <Button type="submit" className='btn-danger' onClick={handleCreateModalClose}>Cancel</Button>
                        </ModalFooter>
                    </>
                )}
                
            </Modal>
        </>
    );
};

export default InventoryAdd;