import React from 'react';
import { Row, Col } from 'reactstrap';

const InventoryTable = ({ inventory }) => {
    return (
        <Row>
            <Col>
                <h5>Inventory</h5>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Item</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Serial1</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Serial2</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Location</th>
                        </tr>
                    </thead>
                    <tbody>
                        {inventory && inventory.map((item, index) => (
                            <tr key={index}>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.item}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.serial1}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.serial2}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.location}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Col>
        </Row>
    );
};

export default InventoryTable;
