import React from 'react';
import ProvisioningList from './components/ProvisioningList';
import { Container } from 'reactstrap';

const RecentDeprrovisioningJobs = () => {
    const title = 'Recent Deprovisioning Jobs';
    const status = [0,1];
    const type = 'deprovisioning';

    document.title = 'Recent Deprovisioning Jobs | OmnitouchCRM';
    return (
        <div className='page-content'>
            <Container fluid>
                <ProvisioningList title={title} status={status} type={type}/>
            </Container>
        </div>
    );
};

export default RecentDeprrovisioningJobs;