import PropTypes from "prop-types";
import React from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

// redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useParams } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { resetPassword } from "../../slices/thunks";

// import images
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { createSelector } from "reselect";

const ResetPasswordPage = props => {
  const dispatch = useDispatch();
  const { token } = useParams();

  const validation = useFormik({
    // enableReinitialize: use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
      confirmPassword: Yup.string()
        .required("Please Confirm Your Password")
        .oneOf([Yup.ref('password')], "Passwords must match"),
    }),
    onSubmit: (values) => {
      dispatch(resetPassword({resetToken: token, newPassword: values.password}));
    }
  });

  const selectLayoutState = (state) => state.ResetPassword;
  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (state) => ({
      resetError: state.resetError,
      resetSuccessMsg: state.resetSuccessMsg,
    })
  );
  
  // Inside your component
  const {
    resetError, resetSuccessMsg
  } = useSelector(selectLayoutProperties);

  document.title = "Reset Password | Velzon - React Admin & Dashboard Template";
  
  return (
    <ParticlesAuth>
      <div className="auth-page-content mt-lg-5">

        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="20" />
                  </Link>
                </div>
                <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">

                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Reset Password</h5>
                    <p className="text-muted">Reset your password for Velzon</p>

                    <lord-icon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      trigger="loop"
                      colors="primary:#0ab39c"
                      className="avatar-xl"
                      style={{ width: "120px", height: "120px" }}
                    >
                    </lord-icon>

                  </div>

                  {resetError && resetError ? (
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      {resetError}
                    </Alert>
                  ) : null}
                  {resetSuccessMsg ? (
                    <Alert color="success" style={{ marginTop: "13px" }}>
                      {resetSuccessMsg}
                    </Alert>
                  ) : null}

                  <div className="p-2">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label">New Password</Label>
                        <Input
                          name="password"
                          className="form-control"
                          placeholder="Enter new password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid"><div>{validation.errors.password}</div></FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-4">
                        <Label className="form-label">Confirm Password</Label>
                        <Input
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Confirm new password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                          }
                        />
                        {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid"><div>{validation.errors.confirmPassword}</div></FormFeedback>
                        ) : null}
                      </div>

                      <div className="text-center mt-4">
                        <button className="btn btn-success w-100" type="submit">Reset Password</button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0">Remember your password? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Click here </Link> </p>
              </div>

            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

ResetPasswordPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(ResetPasswordPage);