import React, { useState, useEffect } from "react";
import { isEmpty, set } from "lodash";

import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag, changeUserPassword, updateUser } from "../../slices/thunks";
import { createSelector } from "reselect";

const UserProfile = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userData, setUserData] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);

  const selectLayoutState = (state) => state.Profile;
  const userprofileData = createSelector(
    selectLayoutState,
    (state) => ({
      user: state.user,
      success: state.success,
      error: state.error,
      changePasswordSuccess: state.changePasswordSuccess,
      changePasswordError: state.changePasswordError,
    })
  );
  // Inside your component
  const { user, success, error, changePasswordSuccess, changePasswordError } = useSelector(userprofileData);

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));

      if (!isEmpty(user)) {
        obj.data.first_name = user.first_name;
        sessionStorage.removeItem("authUser");
        sessionStorage.setItem("authUser", JSON.stringify(obj));
      }

      setUserData(obj.data);
      setUserName(obj.data.first_name);
      setEmail(obj.data.email);
      setUserId(obj.data.id || "1");
      setProfileImage(obj.data.user_image || avatar);

      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, user]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: userName || 'Admin',
      first_name: userData.first_name || '',
      middle_name: userData.middle_name || '',
      last_name: userData.last_name || '',
      email: email || '',
      phone_number: userData.phone_number || '',
      id: userId || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      first_name: Yup.string().required("Please Enter Your First Name"),
      middle_name: Yup.string(),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      email: Yup.string().email('Invalid email address').required("Please Enter Your Email"),
      phone_number: Yup.string(),
    }),
    onSubmit: (values) => {
      console.log("values", values);
      const formData = new FormData();
      Object.keys(values).forEach(key => {
        formData.append(key, values[key]);
      });
      if (isImageSelected && profileImage && profileImage !== avatar) {
        formData.append("user_image", profileImage);
      }
      dispatch(updateUser(formData));
      setIsImageSelected(false);
    }
  });

  const handleProfileImageChange = (event) => {
    const file = event.target.files[0];
    setProfileImage(file);
    setIsImageSelected(true);
  };

  const passwordValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      current_password: '',
      new_password: '',
      confirm_password: ''
    },
    validationSchema: Yup.object({
      current_password: Yup.string().required('Please Enter Your Current Password'),
      new_password: Yup.string().required('Please Enter Your New Password'),
      confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password')], 'Passwords do not match')
        .required('Please Confirm Your New Password'),
    }),
    onSubmit: (values) => {
      dispatch(changeUserPassword(values));
    }
  });

  document.title = "Profile | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content mt-lg-5">
        <Container fluid>
          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">User Information Updated Successfully</Alert> : null}
              {changePasswordError && <Alert color="danger">{changePasswordError}</Alert>}
              {changePasswordSuccess && !changePasswordError && <Alert color="success">Password Updated Successfully</Alert>}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <img
                        src={profileImage}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{userName || "Admin"}</h5>
                        <p className="mb-1">Email Id : {email}</p>
                        <p className="mb-0">Id No : #{userId}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Update User Information</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">User Name</Label>
                  <Input
                    name="username"
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username ? true : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                  ) : null}
                </div>
                <div className="form-group">
                  <Label className="form-label">First Name</Label>
                  <Input
                    name="first_name"
                    className="form-control"
                    placeholder="Enter First Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.first_name || ""}
                    invalid={
                      validation.touched.first_name && validation.errors.first_name ? true : false
                    }
                  />
                  {validation.touched.first_name && validation.errors.first_name ? (
                    <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                  ) : null}
                </div>
                <div className="form-group">
                  <Label className="form-label">Middle Name</Label>
                  <Input
                    name="middle_name"
                    className="form-control"
                    placeholder="Enter Middle Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.middle_name || ""}
                    invalid={
                      validation.touched.middle_name && validation.errors.middle_name ? true : false
                    }
                  />
                  {validation.touched.middle_name && validation.errors.middle_name ? (
                    <FormFeedback type="invalid">{validation.errors.middle_name}</FormFeedback>
                  ) : null}
                </div>
                <div className="form-group">
                  <Label className="form-label">Last Name</Label>
                  <Input
                    name="last_name"
                    className="form-control"
                    placeholder="Enter Last Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.last_name || ""}
                    invalid={
                      validation.touched.last_name && validation.errors.last_name ? true : false
                    }
                  />
                  {validation.touched.last_name && validation.errors.last_name ? (
                    <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                  ) : null}
                </div>
                <div className="form-group">
                  <Label className="form-label">Email</Label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder="Enter Email"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email ? true : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                  ) : null}
                </div>
                <div className="form-group">
                  <Label className="form-label">Phone Number</Label>
                  <Input
                    name="phone_number"
                    className="form-control"
                    placeholder="Enter Phone Number"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.phone_number || ""}
                    invalid={
                      validation.touched.phone_number && validation.errors.phone_number ? true : false
                    }
                  />
                  {validation.touched.phone_number && validation.errors.phone_number ? (
                    <FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
                  ) : null}
                </div>
                <div className="form-group">
                  <Label className="form-label">Profile Image</Label>
                  <Input
                    name="user_image"
                    className="form-control"
                    type="file"
                    onChange={handleProfileImageChange}
                  />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update Information
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>

          <h4 className="card-title mb-4">Roles</h4>

          <Card>
            <CardBody>
              <div className="text-muted">
                {userData && userData.roles && userData.roles.length > 0 ? (
                  userData.roles.map((role, index) => (
                    <p key={index} className="mb-1">Role: {role}</p>
                  ))
                ) : (
                  <p>No roles assigned.</p>
                )}
              </div>
            </CardBody>
          </Card>

          <h4 className="card-title mb-4">Permissions</h4>

          <Card>
            <CardBody>
              <div className="text-muted">
                {userData && userData.permissions && userData.permissions.length > 0 ? (
                  userData.permissions.map((permission, index) => (
                    <p key={index} className="mb-1">Permission: {permission}</p>
                  ))
                ) : (
                  <p>No permissions assigned.</p>
                )}
              </div>
            </CardBody>
          </Card>

          <h4 className="card-title mb-4">Change Password</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  passwordValidation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">Current Password</Label>
                  <Input
                    name="current_password"
                    type="password"
                    placeholder="Enter Current Password"
                    onChange={passwordValidation.handleChange}
                    onBlur={passwordValidation.handleBlur}
                    value={passwordValidation.values.current_password || ""}
                    invalid={
                      passwordValidation.touched.current_password && passwordValidation.errors.current_password ? true : false
                    }
                  />
                  {passwordValidation.touched.current_password && passwordValidation.errors.current_password ? (
                    <FormFeedback type="invalid">{passwordValidation.errors.current_password}</FormFeedback>
                  ) : null}
                </div>

                <div className="form-group">
                  <Label className="form-label">New Password</Label>
                  <Input
                    name="new_password"
                    type="password"
                    placeholder="Enter New Password"
                    onChange={passwordValidation.handleChange}
                    onBlur={passwordValidation.handleBlur}
                    value={passwordValidation.values.new_password || ""}
                    invalid={
                      passwordValidation.touched.new_password && passwordValidation.errors.new_password ? true : false
                    }
                  />
                  {passwordValidation.touched.new_password && passwordValidation.errors.new_password ? (
                    <FormFeedback type="invalid">{passwordValidation.errors.new_password}</FormFeedback>
                  ) : null}
                </div>

                <div className="form-group">
                  <Label className="form-label">Confirm New Password</Label>
                  <Input
                    name="confirm_password"
                    type="password"
                    placeholder="Confirm New Password"
                    onChange={passwordValidation.handleChange}
                    onBlur={passwordValidation.handleBlur}
                    value={passwordValidation.values.confirm_password || ""}
                    invalid={
                      passwordValidation.touched.confirm_password && passwordValidation.errors.confirm_password ? true : false
                    }
                  />
                  {passwordValidation.touched.confirm_password && passwordValidation.errors.confirm_password ? (
                    <FormFeedback type="invalid">{passwordValidation.errors.confirm_password}</FormFeedback>
                  ) : null}
                </div>

                <div className="text-center mt-4">
                  <Button type="submit" color="primary">
                    Change Password
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
