import React, { useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Card, CardBody, CardHeader, Row, Col, Collapse} from 'reactstrap';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import Pagination from './Pagination';
import Filter from './Filter';
import Select from 'react-select';

const TableCommon = (props) => {
  // State for search functionality
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const debounceTimeout = useRef(null);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(props.pagination?.currentPage ?? 1);
  const [itemsPerPage, setItemsPerPage] = useState(props.pagination?.itemsPerPage ?? 10);

  // State for sorting
  const [sortConfig, setSortConfig] = useState({ key: props.sortConfig?.key ?? 'id', direction: props.sortConfig?.direction ?? 'desc' });

  // State for multi-delete
  const [selectedIds, setSelectedIds] = useState([]);
  // State for select all checkbox
  const [selectAll, setSelectAll] = useState(false);

  // filter states
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const toggleFilter = () => setIsFilterOpen(!isFilterOpen);

  useEffect(() => {
    // Function to handle sorting
    const sortData = () => {
      if (!Array.isArray(props.data)) {
        console.error('props.data is not an array:', props.data);
        return [];
      }
    
      const sortedData = [...props.data];
    
      if (sortConfig.key !== null) {
        sortedData.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'desc' ? 1 : -1;
          }
          return 0;
        });
      }

      return sortedData;
    };
  
    // Function to handle search
    const filterData = sortedData => {
      const results = sortedData.filter(item =>
        Object.values(item).some(value =>
          value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
  
      setSearchResults(results);
    };
  
    const sortedData = sortData();
    filterData(sortedData);
  }, [props.data, searchTerm, sortConfig]);

  // Function to handle checkbox change
  const handleCheckboxChange = (id, isChecked) => {
    if (isChecked) {
      setSelectedIds(prevIds => [...prevIds, id]);
      // If all row checkboxes are checked, also check the "Select All" checkbox
      if (props.data.length === selectedIds.length + 1) {
        setSelectAll(true);
      }
    } else {
      setSelectedIds(prevIds => prevIds.filter(prevId => prevId !== id));
      // If a row checkbox is unchecked, also uncheck the "Select All" checkbox
      setSelectAll(false);
    }
  };

  // Function to handle select all checkbox change
  const handleSelectAllCheckboxChange = (isChecked) => {
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedIds(props.data.map(item => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  // Function to handle sorting
  const requestSort = key => {
    // If sortableColumns prop is passed and key is not in sortableColumns, return
    if (props.sortableColumns && !props.sortableColumns.includes(key)) {
      return;
    }
  
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  
    // Fetch records from server if pagination prop is true
    if (props.pagination) {
      props.fetchRecords(currentPage, itemsPerPage, key, direction, searchTerm);
    }
  };

  const handleSort = (key, value) => {
    setSortConfig({ key, direction: value });

    // Fetch records from server if pagination prop is true
    if (props.pagination) {
      props.fetchRecords(currentPage, itemsPerPage, key, value, searchTerm);
    }
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = props.pagination ? props.data : searchResults.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = pageNumber => {
    setCurrentPage(pageNumber);

    // Fetch records from server if pagination prop is true
    if (props.pagination) {
      props.fetchRecords(pageNumber, itemsPerPage, sortConfig.key, sortConfig.direction);
    }
  };

  // Fetch records from server when searchTerm changes
  useEffect(() => {
    // Clear the previous timeout if it exists
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout
    debounceTimeout.current = setTimeout(() => {
      if (props.pagination) {
        props.fetchRecords(currentPage, itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm);
      }
    }, 500); // 500ms delay
  }, [searchTerm]);

  // Update currentPage and itemsPerPage when pagination prop changes
  useEffect(() => {
    if (props.pagination) {
      setCurrentPage(props.pagination.currentPage);
      setItemsPerPage(props.pagination.itemsPerPage);
    }
  }, [props.pagination]);

  const renderWithCard = () => {
    return (
      <Card>
        {!props.noHeader && (
          <CardHeader className='bg-transparent'>
            <h5 className='mb-0'>{props.title ?? 'Table'}</h5>
          </CardHeader>
        )}
        <CardBody>
            { !props?.viewOnly && (
              <Row className="g-4 mb-3 col-md-auto ms-auto d-flex mt-1 gap-1">
                  { (props?.add || props?.multiDelete) && (
                    <Col className="col-sm-auto ms-auto ps-0">
                        <div>
                            { props?.add ? <Button color="success" className="add-btn me-1" onClick={() => props.add()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add New</Button> : null }
                            {props.multiDelete && (
                              <Button className='btn btn-soft-danger' disabled={selectedIds.length <= 0} onClick={() => props.multiDelete(selectedIds)}><i className="ri-delete-bin-2-line"></i>
                              </Button>
                            )}
                        </div>
                    </Col>
                  )}
                  <Col className="col-sm ps-0">
                      <div className="d-flex justify-content-sm-end">
                          <div className="search-box flex-grow-1">
                            {/* Search input */}
                            <Input
                              type="text"
                              placeholder={props.searchPlaceholder ?? "Search..."}
                              value={searchTerm}
                              onChange={e => setSearchTerm(e.target.value)}
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                      </div>
                  </Col>
                  {props.sortOptions && (
                    <Col className="col-sm-auto ps-0">
                      <Select
                          value={props.sortOptions.find(option => option.value === sortConfig.direction)}
                          onChange={selectedOption => handleSort('created', selectedOption.value)}
                          options={props.sortOptions}
                      />
                    </Col>
                  
                  )}
                  { props.filters && (
                    <Col className="col-sm-auto ps-0">
                        <div className="d-flex justify-content-sm-end">
                          <Button color="primary" onClick={toggleFilter} className='span py-1 pb-2'>
                              <i className="ri-filter-2-line fs-16" style={{ marginRight: '5px' }}></i> Filter
                          </Button>
                        </div>
                    </Col>
                  )}
              </Row>
            )}
            { props.filters && (
              <Collapse isOpen={isFilterOpen}>
                  <Card>
                      <CardHeader>Filters</CardHeader>
                      <CardBody>
                          <Filter filterOptions={props.filters?.filterOptions} onFilterChange={props.filters?.handleFilterChange} onApplyFilters={props.filters?.applyFilters} onResetFilters={props.filters?.resetFilters}/>
                      </CardBody>
                  </Card>
              </Collapse>
            )}

            <div className='table-responsive'>
              {/* Table */}
              <Table className='table-striped table-wrap align-middle mb-0 table-hover'>
                <thead>
                  <tr>
                    {props.data && props.data.length > 0 && props.multiDelete && (
                      <th>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={e => handleSelectAllCheckboxChange(e.target.checked)}
                        />
                      </th>
                    )}
                    {props.data && props.data.length > 0 && Object.keys(props.data[0]).map((key, index) => (
                      key !== 'id' ? (
                        <th key={index} onClick={() => key !== 'actions' && requestSort(key)}>
                          {key.replace(/_/g, ' ').toUpperCase()}
                          {sortConfig.key === key && key !== 'actions' ? (
                            sortConfig.direction === 'asc' ? (
                              <FaSortUp />
                            ) : (
                              <FaSortDown />
                            )
                          ) : null}
                        </th>
                      ) : null
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentItems && currentItems.length > 0 ? (
                    currentItems.map((item, index) => (
                      <tr key={index} onClick={() => props.onRowClick && props.onRowClick(item.id)} style={props.onRowClick && {cursor: 'pointer'}}>
                        {props.multiDelete && (
                          <td>
                            <input
                              type="checkbox"
                              checked={selectAll || selectedIds.includes(item.id)}
                              onChange={e => {e.stopPropagation();handleCheckboxChange(item.id, e.target.checked);}}
                              onClick={e => e.stopPropagation()}
                            />
                          </td>
                        )}
                        {Object.entries(item).map(([key, value], index) => (
                          key !== 'id' ? (
                            <td key={index} className='td-style-200'>
                              {key === 'actions' ? (
                                // if value is an array, render buttons
                                Array.isArray(value) ? (
                                  <div className="d-flex gap-2">
                                    {value.map((action, index) => (
                                      <div className={action} key={index}>
                                        <button
                                          className={`btn btn-sm ${action === 'delete' ? 'btn-danger remove-item-btn' : 'btn-success edit-item-btn'}`}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            props[action](item.id);
                                          }}
                                        >
                                          {action.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                        </button>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  value
                                )
                              ) : Array.isArray(value) ? (
                                value.map((val, i) => <div className='list-item' key={i}>{val}</div>)
                              ) : (
                                value
                              )}
                            </td>
                          ) : null
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr className='d-flex justify-content-center align-items-center'>
                      <td colSpan={props.data && props.data.length > 0 ? Object.keys(props.data[0]).length : 1}>No records found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>

            {/* Pagination */}
            <Pagination
              dataLength={props.pagination ? props.pagination.totalRecords ?? 0: searchResults.length}
              perPageData={itemsPerPage}
              currentPage={currentPage}
              setCurrentPage={paginate}
            />
        </CardBody>
      </Card>
    );
  };

  const renderWithoutCard = () => {
    return (
      <React.Fragment>
        {!props.noHeader && (
          <div className='bg-transparent'>
            <h5 className='mb-0'>{props.title ?? 'Table'}</h5>
          </div>
        )}
        <div>
            { !props?.viewOnly && (
              <Row className="g-4 mb-3 col-md-auto ms-auto d-flex mt-1 gap-1">
                  { (props?.add || props?.multiDelete) && (
                    <Col className="col-sm-auto ms-auto ps-0">
                        <div>
                            { props?.add ? <Button color="success" className="add-btn me-1" onClick={() => props.add()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add New</Button> : null }
                            {props.multiDelete && (
                              <Button className='btn btn-soft-danger' disabled={selectedIds.length <= 0} onClick={() => props.multiDelete(selectedIds)}><i className="ri-delete-bin-2-line"></i>
                              </Button>
                            )}
                        </div>
                    </Col>
                  )}
                  <Col className="col-sm ps-0">
                      <div className="d-flex justify-content-sm-end">
                          <div className="search-box flex-grow-1">
                            {/* Search input */}
                            <Input
                              type="text"
                              placeholder={props.searchPlaceholder ?? "Search..."}
                              value={searchTerm}
                              onChange={e => setSearchTerm(e.target.value)}
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                      </div>
                  </Col>
                  {props.sortOptions && (
                    <Col className="col-sm-auto ps-0">
                      <Select
                          value={props.sortOptions.find(option => option.value === sortConfig.direction)}
                          onChange={selectedOption => handleSort('created', selectedOption.value)}
                          options={props.sortOptions}
                      />
                    </Col>
                  
                  )}
                  { props.filters && (
                    <Col className="col-sm-auto ps-0">
                        <div className="d-flex justify-content-sm-end">
                          <Button color="primary" onClick={toggleFilter} className='span py-1 pb-2'>
                              <i className="ri-filter-2-line fs-16" style={{ marginRight: '5px' }}></i> Filter
                          </Button>
                        </div>
                    </Col>
                  )}
              </Row>
            )}
            { props.filters && (
              <Collapse isOpen={isFilterOpen}>
                  <Card>
                      <CardHeader>Filters</CardHeader>
                      <CardBody>
                          <Filter filterOptions={props.filters?.filterOptions} onFilterChange={props.filters?.handleFilterChange} onApplyFilters={props.filters?.applyFilters} onResetFilters={props.filters?.resetFilters}/>
                      </CardBody>
                  </Card>
              </Collapse>
            )}

            <div className='table-responsive'>
              {/* Table */}
              <Table className='table-striped table-wrap align-middle mb-0 table-hover'>
                <thead>
                  <tr>
                    {props.data && props.data.length > 0 && props.multiDelete && (
                      <th>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={e => handleSelectAllCheckboxChange(e.target.checked)}
                        />
                      </th>
                    )}
                    {props.data && props.data.length > 0 && Object.keys(props.data[0]).map((key, index) => (
                      key !== 'id' ? (
                        <th key={index} onClick={() => key !== 'actions' && requestSort(key)}>
                          {key.replace(/_/g, ' ').toUpperCase()}
                          {sortConfig.key === key && key !== 'actions' ? (
                            sortConfig.direction === 'asc' ? (
                              <FaSortUp />
                            ) : (
                              <FaSortDown />
                            )
                          ) : null}
                        </th>
                      ) : null
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentItems && currentItems.length > 0 ? (
                    currentItems.map((item, index) => (
                      <tr key={index} onClick={() => props.onRowClick && props.onRowClick(item.id)} style={props.onRowClick && {cursor: 'pointer'}}>
                        {props.multiDelete && (
                          <td>
                            <input
                              type="checkbox"
                              checked={selectAll || selectedIds.includes(item.id)}
                              onChange={e => {e.stopPropagation();handleCheckboxChange(item.id, e.target.checked);}}
                              onClick={e => e.stopPropagation()}
                            />
                          </td>
                        )}
                        {Object.entries(item).map(([key, value], index) => (
                          key !== 'id' ? (
                            <td key={index} className='td-style-200'>
                              {key === 'actions' ? (
                                // if value is an array, render buttons
                                Array.isArray(value) ? (
                                  <div className="d-flex gap-2">
                                    {value.map((action, index) => (
                                      <div className={action} key={index}>
                                        <button
                                          className={`btn btn-sm ${action === 'delete' ? 'btn-danger remove-item-btn' : 'btn-success edit-item-btn'}`}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            props[action](item.id);
                                          }}
                                        >
                                          {action.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                        </button>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  value
                                )
                              ) : Array.isArray(value) ? (
                                value.map((val, i) => <div className='list-item' key={i}>{val}</div>)
                              ) : (
                                value
                              )}
                            </td>
                          ) : null
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr className='d-flex justify-content-center align-items-center'>
                      <td colSpan={props.data && props.data.length > 0 ? Object.keys(props.data[0]).length : 1}>No records found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>

            {/* Pagination */}
            <Pagination
              dataLength={props.pagination ? props.pagination.totalRecords ?? 0: searchResults.length}
              perPageData={itemsPerPage}
              currentPage={currentPage}
              setCurrentPage={paginate}
            />
        </div>
      </React.Fragment>
    );
  };

  return (
    props.noCard ? (
      renderWithoutCard()
    ) : (
      renderWithCard()
    )
  );
};

export default TableCommon;
