import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';

import { useDispatch } from 'react-redux';

import {
    createInventoryItem as onCreateInventoryItem,
    updateInventory as onUpdate
} from '../../../slices/thunks';

const InventoryForm = ({ initialData, onSubmit = null, onClose = null}) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        item: '',
        item_location: '',
        retail_cost: '',
        wholesale_cost: '',
        serial1: '',
        serial2: '',
    });

    useEffect(() => {
        if (initialData) {
            setFormData({
                item: initialData.item || '',
                item_location: initialData.item_location || '',
                retail_cost: initialData.retail_cost || '',
                wholesale_cost: initialData.wholesale_cost || '',
                serial1: initialData.serial1 || '',
                serial2: initialData.serial2 || '',
            });
        }
    }, [initialData]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleUpdateInventoryItem = (data) => {
        dispatch(onUpdate(data))
            .then(() => {
                toast.success('Inventory Item Updated Successfully');
                if (onSubmit) onSubmit();
                if (onClose) onClose();
            })
            .catch((error) => {
                toast.error('Inventory Item Update Failed');
                console.error(error);
            });
    };

    const handleCreateInventoryItem = (data) => {
        dispatch(onCreateInventoryItem(data))
            .then(() => {
                toast.success('Inventory Item Created Successfully');
                if (onSubmit) onSubmit();
                if (onClose) onClose();
            })
            .catch((error) => {
                toast.error('Inventory Item Creation Failed');
                console.error(error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedData = {
            ...initialData,
            ...formData,
        };
        if (initialData) {
            handleUpdateInventoryItem(updatedData);
        } else {
            handleCreateInventoryItem(formData);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="item">Item:</Label>
                        <Input type="text" name="item" id="item" value={formData.item} onChange={handleChange} required />
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="item_location">Item Location:</Label>
                        <Input type="text" name="item_location" id="item_location" value={formData.item_location} onChange={handleChange} required />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="retail_cost">Retail Cost:</Label>
                        <Input type="number" name="retail_cost" id="retail_cost" value={formData.retail_cost} onChange={handleChange} required />
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="wholesale_cost">Wholesale Cost:</Label>
                        <Input type="number" name="wholesale_cost" id="wholesale_cost" value={formData.wholesale_cost} onChange={handleChange} required />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="serial1">Serial 1:</Label>
                        <Input type="text" name="serial1" id="serial1" value={formData.serial1} onChange={handleChange} required />
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="serial2">Serial 2:</Label>
                        <Input type="text" name="serial2" id="serial2" value={formData.serial2} onChange={handleChange} required />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex justify-content-end'>
                    <Button type="submit" className='btn btn-success' onClick={handleSubmit}>Save</Button>
                </Col>
            </Row>
        </Form>
    );
};

export default InventoryForm;