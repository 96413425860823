// SelfCare/components/MainNavigation.js

import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { withTranslation } from "react-i18next";

const MainNavigation = ({t}) => {
  return (
    <Nav tabs className="nav-tabs-custom border-bottom-0">
      <NavItem>
        <NavLink tag={RouterNavLink} to="/self-care/usage" activeClassName="active">
          {t('Usage')}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={RouterNavLink} to="/self-care/sites" activeClassName="active">
          {t('Sites')}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={RouterNavLink} to="/self-care/services" activeClassName="active">
          {t('Services')}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={RouterNavLink} to="/self-care/contacts" activeClassName="active">
          {t('Contacts')}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={RouterNavLink} to="/self-care/billing" activeClassName="active">
          {t('Billing')}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={RouterNavLink} to="/self-care/activity" activeClassName="active">
          {t('Activity')}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={RouterNavLink} to="/self-care/inventory" activeClassName="active">
          {t('Inventory')}
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default withTranslation()(MainNavigation);
