import React, { useState, useEffect} from 'react'
import { Col, Container, Modal, Row, ModalBody, ModalFooter, ModalHeader, Button, Input} from 'reactstrap';
import TableCommon from '../../Components/Common/TableCommon';
import DeleteModal from '../../Components/Common/DeleteModal';
import ContactForm from './components/ContactForm';
import Tags from '../../Components/Common/Tags';

//Import actions
import {
    deleteContact as onDeleteContact,
    sendAuthMessageToContact as onAuthorizeContact,
    updateContact as onUpdateContact,
    getContactsByCustomerId as onGetContactsByCustomerId,
    resetContacts as onResetContacts,
} from "../../slices/thunks";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const ContactList = ({ customerId, customer, type=null }) => {
    const dispatch = useDispatch();

    const {
        contacts
    } = useSelector(state => state.Contact);

    const [contactList, setContactList] = useState([]);
    const [currentContact, setCurrentContact] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [authorizeModal, setAuthorizeModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [isContactsFetched, setIsContactsFetched] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        itemsPerPage: 10,
        totalRecords: 0,
        totalPages: 0,
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'contact_id', direction: 'desc' });

    const toggleAddModal = () => setAddModal(!addModal);
    const toggleDeleteModal = () => setDeleteModal(!deleteModal);
    const toggleAuthorizeModal = () => setAuthorizeModal(!authorizeModal);
    const toggleUpdateModal = () => setUpdateModal(!updateModal);

    useEffect(() => {
        if (!isContactsFetched) {
            fetchContacts();
        }
    }, [customerId, dispatch, isContactsFetched]);

    useEffect(() => {
        if (contacts.data && Array.isArray(contacts.data)) {
            setContactList(contacts.data.map(contact => ({
                id: contact.contact_id,
                name: contact.contact_firstname + ' ' + contact.contact_lastname,
                email: contact.contact_email,
                "job title": contact.contact_job_title,
                phone: contact.contact_phone,
                "contact_types": (
                    <Tags
                        tags={
                            typeof contact.contact_types === 'string' && contact.contact_types.includes(',')
                                ? contact.contact_types.split(',').filter(type => type !== '')
                                : typeof contact.contact_types === 'array' ? contact.contact_types : [contact.contact_types]
                        }
                    />
                ),
                actions: (
                    <ul className="list-inline hstack gap-2 mb-0">
                        <li className="list-inline-item edit">
                            <Link
                            title={'Edit'}
                            className="text-primary d-inline-block edit-item-btn"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleContactView(contact.contact_id);
                            }}
                            >
                            <i className="ri-pencil-fill fs-16"></i>
                            </Link>
                        </li>
                        <li className="list-inline-item">
                            <Link
                            title={'Delete'}
                            disabled={type === 'self-care' && contacts.data.length === 1}
                            className="text-danger d-inline-block remove-item-btn"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleContactDelete(contact.contact_id);
                            }}
                            >
                            <i className="ri-delete-bin-5-fill fs-16"></i>
                            </Link>
                        </li>
                        <li className="list-inline-item edit">
                            <Link
                            title={'Authorize'}
                            className="text-secondary d-inline-block edit-item-btn"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleContactAuthorize(contact.contact_id);
                            }}
                            >
                            <i className="ri-shield-check-fill fs-16"></i>
                            </Link>
                        </li>
                    </ul>
                )
            })));
        }

        if (contacts.pagination) {
            setPagination({
                currentPage: contacts.pagination.page,
                itemsPerPage: contacts.pagination.per_page,
                totalRecords: contacts.pagination.total_records,
                totalPages: contacts.pagination.total_pages,
            });
        }
    }, [contacts]);
        

    const handleContactView = (id) => {
        setCurrentContact(contacts.data.find(contact => contact.contact_id === id));
        toggleUpdateModal();
    };

    const handleContactDelete = (id) => {
        setCurrentContact(contacts.data.find(contact => contact.contact_id === id));
        toggleDeleteModal();
    };

    const handleContactConfirmDelete = () => {
        dispatch(onDeleteContact(currentContact.contact_id))
            .then(() => {
                toast.success('Contact deleted successfully',
                    {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            })
            .catch(error => {
                console.log(error);
                toast.error('Failed to delete contact', { autoClose: 3000 });
            })
            .finally(() => fetchContacts(pagination.currentPage, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm));

        // Reset the current contact
        setCurrentContact(null);

        // Close the delete modal
        setDeleteModal(false);
    };

    const handleContactAuthorize = (id) => {
        setCurrentContact(contacts.data.find(contact => contact.contact_id === id));
        toggleAuthorizeModal();
    };

    const handleContactConfirmAuthorize = () => {
        dispatch(onAuthorizeContact(currentContact.contact_id))
            .then(() => {
                toast.success('Contact authorized successfully',
                    {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            })
            .catch(error => {
                console.log(error);
                toast.error('Failed to authorize contact', { autoClose: 3000 });
            })
            .finally(() => fetchContacts(pagination.currentPage, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm));

        // Reset the current contact
        setCurrentContact(null);

        // Close the authorize modal
        toggleAuthorizeModal();
    }

    const fetchContacts = (page = 1, perPage = 10, sort = 'contact_id', sortOrder = 'desc', searchTerm = '') => {
        // Process sort key
        if (sort === 'name') {
            sort = 'contact_firstname';
        } else if (sort === 'email') {
            sort = 'contact_email';
        } else if (sort === 'job title') {
            sort = 'contact_job_title';
        } else if (sort === 'phone') {
            sort = 'contact_phone';
        } else if (sort === 'contact types') {
            sort = 'contact_types';
        }

        dispatch(onGetContactsByCustomerId({customerId, searchTerm, page, perPage, sort, sortOrder}))
            .then(() => {
                setIsContactsFetched(true);
                setSearchTerm(searchTerm);
                setSortConfig({ key: sort, direction: sortOrder });
            })
            .catch(error => {
                console.log(error);
                toast.error('Failed to fetch contacts', { autoClose: 3000 });
            });
    }

    useEffect(() => {
        // This function is called once after the component mounts
    
        // Fetch contacts when the component mounts
        fetchContacts();
    
        // Return a function to be called when the component unmounts
        return () => {
            // Reset contacts when the component unmounts
            dispatch(onResetContacts());
        };
    }, []);
    
    document.title = "Contact List | OmniCRM";
    return (
        <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <TableCommon
                                title="Contacts"
                                data={contactList}
                                edit={handleContactView}
                                delete={handleContactDelete}
                                authorize={handleContactAuthorize}
                                add={toggleAddModal}
                                pagination={pagination}
                                sortConfig={sortConfig}
                                fetchRecords={fetchContacts}
                                onRowClick={handleContactView}
                            />
                        </Col>
                    </Row>
                    <DeleteModal
                        show={deleteModal}
                        onDeleteClick={handleContactConfirmDelete}
                        onCloseClick={() => setDeleteModal(false)}
                    />
                    <Modal isOpen={authorizeModal} toggle={toggleAuthorizeModal} centered>
                        <ModalHeader toggle={toggleAuthorizeModal}>Authorize Contact</ModalHeader>
                        <ModalBody>
                            Are you sure you want to authorize this contact?
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="btn btn-secondary" onClick={toggleAuthorizeModal}>Cancel</button>
                            <button type="button" className="btn btn-success" onClick={handleContactConfirmAuthorize}>Authorize</button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={updateModal} toggle={toggleUpdateModal} centered size='xl'>
                        <ModalHeader toggle={toggleUpdateModal}>Update Contact</ModalHeader>
                        <ModalBody>
                            <ContactForm customerId={currentContact?.customer_id ?? null} initialContact={currentContact} onNextStep={() => {toggleUpdateModal(); fetchContacts();}} onCancel={toggleUpdateModal}/>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={addModal} toggle={toggleAddModal} centered size='xl'>
                        <ModalHeader toggle={toggleAddModal}>Add Contact</ModalHeader>
                        <ModalBody>
                            <ContactForm standalone customer={customer} customerId={customer.customer_id} onNextStep={() => {toggleAddModal(); fetchContacts();}} onCancel={toggleAddModal}/>
                        </ModalBody>
                    </Modal>
                </Container>
        </React.Fragment>
    )
}

export default ContactList;