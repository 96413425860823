import { createAsyncThunk } from "@reduxjs/toolkit";

// Include Both Helper File with needed methods
import {
  getAllInventoryItems as getAllInventoryItemsApi,
  createInventoryItem as createInventoryItemApi,
  getInventoryByCustomerId as getInventoryByCustomerIdApi,
  deleteInventory as deleteInventoryApi,
  getInventoryById as getInventoryByIdApi,
  updateInventory as updateInventoryApi,
  getInventoryByItemName as getInventoryByItemNameApi,
  getInventoryByTemplateId as getInventoryByTemplateIdApi,
  getAllInventoryTemplates as getAllInventoryTemplatesApi,
  createInventoryTemplateItem as createInventoryTemplateItemApi,
  deleteInventoryTemplateById as deleteInventoryTemplateByIdApi,
  getInventoryTemplateById as getInventoryTemplateByIdApi,
  updateInventoryTemplateById as updateInventoryTemplateByIdApi,
  getTotalsOfInventoryItems as getTotalsOfInventoryItemsApi,
} from "../../helpers/backend_helper";

export const getAllInventoryItems = createAsyncThunk("inventory/getAllInventoryItems", async ({currentPage = 1, itemsPerPage = null, key, direction, searchTerm, filters}) => {
  try {
    const response = await getAllInventoryItemsApi(currentPage, itemsPerPage, key, direction, searchTerm, filters);
    return response;
  } catch (error) {
    return error;
  }
});

export const createInventoryItem = createAsyncThunk("inventory/createInventoryItem", async (data) => {
  try {
    const response = await createInventoryItemApi(data);
    return response;
  } catch (error) {
    return error;
  }
});

export const getInventoryByCustomerId = createAsyncThunk("inventory/getInventoryByCustomerId", async ({customerId, currentPage = 1, itemsPerPage = null, key = 'inventory_id', direction = 'desc', searchTerm = '', filters = {}}) => {
  try {
    const response = await getInventoryByCustomerIdApi(customerId, currentPage, itemsPerPage, key, direction, searchTerm, filters);
    return response;
  } catch (error) {
    return error;
  }
});

export const deleteInventory = createAsyncThunk("inventory/deleteInventory", async (inventoryId) => {
  try {
    const response = await deleteInventoryApi(inventoryId);
    return response;
  } catch (error) {
    return error;
  }
});

export const getInventoryById = createAsyncThunk("inventory/getInventoryById", async (inventoryId) => {
  try {
    const response = await getInventoryByIdApi(inventoryId);
    return response;
  } catch (error) {
    return error;
  }
});

export const updateInventory = createAsyncThunk("inventory/updateInventory", async (data) => {
  try {
    const response = await updateInventoryApi(data);
    return response;
  } catch (error) {
    return error;
  }
});

export const getInventoryByItemName = createAsyncThunk("inventory/getInventoryByItemName", async (itemName) => {
  try {
    const response = await getInventoryByItemNameApi(itemName);
    return response;
  } catch (error) {
    return error;
  }
});

export const getInventoryByTemplateId = createAsyncThunk("inventory/getInventoryByTemplateId", async (templateId) => {
  try {
    const response = await getInventoryByTemplateIdApi(templateId);
    return response;
  } catch (error) {
    return error;
  }
});

export const getAllInventoryTemplates = createAsyncThunk("inventory/getAllInventoryTemplates", async () => {
  try {
    const response = await getAllInventoryTemplatesApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const createInventoryTemplateItem = createAsyncThunk("inventory/createInventoryTemplateItem", async (data) => {
  try {
    const response = await createInventoryTemplateItemApi(data);
    return response;
  } catch (error) {
    return error;
  }
});

export const deleteInventoryTemplateById = createAsyncThunk("inventory/deleteInventoryTemplateById", async (templateId) => {
  try {
    const response = await deleteInventoryTemplateByIdApi(templateId);
    return response;
  } catch (error) {
    return error;
  }
});

export const getInventoryTemplateById = createAsyncThunk("inventory/getInventoryTemplateById", async (templateId) => {
  try {
    const response = await getInventoryTemplateByIdApi(templateId);
    return response;
  } catch (error) {
    return error;
  }
});

export const updateInventoryTemplateById = createAsyncThunk("inventory/updateInventoryTemplateById", async (data) => {
  try {
    const response = await updateInventoryTemplateByIdApi(data.inventory_template_id, data);
    return response;
  } catch (error) {
    return error;
  }
});

export const getTotalsOfInventoryItems = createAsyncThunk("inventory/getTotalsOfInventoryItems", async () => {
  try {
    const response = await getTotalsOfInventoryItemsApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const resetTemplateStates = createAsyncThunk("inventory/resetTemplateStates", async () => {
  return {};
});

export const resetInventoryStates = createAsyncThunk("inventory/resetInventoryStates", async () => {
  return {};
});
