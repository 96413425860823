import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createNewProduct as createNewProductApi,
  getAllProducts as getAllProductsApi,
  updateProduct as updateProductApi,
  deleteProduct as deleteProductApi,
  getProductById as getProductByIdApi,
} from "../../helpers/backend_helper";

export const createNewProduct = createAsyncThunk("crm/createNewProduct", async (product) => {
  try {
    const response = await createNewProductApi(product);
    return response;
  } catch (error) {
    return error;
  }
});

export const getAllProducts = createAsyncThunk("crm/getAllProducts", async ({ filters }) => {
  try {
    const response = await getAllProductsApi(filters);
    return response;
  } catch (error) {
    return error;
  }
});

export const updateProduct = createAsyncThunk("crm/updateProduct", async ({productId, product}) => {
  try {
    const response = await updateProductApi(productId, product);
    return response;
  } catch (error) {
    return error;
  }
});

export const deleteProduct = createAsyncThunk("crm/deleteProduct", async (productId) => {
  try {
    const response = await deleteProductApi(productId);
    return response;
  } catch (error) {
    return error;
  }
});

export const getProductById = createAsyncThunk("crm/getProductById", async (productId) => {
  try {
    const response = await getProductByIdApi(productId);
    return response;
  } catch (error) {
    return error;
  }
});