import { set, update } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Navdata = () => {
  const location = useLocation();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);

  // Customer state
  const [isCustomer, setIsCustomer] = useState(false);
  const [isService, setIsService] = useState(false);
  const [isSupport, setIsSupport] = useState(false);
  const [isBilling, setIsBilling] = useState(false);
  const [isProvisioning, setIsProvisioning] = useState(false);
  const [isCommunications, setIsCommunications] = useState(false);
  const [isInventory, setIsInventory] = useState(false);
  const [isProducts, setIsProducts] = useState(false);
  const [isReports, setIsReports] = useState(false);
  const [isCellBroadcast, setIsCellBroadcast] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUsers, setIsUsers] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  // Check if user is admin
  const isAdminUser = () => {
    const authUser = sessionStorage.getItem("authUser");
    if (authUser) {
      const { data } = JSON.parse(authUser);
      return data.roles.includes("admin");
    }
    return false;
  };

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Customer") {
      setIsCustomer(false);
    }
    if (iscurrentState !== "Services") {
      setIsService(false);
    }
    if (iscurrentState !== "Support") {
      setIsSupport(false);
    }
    if (iscurrentState !== "Billing") {
      setIsBilling(false);
    }
    if (iscurrentState !== "Provisioning") {
      setIsProvisioning(false);
    }
    if (iscurrentState !== "Communications") {
      setIsCommunications(false);
    }
    if (iscurrentState !== "Inventory") {
      setIsInventory(false);
    }
    if (iscurrentState !== "Products") {
      setIsProducts(false);
    }
    if (iscurrentState !== "Reports") {
      setIsReports(false);
    }
    if (iscurrentState !== "CellBroadcast") {
      setIsCellBroadcast(false);
    }
    if (iscurrentState !== "Admin") {
      setIsAdmin(false);
    }
    if (iscurrentState !== "Users") {
      setIsUsers(false);
    }
    
    // if pathname === /customers/ then set isCustomer to true
    if (location.pathname.includes("/customers/")) {
      setIsCustomer(true);
    }

  }, [
    history,
    iscurrentState,
    setIsDashboard,
    setIsCustomer,
    setIsService,
    setIsSupport,
    setIsBilling,
    setIsProvisioning,
    setIsCommunications,
    setIsInventory,
    setIsProducts,
    setIsProducts,
    setIsReports,
    setIsCellBroadcast,
    setIsAdmin,
    setIsUsers,
  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboards",
      icon: "ri-dashboard-2-line",
      link: "/#",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "overview",
          label: "Overview",
          link: "/dashboard",
          parentId: "dashboard",
        },
      ],
    },
    {
      id: "customer",
      label: "Customers",
      icon: "ri-user-line",
      link: "/#",
      stateVariables: isCustomer,
      click: function (e) {
        e.preventDefault();
        setIsCustomer(!isCustomer);
        setIscurrentState("Customer");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "customer-list",
          label: "Customer List",
          link: "/customers",
          parentId: "customer",
        },
        {
          id: "customer-create",
          label: "Create Customer",
          link: "/customer-create",
          parentId: "customer",
        },
        {
          id: "customer-overview",
          label: "Customer Overview",
          link: "/customers/{id}",
          parentId: "customer",
          hidden: true,
        }
      ],
    },
    {
      id: "services",
      label: "Services",
      icon: "ri-shopping-cart-line",
      link: "/#",
      stateVariables: isService,
      click: function (e) {
        e.preventDefault();
        setIsService(!isService);
        setIscurrentState("Services");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "service-list",
          label: "Service List",
          link: "/services",
          parentId: "services",
        },
        {
          id: "service-create",
          label: "Create Service",
          link: "/service-create",
          parentId: "services",
        },
      ],
    },
    {
      id: "support",
      label: "Support",
      icon: "ri-customer-service-line",
      link: "/#",
      stateVariables: isSupport,
      click: function (e) {
        e.preventDefault();
        setIsSupport(!isSupport);
        setIscurrentState("Support");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "support-list",
          label: "Support Queue",
          link: "/support-list",
          parentId: "support",
        },
        {
          id: "support-create",
          label: "Create Ticket",
          link: "/support-create",
          parentId: "support",
        },
      ],
    },
    {
      id: "billing",
      label: "Billing",
      icon: "ri-bill-line",
      link: "/#",
      stateVariables: isBilling,
      click: function (e) {
        e.preventDefault();
        setIsBilling(!isBilling);
        setIscurrentState("Billing");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "billing-invoices-outstanding",
          label: "Outstanding Invoices",
          link: "/billing-invoices-outstanding",
          parentId: "billing",
        },
        {
          id: "billing-transactions-add",
          label: "Add Payment",
          link: "/billing-transactions-create",
          parentId: "billing",
        },
        {
          id: "billing-transactions-recent",
          label: "Recent Transactions",
          link: "/billing-transactions-recent",
          parentId: "billing",
        },
        {
          id: "billing-cards-expiring",
          label: "Expring Credit Cards",
          link: "/billing-cards-expiring",
          parentId: "billing",
        },
      ],
    },
    {
      id: "provisioning",
      label: "Provisioning",
      icon: "ri-loader-line",
      link: "/#",
      stateVariables: isProvisioning,
      click: function (e) {
        e.preventDefault();
        setIsProvisioning(!isProvisioning);
        setIscurrentState("Provisioning");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "provisioning-recent-provision",
          label: "Recent Provisioning Jobs",
          link: "/provisioning-recent-provision",
          parentId: "provisioning",
        },
        {
          id: "provisioning-recent-failed",
          label: "Failed Provisioning Jobs",
          link: "/provisioning-recent-failed",
          parentId: "provisioning",
        },
        {
          id: "provisioning-recent-deprovision",
          label: "Recent Deprovisioning Jobs",
          link: "/provisioning-recent-deprovision",
          parentId: "provisioning",
        },
      ],
    },
    {
      id: "communications",
      label: "Communications",
      icon: "ri-megaphone-line",
      link: "/#",
      stateVariables: isCommunications,
      click: function (e) {
        e.preventDefault();
        setIsCommunications(!isCommunications);
        setIscurrentState("Communications");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "communications-recent-communications",
          label: "Recent Communications",
          link: "/communications-recent-communications",
          parentId: "communications",
        },
        {
          id: "communications-create-communications",
          label: "Send Communications",
          link: "/communications-create-communications",
          parentId: "communications",
        },
        {
          id: "communications-cell-broadcast",
          label: "Emergency Cell Broadcast",
          link: "/communications-cell-broadcast",
          parentId: "communications",
        },
      ],
    },
    {
      id: "inventory",
      label: "Inventory",
      icon: "ri-store-line",
      link: "/#",
      stateVariables: isInventory,
      click: function (e) {
        e.preventDefault();
        setIsInventory(!isInventory);
        setIscurrentState("Inventory");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "inventory-items-create",
          label: "Add Inventory Items",
          link: "/inventory-items-create",
          parentId: "inventory",
        },
        {
          id: "inventory-items-list",
          label: "View Inventory",
          link: "/inventory-items-list",
          parentId: "inventory",
        },
        {
          id: "inventory-items-template",
          label: "Manage Inventory Templates",
          link: "/inventory-items-template",
          parentId: "inventory",
        },
      ],
    },
    {
      id: "products",
      label: "Products & Plans",
      icon: "ri-price-tag-2-line",
      link: "/#",
      stateVariables: isProducts,
      click: function (e) {
        e.preventDefault();
        setIsProducts(!isProducts);
        setIscurrentState("Products");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "products-items-list",
          label: "Plans & Rates",
          link: "/products-items-list",
          parentId: "products",
        },
      ],
    },
    {
      id: "reporting",
      label: "Reporting & Analytics",
      icon: "ri-price-tag-2-line",
      link: "/#",
      stateVariables: isReports,
      click: function (e) {
        e.preventDefault();
        setIsReports(!isReports);
        setIscurrentState("Reports");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "reports-items-list",
          label: "Reporting Dashboard",
          link: "/reports-items-list",
          parentId: "reports",
        },
        {
          id: "reports-items-schedule",
          label: "Scheduled Reports",
          link: "/reports-items-schedule",
          parentId: "reports",
        },
      ],
    },
    {
      id: "cell-broadcast",
      label: "Cell Broadcast",
      icon: "ri-signal-tower-fill",
      link: "/#",
      stateVariables: isCellBroadcast,
      click: function (e) {
        e.preventDefault();
        setIsCellBroadcast(!isCellBroadcast);
        setIscurrentState("CellBroadcast");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "new-cell-broadcast",
          label: "New Broadcast",
          link: "/cell-broadcast-new",
          parentId: "cell-broadcast",
        },
      ],
    },
    isAdminUser() && {
      id: "users",
      label: "Users & Roles",
      icon: "ri-dashboard-2-line",
      link: "/users-and-roles",
      stateVariables: isUsers,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isUsers);
        setIscurrentState("Users");
        updateIconSidebar(e);
      },
    },
  ].filter(Boolean);
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
