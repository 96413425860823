import { GoogleMap, LoadScript, Autocomplete, Marker } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { OpenLocationCode } from 'open-location-code';
import { Col, Container, Row, Input, Label } from 'reactstrap';
import Loader from "../../../Components/Common/Loader";
import { toast } from "react-toastify";

const libraries = ["places"];

const Site = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [searchResult, setSearchResult] = useState(null);
    let olc = new OpenLocationCode();

    // Default coordinates
    const defaultCoordinates = { lat: 36.1483963, lng: -5.3604605 };

    // State for map center
    const [mapCenter, setMapCenter] = useState(defaultCoordinates);

    // Default placeholder
    const [placeholder, setPlaceholder] = useState("Eg. Unit 5 Waterport Terraces Gibraltar, N Mole Rd, Gibraltar GX11 1AA, Gibraltar");

    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

    useEffect(() => {
        const fetchCoordinates = async () => {
            try {
                const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=Gibraltar&key=${googleMapsApiKey}`);
                const data = await response.json();
                if (data.results && data.results[0]) {
                    const location = data.results[0].geometry.location;
                    setMapCenter({ lat: location.lat, lng: location.lng });
                    setPlaceholder("Eg. " + data.results[0].formatted_address);
                } else {
                    setMapCenter(defaultCoordinates);
                }
            } catch (error) {
                console.error("Error fetching coordinates:", error);
                setMapCenter(defaultCoordinates);
            }
        };

        fetchCoordinates();
    }, [googleMapsApiKey]);

    const mapContainerStyle = {
        width: "100%",
        height: "50vh",
    };

    const onMapLoad = (map) => {
        setMapCenter(map);
    };

    const handlePlaceChanged = () => {
        if (searchResult) {
            const place = searchResult.getPlace();
            const resultLatitude = place.geometry.location.lat();
            const resultLongitude = place.geometry.location.lng();
            const plusCode = olc.encode(resultLatitude, resultLongitude, 11);

            const addressComponents = place.address_components;

            const getAddressComponent = (type) => {
                const component = addressComponents.find(component => component.types.includes(type));
                return component ? component.long_name : '';
            };

            const streetNumber = getAddressComponent('street_number');
            const route = getAddressComponent('route');

            const updatedAddress = {
                site_name: place.name,
                address_line_1: `${streetNumber} ${route}`,
                address_line_2: getAddressComponent('subpremise'),
                city: getAddressComponent('locality') || getAddressComponent('administrative_area_level_2'),
                state: getAddressComponent('administrative_area_level_1') || getAddressComponent('region'),
                country: getAddressComponent('country'),
                zip_code: getAddressComponent('postal_code'),
                plus_code: plusCode,
                latitude: resultLatitude,
                longitude: resultLongitude,
                google_maps_place_id: place.place_id
            };

            // Update parent component's state
            props.onChange(updatedAddress);
            setMapCenter({ lat: resultLatitude, lng: resultLongitude });
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        props.onChange({
            ...props.data,
            [name]: value
        });
    };

    const handleMarkerDragEnd = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        const plusCode = olc.encode(lat, lng, 11);

        props.onChange({
            ...props.data,
            latitude: lat,
            longitude: lng,
            plus_code: plusCode
        });

        setMapCenter({ lat, lng });
    };

    const onSaveValidation = () => {
        return props.data.country.length > 0 && props.data.plus_code.length > 0;
    };

    const handleSiteSave = (event) => {
        event.preventDefault();

        if (!onSaveValidation()) {
            toast.error("Please fill in the required fields.");
            return;
        }

        // Proceed to the next step if needed
        if (props.onNextStep) {
            props.onNextStep();
        }
    };

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const renderFormFields = () => {
        return (
            <>
                <h4 className="card-title">Site Information</h4>
                <form>
                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">Site Name</Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="name"
                            name="site_name"
                            value={props.data.site_name || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="addressLine1" className="form-label">Address Line 1</Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="addressLine1"
                            name="address_line_1"
                            value={props.data.address_line_1 || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="addressLine2" className="form-label">Address Line 2</Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="addressLine2"
                            name="address_line_2"
                            value={props.data.address_line_2 || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="city" className="form-label">City</Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            value={props.data.city || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="state" className="form-label">State/Region</Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="state"
                            name="state"
                            value={props.data.state || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="country" className="form-label">Country</Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="country"
                            name="country"
                            value={props.data.country || ''}
                            onChange={handleInputChange}
                            valid={props.data.country.length > 0}
                            invalid={props.data.country.length === 0}
                        />
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="zip_code" className="form-label">Post Code</Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="zip_code"
                            name="zip_code"
                            value={props.data.zip_code || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="plus_code" className="form-label">Plus Code</Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="plus_code"
                            name="plus_code"
                            value={props.data.plus_code || ''}
                            onChange={handleInputChange}
                            valid={props.data.plus_code.length > 0}
                            invalid={props.data.plus_code.length === 0}
                        />
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="site_notes" className="form-label">Site Notes</Label>
                        <textarea
                            className="form-control"
                            id="site_notes"
                            name="site_notes"
                            value={props.data.site_notes || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </form>
            </>
        );
    };

    if (!isLoaded) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <Container>
                <Row className="mb-3">
                    <Col lg={12}>
                        <Autocomplete
                            onPlaceChanged={handlePlaceChanged}
                            onLoad={setSearchResult}
                        >
                            <input
                                className="form-control"
                                type="text"
                                placeholder={placeholder}
                                style={{
                                    fontSize: 18,
                                    borderStyle: "solid",
                                    borderColor: "var(--vz-primary)",
                                    borderWidth: 2,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                }}
                            />
                        </Autocomplete>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            center={mapCenter}
                            zoom={15}
                            onLoad={onMapLoad}
                        >
                            {mapCenter && <Marker draggable={true} onDragEnd={handleMarkerDragEnd} position={mapCenter} />}
                        </GoogleMap>
                    </Col>
                    <Col lg={6}>
                        {renderFormFields()}
                    </Col>
                </Row>
                <Row style={{float: 'right'}} className="d-flex justify-content-start align-items-right gap-1 mt-4">
                    <Col xs={12} md="auto">
                        <button
                            type="button"
                            className="btn btn-primary btn-label left ms-auto nexttab nexttab"
                            onClick={(event) => {
                                event.preventDefault();
                                props.onPreviousStep && props.onPreviousStep();
                            }}
                        >
                            <i className="ri-arrow-left-line label-icon align-middle fs-16"></i>
                            Back
                        </button>
                    </Col>
                    <Col xs={12} md="auto" >
                        <button
                                type="button"
                                className="btn btn-success btn-label right ms-auto"
                                onClick={handleSiteSave}
                            >
                                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                Next
                        </button>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default Site;
