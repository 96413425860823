import React from 'react';
import { Card, CardBody } from 'reactstrap';
import CountUp from 'react-countup';
import { USAGE_KEYS, USAGE_MAP } from '../../../Components/constants/crm';
import { Link } from 'react-router-dom';

const UsageCard = ({ data, dataType, moreInfoClick }) => {
    // Consolidate the data if it's an array
    const consolidatedData = Array.isArray(data)
        ? data.reduce((acc, curr) => {
            return {
            ID: curr.ID || acc.ID,
            custom_Percentage: parseInt(curr.custom_Percentage ?? 0) + parseInt(acc.custom_Percentage ?? 0),
            custom_Value_hr: parseFloat(curr.custom_Value_hr ?? 0) + parseFloat(acc.custom_Value_hr ?? 0),
            Value: parseFloat(curr.Value ?? 0) + parseFloat(acc.Value ?? 0),
            custom_Bucket: parseFloat(curr.custom_Bucket ?? 0) + parseFloat(acc.custom_Bucket ?? 0),
            custom_Units: curr.custom_Units || acc.custom_Units,
            custom_Description_String: curr.custom_Description_String || acc.custom_Description_String,
            };
        }, { ID: '', custom_Percentage: 0, custom_Value_hr: 0, Value: 0, custom_Bucket: 0, custom_Units: '', custom_Description_String: '' })
        : data;

    // Calculate the custom_Percentage based on the Value and custom_Bucket
    const customPercentage = parseInt((consolidatedData.Value / consolidatedData.custom_Bucket) * 100);

    return (
        <Card className={`card-animate card-${USAGE_MAP[dataType].color}`} >
        <CardBody>
            <div className="d-flex align-items-center">
            <div className="flex-grow-1">
                <p className="text-uppercase fw-medium text-white mb-0">
                    {USAGE_MAP[dataType].label}
                </p>
            </div>
            <div className="flex-shrink-0">
                <h5 className="fs-14 mb-0 text-white">
                    {dataType !== USAGE_KEYS.MONETARY && (
                        isNaN(customPercentage) ? 'N/A' : `${customPercentage}%`
                    )}
                </h5>
            </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                    <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                        <CountUp
                            start={0}
                            prefix={dataType === USAGE_KEYS.MONETARY ? USAGE_MAP[dataType].unit : ''}
                            suffix=""
                            decimals={dataType === USAGE_KEYS.SMS ? 0 : 2}
                            end={dataType === USAGE_KEYS.MONETARY ? consolidatedData.Value/100.0 : consolidatedData.custom_Value_hr}
                            duration={4}
                            className="counter-value text-white"
                        />
                        <span className="badge me-1 fs-12">
                            {dataType !== USAGE_KEYS.MONETARY && USAGE_MAP[dataType].unit}
                        </span>{" "}
                    </h4>
                    <span className="text-muted">
                        <Link
                            className="text-white"
                            title="More Info"
                            onClick={moreInfoClick}
                        >
                            More Info
                        </Link>
                    </span>
                </div>
                <div className="avatar-sm flex-shrink-0 text-end align-items-end">
                    <i className={`${USAGE_MAP[dataType].icon} fs-40`}></i>
                </div>
            </div>
        </CardBody>
        </Card>
    );
};

export default UsageCard;