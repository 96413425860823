import React from "react";
import { Row, Col } from "reactstrap";

const Navigation = ({ activeVerticalTab, toggleVerticalTab }) => {
  const tabNavigationMap = {
    1: 'dataSelection',
    2: 'billingInfo',
    3: 'payment',
    4: 'confirmation'
  };

  const renderTabNavigations = {
    'dataSelection': (
      <>
        <button
          type="button"
          className="btn btn-black btn-label right ms-auto nexttab"
          onClick={() => {
            toggleVerticalTab(activeVerticalTab + 1);
          }}
        >
          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
          Go to Billing Info
        </button>
      </>
    ),
    'billingInfo': (
      <>
        <button
          type="button"
          className="btn btn-gold btn-label left previestab"
          onClick={() => {
            toggleVerticalTab(activeVerticalTab - 1);
          }}
        >
          <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
          Back to Data Selection
        </button>
        <button
          type="button"
          className="btn btn-black btn-label right ms-auto nexttab"
          onClick={() => {
            toggleVerticalTab(activeVerticalTab + 1);
          }}
        >
          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
          Go to Payment
        </button>
      </>
    ),
    'payment': (
      <>
        <button
          type="button"
          className="btn btn-gold btn-label previestab"
          onClick={() => {
            toggleVerticalTab(activeVerticalTab - 1);
          }}
        >
          <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
          Back to Billing Info
        </button>
        <button
          type="button"
          className="btn btn-black btn-label right ms-auto nexttab"
          onClick={() => {
            toggleVerticalTab(activeVerticalTab + 1);
          }}
        >
          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
          Complete Payment
        </button>
      </>
    ),
    'confirmation': (
      <>
        {/* No buttons for confirmation step */}
      </>
    )
  };

  return (
    <React.Fragment>
      <Row className="align-items-center justify-content-center button-row">
        <Col lg={5} md={7} sm={10} xs={11} className="text-center">
          <div className="d-flex flex-wrap align-items-center justify-content-center button-container">
            {renderTabNavigations[tabNavigationMap[activeVerticalTab]]}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Navigation;
