import { createSlice } from "@reduxjs/toolkit";
import {
  getContacts,
  addNewContact,
  updateContact,
  deleteContact,
  getContactById,
  sendAuthMessageToContact,
  getContactsByCustomerId,
  resetContacts,
} from './thunk';
export const initialState = {
  contacts: [],
  contact: {},
  error: {},
};

const ContactSlice = createSlice({
  name: 'ContactSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getContacts.fulfilled, (state, action) => {
      state.contacts = action.payload;
      state.isContactCreated = false;
      state.isContactSuccess = true;
    });
    builder.addCase(getContacts.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isContactCreated = false;
      state.isContactSuccess = false;
    });
    builder.addCase(addNewContact.fulfilled, (state, action) => {
      state.contact = action.payload;
      state.isContactCreated = true;
    });
    builder.addCase(addNewContact.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(getContactById.fulfilled, (state, action) => {
      state.contact = action.payload;
    });
    builder.addCase(getContactById.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(updateContact.fulfilled, (state, action) => {
      state.contact = action.payload;
    });
    builder.addCase(updateContact.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(deleteContact.fulfilled, (state, action) => {
      state.deletedContact = action.payload;
      state.contact = {};
      state.contactDeleteSuccess = true;
    });
    builder.addCase(deleteContact.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(sendAuthMessageToContact.fulfilled, (state, action) => {
      state.contact = action.payload;
    });
    builder.addCase(sendAuthMessageToContact.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(getContactsByCustomerId.fulfilled, (state, action) => {
      state.contacts = action.payload;
    });
    builder.addCase(getContactsByCustomerId.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(resetContacts.fulfilled, (state, action) => {
      state.contact = {};
      state.contacts = [];
      state.error = {};
    });
    builder.addCase(resetContacts.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
  }
});

export default ContactSlice.reducer;