import { createSlice } from "@reduxjs/toolkit";
import {
  createNewProduct,
  getAllProducts,
  updateProduct,
  deleteProduct,
  getProductById,
} from './thunk';

export const initialState = {
  products: [],
  product: {},
  error: {},
};

const ProductsSlice = createSlice({
  name: 'ProductsSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(createNewProduct.fulfilled, (state, { payload }) => {
      state.products.push(payload);
    });
    builder.addCase(createNewProduct.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(getAllProducts.fulfilled, (state, { payload }) => {
      state.products = payload;
    });
    builder.addCase(getAllProducts.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(updateProduct.fulfilled, (state, { payload }) => {
      const index = state.products.findIndex(product => product.id === payload.id);
      if (index !== -1) {
        state.products[index] = payload;
      }
    });
    builder.addCase(updateProduct.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(deleteProduct.fulfilled, (state, { payload }) => {
      state.products = state.products.filter(product => product.id !== payload.id);
    });
    builder.addCase(deleteProduct.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(getProductById.fulfilled, (state, { payload }) => {
      state.product = payload;
    });
    builder.addCase(getProductById.rejected, (state, { payload }) => {
      state.error = payload;
    });
  }
});

export default ProductsSlice.reducer;