function getClosestBootstrapColor(hexColor) {
    const colors = {
        '#007bff': 'primary',
        '#6c757d': 'secondary',
        '#28a745': 'success',
        '#17a2b8': 'info',
        '#ffc107': 'warning',
        '#dc3545': 'danger',
        '#f8f9fa': 'light',
        '#343a40': 'dark',
    };

    let closestColor = 'primary';
    let smallestDifference = Infinity;

    for (const [hex, colorName] of Object.entries(colors)) {
        const difference = getDifferenceBetweenColors(hexColor, hex);
        if (difference < smallestDifference) {
            smallestDifference = difference;
            closestColor = colorName;
        }
    }

    return closestColor;
}

function getDifferenceBetweenColors(color1, color2) {
    const [r1, g1, b1] = hexToRgb(color1);
    const [r2, g2, b2] = hexToRgb(color2);

    return Math.abs(r1 - r2) + Math.abs(g1 - g2) + Math.abs(b1 - b2);
}

function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}

export {
    getClosestBootstrapColor,
    getDifferenceBetweenColors,
    hexToRgb
};