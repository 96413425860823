import { createSlice } from "@reduxjs/toolkit";
import {
  createNewProvisioningJob,
  updateProvisioningRecord,
  createNewProvisioningRecord,
  getAllProvisioningJobs,
  getAvailablePlaybooks,
  getProvisioningStatus,
  createSimpleProvisionJob,
  resetProvisioningServices,
} from './thunk';

export const initialState = {
  provisioningJobs: [],
  provisioningRecords: [],
  availablePlaybooks: [],
  provisioningStatus: {},
  error: {},
};

const ProvisioningSlice = createSlice({
  name: 'ProvisioningSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(createNewProvisioningJob.fulfilled, (state, { payload }) => {
      state.createdProvisioningJob = payload;
    });
    builder.addCase(createNewProvisioningJob.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(updateProvisioningRecord.fulfilled, (state, { payload }) => {
      state.updatedProvisioningRecord = payload;
    });
    builder.addCase(updateProvisioningRecord.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(createNewProvisioningRecord.fulfilled, (state, { payload }) => {
      state.createdProvisioningRecord = payload;
    });
    builder.addCase(createNewProvisioningRecord.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(getAllProvisioningJobs.fulfilled, (state, { payload }) => {
      state.provisioningJobs = payload;
    });
    builder.addCase(getAllProvisioningJobs.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(getAvailablePlaybooks.fulfilled, (state, { payload }) => {
      state.availablePlaybooks = payload;
    });
    builder.addCase(getAvailablePlaybooks.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(getProvisioningStatus.fulfilled, (state, { payload }) => {
      state.provisioningStatus = payload;
    });
    builder.addCase(getProvisioningStatus.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(createSimpleProvisionJob.fulfilled, (state, { payload }) => {
      state.createdProvisioningJob = payload;
    });
    builder.addCase(createSimpleProvisionJob.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(resetProvisioningServices.fulfilled, (state, { payload }) => {
      state.provisioningRecords = [];
      state.provisioningJobs = [];
    });
    builder.addCase(resetProvisioningServices.rejected, (state, { payload }) => {
      state.provisioningResetError = true;
    });
  }
});

export default ProvisioningSlice.reducer;