import { GoogleMap, LoadScript, Autocomplete, Marker } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { OpenLocationCode } from 'open-location-code';

import { useDispatch } from "react-redux";

//Import actions
import {
    addNewSite as onAddNewSite
} from "../../../slices/thunks";

import { Col, Container, Row } from 'reactstrap';
import Loader from "../../../Components/Common/Loader";
import { set } from "lodash";

const libraries = ["places"];

const Site = (props) => {
    const dispatch = useDispatch();

    const [isLoaded, setIsLoaded] = useState(false);
    const [searchResult, setSearchResult] = useState(null);
    let olc = new OpenLocationCode();

    // Default coordinates
    const defaultCoordinates = { lat: 36.1483963, lng: -5.3604605 };
    
    // State for map center
    const [mapCenter, setMapCenter] = useState(defaultCoordinates);

    // Default placeholder
    const [placeholder, setPlaceholder] = useState("Eg. Unit 5 Waterport Terraces Gibraltar, N Mole Rd, Gibraltar GX11 1AA, Gibraltar");
    
    // Default Location from environment variable
    const defaultLocation = process.env.REACT_APP_DEFAULT_LOCATION;
    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

    useEffect(() => {
        const fetchCoordinates = async () => {
            if (!defaultLocation) {
                setMapCenter(defaultCoordinates);
                return;
            }
            
            try {
                // Fetch coordinates using Geocoding API
                const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(defaultLocation)}&key=${googleMapsApiKey}`);
                const data = await response.json();
                
                if (data.results && data.results[0]) {
                    const location = data.results[0].geometry.location;
                    setMapCenter({ lat: location.lat, lng: location.lng });
                    setPlaceholder("Eg. " + data.results[0].formatted_address || "Eg. " + defaultLocation);
                } else {
                    setMapCenter(defaultCoordinates); // Fallback to default coordinates
                }
            } catch (error) {
                console.error("Error fetching coordinates:", error);
                setMapCenter(defaultCoordinates); // Fallback to default coordinates
            }
        };

        fetchCoordinates();
    }, [defaultLocation, googleMapsApiKey]);

    const [address, setAddress] = useState({
        customer_id: props?.customer?.customer_id ?? null,
        site_name: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        country: '',
        zip_code: '',
        plus_code: '',
        site_notes: '',
        latitude: '',
        longitude: '',
        google_maps_place_id: ''
    });

    const mapContainerStyle = {
        width: "100%",
        height: "50vh",
    };

    const [map, setMap] = useState(null);

    const onMapLoad = (map) => {
        setMap(map);
    };

    const onMapUnmount = (map) => {
        setMap(null);
    };

    const handlePlaceChanged = () => {
        if (searchResult) {
            const place = searchResult.getPlace();
            const resultLatitude = place.geometry.location.lat();
            const resultLongitude = place.geometry.location.lng();

            const addressComponents = place.address_components;

            const plusCode = olc.encode(resultLatitude, resultLongitude, 11);

            const getAddressComponent = (type) => {
                const component = addressComponents.find(component => component.types.includes(type));
                return component ? component.long_name : '';
            }

            const streetNumber = getAddressComponent('street_number');
            const route = getAddressComponent('route');

            setAddress({
                ...address,
                site_name: place.name,
                address_line_1: `${streetNumber} ${route}`,
                address_line_2: getAddressComponent('subpremise'),
                city: getAddressComponent('locality') ?? getAddressComponent('administrative_area_level_2'),
                state: getAddressComponent('administrative_area_level_1') ?? getAddressComponent('region') ?? getAddressComponent('locality'),
                country: getAddressComponent('country'),
                zip_code: getAddressComponent('postal_code'),
                plus_code: plusCode,
                latitude: resultLatitude,
                longitude: resultLongitude,
                site_notes: address.site_notes,
                google_maps_place_id: place.place_id
            });

            setMapCenter({ lat: resultLatitude, lng: resultLongitude });
        }
    }

    const handleInputChange = (event) => {
        setAddress({
            ...address,
            [event.target.name]: event.target.value
        });
    }

    const handleOnLoad = (autocomplete) => {
        setSearchResult(autocomplete);
    }

    const handleSiteSave = (event) => {
        event.preventDefault();

        // handle form submission
        dispatch(onAddNewSite(address)).then(() => {
            if(props.refetchSites) {
                props.refetchSites();
            }
        });

        // only call next pane if passed on as props
        if (props.onNextStep) {
            props.onNextStep();
        }
    }

    const handleSiteSaveAndAnother = (event) => {
        event.preventDefault();

        // handle form submission
        dispatch(onAddNewSite(address));

        handleClearAddress();
    }

    const handleClearAddress = () => {
        setAddress({
            ...address,
            site_name: '',
            address_line_1: '',
            address_line_2: '',
            city: '',
            state: '',
            country: '',
            zip_code: '',
            plus_code: '',
            latitude: '',
            longitude: '',
            site_notes: '',
            google_maps_place_id: ''
        });
    }

    const handleMarkerDragEnd = (e) => {
        const newPosition = e.latLng;
        const lat = newPosition.lat();
        const lng = newPosition.lng();
    
        const geocoder = new window.google.maps.Geocoder();
    
        geocoder.geocode({ location: newPosition }, (results, status) => {
            if (status === "OK") {
                if (results[0]) {
                    const place = results[0];
                    const addressComponents = place.address_components;
    
                    const getAddressComponent = (type) => {
                        const component = addressComponents.find(component => component.types.includes(type));
                        return component ? component.long_name : '';
                    }
    
                    const streetNumber = getAddressComponent('street_number');
                    const route = getAddressComponent('route');
                    const plusCode = olc.encode(lat, lng, 11);

    
                    setAddress({
                        ...address,
                        site_name: place.name ?? place.formatted_address ?? '',
                        address_line_1: `${streetNumber} ${route}`,
                        address_line_2: getAddressComponent('subpremise'),
                        city: getAddressComponent('locality') ?? getAddressComponent('administrative_area_level_2'),
                        state: getAddressComponent('administrative_area_level_1') ?? getAddressComponent('region') ?? getAddressComponent('locality'),
                        country: getAddressComponent('country'),
                        zip_code: getAddressComponent('postal_code'),
                        plus_code: plusCode,
                        latitude: lat,
                        longitude: lng,
                        site_notes: address.site_notes,
                        google_maps_place_id: place.place_id
                    });
    
                    setMapCenter({ lat, lng });
                } else {
                    window.alert("No results found");
                }
            } else {
                window.alert("Geocoder failed due to: " + status);
            }
        });
    }

    const handleOnScriptLoad = () => {
        setIsLoaded(true);
    }

    useEffect(() => {
        const style = document.createElement('style');
        style.id = 'autocomplete-style';
        style.type = 'text/css';
        style.innerText = '.pac-container { z-index: 1051 !important; }';
        document.head.appendChild(style);

        return () => {
            const styleElement = document.getElementById('autocomplete-style');
            if (styleElement) {
                styleElement.parentNode.removeChild(styleElement);
            }
        }
    }, []);

    useEffect(() => {
        if (!props.loadScript) {
            setIsLoaded(true);
        }
    }, [props.loadScript]);

    const renderFormFields = () => {
        return (
            <>
                <h4 className="card-title">Site Information</h4>
                <form>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Site Name</label>
                        <input type="text" className="form-control" id="name" name="site_name" value={address.site_name} onChange={handleInputChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="addressLine1" className="form-label">Address Line 1</label>
                        <input type="text" className="form-control" id="addressLine1" name="address_line_1" value={address.address_line_1} onChange={handleInputChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="addressLine2" className="form-label">Address Line 2</label>
                        <input type="text" className="form-control" id="addressLine2" name="address_line_2" value={address.address_line_2} onChange={handleInputChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="suburb" className="form-label">Suburb</label>
                        <input type="text" className="form-control" id="suburb" name="city" value={address.city} onChange={handleInputChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="stateRegion" className="form-label">State/Region</label>
                        <input type="text" className="form-control" id="stateRegion" name="state" value={address.state} onChange={handleInputChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="country" className="form-label">Country</label>
                        <input type="text" className="form-control" id="country" name="country" value={address.country} onChange={handleInputChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="postCode" className="form-label">Post Code</label>
                        <input type="text" className="form-control" id="postCode" name="zip_code" value={address.zip_code} onChange={handleInputChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="location" className="form-label">Location</label>
                        <input type="text" className="form-control" id="location" name="plus_code" value={address.plus_code} onChange={handleInputChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="addressNote" className="form-label">Address Note</label>
                        <textarea className="form-control" id="addressNote" name="site_notes" value={address.site_notes} onChange={handleInputChange} />
                    </div>
                </form>
            </>
        );
    }

    const renderWithoutLoader = () => {
        return (
            <>
                <Row className="mb-3">
                    <Col lg={12}>
                        <Autocomplete
                            onPlaceChanged={handlePlaceChanged}
                            onLoad={handleOnLoad}
                        >
                            <input
                                className="form-control text-start rounded justify-content-center"
                                type="text"
                                id="name-7"
                                name="Site Name"
                                placeholder={placeholder}
                                style={{
                                    fontSize: 18,
                                    borderStyle: "solid",
                                    borderColor: "var(--vz-primary)",
                                    borderWidth: 2,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                }}
                                // value={addressSearchInput}
                                // onChange={e => setAddressSearchInput(e.target.value)}
                            />
                        </Autocomplete>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            center={mapCenter}
                            zoom={15}
                            onLoad={onMapLoad}
                            onUnmount={onMapUnmount}
                        >
                        {mapCenter && <Marker draggable={true} onDragEnd={handleMarkerDragEnd} position={mapCenter} />}

                        <></>
                        </GoogleMap>{" "}
                    </Col>
                    <Col lg={6}>
                        {renderFormFields()}
                    </Col>
                </Row>
            </>
        );
    }

    document.title = "Customer Site | Omnitouch CRM";

    if (!isLoaded) {
        return <Loader />;
    }

    return (
        <React.Fragment>                
                <div>
                    <Container>
                        {renderWithoutLoader()}
                        
                        <Row style={{float: 'right'}} className="d-flex justify-content-start align-items-right gap-3 mt-4">
                            <Col xs={12} md="auto">
                                <button
                                    type="button"
                                    className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                    onClick={(event) => {
                                        handleSiteSaveAndAnother(event);
                                        handleClearAddress();
                                    }}
                                >
                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                    Save & Add Another
                                </button>
                            </Col>
                            <Col xs={12} md="auto" >
                                <button
                                    type="button"
                                    className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                    onClick={(event) => {
                                        handleSiteSave(event);
                                    }}
                                >
                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                    Save & Finish
                                </button>
                            </Col>
                        </Row>
                    </Container>                    
                </div>
        </React.Fragment>
    );
}

export default Site;