import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardFooter, Button, Col, Container, Form, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import LineChart from './components/LineChart';
import CountUp from "react-countup";
import { toast } from 'react-toastify';
import moment from 'moment';

import { useDispatch } from 'react-redux';

import {
    getCustomerById as onGetCustomerById,
    updateCustomer as onUpdateCustomer,
} from "../../../slices/thunks";
import Loader from '../../../Components/Common/Loader';

const OverviewTab = ({ customer }) => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        customer_account_type: customer?.customer_account_type ?? '',
        lead_source: customer?.lead_source ?? '',
        customer_payment_type: customer?.customer_payment_type ?? '',
        customer_status: customer?.customer_status ?? '',
        promo_code: customer?.promo_code ?? '',
        contract_start_date: customer?.contract_start_date ?? customer?.created ?? '',
        contract_end_date: customer?.contract_end_date ?? customer?.created ?? '',
        tax_identifier: customer?.tax_identifier ?? '',
        customer_default_payment_method: customer?.customer_default_payment_method ?? '',
        customer_notes: customer?.customer_notes ?? '',
        customer_id: customer?.customer_id ?? '',
    });

    const handleInputChange = (e) => {
        if (e.target.value) {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleDateInputChange = (date, name) => {
        setFormData({
            ...formData,
            [name]: moment(date[0]).format("YYYY-MM-DD HH:mm:ss") ?? '',
        });
    };

    const handleSave = () => {
        dispatch(onUpdateCustomer(formData))
            .then((response) => {
                toast.success("Customer updated successfully", { autoClose: 3000 });
                dispatch(onGetCustomerById(customer.customer_id));
            });
    };

    useEffect(() => {
        setFormData({
            customer_account_type: customer?.customer_account_type ?? '',
            lead_source: customer?.lead_source ?? '',
            customer_payment_type: customer?.customer_payment_type ?? '',
            customer_status: customer?.customer_status ?? '',
            promo_code: customer?.promo_code ?? '',
            contract_start_date: customer?.contract_start_date ?? customer?.created ?? '',
            contract_end_date: customer?.contract_end_date ?? customer?.created ?? '',
            tax_identifier: customer?.tax_identifier ?? '',
            customer_default_payment_method: customer?.customer_default_payment_method ?? '',
            customer_notes: customer?.customer_notes ?? '',
            customer_id: customer?.customer_id ?? '',
        });
    }, [customer]);

    const tileBoxs2 = [
        {
            id: 1,
            bgColor: "bg-success",
            icon: "bx bx-shopping-bag",
            iconClass: "white bg-opacity-25",
            label: "Total Sales",
            labelClass: "white-50",
            counter: "2045",
            counterClass: "text-white",
            caption: "From 1930 last year",
            captionClass: "white-50",
            percentage: "6.11 %",
            percentageClass: "white bg-opacity-25",
            badge: "ri-arrow-up-s-line",
            decimals: 0,
            separator: ",",
            suffix: "",
            prefix: "",
        },
    ];
    
    if (!formData.customer_id) {
        return <Loader />;
    }

    document.title = "Customer Overview | Omnitouch CRM";
    return (
        <Row>
            <Col xl={3}>
                <Card>
                    <CardBody>
                        <LineChart />
                    </CardBody>
                </Card>
                <Card className="card-success">
                    <CardBody className="p-0">
                        <Row className="row-cols-md-3 row-cols-1">

                            {(tileBoxs2 || []).map((item, key) => (
                                <Col className={item.id === 4 ? "col-lg" : "col-lg border-end"} key={key}>
                                    <div className="mt-3 mt-md-0 py-4 px-3">
                                        <h5 className="text-white text-uppercase fs-13">{item.label} <i className={"fs-18 float-end align-middle " + item.badge}></i></h5>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <i className={"display-6 text-white " + item.icon}></i>
                                            </div>
                                            <div className="flex-grow-1 ms-3 text-white">
                                                <h2 className="mb-0"><span className="counter-value text-white">
                                                    <CountUp
                                                        start={0}
                                                        prefix={item.prefix}
                                                        suffix={item.suffix}
                                                        separator={item.separator}
                                                        end={item.counter}
                                                        decimals={item.decimals}
                                                        duration={4}
                                                    /></span></h2>
                                            </div>
                                        </div>
                                    </div>
                                </Col>))}
                        </Row>
                    </CardBody>
                </Card>
            </Col>

            <Col xl={9}>
                <Card className="mt-xxl">
                    <CardHeader>
                        <h5 className="card-title mb-0">Basic Information</h5>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <Row className='mb-2'>
                                <Col md={6}>
                                    <Label for="customer_account_type">Account Type</Label>
                                    <Input type="select" id="customer_account_type" name="customer_account_type" value={formData.customer_account_type} onChange={handleInputChange}>
                                        <option>Individual</option>
                                        <option>Business</option>
                                    </Input>
                                    {/* Lead Source */}
                                    <Label for="lead_source">Lead Source</Label>
                                    <Input className='mb-2' type="text" id="lead_source" name="lead_source" value={formData?.lead_source} onChange={handleInputChange} />
                                </Col>
                                <Col md={6}>
                                    {/* Customer Status */}
                                    <Label for="customer_status">Customer Status</Label>
                                    <Input type="select" id="customer_status" name="customer_status" value={formData?.customer_status} onChange={handleInputChange}>
                                        <option>Open</option>
                                        <option>Closed</option>
                                        <option>Suspended</option>
                                        <option>Archived</option>
                                    </Input>
                                    {/* Payment Type */}
                                    <Label for="customer_payment_type">Payment Type</Label>
                                    <Input className='mb-2' type="text" id="customer_payment_type" name="customer_payment_type" value={formData?.customer_payment_type} onChange={handleInputChange} />
                                </Col>
                                <Col md={6}>
                                    {/* Promo Code */}
                                    <Label for="promo_code">Promo Code</Label>
                                    <Input className='mb-2' type="text" id="promo_code" name="promo_code" value={formData?.promo_code} onChange={handleInputChange} />
                                </Col>
                            </Row>
                            <Row className='mb-2'>
                                <Col md={6}>
                                    {/* Contract Start Date */}
                                    <Label for="contract_start_date">Contract Start Date</Label>
                                    <Flatpickr
                                        value={formData?.contract_start_date}
                                        options={{
                                            altInput: true,
                                            altFormat: "F j, Y",
                                            dateFormat: "Y-m-d",
                                            disableMobile: "true",
                                        }}
                                        className="form-control"
                                        id="contract_start_date"
                                        name="contract_start_date"
                                        onChange={(date) => handleDateInputChange(date, "contract_start_date")}
                                    />
                                </Col>
                                <Col md={6}>
                                    {/* Contract renewal Date */}
                                    <Label for="contract_end_date">Contract Renewal Date</Label>
                                    <Flatpickr
                                        value={formData?.contract_end_date}
                                        options={{
                                            altInput: true,
                                            altFormat: "F j, Y",
                                            dateFormat: "Y-m-d",
                                            disableMobile: "true",
                                        }}
                                        className="form-control"
                                        id="contract_end_date"
                                        name="contract_end_date"
                                        onChange={(date) => handleDateInputChange(date, "contract_end_date")}
                                    />
                                </Col>
                            </Row>
                            <Row className='mb-2'>
                                <Col md={6}>
                                    {/* Tax Identifier */}
                                    <Label for="tax_identifier">Tax ID</Label>
                                    <Input className='mb-2' type="text" id="tax_identifier" name="tax_identifier" value={formData?.tax_identifier} onChange={handleInputChange} />
                                </Col>
                                <Col md={6}>
                                    {/* Default Payment Method */}
                                    <Label for="customer_default_payment_method">Default Payment Method</Label>
                                    <Input className='mb-2' type="text" id="customer_default_payment_method" name="customer_default_payment_method" value={formData?.customer_default_payment_method} onChange={handleInputChange} />
                                </Col>
                            </Row>
                            <Row className='mb-2'>
                                <Col md={12}>
                                    {/* Customer Notes */}
                                    <Label for="customer_notes">Customer Notes</Label>
                                    <Input type="textarea" id="customer_notes" name="customer_notes" value={formData?.customer_notes ?? ''} onChange={handleInputChange} />
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                    <CardFooter>
                        <Button className='float-end' onClick={handleSave}>Save</Button>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    );
};

export default OverviewTab;