import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAllInventoryTemplates } from '../../slices/thunks';
import InventoryAdd from './components/InventoryAdd';
import { Container, Row, Col, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import BreadCrumb from '../../Components/Common/BreadCrumb';

const InventoryCreate = ({page = true, customerId = null}) => {
    const dispatch = useDispatch();
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
        dispatch(getAllInventoryTemplates())
        .then((response) => {
            setTemplates(response.payload);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [dispatch]);

    const handleTemplateSelect = (templateId) => {
        const selected = templates.find(template => template.inventory_template_id === templateId);
        setSelectedTemplate(selected);
    };

    const renderWithCard = () => {
        return (
            <Card>
                <CardBody>
                    {renderContent()}
                </CardBody>
            </Card>
        );
    }

    const renderWithoutCard = () => {
        return (
            <>
                {renderContent()}
            </>
        );
    }

    const renderContent = () => {
        if (selectedTemplate) {
            return (
                <>
                    <Row className="mb-3">
                        <Col>
                        <Link onClick={() => setSelectedTemplate(null)} className="">
                            <i className="ri-arrow-left-line me-1"></i>
                            Back to Template Selection
                        </Link>
                        </Col>
                    </Row>
                    <h3>Create {selectedTemplate.item} Inventory</h3>
                    <InventoryAdd inventoryTemplate={selectedTemplate} customerId={customerId}/>
                </>
            );
        } else {
            return (
                <>
                    <h4>Select a template</h4>
                    <Select
                        options={templates.map((template) => ({ value: template.inventory_template_id, label: template.item }))}
                        onChange={(selected) => handleTemplateSelect(selected.value)}
                    />
                </>
            );
        }
    }

    return (
        <div className={page ? 'page-content' : ''}>
            <Container fluid>
                {page && (
                    <>
                        <BreadCrumb title="Create Inventory" pageTitle={'Inventory'} path={'/inventory-items-list'}/>
                    </>
                )}
                {page ? renderWithCard() : renderWithoutCard()}
            </Container>
        </div>
    );
};

export default InventoryCreate;