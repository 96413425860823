import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { withTranslation } from "react-i18next";
import MainNavigation from './MainNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {changeLayoutMode} from '../../../slices/thunks';

import Header from './Header';

import { useLocation, useNavigate } from 'react-router-dom';

const HeaderNav = ({ t, customer }) => {
    const dispatch = useDispatch();
    const [headerClass, setHeaderClass] = useState("");

    const selectLayoutState = (state) => state.Layout;
    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            layoutModeType: layout.layoutModeType,
        })
    );
    // Inside your component
    const {
        layoutModeType,
    } = useSelector(selectLayoutProperties);

    useEffect(() => {
        if (customer) {
            document.title = `${customer.customer_name} | Omnitouch CRM`;
        }
    }, [customer]);

    const creationDate = new Date(customer?.created).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });

    /*
    call dark/light mode
    */
    const onChangeLayoutMode = (value) => {
        if (changeLayoutMode) {
            dispatch(changeLayoutMode(value));
        }
    };

    // class add remove in header 
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });

    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setHeaderClass("topbar-shadow");
        } else {
            setHeaderClass("");
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Header
                    headerClass={headerClass}
                    layoutModeType={layoutModeType}
                    onChangeLayoutMode={onChangeLayoutMode}
                    className="mx-0 position-absolute start-0"
                    wholeWidth
                />
            </Row>
            <Row className='mx-0 px-0'>
                <Col lg={12} sm={12}>
                    <Card className="">
                        <CardHeader className="bg-primary">
                            <Row className="align-items-center">
                                <Col md={8}>
                                    <h4 className="fw-bold text-white">{customer?.Name ?? "Self Care"}</h4>
                                    <div className="d-flex align-items-center">
                                        <div className="text-white">Creation Date: <span className="fw-medium">{creationDate}</span></div>
                                        <div className="vr mx-3"></div>
                                        {customer?.tags?.map(tag => (
                                            <a key={'tag-' + tag} href={tag.tag_link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                                <div
                                                    className="badge rounded-pill fs-12"
                                                    style={{
                                                        backgroundColor: `#${tag.tag_hex_color}`,
                                                        color: '#fff',
                                                    }}
                                                >
                                                    {tag.tag_text}
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                </Col>
                                <Col md={4} className="d-flex justify-content-end">
                                    <div className="hstack gap-2">
                                        <div className="icon-button px-3">
                                            <i className="ri-star-fill"></i>
                                        </div>
                                        <div className="icon-button px-3">
                                            <i className="ri-share-line"></i>
                                        </div>
                                        <div className="icon-button px-3">
                                            <i className="ri-flag-line"></i>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className='pb-0'>
                            <MainNavigation />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default withTranslation()(HeaderNav);
