import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
} from 'reactstrap';

// import actions
import {
  getStripeObjectsByCustomerId as onGetStripeObjectsByCustomerId,
  addStripePaymentForAnInvoice as onAddStripePaymentForAnInvoice,
  addNewTransaction as onAddNewTransaction,
  updateInvoice as onUpdateInvoice,
  getInvoicesByCustomerId as onGetInvoicesByCustomerId,
  getTransactionsByInvoiceId as onGetTransactionsByInvoiceId,
} from '../../../slices/thunks';

import TableCommon from '../../../Components/Common/TableCommon';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const PaymentModal = ({ isOpen, toggle, selectedInvoice, onSuccess = null }) => {
  const dispatch = useDispatch();

  const { cards: paymentMethods } = useSelector((state) => state.Stripe);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [invoiceTransactions, setInvoiceTransactions] = useState([]);
  const [customerId, setCustomerId] = useState(null);

  useEffect(() => {
    if (selectedInvoice) {
      dispatch(onGetStripeObjectsByCustomerId(selectedInvoice.customer_id));
      dispatch(onGetTransactionsByInvoiceId(selectedInvoice.invoice_id))
        .then((response) => {
          const transactions = response.payload;
          setInvoiceTransactions(formatTransactions(transactions));
        });
      setCustomerId(selectedInvoice.customer_id);
    }
  }, [selectedInvoice]);

  useEffect(() => {
    const defaultCard = paymentMethods.find((method) => method.default_payment_method);
    if (defaultCard) {
      setSelectedCard(defaultCard.stripe_card);
    }
  }, [paymentMethods]);

  const formatTransactions = (transactions) => {
    if (transactions) {
      // format transactions
      const formattedTransactions = transactions.map((transaction) => ({
        id: transaction.transaction_id,
        title: transaction.title,
        description: transaction.description,
        retail_cost: transaction.retail_cost,
      }));
      return formattedTransactions;
    }
  };

  const handlePaymentUpdates = () => {
    const invoiceId = selectedInvoice.invoice_id;
    console.log("adding transaction for selectedInvoice", selectedInvoice);
    dispatch(onAddNewTransaction({
      customer_id: selectedInvoice.customer_id,
      description: "Payment for Invoice " + selectedInvoice.invoice_id,
      invoice_id: selectedInvoice.invoice_id,
      retail_cost: selectedInvoice.retail_cost*-1,
      title: 'Payment for Invoice ' + selectedInvoice.invoice_id,
      wholesale_cost: selectedInvoice.wholesale_cost*-1,
    })).then((response) => {
      dispatch(onUpdateInvoice({
        invoice_id: invoiceId,
        paid: true,
        payment_transaction_id: response.transaction_id,
      }));
    }).then(() => {
      if (onSuccess) {
        onSuccess();
      }
      if (toggle) toggle();
    }).then(() => {
      toast.success("Payment Successful", { autoClose: 3000 });
    }).catch((error) => {
      toast.error("Payment Error: " + error, { autoClose: 3000 });
    });
  };

  const handleStripePayment = () => {
    const invoiceId = selectedInvoice.invoice_id;
    const selectedStripeCard = paymentMethods.find((method) => method.stripe_card === selectedCard);
    const selectedStripeCardId = selectedStripeCard.customer_stripe_id;

    dispatch(onAddStripePaymentForAnInvoice({invoiceId: invoiceId, paymentId: selectedStripeCardId}))
    .then((response) => {
      if (response) {
        //handlePaymentUpdates();
        if (toggle) toggle();
        if (onSuccess) {
          onSuccess();
        }
        toast.success("Payment Successful", { autoClose: 3000 });
      }
    })
    .catch((error) => {
      toast.error("Stripe Payment Error: " + error, { autoClose: 3000 });
    });
  };

  const onPayInvoiceConfirm = () => {
    if (selectedPaymentMethod === 'stripe') {
      handleStripePayment();
    } else {
      handlePaymentUpdates();
    }
  };

  const handleToggle = () => {
    if (toggle) {
      toggle();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} centered>
      <ModalHeader toggle={handleToggle}>Pay Invoice</ModalHeader>
      <ModalBody>
        {selectedInvoice && (
          <>
          <h6>Invoice ID: {selectedInvoice.invoice_id}</h6>
          <h6>Due Date: {selectedInvoice.due_date}</h6>
          <h6>Amount Due: {selectedInvoice.retail_cost}</h6>

          <TableCommon
              title="Transactions"
              data={invoiceTransactions ?? []}
              viewOnly={true}
          />

          <div className="mb-3">
            <label className="form-label">Select Payment Method</label>
            <select
              className="form-select"
              value={selectedPaymentMethod}
              onChange={(e) => setSelectedPaymentMethod(e.target.value)}
            >
              <option>Select Payment Method</option>
              <option value="stripe">Stripe Transaction</option>
              <option value="cash">Cash</option>
              <option value="refund">Refund</option>
              <option value="pos">POS Transaction</option>
              <option value="bank">Bank Transfer</option>
            </select>
          </div>

          {selectedPaymentMethod === 'stripe' && (
            <>
              {paymentMethods && paymentMethods.map((method) => (
                <Card
                  key={method.stripe_card}
                  onClick={() => setSelectedCard(method.stripe_card)}
                  className={selectedCard === method.stripe_card ? 'bg-primary text-white' : ''}
                >
                  <CardBody>
                    <Row>
                      <Col>
                        <CardTitle
                          className={selectedCard === method.stripe_card ? 'bg-primary text-white' : ''}
                          tag="h5"
                        >
                          {method.card_nickname}
                        </CardTitle>
                      </Col>
                      <Col>
                        <CardText>Expiry: {method.exp_month}/{method.exp_year}</CardText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CardText>Issuer: {method.cc_type}</CardText>
                      </Col>
                      <Col>
                        <CardText>Default: {method.default_payment_method ? 'Yes' : 'No'}</CardText>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ))}
            </>
          )}

          {selectedPaymentMethod !== 'stripe' && (
            <div className="mb-3">
              <label className="form-label">Reference Number</label>
              <input
                type="text"
                className="form-control"
                value={referenceNumber}
                onChange={(e) => setReferenceNumber(e.target.value)}
              />
            </div>
          )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={toggle ? toggle : () => {}}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-success"
          onClick={onPayInvoiceConfirm}
          disabled={!selectedPaymentMethod}
        >
          Pay Invoice
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default PaymentModal;