import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

const StepNavigation = ({ activeTab, toggleTab, formCompletionStatus }) => {

  return (
    <Nav className="nav-pills custom-nav nav-justified" role="tablist">
      <NavItem>
        <NavLink
          href="#"
          id="steparrow-gen-info-tab"
          className={classnames({
            active: activeTab === 4,
            done: activeTab <= 6 && activeTab > 3,
          })}
          onClick={() => toggleTab(4)}
        >
          Customer Info
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href="#"
          id="steparrow-contacts-info-tab"
          className={classnames({
            active: activeTab === 5,
            done: activeTab <= 7 && activeTab > 4,
            disabled: !formCompletionStatus.customerInfo,
          })}
          onClick={() => {
            if (formCompletionStatus.customerInfo) {
              toggleTab(5);
            }
          }}
        >
          Contacts
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href="#"
          id="steparrow-site-info-tab"
          className={classnames({
            active: activeTab === 6,
            done: activeTab <= 8 && activeTab > 5,
            disabled: !formCompletionStatus.contacts,
          })}
          onClick={() => {
            if (formCompletionStatus.contacts) {
              toggleTab(6);
            }
          }}
        >
          Site
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href="#"
          id="steparrow-payment-info-tab"
          className={classnames({
            active: activeTab === 7,
            done: activeTab <= 9 && activeTab > 6,
            disabled: !formCompletionStatus.site,
          })}
          onClick={() => {
            if (formCompletionStatus.site) {
              toggleTab(7);
            }
          }}
        >
          Payment Method
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href="#"
          id="steparrow-finish-info-tab"
          className={classnames({
            active: activeTab === 8,
            done: activeTab <= 8 && activeTab > 7,
            disabled: !formCompletionStatus.payment,
          })}
          onClick={() => {
            if (formCompletionStatus.payment) {
              toggleTab(8);
            }
          }}
        >
          Finish
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default StepNavigation;
