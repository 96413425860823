import { createSlice } from "@reduxjs/toolkit";
import { 
    getAttributes,
    addNewAttribute,
    deleteAttribute,
    updateAttribute,
    getAttributeById,
    getAttributesByCustomerId,
} from './thunk';

export const initialState = {
    attributes: [],
    attribute: {},
    error: null,
};

const AttributeSlice = createSlice({
    name: 'AttributeSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAttributes.fulfilled, (state, action) => {
            state.attributes = action.payload;
        });
        builder.addCase(getAttributes.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(addNewAttribute.fulfilled, (state, action) => {
            state.attribute = action.payload;
        });
        builder.addCase(addNewAttribute.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(deleteAttribute.fulfilled, (state, action) => {
            state.attribute = action.payload;
        });
        builder.addCase(deleteAttribute.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(updateAttribute.fulfilled, (state, action) => {
            state.attribute = action.payload;
            state.attributes = state.attributes.map((attribute) =>
                attribute.attribute_id === action.payload.attribute_id ? action.payload : attribute
            );
        });
        builder.addCase(updateAttribute.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(getAttributeById.fulfilled, (state, action) => {
            state.attribute = action.payload;
        });
        builder.addCase(getAttributeById.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(getAttributesByCustomerId.fulfilled, (state, action) => {
            state.attributes = action.payload;
        });
        builder.addCase(getAttributesByCustomerId.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
    }
});

export default AttributeSlice.reducer;