import { createSlice } from "@reduxjs/toolkit";
import { 
    addNewActivity,
    deleteActivity,
    getActivityById,
    updateActivity,
    getActivitiesByCustomerId
} from './thunk';

export const initialState = {
    activities: [],
    activity: {},
    error: null,
};

const ActivitySlice = createSlice({
    name: 'ActivitySlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addNewActivity.fulfilled, (state, action) => {
            state.activities = action.payload;
        });
        builder.addCase(addNewActivity.rejected, (state, action) => {
            state.error = action?.payload?.error || null;
        });
        builder.addCase(deleteActivity.fulfilled, (state, action) => {
            state.deleteActivity = action.payload;
        });
        builder.addCase(deleteActivity.rejected, (state, action) => {
            state.error = action?.payload?.error || null;
        });
        builder.addCase(updateActivity.fulfilled, (state, action) => {
            state.updateActivity = action.payload;
        });
        builder.addCase(updateActivity.rejected, (state, action) => {
            state.error = action?.payload?.error || null;
        });
        builder.addCase(getActivityById.fulfilled, (state, action) => {
            state.activity = action.payload;
        });
        builder.addCase(getActivityById.rejected, (state, action) => {
            state.error = action?.payload?.error || null;
        });
        builder.addCase(getActivitiesByCustomerId.fulfilled, (state, action) => {
            state.activities = action.payload;
        });
        builder.addCase(getActivitiesByCustomerId.rejected, (state, action) => {
            state.error = action?.payload?.error || null;
        });
    }
});

export default ActivitySlice.reducer;