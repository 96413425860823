import React from 'react';
import { Card, CardBody, Col, Progress, Row } from 'reactstrap';
import { USAGE_KEYS, USAGE_MAP } from '../../../Components/constants/crm';

const UsageProgress = ({ data, dataType, isOpen, simple }) => {
    // Consolidate the data if it's an array
    const consolidatedData = Array.isArray(data)
        ? data.reduce((acc, curr) => {
            return {
            ID: curr.ID || acc.ID,
            custom_Percentage: parseInt(curr.custom_Percentage ?? 0) + parseInt(acc.custom_Percentage ?? 0),
            custom_Value_hr: parseFloat(curr.custom_Value_hr ?? 0) + parseFloat(acc.custom_Value_hr ?? 0),
            Value: parseFloat(curr.Value ?? 0) + parseFloat(acc.Value ?? 0),
            custom_Bucket: parseFloat(curr.custom_Bucket ?? 0) + parseFloat(acc.custom_Bucket ?? 0),
            custom_Units: curr.custom_Units || acc.custom_Units,
            custom_Description_String: curr.custom_Description_String || acc.custom_Description_String,
            };
        }, { ID: '', custom_Percentage: 0, custom_Value_hr: 0, Value: 0, custom_Bucket: 0, custom_Units: '', custom_Description_String: '' })
        : data;

    // Calculate the custom_Percentage based on the Value and custom_Bucket
    const customPercentage = parseInt((consolidatedData.Value / consolidatedData.custom_Bucket) * 100);

    let usedPercentage = 100 - customPercentage;

    if (simple) {
        return (
            <React.Fragment>
                {dataType === USAGE_KEYS.MONETARY ? (
                    <h6 className="mb-0 mt-3 ">
                        <b className={`text-${USAGE_MAP[dataType].color}`}>{USAGE_MAP[dataType].unit}{consolidatedData.Value/100.0}</b> Balance Available
                    </h6>
                ) : (
                    <>
                        <div className="d-flex mb-2">
                            <div className="flex-grow-1">
                                <h6 className="mb-0">
                                    <b className={`text-${USAGE_MAP[dataType].color}`}>{isNaN(usedPercentage) ? 'N/A' : `${usedPercentage}%`}</b> {USAGE_MAP[dataType].label} Used
                                </h6>
                            </div>
                            <div className="flex-shrink-0">
                                <h6 className="mb-0">
                                    <b className={`text-${USAGE_MAP[dataType].color}`}>{isNaN(consolidatedData.custom_Percentage) ? 'N/A' : `${consolidatedData.custom_Percentage}%`}</b> remaining
                                </h6>
                            </div>
                        </div>
                        <Progress value={usedPercentage} color={USAGE_MAP[dataType].color} className={`bg-${USAGE_MAP[dataType].color}-subtle rounded-0`} />
                    </>
                )}
            </React.Fragment>
        );
    }

    return (
        <Card className="bg-light overflow-hidden shadow-none mb-0">
            <CardBody>
                <Row>
                    <Col sm={'auto'}>
                        <div className="avatar-sm d-flex text-center align-items-center">
                            <i className={`${USAGE_MAP[dataType].icon} text-${USAGE_MAP[dataType].color} fs-40`}></i>
                        </div>
                    </Col>
                    <Col className='align-items-center'>
                        {dataType === USAGE_KEYS.MONETARY ? (
                            <h6 className="mb-0 mt-3 ">
                                <b className={`text-${USAGE_MAP[dataType].color}`}>{USAGE_MAP[dataType].unit}{consolidatedData.Value/100.0}</b> Balance Available
                            </h6>
                        ) : (
                            <>
                                <div className="d-flex mb-2">
                                    <div className="flex-grow-1">
                                        <h6 className="mb-0">
                                            <b className={`text-${USAGE_MAP[dataType].color}`}>{isNaN(usedPercentage) ? 'N/A' : `${usedPercentage}%`}</b> {USAGE_MAP[dataType].label} Used
                                        </h6>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <h6 className="mb-0">
                                            <b className={`text-${USAGE_MAP[dataType].color}`}>{isNaN(customPercentage) ? 'N/A' : `${customPercentage}%`}</b> remaining
                                        </h6>
                                    </div>
                                </div>
                                <Progress value={usedPercentage} color={USAGE_MAP[dataType].color} className={`bg-${USAGE_MAP[dataType].color}-subtle rounded-0`} />
                            </>
                        )}
                    </Col>
                    <Col sm={'auto'} className='d-flex align-items-center text-center'>
                        {isOpen ? (
                            <i className="ri-arrow-up-s-fill fs-20"></i>
                        ) : (
                            <i className="ri-arrow-down-s-fill fs-20"></i>
                        )}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default UsageProgress;