import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Modal, ModalHeader, ModalBody, Container } from 'reactstrap';
import classnames from 'classnames';
import ManageUsers from './ManageUsers';
import ManageRoles from './ManageRoles';
import ManagePermissions from './ManagePermissions';

const ManageAccess = () => {
    const [activeTab, setActiveTab] = useState('users');
    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const toggleModal = () => setModal(!modal);

    const openModal = (content) => {
        setModalContent(content);
        toggleModal();
    };

    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card className="mt-n4 mx-n4">
                            <CardHeader><h5>Users & Roles</h5></CardHeader>
                            <div className="bg-primary text-white">
                                <CardBody className="pb-0 px-4">
                                    <Nav className="nav-tabs-custom border-bottom-0 nav" role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === 'users' }, "fw-semibold")}
                                                onClick={() => { toggleTab('users'); }}
                                            >
                                                Users
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === 'roles' }, "fw-semibold")}
                                                onClick={() => { toggleTab('roles'); }}
                                            >
                                                Roles
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === 'permissions' }, "fw-semibold")}
                                                onClick={() => { toggleTab('permissions'); }}
                                            >
                                                Permissions
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardBody>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <TabContent activeTab={activeTab} className="text-muted">
                            <TabPane tabId="users">
                                { activeTab === 'users' &&
                                    <ManageUsers openModal={openModal} />
                                }
                            </TabPane>
                            <TabPane tabId="roles">
                                { activeTab === 'roles' &&
                                    <ManageRoles openModal={openModal} />
                                }
                            </TabPane>
                            <TabPane tabId="permissions">
                                { activeTab === 'permissions' &&
                                    <ManagePermissions openModal={openModal} />
                                }
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Add New</ModalHeader>
                    <ModalBody>
                        {modalContent}
                    </ModalBody>
                </Modal>
            </Container>
        </div>
    );
};

export default ManageAccess;
