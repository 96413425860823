const SERVICE_STATUSES = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    SUSPENDED: 'Suspended',
};

const SERVICE_STATUS_THEME_COLORS = {
    [SERVICE_STATUSES.ACTIVE]: 'success',
    [SERVICE_STATUSES.INACTIVE]: 'danger',
    [SERVICE_STATUSES.SUSPENDED]: 'warning',
};

const TRANSACTION = {
    CREDIT_TYPES: {
        CASH_PAYMENT: 'Cash Payment',
        REFUND: 'Refund'
    },
    TYPES: {
        DEBIT: 'debit',
        CREDIT: 'credit'
    }
} 

const USAGE_LABEL_MAP = {
    '*data': 'Data',
    '*voice': 'Talk',
    '*sms': 'Text',
    '*data_dongle': 'Data',
};

const USAGE_KEYS = {
    DATA: '*data',
    DATA_DONGLE: '*data_dongle',
    VOICE: '*voice',
    SMS: '*sms',
    MONETARY: '*monetary'
};

const USAGE_MAP = {
    [USAGE_KEYS.DATA]: {
        label: 'Data',
        unit: 'GB',
        icon: 'ri-arrow-up-down-fill',
        color: 'warning'
    },
    [USAGE_KEYS.DATA_DONGLE]: {
        label: 'Data',
        unit: 'GB',
        icon: 'ri-arrow-up-down-fill',
        color: 'primary'
    },
    [USAGE_KEYS.VOICE]: {
        label: 'Talk',
        unit: 'Minutes',
        icon: 'ri-phone-fill',
        color: 'info'
    },
    [USAGE_KEYS.SMS]: {
        label: 'Text',
        unit: 'SMS',
        icon: 'ri-message-2-fill',
        color: 'success'
    },
    [USAGE_KEYS.MONETARY]: {
        label: 'Balance',
        unit: '£',
        icon: 'ri-money-pound-box-fill',
        color: 'danger'
    },
};

export {
    SERVICE_STATUSES,
    SERVICE_STATUS_THEME_COLORS,
    TRANSACTION,
    USAGE_LABEL_MAP,
    USAGE_KEYS,
    USAGE_MAP,
};