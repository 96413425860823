import React, { useEffect, useState } from 'react';
import {
    getInventoryByTemplateId,
    deleteInventory,
    getCustomers,
    updateInventory
} from '../../../slices/thunks';
import { Container, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, FormGroup, Label, Button  } from 'reactstrap';
import { useDispatch } from 'react-redux';
import TableCommon from '../../../Components/Common/TableCommon';
import InventoryForm from './InventoryForm';
import DeleteModal from '../../../Components/Common/DeleteModal';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { useParams, Link } from 'react-router-dom';

const TemplateDetailList = ({ inventoryTemplateId = null, asPage = false, noHeader = true }) => {
    const { templateId } = inventoryTemplateId ?? useParams();
    const dispatch = useDispatch();
    const [inventoryItems, setInventoryItems] = useState(null);
    const [selectedInventoryItem, setSelectedInventoryItem] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [assignModal, setAssignModal] = useState(false);
    const [selectCustomerValidation, setSelectCustomerValidation] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const handleAssignModalClose = () => {
        setAssignModal(false);
    };

    const toggleAssignModal = () => {
        setAssignModal(!assignModal);
    };

    const toggleEditModal = () => {
        setEditModal(!editModal);
    };

    const handleEditModalClose = () => {
        setEditModal(false);
    };

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handleDeleteConfirm = () => {
        dispatch(deleteInventory(selectedInventoryItem.inventory_id))
            .then(() => {
                dispatch(getInventoryByTemplateId(templateId))
                    .then((response) => {
                        toast.success('Inventory Item Deleted Successfully');
                        setInventoryItems(response.payload);
                    });
                setDeleteModal(false);
            })
            .catch((error) => {
                toast.error('Inventory Item Delete Failed');
                console.error(error);
            });
    };

    const handleDelete = (id) => {
        const item = inventoryItems.find(item => item.inventory_id === id);
        setSelectedInventoryItem(item);
        setDeleteModal(true);
    };

    const handleEdit = (id) => {
        const item = inventoryItems.find(item => item.inventory_id === id);
        setSelectedInventoryItem(item);
        setEditModal(true);
    };

    const handleView = (id) => {
        const item = inventoryItems.find(item => item.inventory_id === id);
        // redirect to customer detail page
        window.location.href = `/customers/${item.customer_id}#8`;
    };

    const handleAssign = (id) => {
        const item = inventoryItems.find(item => item.inventory_id === id);
        setSelectedInventoryItem(item);
        setAssignModal(true);
    };

    const fetchInventoryItems = () => {
        dispatch(getInventoryByTemplateId(templateId))
            .then((response) => {
                setInventoryItems(response.payload);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const onAssignToCustomerConfirm = () => {
        if (selectedCustomer && selectedCustomer.value) {
            dispatch(updateInventory({ ...selectedInventoryItem, customer_id: selectedCustomer.value }))
                .then(() => {
                    toast.success('Inventory Item Assigned Successfully');
                    fetchInventoryItems();
                    setAssignModal(false);
                })
                .catch((error) => {
                    toast.error('Inventory Item Assign Failed');
                    console.error(error);
                });
        }
    };

    const loadOptions = (inputValue, callback) => {
        dispatch(getCustomers({searchTerm: inputValue}))
            .then((response) => {
                const options = response.payload.data.map(customer => ({ value: customer.customer_id, label: customer.customer_name }));
                callback(options);
            });
    };

    useEffect(() => {
        fetchInventoryItems();
        return () => {
            setInventoryItems([]);
            setSelectedInventoryItem(null);
            setDeleteModal(false);
        };

    }, [templateId, dispatch]);

    if (!inventoryItems) {
        return <div>Loading...</div>;
    }

    const formatKey = (key) => {
        return key
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    };

    const formattedInventoryItems = inventoryItems.map(item => {
        return {
            "id": item.inventory_id,
            "inventory_id": item.inventory_id,
            "Customer ID": item.customer_id,
            "Inventory Template ID": item.inventory_template_id,
            "Item": item.item,
            "Item Location": item.item_location,
            "Retail Cost": item.retail_cost,
            "Serial1": item.serial1,
            "Serial2": item.serial2,
            "Service ID": item.service_id,
            "Sold Date": item.sold_date,
            "Wholesale Cost": item.wholesale_cost,
            "Created": item.created,
            "Last Modified": item.last_modified,
            // "actions": item.customer_id ? ['edit', 'delete', 'view'] : ['edit', 'delete', 'assign']
            actions: (
                <ul className="list-inline hstack gap-2 mb-0">
                    <li className="list-inline-item edit">
                        <Link
                        title='Edit'
                        className="text-primary d-inline-block edit-item-btn"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(item.inventory_id);
                        }}
                        >
                        <i className="ri-pencil-fill fs-16"></i>
                        </Link>
                    </li>
                    <li className="list-inline-item">
                        <Link
                        title='Delete'
                        className="text-danger d-inline-block remove-item-btn"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(item.inventory_id);
                        }}
                        >
                        <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Link>
                    </li>
                    { item.customer_id ? (
                        <li className="list-inline-item">
                            <Link
                                className="text-primary d-inline-block"
                                title='View'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleView(item.inventory_id);
                                }}
                            >
                            <i className="ri-eye-fill fs-16"></i>
                            </Link>
                        </li>
                    ) : (
                        <li className="list-inline-item">
                            <Link
                                title='Assign to Customer'
                                className="text-success d-inline-block remove-item-btn"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleAssign(item.inventory_id);
                                }}
                            >
                            <i className="ri-user-add-fill fs-16"></i>
                            </Link>
                        </li>
                    )}
                </ul>
            )
        };
    });

    return (
        <React.Fragment>
            <div className={ asPage ? "page-content" : ''}>
                <Container fluid>
                    <TableCommon
                        title={'Inventory Items'}
                        noHeader={noHeader}
                        data={formattedInventoryItems}
                        delete={handleDelete}
                        edit={handleEdit}
                        view={handleView}
                        assign={handleAssign}
                        onRowClick={handleEdit}
                    />
                </Container>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteConfirm}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <Modal isOpen={editModal} toggle={toggleEditModal} size='lg' centered>
                    <ModalHeader toggle={toggleEditModal}>Edit Inventory Item</ModalHeader>
                    <ModalBody>
                        <InventoryForm initialData={selectedInventoryItem} onClose={handleEditModalClose} onSubmit={fetchInventoryItems}/>
                    </ModalBody>
                </Modal>
                {/* Assign Modal React Select */}
                <Modal isOpen={assignModal} toggle={toggleAssignModal} size='lg' centered>
                    <ModalHeader toggle={toggleAssignModal}>Assign Inventory Item to Customer</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="customer_id">Select Customer:</Label>
                                    <AsyncSelect
                                        loadOptions={loadOptions}
                                        onChange={(selectedOption) => {
                                            setSelectedCustomer(selectedOption);
                                            setSelectCustomerValidation(selectedOption.value !== null);
                                        }}
                                    />
                                </FormGroup>
                                {!selectCustomerValidation && <div className="alert alert-danger">Please select a customer</div>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <Button type="submit" className='btn-success mr-2' onClick={onAssignToCustomerConfirm}>Assign</Button>
                                <Button type="submit" className='btn-danger' onClick={handleAssignModalClose}>Cancel</Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </div>
        </React.Fragment>
    );
};

export default TemplateDetailList;