import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

//Import actions
import {
    addNewContact as onAddNewContact,
    updateCustomer as onUpdateCustomer,
    getCustomers as getCustomers,
    updateContact as onUpdateContact
} from "../../../slices/thunks";

import {
    Col,
    Container,
    Row,
    Label,
    Input,
    Form,
    Button
} from 'reactstrap';

import Select from "react-select";
import PhoneInput from "../../../Components/Common/PhoneInput";
import { toast } from 'react-toastify';

const ContactForm = (props) => {
    const dispatch = useDispatch();

    const contactTypeOptions = [
          { value: 'all', label: 'Contact for All Types' },
          { value: 'technical', label: 'Technical Contact' },
          { value: 'billing', label: 'Billing Contact' },
          { value: 'installation', label: 'Installation Contact' },
          { value: 'enduser', label: 'End User Contact' }
      ];

    const [selectedContactTypes, setSelectedContactTypes] = useState([contactTypeOptions[0]]);
    

    const [isContactsEmpty, setIsContactsEmpty] = useState(true);
    const [contacts, setContacts] = useState([]);

    const defaultContact = {
        customer_id: props.customerId ?? props.customer.customer_id,
        contact_firstname: "",
        contact_email: "",
        contact_job_title: "",
        contact_notes: "",
        contact_lastname: "",
        contact_honorific: "",
        contact_phone: "",
        contact_types: "all,"
    };

    const [contact, setContact] = useState(props.initialContact ?? defaultContact);

    const [validation, setValidation] = useState({
        contact_firstname: null,
        contact_email: null,
        contact_lastname: null,
        contact_phone: null
      });

    function handleSubmitValidation() {
        const modifiedV = { ...validation };
        var fnm = document.getElementById("firstName").value;
        var lnm = document.getElementById("lastName").value;
        var emailV = document.getElementById("emailAddress").value;
        var phoneV = document.getElementById("phoneNumber").value;

        if (fnm === "") {
            modifiedV['contact_firstname'] = false;
        } else {
            modifiedV['contact_firstname'] = true;
        }

        if (lnm === "") {
            modifiedV['contact_lastname'] = false;
        } else {
            modifiedV['contact_lastname'] = true;
        }

        if (emailV === "") {
            modifiedV['contact_email'] = false;
        } else {
            modifiedV['contact_email'] = true;
        }

        if (phoneV === "") {
            modifiedV['contact_phone'] = false;
        } else {
            modifiedV['contact_phone'] = true;
        }
        setValidation(modifiedV);

        // Check if any validation has failed
        const validationFailed = Object.values(modifiedV).some(value => value === false);
        return validationFailed;
    }

    const handleCustomerUpdate = () => {
        // check if customer name in customer props is empty
        if (props.customer.customer_name === "" && contacts.length === 0) {

            // create a new customer object with the customer name
            const updatedCustomer = {
                ...props.customer,
                customer_name: contact.contact_firstname + " " + contact.contact_lastname
            };

            // Remove 'sites', 'tags', 'attributes', and 'contacts' from updatedCustomer
            delete updatedCustomer.sites;
            delete updatedCustomer.tags;
            delete updatedCustomer.attributes;
            delete updatedCustomer.contacts;

            // if empty, update customer name
            dispatch(onUpdateCustomer(updatedCustomer));
        }
    };

    //for change tooltip display propery
    const onChangeValidation = (fieldName, value) => {
        const modifiedV  = { ...validation };
        if (value !== "") {
            modifiedV[fieldName] = true;
        } else {
            modifiedV[fieldName] = false;
        }
        setValidation(modifiedV);
    };

    const handleInputChange = (event) => {
        setContact({
            ...contact,
            [event.target.name]: event.target.value
        });

        onChangeValidation(event.target.name, event.target.value);
    }

    useEffect(() => {
        if (contacts.length === 0) {
            setIsContactsEmpty(true);
        } else {
            setIsContactsEmpty(false);
        }
    }, [contacts]);

    useEffect(() => {
        if (props.initialContact) {
            setContact(props.initialContact);
        }
    }, [props.initialContact]);

    const handleContactTypesSelection = (selectedContactTypes) => {
        setSelectedContactTypes(selectedContactTypes);
        const contactTypes = selectedContactTypes ? selectedContactTypes.map(option => option.value).join(',') : '';
        setContact({ ...contact, contact_types: contactTypes });
    }

    const skipFurtherContactAddition = (event) => {
        event.preventDefault();
        if (props.onNextStep) {
            props.onNextStep();
        }
    }

    const handleContactSave = (event) => {
        event.preventDefault();

        // Validate Fields
        const validationFailed = handleSubmitValidation();
        if (validationFailed) {
            toast.error('Validation failed. Please check your input and try again.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return; // Return early to prevent form submission
        }

        if (contact.customer_id === "" || contact.customer_id === null || contact.customer_id === undefined) {
            toast.error('Customer ID is required. Please try again.', { autoClose: 3000 });
            return;
        }

        // handle form submission
        if (props.initialContact) {
            dispatch(onUpdateContact(contact))
                .then(() => {
                    // update contact in contacts array
                    const updatedContacts = contacts.map(c => {
                        if (c.contact_id === contact.contact_id) {
                            return contact;
                        }
                        return c;
                    });
                    setContacts(updatedContacts);

                    toast.success('Contact updated successfully!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                })
                .catch(() => {
                    toast.error('Failed to update contact. Please try again.', { autoClose: 3000 });
                })
                .finally(() => {
                    if (!props.standalone) {
                        dispatch(getCustomers());
                    }
                    // only call next pane if passed on as props
                    if (props.onNextStep) {
                        props.onNextStep();
                    }
                });
        } else {
            dispatch(onAddNewContact(contact))
                .then(() => {
                

                    // add contact to contacts array
                    setContacts([...contacts, contact]);

                    // update customer name if empty
                    handleCustomerUpdate();

                    toast.success('Contact saved successfully!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                })
                .catch(() => {
                    toast.error('Failed to save contact. Please try again.', { autoClose: 3000 });
                })
                .finally(() => {
                    if (!props.standalone) {
                        dispatch(getCustomers());
                    }
                    // only call next pane if passed on as props
                    if (props.onNextStep) {
                        props.onNextStep();
                    }
                });
        }
    }

    const handleContactSaveAndAnother = (event) => {
        event.preventDefault();

        // Validate Fields
        const validationFailed = handleSubmitValidation();
        if (validationFailed) {
            toast.error('Validation failed. Please check your input and try again.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return; // Return early to prevent form submission
        }

        // handle form submission
        dispatch(onAddNewContact(contact))
            .then(() => {
                // add contact to contacts array
                setContacts([...contacts, contact]);

                // update customer name if empty
                handleCustomerUpdate();

                toast.success('Contact saved successfully!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                // clear form fields and validation
                handleClearContact();
                handleClearValidation();
            })
            .catch(() => {
                toast.error('Failed to save contact. Please try again.', { autoClose: 3000 });
            });
    }

    const handleClearValidation = () => {
        setValidation({
            contact_firstname: null,
            contact_email: null,
            contact_lastname: null,
            contact_phone: null
        });
    }

    const handleClearContact = () => {
        setContact({
            ...contact,
            contact_firstname: "",
            contact_email: "",
            contact_job_title: "",
            contact_notes: "",
            contact_lastname: "",
            contact_honorific: "",
            contact_phone: "",
            contact_types: "all,"
        });
    }

    const onCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    document.title = "Customer Contacts | Omnitouch CRM";
    return (
        <React.Fragment>
                <div>
                    <Container>
                        <Form className="needs-validation" noValidate="novalidate">
                            <Row>
                                <Col lg={2}>
                                    <Label className="form-label" htmlFor="contactPrefix">Customer Title</Label>
                                    <Input
                                        type="text"
                                        id="contactPrefix"
                                        name="contact_honorific"
                                        value={contact.contact_honorific || ''}
                                        placeholder="Contact Prefix"
                                        onChange={(event) => handleInputChange(event, "contacts")}
                                    />
                                </Col>
                                <Col lg={5}>
                                    <Label className="form-label" htmlFor="firstName">First Name</Label>
                                    <Input
                                        type="text"
                                        id="firstName"
                                        required
                                        name="contact_firstname"
                                        value={contact.contact_firstname || ''}
                                        placeholder="First Name"
                                        onChange={(event) => handleInputChange(event, "contacts")}
                                        valid={validation["contact_firstname"] === true}
                                        invalid={
                                            validation["contact_firstname"] !== true &&
                                            validation["contact_firstname"] !== null
                                        }
                                    />
                                </Col>
                                <Col lg={5}>
                                    <Label className="form-label" htmlFor="lastName">Last Name</Label>
                                    <Input
                                        type="text"
                                        id="lastName"
                                        required
                                        name="contact_lastname"
                                        value={contact.contact_lastname}
                                        placeholder="Last Name" 
                                        onChange={(event) => handleInputChange(event, "contacts")}
                                        valid={validation["contact_lastname"] === true}
                                        invalid={
                                            validation["contact_lastname"] !== true &&
                                            validation["contact_lastname"] !== null
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <PhoneInput
                                        label={'Phone Number'}
                                        id='phoneNumber'
                                        name='contact_phone'
                                        value={contact.contact_phone || ''}
                                        onChange={(event) => handleInputChange(event, "contacts")}
                                        valid={validation["contact_phone"] === true}
                                        invalid={
                                            validation["contact_phone"] !== true &&
                                            validation["contact_phone"] !== null
                                        }
                                    />
                                </Col>
                                <Col lg={6}>
                                    <Label className="form-label" htmlFor="emailAddress">Email Address</Label>
                                    <Input
                                        type="email"
                                        id="emailAddress"
                                        required
                                        name="contact_email"
                                        value={contact.contact_email || ''}
                                        placeholder="Email Address"
                                        onChange={(event) => handleInputChange(event, "contacts")}
                                        valid={validation["contact_email"] === true}
                                        invalid={
                                            validation["contact_email"] !== true &&
                                            validation["contact_email"] !== null
                                        }
                                    />
                                </Col>
                                </Row>
                                <Row>
                                <Col lg={6}>
                                    <Label className="form-label" htmlFor="jobTitle">Job Title</Label>
                                    <Input
                                        type="text"
                                        id="jobTitle"
                                        name="contact_job_title"
                                        value={contact.contact_job_title || ''}
                                        placeholder="Job Title"
                                        onChange={(event) => handleInputChange(event, "contacts")}
                                    />
                                </Col>
                                <Col lg={6}>
                                    <Label htmlFor="contactTypes" className="form-label text-muted">Contact Types</Label>
                                    <Select
                                        value={selectedContactTypes}
                                        isMulti={true}
                                        required
                                        name="contact_types"
                                        onChange={handleContactTypesSelection}
                                        options={contactTypeOptions}
                                        id="contactTypes"
                                        closeMenuOnSelect={false}
                                        styles={{
                                            multiValueLabel: (styles) => ({
                                                ...styles,
                                                color: 'white',
                                            }),
                                        }}
                                    />
                                </Col>
                                </Row>
                                <Row>
                                <Col lg={12}>
                                    <Label className="form-label" htmlFor="additionalNotes">Additional Notes</Label>
                                    <textarea className="form-control" id="additionalNotes" name="contact_notes" value={contact.contact_notes ?? ''} placeholder="Additional Notes" rows="3" onChange={(event) => handleInputChange(event, "contacts")}></textarea>
                                </Col>
                            </Row>
                            {props.initialContact ? (
                                <Row style={{float: 'right'}} className="d-flex justify-content-start align-items-right gap-3 mt-4">
                                    <Col xs={12} md="auto">
                                        <Button
                                            type="button"
                                            className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                            onClick={(event) => {
                                                handleContactSave(event);
                                            }
                                            }
                                        >
                                            <i className="ri-arrow-right-line right label-icon align-middle fs-16 ms-2"></i>
                                            Update
                                        </Button>
                                    </Col>
                                    <Col xs={12} md="auto">
                                        <Button
                                            type="button"
                                            className="btn btn-danger btn-label right ms-auto nexttab nexttab"
                                            onClick={onCancel}
                                        >
                                            <i className="ri-arrow-right-line right label-icon align-middle fs-16 ms-2"></i>
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            ) : (
                                <Row style={{float: 'right'}} className="d-flex justify-content-start align-items-right gap-3 mt-4">
                                    <Col xs={12} md="auto">
                                        <Button
                                            type="button"
                                            className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                            onClick={(event) => {
                                                handleContactSaveAndAnother(event);
                                            }}
                                        >
                                            <i className="ri-arrow-right-line right label-icon align-middle fs-16 ms-2"></i>
                                            Save & Add Another
                                        </Button>
                                    </Col>
                                    <Col xs={12} md="auto" >
                                        <Button
                                            type="button"
                                            className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                            onClick={(event) => {
                                                handleContactSave(event);
                                            }}
                                        >
                                            <i className="ri-arrow-right-line right label-icon align-middle fs-16 ms-2"></i>
                                            Save & {props.standalone? "Exit" : "Next"}
                                        </Button>
                                    </Col>
                                    { contacts.length > 0 && (
                                        <Col xs={12} md="auto" className="d-flex align-items-center justify-content-end">
                                            <a
                                                role="button"
                                                className="text-success nexttab"
                                                style={{ cursor: 'pointer' }}
                                                onClick={(event) => {
                                                    skipFurtherContactAddition(event);
                                                }}
                                            >
                                                Skip
                                            </a>
                                        </Col>
                                    )}
                                </Row>
                            )}
                        </Form>
                    </Container>                    
                </div>
        </React.Fragment>
    );
}

export default ContactForm;