import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import * as Icons from 'react-icons/fa';
import {
    getAllInventoryTemplates,
    getTotalsOfInventoryItems,
} from '../../slices/thunks';
import TableCommon from '../../Components/Common/TableCommon';
import TemplateDetailList from './components/TemplateDetailList';
import InventoryTemplateForm from './components/InventoryTemplateForm';
import InventoryAdd from './components/InventoryAdd';
import { toast } from 'react-toastify';
import { Link, useMatch, Outlet, useNavigate } from 'react-router-dom';
import BreadCrumb from '../../Components/Common/BreadCrumb';

const TemplateList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [totals, setTotals] = useState([]);
  const [templateViewModal, setTemplateViewModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [addEditModal, setAddEditModal] = useState(false);
  const [inventoryAddModal, setInventoryAddModal] = useState(false);

  const match = useMatch("/inventory-items-template/:templateId");

  const handleInventoryAddModalClose = () => {
    setInventoryAddModal(false);
  };

  const toggleInventoryAddModal = () => {
    setInventoryAddModal(!inventoryAddModal);
  };

  const toggleAddEditModal = () => {
    setAddEditModal(!addEditModal);
  };

  const handleAddEditModalClose = () => {
    setAddEditModal(false);
  };

  const handleEditSubmit = () => {
    setAddEditModal(false);
    fetchTemplatesAndTotals();
  };

  const fetchTemplatesAndTotals = async () => {
    dispatch(getAllInventoryTemplates())
      .then((response) => {
        setTemplates(response.payload);
      })
      .then(() => {
        dispatch(getTotalsOfInventoryItems())
          .then((response) => {
            setTotals(response.payload);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const toggleTemplateViewModal = () => {
    setTemplateViewModal(!templateViewModal);
  };

  const handleTemplateViewModalClose = () => {
    setTemplateViewModal(false);
  };

  useEffect(() => {
    
    fetchTemplatesAndTotals();

    return () => {
      setTemplates([]);
      setTotals([]);
      setSelectedTemplate({});
      setSelectedTemplateId(null);
    };
  }, []);

  const handleView = (id) => {
    setSelectedTemplateId(id);
    setTemplateViewModal(true);
  };

  const handleViewRow = (inventoryTemplateId) => {
    navigate(`/inventory-items-template/${inventoryTemplateId}`);
  }

  const handleEdit = (id) => {
    setSelectedTemplateId(id);
    setSelectedTemplate(templates.find(template => template.inventory_template_id === id));
    setAddEditModal(true);
  };

  const handleAddNewToInventory = (id) => {
    setSelectedTemplate(templates.find(template => template.inventory_template_id === id));
    setInventoryAddModal(true);
  };

  const iconClassToComponentName = (iconClass) => {
    const parts = iconClass.split(' ');
    if (parts.length < 1) return null;
    const iconName = parts[parts.length - 1].slice(3); // remove 'fa-' prefix
    const finalIconName = iconName
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
    return `Fa${finalIconName}`;

  };
  
  const formattedTemplates = templates.map((template) => {
    const total = totals?.defined?.[template.inventory_template_id] || 0;
    const used = totals?.used?.[template.inventory_template_id] || 0;
    const iconComponentName = iconClassToComponentName(template.icon);
    const IconComponent = Icons[iconComponentName];
    const icon = IconComponent ? <IconComponent /> : null;
    return {
      id: template.inventory_template_id,
      Icon: icon,
      'Template Name': template.item,
      Total: total,
      Used: used,
      'Created At': new Date(template.created).toLocaleDateString(),
      'Updated At': new Date(template.last_modified).toLocaleDateString(),
      'Retail Cost': template.retail_cost,
      'Serial 1 Label': template.serial1_label,
      'Serial 2 Label': template.serial2_label,
      'Wholesale Cost': template.wholesale_cost,
      actions: (
        <ul className="list-inline hstack gap-2 mb-0">
          <li className="list-inline-item">
            <Link
              className="text-primary d-inline-block"
              title='View'
              to={`/inventory-items-template/${template.inventory_template_id}`}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedTemplate(template);
              }}
            >
              <i className="ri-eye-fill fs-16"></i>
            </Link>
          </li>
          <li className="list-inline-item edit">
            <Link
              title='Edit'
              className="text-primary d-inline-block edit-item-btn"
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(template.inventory_template_id);
              }}
            >
              <i className="ri-pencil-fill fs-16"></i>
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              title='Add to Inventory'
              className="text-success d-inline-block remove-item-btn"
              onClick={(e) => {
                e.stopPropagation();
                handleAddNewToInventory(template.inventory_template_id);
              }}
            >
              <i className="ri-add-box-fill fs-16"></i>
            </Link>
          </li>
        </ul>
      ),
    };
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {match ? (
            <div>
              <BreadCrumb title={selectedTemplate.item + " Inventory Items"} pageTitle="Inventory Templates" path="/inventory-items-template"/>
              <Row className="mb-3">
                <Col>
                  <Link to="/inventory-items-template" className="">
                    <i className="ri-arrow-left-line me-1"></i>
                    Back to Inventory Templates
                  </Link>
                </Col>
              </Row>
              <TemplateDetailList />
            </div>
          ) : (
            <>
              <BreadCrumb title="Inventory Templates" pageTitle="Inventory Templates" path="/inventory-items-template"/>
              <TableCommon
                title={'Inventory Templates'}
                data={formattedTemplates}
                onRowClick={handleViewRow}
              />
            </>
          )}
        </Container>
      </div>
      <Modal isOpen={templateViewModal} toggle={toggleTemplateViewModal} size='xl' centered>
        <ModalHeader toggle={toggleTemplateViewModal}>Inventory Items</ModalHeader>
        <ModalBody>
          <TemplateDetailList inventoryTemplateId={selectedTemplateId} />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary" onClick={handleTemplateViewModalClose}>Close</button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={addEditModal} toggle={toggleAddEditModal} size='lg' centered>
        <ModalHeader toggle={toggleAddEditModal}>{selectedTemplate?.inventory_template_id ? 'Edit' : 'Add'} Inventory Template</ModalHeader>
        <ModalBody>
          <InventoryTemplateForm initialData={selectedTemplate} onClose={handleAddEditModalClose} onSubmit={handleEditSubmit}/>
        </ModalBody>
      </Modal>
      <Modal isOpen={inventoryAddModal} toggle={toggleInventoryAddModal} size='lg' centered>
        <ModalHeader toggle={toggleInventoryAddModal}>Add Inventory Item</ModalHeader>
        <ModalBody>
          <InventoryAdd inventoryTemplate={selectedTemplate} onClose={handleInventoryAddModalClose}/>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default TemplateList;