import React, { useEffect, useState } from "react";
import { getInvoiceStats } from "../../../slices/thunks";
import { useDispatch } from "react-redux";
import { Col, Card, CardBody, Row } from "reactstrap";
import CountUp from "react-countup";
import FeatherIcon from "feather-icons-react";

import  "../css/custom.css";


// get Currency Symbol and Code from the environment
const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL || "$";
const currencyCode = process.env.REACT_APP_CURRENCY_CODE || "USD";

export default function InvoiceWidgetsData() {
    const dispatch = useDispatch();

    const [invoiceStats, setInvoiceStats] = useState({});
    const [invoiceWidgets, setInvoiceWidgets] = useState([]);

    const getPercentage = (current, last) => {
        if (last === 0) {
            return 100;
        } else {
            return ((current - last) / last) * 100;
        }
    };

    const getIcon = (current, last) => {
        if (current > last) {
            return "ri-arrow-right-up-line";
        } else if (current < last) {
            return "ri-arrow-right-down-line";
        } else {
            return "ri-arrow-right-line";
        }
    };

    const getPercentageClass = (percentage) => {
        if (percentage > 0) {
            return "success";
        } else if (percentage < 0) {
            return "danger";
        } else {
            return "primary";
        }
    };

    const shortenValue = (value) => {
        if (value >= 1000) {
            return (value / 1000).toFixed(2);
        } else if (value >= 1000000) {
            return (value / 1000000).toFixed(2);
        } else if (value >= 1000000000) {
            return (value / 1000000000).toFixed(2);
        } else {
            return value;
        }
    };

    const getSuffix = (value) => {
        if (value >= 1000) {
            return "k";
        } else if (value >= 1000000) {
            return "M";
        } else if (value >= 1000000000) {
            return "B";
        } else {
            return "";
        }
    };

    const shortenValueWithSuffix = (value) => {
        return shortenValue(value) + getSuffix(value);
    };

    // Total cost of all invoices
    // Total cost of unpaid invoices
    // Total cost of invoices this month
    // Total cost of invoices last month
    const generateInvoiceWidgets = (invoiceStats) => {
        return [
            {
                id: 1,
                label: "Total Invoices",
                percentage: null,
                percentageClass: "success",
                icon: "",
                counter: shortenValue(invoiceStats.total_invoices_cost || 0),
                badge: shortenValueWithSuffix(invoiceStats.total_invoices || 0),
                caption: "Invoices Sent",
                feaIcon: "file-text",
                decimals: 1,
                prefix: currencySymbol,
                suffix: getSuffix(invoiceStats.total_invoices_cost)
            },
            {
                id: 2,
                label: "Unpaid Invoices",
                percentage: null,
                percentageClass: "danger",
                icon: "",
                counter: shortenValue(invoiceStats.total_unpaid_invoices_cost || 0),
                badge: shortenValueWithSuffix(invoiceStats.total_unpaid_invoices || 0),
                caption: "Unpaid by clients",
                feaIcon: "check-square",
                decimals: 2,
                prefix: currencySymbol,
                suffix: getSuffix(invoiceStats.total_unpaid_invoices_cost)
            },
            {
                id: 3,
                label: "Invoices This Month",
                percentage: getPercentage(
                    invoiceStats.total_cost_of_invoices_this_month,
                    invoiceStats.total_cost_of_invoices_last_month
                ),
                percentageClass: getPercentageClass(
                    getPercentage(
                        invoiceStats.total_cost_of_invoices_this_month,
                        invoiceStats.total_cost_of_invoices_last_month
                    )
                ),
                icon: getIcon(
                    invoiceStats.total_cost_of_invoices_this_month,
                    invoiceStats.total_cost_of_invoices_last_month
                ),
                counter: shortenValue(invoiceStats.total_cost_of_invoices_this_month || 0),
                badge: shortenValueWithSuffix(invoiceStats.total_number_of_invoices_this_month || 0),
                caption: "Invoices sent",
                feaIcon: "clock",
                decimals: 2,
                prefix: currencySymbol,
                suffix: getSuffix(invoiceStats.total_cost_of_invoices_this_month)
            },
            {
                id: 4,
                label: "Invoices Last Month",
                percentage: getPercentage(
                    invoiceStats.total_cost_of_invoices_last_month,
                    invoiceStats.total_cost_of_invoices_this_month
                ),
                percentageClass: getPercentageClass(
                    getPercentage(
                        invoiceStats.total_cost_of_invoices_last_month,
                        invoiceStats.total_cost_of_invoices_this_month
                    )
                ),
                icon: getIcon(
                    invoiceStats.total_cost_of_invoices_last_month,
                    invoiceStats.total_cost_of_invoices_this_month
                ),
                counter: shortenValue(invoiceStats.total_cost_of_invoices_last_month || 0),
                badge: shortenValueWithSuffix(invoiceStats.total_number_of_invoices_last_month || 0),
                caption: "Invoices sent",
                feaIcon: "x-octagon",
                decimals: 1,
                prefix: currencySymbol,
                suffix: getSuffix(invoiceStats.total_cost_of_invoices_last_month)
            }
        ];
    };

    useEffect(() => {
        dispatch(getInvoiceStats())
            .then((response) => {
                setInvoiceStats(response.payload);
                setInvoiceWidgets(generateInvoiceWidgets(response.payload));
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dispatch]);

    return (
        <Row>
            {invoiceWidgets.map((invoicewidget, key) => (
                <React.Fragment key={key}>
                <Col xl={3} md={6}>
                    <Card className="card-animate">
                    <CardBody>
                        <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <p className="text-uppercase fw-medium text-muted mb-0">
                            {invoicewidget.label}
                            </p>
                        </div>
                        <div className="flex-shrink-0">
                            <h5
                            className={
                                "fs-14 mb-0 text-" + invoicewidget.percentageClass
                            }
                            >
                            <i className={invoicewidget.icon + " fs-13 align-middle"}></i>{" "}
                            {invoicewidget.percentage}
                            </h5>
                        </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                        <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                            <CountUp
                                start={0}
                                prefix={invoicewidget.prefix}
                                suffix={invoicewidget.suffix}
                                decimals="2"
                                end={invoicewidget.counter}
                                duration={4}
                                className="counter-value"
                            />
                            </h4>
                            <span className="badge bg-warning me-1">
                            {invoicewidget.badge}
                            </span>{" "}
                            <span className="text-muted">
                            {" "}
                            {invoicewidget.caption}
                            </span>
                        </div>
                        <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-light rounded fs-3">
                            <FeatherIcon
                                icon={invoicewidget.feaIcon}
                                className="text-success icon-dual-success"
                            />
                            </span>
                        </div>
                        </div>
                    </CardBody>
                    </Card>
                </Col>
                </React.Fragment>
            ))}
        </Row>
    );
}