import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import SignUpForm from './components/SignUpForm';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { ToastContainer } from 'react-toastify';

// Import default images
import defaultLogoDark from "../../assets/images/omnitouch/DefaultLogoDark.png";

// Function to conditionally import user-uploaded images
const tryImport = (filename) => {
    try {
        return require(`../../assets/images/omnitouch/${filename}`);
    } catch (err) {
        return null;
    }
};
  
// Attempt to import user-uploaded images
const userLogoLight = tryImport("logoLight.png");

// get stripe key from env
const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? 'pk_test_51NYLueJyScbvk8qvDtdK50Lj4Lpju0gfCS0zzSSXSaaTRgISO8AT4b9VuZPmhvtorper4N1dvPUgsIqHlJyzznos009ugmBuTI';

const stripePromise = loadStripe(stripeKey);

const SignUp = (props) => {

    const getLogo = (userLogo, defaultLogo) => userLogo || defaultLogo;

    return (
        <React.Fragment>
            <ToastContainer />
            <ParticlesAuth>
                <div className="auth-page-content mt-lg-5 mt-13">
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={getLogo(userLogoLight, defaultLogoDark)} alt="" height="170" />
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    <Elements stripe={stripePromise}>
                        <SignUpForm {...props} />
                    </Elements>
                    <p className="text-center mt-2">Already have an account ? <Link to="/login" className="font-weight-medium"> Sign In</Link> </p>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default SignUp;
