import React from 'react';
import { Col, Spinner, Row, Container } from 'reactstrap';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Redirect = (props) => {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="d-flex justify-content-center mx-2 mt-2">
                        <Row>
                            <Col sm={12}>
                                <Spinner color="primary"> Loading... </Spinner>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                {props.component ? <props.component /> : null}
                            </Col>
                        </Row>
                    </div>
                    {toast.error(props.error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Redirect;
