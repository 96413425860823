import React, { useEffect, useState } from 'react';
import { ListGroup, ListGroupItem, Row, Col, Card, CardBody, Collapse, Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

// import actions
import { getProvisioningStatus } from '../../slices/thunks';

const provisioningEventStatusText = {
    0: 'Running',
    1: 'Run OK',
    2: 'Failed'
};

const provisioningStatusText = {
    0: 'Success',
    1: 'Running',
    2: 'Failed'
};

const provisioningStatusClassName = {
    0: 'list-group-item-success',
    1: 'list-group-item-default',
    2: 'list-group-item-danger'
};

const ProvisioningStatus = ({ provision_id, newProvisioning, refresh, simple = false }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [provisioningData, setProvisioningData] = useState(null);
    const [basicInfo, setBasicInfo] = useState(null);
    const [collapseOpen, setCollapseOpen] = useState(null);
    const [provisioningError, setProvisioningError] = useState(null);
    const [redirectModal, setRedirectModal] = useState(false);
    const [redirectTimer, setRedirectTimer] = useState(null);

    const goToService = (customerId) => {
        if (simple) {
            navigate(`/self-care/services`, { state: { key: Date.now() } });
        } else {
            navigate(`/customers/${customerId}#3`, { state: { key: Date.now() } });
        }

        if (refresh) {
            refresh();
        }
    };

    const toggleRedirectModal = () => {
        setRedirectModal(!redirectModal);
    };

    const cancelRedirect = () => {
        clearInterval(redirectTimer);
        toggleRedirectModal();
    };

    useEffect(() => {
        let retryCount = 0;
        const maxRetries = 5;
        const intervalId = setInterval(() => {
            dispatch(getProvisioningStatus(provision_id))
                .then(response => {
                    if (response.payload) {
                        const results = response?.payload?.provisioning_result_json;
                        const sortedEvents = [].concat(results).sort((a, b) => a.event_number - b.event_number);
                        setProvisioningData(sortedEvents);
                        setBasicInfo({
                            service_id: response.payload.service_id,
                            provision_id: response.payload.provision_id,
                            customer_id: response.payload.customer_id,
                            playbook_description: response.payload.playbook_description,
                            provisioning_json_vars: response.payload.provisioning_json_vars,
                            last_modified: response.payload.last_modified,
                            site_id: response.payload.site_id,
                            product_id: response.payload.product_id,
                            task_count: response.payload.task_count,
                            provisioning_play: response.payload.provisioning_play,
                            provisioning_status: response.payload?.provisioning_status,
                            created: response.payload.created,
                        });

                        if (response.payload?.provisioning_status === 0) {
                            clearInterval(intervalId);
                            if (newProvisioning) {
                                setRedirectModal(true);
                                const timer = setTimeout(() => goToService(response.payload.customer_id), 5000); 
                                setRedirectTimer(timer);
                            }
                        }

                        // poll for another 5 times when provisioning has fails to ensure all status is returned
                        if (response.payload?.provisioning_status === 2) {
                            retryCount++;
                            if (retryCount >= maxRetries) {
                                clearInterval(intervalId);

                                toast.error('Provisioning failed. Please contact the administrator for more information.', {autoClose: 3000});
                            } else {
                                console.log('Retrying provisioning status data...');
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log('Error fetching provisioning status data:', error);
                    retryCount++;
                    if (retryCount >= maxRetries) {
                        clearInterval(intervalId);
                        setProvisioningData(null);
                        setBasicInfo(null);
                        setProvisioningError(error);

                        toast.error('Provisioning failed. Please contact the administrator for more information.', {autoClose: 3000});
                    } else if (basicInfo?.provisioning_status === 2) {
                        console.log('Retrying provisioning status data...');
                    }
                });
        }, 2000); 
    
        return () => {
            clearInterval(intervalId);
            clearTimeout(redirectTimer);
        };
    }, [provision_id]);

    if (simple) {
        return (
            <React.Fragment>
                {/* Show loading/provisioning animation only */}
                <div className="text-center my-3">
                    <Spinner style={{ width: '3rem', height: '3rem' }} />
                    <p>Provisioning in progress...</p>
                </div>
                <Modal isOpen={redirectModal} toggle={toggleRedirectModal} centered>
                    <ModalHeader toggle={toggleRedirectModal}>Redirecting</ModalHeader>
                    <ModalBody>
                        Provisioning was successful. You will be redirected to the service page in a few seconds.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={cancelRedirect}>Cancel Redirect</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {basicInfo && (
                <Row>
                    <Col md={6}>
                        <Card>
                            <CardBody>
                                <p><span className="text-muted">Service ID:</span> {basicInfo.service_id}</p>
                                <p><span className="text-muted">Provision ID:</span> {basicInfo.provision_id}</p>
                                <p><span className="text-muted">Customer ID:</span> {basicInfo.customer_id}</p>
                                <p><span className="text-muted">Playbook Description:</span> {basicInfo.playbook_description}</p>
                                <pre><span className="text-muted">Provisioning JSON Vars:</span> 
                                    { 
                                        (() => {
                                            try {
                                                return JSON.stringify(JSON.parse(basicInfo.provisioning_json_vars), null, 2);
                                            } catch (error) {
                                                return JSON.stringify(basicInfo.provisioning_json_vars, null, 2);
                                            }
                                        })()
                                    } 
                                </pre>
                                <p><span className="text-muted">Last Modified:</span> {basicInfo.last_modified}</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <CardBody>
                                <p><span className="text-muted">Site ID:</span> {basicInfo.site_id}</p>
                                <p><span className="text-muted">Product ID:</span> {basicInfo.product_id}</p>
                                <p><span className="text-muted">Task Count:</span> {basicInfo.task_count}</p>
                                <p><span className="text-muted">Provisioning Play:</span> {basicInfo.provisioning_play}</p>
                                <p><span className="text-muted">Provisioning Status:</span> {provisioningStatusText[basicInfo?.provisioning_status]}</p>
                                <p><span className="text-muted">Created:</span> {basicInfo.created}</p>
                                <button onClick={() => goToService(basicInfo.customer_id)} className="btn btn-primary">Go to Service</button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
            {provisioningData ? (
                <ListGroup>
                    {provisioningData.map((event, index) => (
                        event && (
                            <ListGroupItem key={event?.provision_event_id} className={`${provisioningStatusClassName[event?.provisioning_status]} ${index % 2 !== 0 ? 'bg-light' : ''}`}>
                                <Row>
                                    <Col className={index % 2 !== 0 ? 'text-end' : ''}>
                                        <h5>{event?.event_name}</h5>
                                        <p>Status: {provisioningEventStatusText[event?.provisioning_status]}</p>
                                    </Col>
                                </Row>
                                <div className="text-center" onClick={() => setCollapseOpen(prev => prev === event?.provision_event_id ? null : event?.provision_event_id)} style={{ marginBottom: '1rem', cursor: 'pointer' }}>
                                    Toggle Details
                                    {collapseOpen === event?.provision_event_id ?
                                        <FaChevronUp />
                                        : <FaChevronDown />
                                    }
                                </div>
                                <Collapse isOpen={collapseOpen === event?.provision_event_id}>
                                    <pre>{JSON.stringify(event?.provisioning_result_json, null, 2)}</pre>
                                </Collapse>
                            </ListGroupItem>
                        )
                    ))}
                </ListGroup>
            ) : (
                <p>Loading...</p>
            )}
            <Modal isOpen={redirectModal} toggle={toggleRedirectModal} centered>
                <ModalHeader toggle={toggleRedirectModal}>Redirecting</ModalHeader>
                <ModalBody>
                    Provisioning was successful. You will be redirected to the service page in a few seconds.
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={cancelRedirect}>Cancel Redirect</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default ProvisioningStatus;
