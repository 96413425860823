import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  searchDatabase as searchDatabaseApi,
  getDatabaseStats as getDatabaseStatsApi,
  getUsage as getUsageApi,
  findCustomerByImsi as findCustomerByImsiApi,
  findServiceByImsi as findServiceByImsiApi,
  listImsiByCustomerId as listImsiByCustomerIdApi 
} from "../../helpers/backend_helper";

export const searchDatabase = createAsyncThunk("oam/searchDatabase", async (searchQuery) => {
  try {
    const response = await searchDatabaseApi(searchQuery);
    return response;
  } catch (error) {
    return error;
  }
});

export const getDatabaseStats = createAsyncThunk("oam/getDatabaseStats", async () => {
  try {
    const response = await getDatabaseStatsApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const getUsage = createAsyncThunk("oam/getusage", async () => {
  try {
    const response = await getUsageApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const findCustomerByImsi = createAsyncThunk("oam/findCustomerByImsi", async () => {
  try {
    const response = await findCustomerByImsiApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const findServiceByImsi = createAsyncThunk("oam/findServiceByImsi", async () => {
  try {
    const response = await findServiceByImsiApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const listImsiByCustomerId = createAsyncThunk("oam/listImsiByCustomerId", async () => {
  try {
    const response = await listImsiByCustomerIdApi();
    return response;
  } catch (error) {
    return error;
  }
});