import { createSlice } from "@reduxjs/toolkit";
import { 
  getCustomers,
  addNewCustomer,
  updateCustomer,
  deleteCustomer,
  getCustomerById,
  resetCustomer
} from './thunk';
export const initialState = {
  customers: [],
  customer: {},
  error: {},
};

const CustomerSlice = createSlice({
  name: 'CustomerSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomers.fulfilled, (state, action) => {
      state.customers = action.payload;
      state.isCustomerCreated = false;
      state.isCustomerSuccess = true;
    });
    builder.addCase(getCustomers.rejected, (state, action) => {
      state.error = action.payload || null;
      state.isCustomerCreated = false;
      state.isCustomerSuccess = false;
    });
    builder.addCase(addNewCustomer.fulfilled, (state, action) => {
      state.customer = action.payload;
      state.isCustomerCreated = true;
    });
    builder.addCase(addNewCustomer.rejected, (state, action) => {
      state.error = action.payload || null;
    });
    builder.addCase(getCustomerById.fulfilled, (state, action) => {
      state.customer = action.payload;
    });
    builder.addCase(getCustomerById.rejected, (state, action) => {
      state.error = action.payload || null;
    });
    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      state.customer = action.payload;
    });
    builder.addCase(updateCustomer.rejected, (state, action) => {
      state.error = action.payload || null;
    });
    builder.addCase(deleteCustomer.fulfilled, (state, action) => {
      state.deletedCustomer = action.payload;
    });
    builder.addCase(deleteCustomer.rejected, (state, action) => {
      state.error = action.payload || null;
    });
    builder.addCase(resetCustomer.fulfilled, (state, action) => {
      state.customer = {};
      state.error = {};
    });
    builder.addCase(resetCustomer.rejected, (state, action) => {
      state.error = action.payload || null;
    });
  }
});

export default CustomerSlice.reducer;