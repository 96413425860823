import { createSlice } from "@reduxjs/toolkit";
import { 
  getInvoices,
  addNewInvoice,
  updateInvoice,
  deleteInvoice,
  getInvoicesByCustomerId,
  getInvoiceById,
  getInvoicePdfById,
  resetInvoiceStates,
  getInvoiceStats,
} from './thunk';
export const initialState = {
  invoices: [],
  error: {},
  isInvoiceCreated: false,
  isInvoiceSuccess: false,
  invoice: null,
  invoicePdfSuccess: false,
  invoiceStats: {},
  invoiceStatsError: {},
};


const InvoiceSlice = createSlice({
  name: 'InvoiceSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getInvoices.fulfilled, (state, action) => {
      state.invoices = action.payload;
      state.isInvoiceCreated = false;
      state.isInvoiceSuccess = true;
    });
    builder.addCase(getInvoices.rejected, (state, action) => {
      state.error = action.payload.message || null;
      state.isInvoiceCreated = false;
      state.isInvoiceSuccess = false;
    });
    builder.addCase(addNewInvoice.fulfilled, (state, action) => {
      state.isInvoiceCreated = true;
    });
    builder.addCase(addNewInvoice.rejected, (state, action) => {
      state.error = action.payload.message || null;
    });
    builder.addCase(updateInvoice.fulfilled, (state, action) => {
      state.updateInvoiceSuccess = true; 
    });
    builder.addCase(updateInvoice.rejected, (state, action) => {
      state.error = action.payload.message || null;
    });
    builder.addCase(deleteInvoice.fulfilled, (state, action) => {
      state.deletedInvoice = action.payload;
      state.deleteInvoiceSuccess = true;
    });
    builder.addCase(deleteInvoice.rejected, (state, action) => {
      state.error = action.payload.message || null;
    });
    builder.addCase(getInvoicesByCustomerId.fulfilled, (state, action) => {
      state.invoices = action.payload;
      state.isInvoiceSuccess = true;
    });
    builder.addCase(getInvoicesByCustomerId.rejected, (state, action) => {
      state.error = action.payload.message || null;
    });
    builder.addCase(getInvoiceById.fulfilled, (state, action) => {
      state.invoice = action.payload;
    });
    builder.addCase(getInvoiceById.rejected, (state, action) => {
      state.error = action.payload.message || null;
    });
    builder.addCase(getInvoicePdfById.fulfilled, (state, action) => {
      state.invoicePdfSuccess = true;
    });
    builder.addCase(getInvoicePdfById.rejected, (state, action) => {
      state.error = action.payload.message || null;
    });
    builder.addCase(resetInvoiceStates.fulfilled, (state, action) => {
      state.invoices = [];
      state.error = {};
      state.isInvoiceCreated = false;
      state.isInvoiceSuccess = false;
      state.invoice = null;
      state.invoicePdfSuccess = false;
    });
    builder.addCase(resetInvoiceStates.rejected, (state, action) => {
      state.error = action.payload.message || null;
    });

    builder.addCase(getInvoiceStats.fulfilled, (state, action) => {
      state.invoiceStats = action.payload;
    });
    builder.addCase(getInvoiceStats.rejected, (state, action) => {
      state.invoiceStatsError = action.payload.message || null;
    });
  }
});

export default InvoiceSlice.reducer;