import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import InputMask from 'react-input-mask';

const PhoneInput = ({ label, ...props }) => {
  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <InputMask mask="9999 9999 9999 9999" maskChar={null} {...props}>
        {(inputProps) => <Input {...inputProps} />}
      </InputMask>
    </FormGroup>
  );
};

export default PhoneInput;