import { createAsyncThunk } from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {
    getAttributes as getAttributesApi,
    addNewAttribute as addNewAttributeApi,
    deleteAttribute as deleteAttributeApi,
    updateAttribute as updateAttributeApi,
    getAttributeById as getAttributeByIdApi,
    getAttributesByCustomerId as getAttributesByCustomerIdApi,
} from "../../helpers/backend_helper";


export const getAttributes = createAsyncThunk("attribute/getAttributes", async () => {
    try {
        const response = await getAttributesApi();
        return response;
    } catch (error) {
        return error;
    }
});

export const addNewAttribute = createAsyncThunk("attribute/addNewAttribute", async (data) => {
    try {
        const response = await addNewAttributeApi(data);
        return response;
    } catch (error) {
        return error;
    }
});

export const deleteAttribute = createAsyncThunk("attribute/deleteAttribute", async (id) => {
    try {
        const response = await deleteAttributeApi(id);
        return response;
    } catch (error) {
        return error;
    }
});

export const updateAttribute = createAsyncThunk("attribute/updateAttribute", async (data) => {
    try {
        const response = await updateAttributeApi(data);
        return response;
    } catch (error) {
        return error;
    }
});

export const getAttributeById = createAsyncThunk("attribute/getAttributeById", async (id) => {
    try {
        const response = await getAttributeByIdApi(id);
        return response;
    } catch (error) {
        return error;
    }
});

export const getAttributesByCustomerId = createAsyncThunk("attribute/getAttributesByCustomerId", async (id) => {
    try {
        const response = await getAttributesByCustomerIdApi(id);
        return response;
    } catch (error) {
        return error;
    }
});