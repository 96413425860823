import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { useDispatch, useSelector } from 'react-redux';
import {
    addUser,
    updateUser,
    getRoles,
    getContacts,
} from '../../../slices/thunks';
import { useNavigate } from 'react-router-dom';

const UserForm = ({ initialUser = {}, roles = [], onNextStep, onCancel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isEdit = Boolean(initialUser.id);
  const [selectContactValidation, setSelectContactValidation] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Please enter your email'),
    username: Yup.string().required('Please enter your username'),
    first_name: Yup.string().required('Please enter your first name'),
    middle_name: Yup.string(),
    last_name: Yup.string().required('Please enter your last name'),
    phone_number: Yup.string(),
    user_image: Yup.mixed(),
    ...(isEdit ? {} : {
      password: Yup.string().required('Please enter your password'),
      confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Please confirm your password'),
    }),
    role: Yup.string().required('Please Select a Role'),
  });

  const loadOptions = (inputValue, callback) => {
        dispatch(getContacts({searchTerm: inputValue}))
            .then((response) => {
                const options = response.payload.map(contact => ({ data: contact, value: contact.contact_id, label: contact.contact_firstname + ' ' + contact.contact_lastname}));
                callback(options);
            });
    };

  useEffect(() => {
    if (initialUser.contact_id) {
        dispatch(getContacts({ searchTerm: initialUser.contact_id }))
          .then((response) => {
            const contact = response.payload.find(c => c.contact_id === initialUser.contact_id);
            if (contact) {
              const selectedOption = {
                data: contact,
                value: contact.contact_id,
                label: contact.contact_firstname + ' ' + contact.contact_lastname
              };
              setSelectedContact(selectedOption);
              setSelectContactValidation(true);
            }
          });
      }
  }, [initialUser, dispatch]);

  const formik = useFormik({
    initialValues: {
      id: initialUser.id,
      email: initialUser.email || '',
      username: initialUser.username || '',
      first_name: initialUser.first_name || '',
      middle_name: initialUser.middle_name || '',
      last_name: initialUser.last_name || '',
      phone_number: initialUser.phone_number || '',
      user_image: initialUser.user_image || '',
      role: initialUser.roles?.[0]?.name || 'customer',
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      if (isEdit) {
        dispatch(updateUser(formData)).then(onNextStep);
      } else {
        dispatch(addUser(formData)).then(onNextStep);
      }
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit} encType="multipart/form-data">
      {/* SearchBox for customers */}
      <div className="mb-3">
      <Label className='mb-2'>Select a Contact</Label>
        <AsyncSelect
            loadOptions={loadOptions}
            value={selectedContact}
            onChange={(selectedOption) => {
                setSelectedContact(selectedOption);
                // Set multiple Formik values at once using setValues on new user
                if (!isEdit) {
                    formik.setValues({
                        ...formik.values,
                        contact_id: selectedOption.value,
                        email: selectedOption.data.contact_email ?? formik.values.email,
                        first_name: selectedOption.data.contact_firstname ?? formik.values.first_name,
                        last_name: selectedOption.data.contact_lastname ?? formik.values.last_name,
                        phone_number: selectedOption.data.contact_phone ?? formik.values.phone_number,
                    });
                }
                setSelectContactValidation(selectedOption.value !== null);
            }}
            styles={{
                menu: provided => ({ ...provided, zIndex: 2 })
            }}
        />
        {!selectContactValidation && <div className="alert alert-danger">Please select a contact</div>}
      </div>
      
      <div className="mb-3">
        <Label htmlFor="email" className="form-label">Email <span className="text-danger">*</span></Label>
        <Input
          id="email"
          name="email"
          type="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          invalid={formik.touched.email && !!formik.errors.email}
        />
        <FormFeedback>{formik.errors.email}</FormFeedback>
      </div>

      <div className="mb-3">
        <Label htmlFor="username" className="form-label">Username <span className="text-danger">*</span></Label>
        <Input
          id="username"
          name="username"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.username}
          invalid={formik.touched.username && !!formik.errors.username}
        />
        <FormFeedback>{formik.errors.username}</FormFeedback>
      </div>

      <div className="mb-3">
        <Label htmlFor="first_name" className="form-label">First Name <span className="text-danger">*</span></Label>
        <Input
          id="first_name"
          name="first_name"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.first_name}
          invalid={formik.touched.first_name && !!formik.errors.first_name}
        />
        <FormFeedback>{formik.errors.first_name}</FormFeedback>
      </div>

      <div className="mb-3">
        <Label htmlFor="middle_name" className="form-label">Middle Name</Label>
        <Input
          id="middle_name"
          name="middle_name"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.middle_name}
        />
      </div>

      <div className="mb-3">
        <Label htmlFor="last_name" className="form-label">Last Name <span className="text-danger">*</span></Label>
        <Input
          id="last_name"
          name="last_name"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.last_name}
          invalid={formik.touched.last_name && !!formik.errors.last_name}
        />
        <FormFeedback>{formik.errors.last_name}</FormFeedback>
      </div>

      <div className="mb-3">
        <Label htmlFor="phone_number" className="form-label">Phone Number</Label>
        <Input
          id="phone_number"
          name="phone_number"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phone_number}
        />
      </div>

      <div className="mb-3">
        <Label htmlFor="user_image" className="form-label">Profile Image</Label>
        <Input
          id="user_image"
          name="user_image"
          type="file"
          onChange={(event) => {
            formik.setFieldValue("user_image", event.currentTarget.files[0]);
          }}
        />
      </div>

      {!isEdit && (
        <>
          <div className="mb-3">
            <Label htmlFor="password" className="form-label">Password <span className="text-danger">*</span></Label>
            <Input
              id="password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              invalid={formik.touched.password && !!formik.errors.password}
            />
            <FormFeedback>{formik.errors.password}</FormFeedback>
          </div>

          <div className="mb-3">
            <Label htmlFor="confirm_password" className="form-label">Confirm Password <span className="text-danger">*</span></Label>
            <Input
              id="confirm_password"
              name="confirm_password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirm_password}
              invalid={formik.touched.confirm_password && !!formik.errors.confirm_password}
            />
            <FormFeedback>{formik.errors.confirm_password}</FormFeedback>
          </div>
        </>
      )}

        <div className="mb-3">
                <Label htmlFor="role" className="form-label">Role <span className="text-danger">*</span></Label>
                <Input
                id="role"
                name="role"
                type="select"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.role}
                invalid={formik.touched.role && !!formik.errors.role}
                >
                    {/* <option value="">Select a role</option> */}
                    {/* { roles.map((role) => (
                        <option key={role.id} value={role.name}>{role.name}</option>
                    ))} */}
                
                    <option value="admin">Admin</option>
                    <option value="customer_service_agent_1">Customer Service Agent 1</option>
                    <option value="customer_service_agent_2">Customer Service Agent 2</option>
                    <option value="customer">Customer</option>
                </Input>
                <FormFeedback>{formik.errors.role}</FormFeedback>
      </div>

      <div className="mt-6">
        <button className="btn btn-success w-100" type="submit">{isEdit ? 'Update' : 'Create user'}</button>
      </div>
    </Form>
  );
};

export default UserForm;
