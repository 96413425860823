import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import {
    getTicketsList as getTicketsListApi,
    addNewTicket as addNewTicketApi,
    updateTicket as updateTicketApi,
    deleteTicket as deleteTicketApi
} from "../../helpers/backend_helper";

export const getTicketsList = createAsyncThunk("tickets/getTicketsList", async () => {
    try {
        const response = await getTicketsListApi();
        return response;
    } catch (error) {
        return error;
    }
});

export const addNewTicket = createAsyncThunk("tickets/addNewTicket", async (ticket) => {
    try {
        const response = await addNewTicketApi(ticket);
        return response;
    } catch (error) {
        return error;
    }
});

export const updateTicket = createAsyncThunk("tickets/updateTicket", async (ticket) => {
    try {
        const response = await updateTicketApi(ticket);
        return response;
    } catch (error) {
        return error;
    }
});

export const deleteTicket = createAsyncThunk("tickets/deleteTicket", async (ticket) => {
    try {
        const response = await deleteTicketApi(ticket);
        return response;
    } catch (error) {
        return error;
    }
});