import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createCbcMessage as createCbcMessageApi,
  getAllCbcItems as getAllCbcItemsApi,
  deleteCbcMessage as deleteCbcMessageApi,
} from "../../helpers/backend_helper";

export const createCbcMessage = createAsyncThunk("cbc/createCbcMessage", async (cbcMessage) => {
  try {
    const response = await createCbcMessageApi(cbcMessage);
    return response;
  } catch (error) {
    return error;
  }
});

export const getAllCbcItems = createAsyncThunk("cbc/getAllCbcItems", async () => {
  try {
    const response = await getAllCbcItemsApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const deleteCbcMessage = createAsyncThunk("cbc/deleteCbcMessage", async (cbcMessageId) => {
  try {
    const response = await deleteCbcMessageApi(cbcMessageId);
    return response;
  } catch (error) {
    return error;
  }
});