import { createAsyncThunk } from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {
    createActivity as createActivityApi,
    deleteActivity as deleteActivityApi,
    getActivityById as getActivityByIdApi,
    updateActivity as updateActivityApi,
    getActivitiesByCustomerId as getActivitiesByCustomerIdApi,
} from "../../helpers/backend_helper";

export const addNewActivity = createAsyncThunk(
    "activity/addNewActivity",
    async (activityData) => {
        try {
            const response = await createActivityApi(activityData);
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const deleteActivity = createAsyncThunk(
    "activity/deleteActivity",
    async (activityId) => {
        try {
            const response = await deleteActivityApi(activityId);
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const updateActivity = createAsyncThunk(
    "activity/updateActivity",
    async (activityData) => {
        try {
            const response = await updateActivityApi(activityData);
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const getActivityById = createAsyncThunk(
    "activity/getActivityById",
    async (activityId) => {
        try {
            const response = await getActivityByIdApi(activityId);
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const getActivitiesByCustomerId = createAsyncThunk(
    "activity/getActivitiesByCustomerId",
    async ({customerId, page, perPage, sort, sortOrder, search, filters}) => {
        try {
            const response = await getActivitiesByCustomerIdApi(customerId, page, perPage, sort, sortOrder, search, filters);
            return response;
        } catch (error) {
            return error;
        }
    }
);