import React from "react";
import { Card, CardBody, CardHeader, Row, Col, Label, Input } from "reactstrap";
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckout from "./StripeCheckout";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Payment = ({ billingInfo = {}, resetOnPayment = null, onPaymentSuccess = null, setHandleStripePayment }) => {
    return (
        <React.Fragment>
            <Card className="rounded-5 pb-2 equalize-height-col-item">
                <CardHeader className="pb-0 pt-3 rounded-5">
                    <h5>Payment</h5>
                    <p className="text-muted">Fill all information below</p>
                </CardHeader>
                <CardBody className="pt-0 pb-4">
                    <Row className="gy-3">
                        <Col md={12}>
                            <Label htmlFor="cc-name" className="form-label">Name on card</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="cc-name"
                                placeholder=""
                                required
                            />
                            <small className="text-muted">Full name as displayed on card</small>
                            <div className="invalid-feedback">Name on card is required</div>
                        </Col>
                        <Col md={12}>
                            <Elements stripe={stripePromise}>
                                <StripeCheckout 
                                    billingInfo={billingInfo}
                                    onPaymentSuccess={onPaymentSuccess}
                                    setHandleStripePayment={setHandleStripePayment}
                                />
                            </Elements>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default Payment;
