import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, ButtonGroup, Input, Label } from 'reactstrap';

//redux
import { useSelector, useDispatch } from "react-redux";
import TableCommon from '../../../Components/Common/TableCommon';
import {
    getTransactionsByCustomerId,
    addNewTransaction as onAddNewTransaction,
    resetTransactionStates as onResetTransactionStates,
    deleteTransaction as onDeleteTransaction,
} from "../../../slices/thunks";

import {
    getInvoicePdfById as getInvoicePdfByIdApi,
} from "../../../helpers/backend_helper";

import { createSelector } from 'reselect';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import DeleteModal from '../../../Components/Common/DeleteModal';
import ErrorModal from '../../../Components/Common/ErrorModal';

import { TRANSACTION } from '../../../Components/constants/crm';
import './css/custom.css'

const AllTransactions = (props) => {
    const dispatch = useDispatch();

    const selectLayoutState = (state) => state.Transaction;
    const selectTransactionProperties = createSelector(
        selectLayoutState,
        (state) => ({
            transactionsList: state.transactions,
        })
    );

    const [hasFetchedTransactions, setHasFetchedTransactions] = useState(false);
    const { transactionsList } = useSelector(selectTransactionProperties);
    const [transactions, setTransactions] = useState(transactionsList);
    const [searchTerm, setSearchTerm] = useState("");
    const [addModal, setAddModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [newTransaction, setNewTransaction] = useState({
        customer_id: props.customer.customer_id,
        title: '',
        description: '',
        retail_cost: '',
        wholesale_cost: '',
        service_id: null,
        site_id: null
    });
    const [newTransactionAdditionalState, setNewTransactionAdditionalState] = useState({
        transactionType: TRANSACTION.TYPES.DEBIT,
        creditType: TRANSACTION.CREDIT_TYPES.CASH_PAYMENT
    });
    const [pagination, setPagination] = useState({
        currentPage: 1,
        itemsPerPage: 10,
        totalRecords: 0,
        totalPages: 0,
    });
    const [formattedTransactions, setFormattedTransactions] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'transaction_id', direction: 'desc' });

    const [filterOptions, setFilterOptions] = useState({
        void: {
            options: [
                { value: true, label: 'Void' },
                { value: false, label: 'Not Void' }
            ]
        },
        invoice_id: {
            options: [
                { value: 'true', label: 'Invoiced' },
                { value: 'false', label: 'Not Invoiced' }
            ]
        },
    });

    const [filters, setFilters] = useState({});

    const handleFilterChange = (field, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [field]: [value],
        }));
    };

    const applyFilters = () => {
        fetchRecords();
    };

    const resetFilters = () => {
        setFilters({});
    };

    // for use in reset filters to refetch inventory
    useEffect(() => {
        if (Object.keys(filters).length === 0) {
            fetchRecords();
        }
    }, [filters]);

    const toggleAddModal = () => {
        setAddModal(!addModal);
        clearNewTransactionFormStates();
    };

    useEffect(() => {
        if (!hasFetchedTransactions && props.customer.customer_id) {
            fetchRecords(1, 10, 'transaction_id', 'desc');
            setHasFetchedTransactions(true);
        }
    }, [dispatch, hasFetchedTransactions, transactionsList, props.customer.customer_id]);

    useEffect(() => {
        setTransactions(transactionsList?.data ?? []);
        setPagination({
            currentPage: transactionsList?.pagination?.page ?? 1,
            itemsPerPage: transactionsList?.pagination?.per_page ?? 10,
            totalRecords: transactionsList?.pagination?.total_records ?? 0,
            totalPages: transactionsList?.pagination?.total_pages ?? 0,
        });
    }, [transactionsList]);

    const onSearchChange = (e) => {
        setSearchTerm(e.target.value);

        if (e.target.value !== "") {
            const results = transactionsList.filter((item) =>
                item.description.toLowerCase().includes(e.target.value.toLowerCase())
            );
            setTransactions(results);
        } else {
            setTransactions(transactionsList);
        }
    };

    const onNewTransactionFormChange = (e) => {
        // save to state the new value based on the input's name
        setNewTransaction({
            ...newTransaction,
            [e.target.name]: e.target.value
        });
    };

    const onNewTransactionAdditionalStateChange = (key, value) => {
        setNewTransactionAdditionalState(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const fetchRecords = (page = pagination.currentPage, perPage = pagination.itemsPerPage, sort = sortConfig.key, sortOrder = sortConfig.direction, search = searchTerm) => {
        setSearchTerm(search);

        // map the sort key to the correct column name
        if (sort === 'invoiced?') {
            sort = 'invoice_id';
        } else if (sort === 'purchase_date') {
            sort = 'created';
        } else if (sort === 'price') {
            sort = 'retail_cost';
        }

        dispatch(getTransactionsByCustomerId({customerId: props.customer.customer_id, page, perPage, sort, sortOrder, searchTerm: search, filters}))
            .then(() => {
                setSortConfig({ key: sort, direction: sortOrder });
            });
    };

    const onNewTransactionFormSubmit = (e) => {
        e.preventDefault();
        let transaction = { ...newTransaction };
        if (newTransactionAdditionalState.transactionType === TRANSACTION.TYPES.CREDIT) {
            // change wholesale_cost to 0
            transaction.wholesale_cost = 0;
            transaction.title = newTransactionAdditionalState.creditType + ': ' + transaction.title;
            transaction.retail_cost = transaction.retail_cost * -1.0;
        }

        dispatch(onAddNewTransaction(transaction))
            .then(() => {
                fetchRecords(pagination.currentPage, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm);
                toast.success('Transaction added successfully', { autoClose: 3000 });
                clearNewTransactionFormStates();
            })
            .catch(error => {
                toast.error('Transaction addition failed', { autoClose: 3000 });
            });

        toggleAddModal();
    };

    const onDownloadInvoice = async (invoiceId) => {
        try {
            const response = await getInvoicePdfByIdApi(invoiceId);
            // Create a link to download the blob
            const fileURL = URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = fileURL;
        
            // Pad the invoiceId with leading zeros to ensure it's at least 5 digits long
            const paddedInvoiceId = String(invoiceId).padStart(5, '0');
        
            link.download = `GFS_${paddedInvoiceId}.pdf`;
            link.click();
        
            // Clean up by revoking the object URL after the link has been clicked
            URL.revokeObjectURL(fileURL);
        
            toast.success("Invoice Downloaded Successfully", { autoClose: 3000 });
        } catch (error) {
            toast.error("Invoice Download Failed", { autoClose: 3000 });
        }
    };

    const handleTransactionDeleteConfirm = () => {
        dispatch(onDeleteTransaction(selectedTransaction.transaction_id))
            .then(() =>{
                fetchRecords(pagination.currentPage, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm);
                toast.success('Transaction deleted successfully', { autoClose: 3000 });
                setDeleteModal(false);
            })
            .catch((error) => {
                toast.error("Transaction deletion Failed", { autoClose: 3000 });
            });
    };

    const clearNewTransactionFormStates = () => {
        setNewTransaction({
            customer_id: props.customer.customer_id,
            title: '',
            description: '',
            retail_cost: '',
            wholesale_cost: '',
            service_id: null,
            site_id: null
        });
        setNewTransactionAdditionalState({
            transactionType: TRANSACTION.TYPES.DEBIT,
            creditType: TRANSACTION.CREDIT_TYPES.CASH_PAYMENT
        });
    };

    useEffect(() => {
        if (transactions) {
            setFormattedTransactions(transactions.map((transaction) => {
                const isVoid = transaction.void;
                const voidClass = isVoid === true ? 'void-transaction' : '';
                const voidPrefix = isVoid === true ? 'Void: ' : '';
                let formattedItems = {
                    id: transaction.transaction_id,
                    transaction_id: transaction.transaction_id,
                    title: <span className={voidClass}>{voidPrefix + transaction.title}</span>,
                    retail_cost: null,
                    price: null,
                    wholesale_cost: null,
                    'invoiced?': transaction.invoice_id ? 'Yes' : 'No',
                    'purchase_date': transaction.created,
                    actions: (
                        <ul className="list-inline hstack gap-2 mb-0">
                            <li className="list-inline-item edit">
                                <Link
                                    title={'Download Invoice'}
                                    className="text-primary d-inline-block edit-item-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (transaction.invoice_id) {
                                            onDownloadInvoice(transaction.invoice_id);
                                        } else {
                                            setErrorModalMessage('Transaction is not yet invoiced!');
                                            setErrorModal(true);
                                        }
                                    }}
                                >
                                    <i className="ri-download-2-fill fs-16"></i>
                                </Link>
                            </li>
                            {(!transaction.void && props.type !== 'self-care') && (
                                <li className="list-inline-item">
                                    <Link
                                        title={'Delete'}
                                        className="text-danger d-inline-block remove-item-btn"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (transaction.invoice_id) {
                                                setErrorModalMessage('This transaction cannot be modified as it has already been invoiced under invoice ID ' + transaction.invoice_id);
                                                setErrorModal(true);
                                            } else {
                                                setSelectedTransaction(transaction);
                                                setDeleteModal(true);
                                            }
                                        }}
                                    >
                                        <i className="ri-delete-bin-5-fill fs-16"></i>
                                    </Link>
                                </li>
                            )}
                        </ul>
                    )
                };

                if (props.type === 'self-care') {
                    formattedItems.price = transaction.retail_cost;
                    // remove retail_cost and wholesale_cost columns
                    delete formattedItems.retail_cost;
                    delete formattedItems.wholesale_cost;

                } else {
                    formattedItems.retail_cost = transaction.retail_cost;
                    formattedItems.wholesale_cost = transaction.wholesale_cost;
                    // remove price column
                    delete formattedItems.price;
                }

                return formattedItems;
            }));
        }
    }, [transactions]);

    useEffect(() => {
        return () => {
            dispatch(onResetTransactionStates());
            // Reset states here
            setHasFetchedTransactions(false);
            setTransactions([]);
            setSearchTerm("");
            setAddModal(false);
            setNewTransaction({
                customer_id: props.customer.customer_id,
                title: '',
                description: '',
                retail_cost: '',
                wholesale_cost: '',
                service_id: null,
                site_id: null
            });
            setNewTransactionAdditionalState({
                transactionType: TRANSACTION.TYPES.DEBIT,
                creditType: TRANSACTION.CREDIT_TYPES.CASH_PAYMENT
            });
            setPagination({
                currentPage: 1,
                itemsPerPage: 10,
                totalRecords: 0,
                totalPages: 0,
            });
            setFormattedTransactions([]);
            setSortConfig({ key: 'transaction_id', direction: 'desc' });
        };
    }, []);

    return (
        <React.Fragment>
            <TableCommon
                title="All Transactions"
                data={formattedTransactions ?? []}
                download_invoice={onDownloadInvoice}
                add={ props.type === 'self-care' ? null : toggleAddModal}
                pagination={pagination}
                sortConfig={sortConfig}
                fetchRecords={fetchRecords}
                sortableColumns={['transaction_id', 'title', 'retail_cost', 'wholesale_cost', 'price', 'invoiced?', 'purchase_date']}
                filters={{
                    filterOptions: filterOptions,
                    handleFilterChange: handleFilterChange,
                    applyFilters: applyFilters,
                    resetFilters: resetFilters
                }}
            />
            <Modal isOpen={addModal} toggle={toggleAddModal} centered>
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Add Transaction</h5>
                    <button type="button" onClick={toggleAddModal} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <h4 className="card-title mb-4">Transaction Type</h4>
                    {/* Radio Button Selection Individual/Business */}
                    <Row>
                        <ButtonGroup>
                            <Input type="radio" className="btn-check" name="transactionType" id="transactionType1" autoComplete="off" value={TRANSACTION.TYPES.DEBIT} defaultChecked onChange={() => onNewTransactionAdditionalStateChange('transactionType', TRANSACTION.TYPES.DEBIT)} />
                            <Label className="btn btn-primary" htmlFor="transactionType1">Debit</Label>

                            <Input type="radio" className="btn-check" name="transactionType" id="transactionType2" autoComplete="off" value={TRANSACTION.TYPES.CREDIT} onChange={() => onNewTransactionAdditionalStateChange('transactionType', TRANSACTION.TYPES.CREDIT)} />
                            <Label className="btn btn-primary" htmlFor="transactionType2">Credit</Label>
                        </ButtonGroup>
                    </Row>
                    {newTransactionAdditionalState.transactionType === TRANSACTION.TYPES.CREDIT && (
                        <Row>
                            <Col>
                                <label htmlFor="credit_type" className="form-label">Credit Type</label>
                                <Input type="select" id="credit_type" name="credit_type" value={newTransactionAdditionalState.creditType} onChange={(e) => onNewTransactionAdditionalStateChange('creditType', e.target.value)}>
                                    <option>{TRANSACTION.CREDIT_TYPES.CASH_PAYMENT}</option>
                                    <option>{TRANSACTION.CREDIT_TYPES.REFUND}</option>
                                </Input>
                            </Col>
                        </Row>    
                    )}
                    <Row>
                        <Col>
                            <label htmlFor="title" className="form-label">Transaction Name</label>
                            <input type="text" name='title' onChange={onNewTransactionFormChange} className="form-control" id="title" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor='description' className='form-label'>Description</label>
                            <textarea name='description' onChange={onNewTransactionFormChange} className='form-control' id='description'></textarea>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="retail_cost" className="form-label">{newTransactionAdditionalState.transactionType === 'credit' ? 'Credit' : 'Charge'}</label>
                            <input name='retail_cost' onChange={onNewTransactionFormChange} type="text" className="form-control" id="retail_cost" />
                        </Col>
                    </Row>
                    { newTransactionAdditionalState.transactionType === TRANSACTION.TYPES.DEBIT && (
                        <Row>
                            <Col>
                                <label htmlFor="wholesale_cost" className="form-label">Wholesale Cost</label>
                                <input name='wholesale_cost' onChange={onNewTransactionFormChange} type="text" className="form-control" id="wholesale_cost" />
                            </Col>
                        </Row>
                    )}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" onClick={toggleAddModal}>Close</button>
                    <button type="button" className="btn btn-success" onClick={onNewTransactionFormSubmit}>Save changes</button>
                </div>
            </Modal>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleTransactionDeleteConfirm()}
                onCloseClick={() => setDeleteModal(false)}
            />
            <ErrorModal
                show={errorModal}
                message={errorModalMessage}
                onCloseClick={() => setErrorModal(false)}
            />
        </React.Fragment>
    );
};

export default AllTransactions;