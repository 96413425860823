import React, { useEffect } from "react";
import { Container, Row, Card, CardBody } from 'reactstrap';

import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { toast } from 'react-toastify';

import Transactions from './Transactions';
import InvoiceList from '../Invoices/InvoiceList';
import PaymentMethods from "./PaymentMethods";


const Billing = ({customer, showWidgets=true, type=null}) => {
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <div>
                <Container fluid>
                    <Row>
                        <Card>
                            <CardBody>
                                <Transactions customer={customer} showWidgets={showWidgets} type={type} />
                            </CardBody>
                        </Card>
                    </Row>
                    <Row>
                        <Card>
                            <CardBody>
                                <InvoiceList customer={customer} showWidgets={showWidgets} type={type} />
                            </CardBody>
                        </Card>
                    </Row>
                    <Row>
                        <Card>
                            <CardBody>
                                <PaymentMethods customer={customer}/>
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Billing;