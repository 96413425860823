import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Row,
    Label,
    Input,
    Form,
    ButtonGroup,
} from 'reactstrap';

import { toast } from 'react-toastify';

const CustomerInfo = (props) => {

    const [customerType, setCustomerType] = useState("Individual");

    // Handle input change and update parent state
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        props.onChange({
            ...props.data,
            [name]: value
        });
    };

    // Handle form submission and move to next step
    const handleCustomerSubmit = (event) => {
        event.preventDefault();

        if (props.onNextStep) {
            props.onNextStep();
        }
    };

    const handleClearCustomerInfo = () => {
        props.onChange({
            customer_name: "",
            customer_enabled: true,
            customer_default_payment_method: "stripe",
            tax_identifier: "",
            promo_code: "",
            customer_payment_type: "postpaid",
            lead_source: "inStore"
        });
    };

    useEffect(() => {
        // Reset customer info when unmounting
        return () => {
          handleClearCustomerInfo();
        };
    }, []);

    document.title = "Customer Info | Omnitouch CRM";
    return (
        <React.Fragment>
            <div>
                <Container>
                    <Form>
                        <Row>
                            <ButtonGroup>
                                <input
                                    type="radio"
                                    className="btn-check"
                                    name="customerType"
                                    id="customerType1"
                                    autoComplete="off"
                                    defaultChecked
                                    onChange={() => setCustomerType('Individual')}
                                />
                                <label className="btn btn-primary" htmlFor="customerType1">Individual</label>

                                <input
                                    type="radio"
                                    className="btn-check"
                                    name="customerType"
                                    id="customerType2"
                                    autoComplete="off"
                                    onChange={() => setCustomerType('Business')}
                                />
                                <label className="btn btn-primary" htmlFor="customerType2">Business</label>
                            </ButtonGroup>
                        </Row>
                        {customerType === 'Business' && (
                            <>
                                <Row>
                                    <Col lg={12}>
                                        <Label className="form-label" htmlFor="customer_name">Business Name</Label>
                                        <Input
                                            type="text"
                                            id="customer_name"
                                            name="customer_name"
                                            placeholder="Business Name"
                                            value={props.data.customer_name}
                                            onChange={handleInputChange}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Label className="form-label" htmlFor="taxIdentifier">Tax Identifier</Label>
                                        <Input
                                            type="text"
                                            id="taxIdentifier"
                                            name="tax_identifier"
                                            placeholder="Tax Identifier"
                                            value={props.data.tax_identifier}
                                            onChange={handleInputChange}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Row>
                            <Col lg={12}>
                                <Label className="form-label" htmlFor="leadSource">Lead Source</Label>
                                <select
                                    className="form-select mb-3"
                                    aria-label="In store"
                                    name="lead_source"
                                    id="leadSource"
                                    value={props.data.lead_source}
                                    onChange={handleInputChange}
                                >
                                    <option value="inStore">In store</option>
                                    <option value="referral">Referral</option>
                                    <option value="website">Website</option>
                                    <option value="outreach">Outreach</option>
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Label className="form-label" htmlFor="promoCode">Promo Code</Label>
                                <select
                                    className="form-select mb-3"
                                    aria-label="No Promo"
                                    name="promo_code"
                                    id="promoCode"
                                    value={props.data.promo_code}
                                    onChange={handleInputChange}
                                >
                                    <option value="">No Promo</option>
                                    <option value="freemodem">Free Modem</option>
                                    <option value="firstmonthfree">First Month Free</option>
                                    <option value="zeroinstall">$0 Install</option>
                                </select>
                            </Col>
                        </Row>
                        <div className="d-flex align-items-start gap-3 mt-4">
                            <button
                                type="button"
                                className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                onClick={handleCustomerSubmit}
                            >
                                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                Next
                            </button>
                        </div>
                    </Form>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default CustomerInfo;
