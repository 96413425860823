import { createAsyncThunk } from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {
    getServices as getServicesApi,
    addNewService as addNewServiceApi,
    deleteService as deleteServiceApi,
    getServicesByCustomerId as getServicesByCustomerIdApi,
    updateService as updateServiceApi,
    getServiceById as getServiceByIdApi,
    getSimpleServiceById as getSimpleServiceByIdApi,
    deleteActionPlanFromServiceUUID as deleteActionPlanFromServiceUUIDApi,
    getServicesByMSISDN as getServicesByMSISDNApi,
    deactivateServiceByUUID as deactivateServiceByUUIDApi,
} from "../../helpers/backend_helper";

export const getServices = createAsyncThunk("services/getServices", async ({ search = '', page = 1, perPage = null, sort, sortOrder, filters }) => {
    const response = await getServicesApi(search, page, perPage, sort, sortOrder, filters);
    return response;
});

export const addNewService = createAsyncThunk("services/addNewService", async (service) => {
    const response = await addNewServiceApi(service);
    return response;
});

export const deleteService = createAsyncThunk("services/deleteService", async (uuid) => {
    const response = await deleteServiceApi(uuid);
    return response;
});

export const getServicesByCustomerId = createAsyncThunk("services/getServicesByCustomerId", async ({customerId, search = '', page = 1, perPage = null, sort = "service_id", sortOrder = "desc", filters = {} }) => {
    const response = await getServicesByCustomerIdApi(customerId, search, page, perPage, sort, sortOrder, filters);
    return response;
});

export const updateService = createAsyncThunk("services/updateService", async (service) => {
    const response = await updateServiceApi(service);
    return response;
});

export const getServiceById = createAsyncThunk("services/getServiceById", async (uuid) => {
    const response = await getServiceByIdApi(uuid);
    return response;
});

export const getSimpleServiceById = createAsyncThunk("services/getSimpleServiceById", async (uuid) => {
    const response = await getSimpleServiceByIdApi(uuid);
    return response;
});

export const deleteActionPlanFromServiceUUID = createAsyncThunk("services/deleteActionPlanFromServiceUUID", async (uuid, actionPlan) => {
    const response = await deleteActionPlanFromServiceUUIDApi(uuid, actionPlan);
    return response;
});

export const getServicesByMSISDN = createAsyncThunk("services/getServicesByMSISDN", async (msisdn) => {
    const response = await getServicesByMSISDNApi(msisdn);
    return response;
});

export const deactivateServiceByUUID = createAsyncThunk("services/deactivateServiceByUUID", async (uuid) => {
    const response = await deactivateServiceByUUIDApi(uuid);
    return response;
});


