import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
  getCommunicationById as getCommunicationByIdApi,
  getCommunicationsByCustomerId as getCommunicationsByCustomerIdApi,
  addNewCommunication as addNewCommunicationApi,
  sendInvoiceEmailToCustomer as sendInvoiceEmailToCustomerApi,
} from "../../helpers/backend_helper";

export const getCommunicationById = createAsyncThunk("communication/getCommunicationById", async (communicationId) => {
  try {
    const response = await getCommunicationByIdApi(communicationId);
    return response;
  } catch (error) {
    return error;
  }
});

export const getCommunicationsByCustomerId = createAsyncThunk("communication/getCommunicationsByCustomerId", async (customerId) => {
  try {
    const response = await getCommunicationsByCustomerIdApi(customerId);
    return response;
  } catch (error) {
    return error;
  }
});

export const addNewCommunication = createAsyncThunk("communication/addNewCommunication", async (communication) => {
  try {
    const response = await addNewCommunicationApi(communication);
    return response;
  } catch (error) {
    return error;
  }
});

export const sendInvoiceEmailToCustomer = createAsyncThunk("communication/sendInvoiceEmailToCustomer", async (invoiceId) => {
  try {
    const response = await sendInvoiceEmailToCustomerApi(invoiceId);
    return response;
  } catch (error) {
    return error;
  }
});
