import React from 'react';
import ProvisioningList from './components/ProvisioningList';
import { Container } from 'reactstrap';

const FailedProvisioningJobs = () => {
    const title = 'Failed Provisioning Jobs';
    const status = [2];

    document.title = 'Failed Provisioning Jobs | OmnitouchCRM';
    return (
        <div className='page-content'>
            <Container fluid>
                <ProvisioningList title={title} status={status}/>
            </Container>
        </div>
    );
};

export default FailedProvisioningJobs;