import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { withTranslation } from "react-i18next";

import OverviewTab from './OverviewTab';
import ActivitiesTab from './ActivitiesTab';
import Billing from '../../Billing';
import ContactList from '../../Contacts/ContactList';
import SiteListPage from './SiteList';
import CommunicationList from './CommunicationList';
import ServiceList from './Services';
import CustomerInventoryList from './Inventory/InventoryList';

import "./css/styles.scss";
import { useLocation, useNavigate } from 'react-router-dom';

const Section = ({ customer, t }) => {
    const location = useLocation();
    const navigate = useNavigate();

    // Tab state
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            navigate(`${location.pathname}#${tab}`);
        }
    };

    useEffect(() => {
        if (customer) {
            document.title = `${customer.customer_name} | Omnitouch CRM`;
        }
    }, [customer]);

    useEffect(() => {
        const hash = location.hash.replace('#', '');
        if (hash) {
            setActiveTab(hash);
        }
    }, [location]);

    const customerName = customer.customer_name ?? customer.contacts?.[0]?.contact_firstname + ' ' + customer.contacts?.[0]?.contact_lastname ?? customer;
    const creationDate = new Date(customer.created).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card className="mt-n4 mx-n4">
                        <CardHeader className="bg-primary">
                            <Row className="align-items-center">
                                <Col md={8}>
                                    <h4 className="fw-bold text-white">{customerName}</h4>
                                    <div className="d-flex align-items-center">
                                        <div className="text-white">Creation Date: <span className="fw-medium">{creationDate}</span></div>
                                        <div className="vr mx-3"></div>
                                        {customer?.tags?.map(tag => (
                                            <a key={'tag-' + tag} href={tag.tag_link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                                <div
                                                    className="badge rounded-pill fs-12"
                                                    style={{
                                                        backgroundColor: `#${tag.tag_hex_color}`,
                                                        color: '#fff',
                                                    }}
                                                >
                                                    {tag.tag_text}
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                </Col>
                                <Col md={4} className="d-flex justify-content-end">
                                    <div className="hstack gap-2">
                                        <div className="icon-button px-3">
                                            <i className="ri-star-fill"></i>
                                        </div>
                                        <div className="icon-button px-3">
                                            <i className="ri-share-line"></i>
                                        </div>
                                        <div className="icon-button px-3">
                                            <i className="ri-flag-line"></i>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className='pb-0'>
                            <Nav tabs className="nav-tabs-custom border-bottom-0">
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' }, "fw-semibold")}
                                        onClick={() => { toggleTab('1'); }}
                                        href="#"
                                    >
                                        {t('Overview')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' }, "fw-semibold")}
                                        onClick={() => { toggleTab('2'); }}
                                        href="#"
                                    >
                                        {t('Sites')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '3' }, "fw-semibold")}
                                        onClick={() => { toggleTab('3'); }}
                                        href="#"
                                    >
                                        {t('Services')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '4' }, "fw-semibold")}
                                        onClick={() => { toggleTab('4'); }}
                                        href="#"
                                    >
                                        {t('Contacts')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '5' }, "fw-semibold")}
                                        onClick={() => { toggleTab('5'); }}
                                        href="#"
                                    >
                                        {t('Billing')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '6' }, "fw-semibold")}
                                        onClick={() => { toggleTab('6'); }}
                                        href="#"
                                    >
                                        {t('Activity')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '7' }, "fw-semibold")}
                                        onClick={() => { toggleTab('7'); }}
                                        href="#"
                                    >
                                        {t('Interactions')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '8' }, "fw-semibold")}
                                        onClick={() => { toggleTab('8'); }}
                                        href="#"
                                    >
                                        {t('Inventory')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                        {activeTab === '1' && (
                            <TabPane tabId="1">
                                <OverviewTab customer={customer} />
                            </TabPane>
                        )}
                        {activeTab === '2' && (
                            <TabPane tabId="2">
                                <SiteListPage sites={customer.sites} customer={customer} />
                            </TabPane>
                        )}
                        {activeTab === '3' && (
                            <TabPane tabId="3">
                                <ServiceList customerId={customer.customer_id} customerName={customer.customer_name} />
                            </TabPane>
                        )}
                        {activeTab === '4' && (
                            <TabPane tabId="4">
                                <ContactList customer={customer} customerId={customer.customer_id} />
                            </TabPane>
                        )}
                        {activeTab === '5' && (
                            <TabPane tabId="5">
                                <Billing customer={customer} />
                            </TabPane>
                        )}
                        {activeTab === '6' && (
                            <TabPane tabId="6">
                                <ActivitiesTab customerId={customer.customer_id} />
                            </TabPane>
                        )}
                        {activeTab === '7' && (
                            <TabPane tabId="7">
                                <CommunicationList customer={customer} customerId={customer.customer_id} />
                            </TabPane>
                        )}
                        {activeTab === '8' && (
                            <TabPane tabId="8">
                                <CustomerInventoryList customerId={customer.customer_id} />
                            </TabPane>
                        )}
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default withTranslation()(Section);
