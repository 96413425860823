import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Button, ModalFooter } from 'reactstrap';

// import actions
import { addNewStripeObject as onAddNewStripeObject } from "../../../slices/thunks";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4',
        },
        },
        valid: {
            color: '#28a745',
            iconColor: '#28a745',
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
};

function AddPaymentMethodModal({ isOpen, toggle, customerId }) {
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const [isDefaultPayment, setIsDefaultPayment] = useState(false);
    const [cardNickname, setCardNickname] = useState('');

    const handleCardNicknameChange = (e) => setCardNickname(e.target.value);
    const handleDefaultPaymentChange = (e) => setIsDefaultPayment(e.target.checked);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) return;

        const card = elements.getElement(CardElement);
        const { token, error } = await stripe.createToken(card);

        if (error) {
            console.error(error);
        } else {
            const {
                id: stripe_token,
                card: { id: stripe_card, brand: cc_type, exp_month, exp_year },
                client_ip
            } = token;

            const requestBody = {
                customer_id: customerId,
                card_nickname: cardNickname,
                stripe_token,
                stripe_card,
                cc_type,
                exp_month,
                exp_year,
                client_ip,
                default_payment_method: isDefaultPayment
            };

            dispatch(onAddNewStripeObject(requestBody))
                .then(() => {
                    toast.success('Payment Method successfully added', { autoClose: 3000 });
                    handleModalClose();
                })
                .catch(err => {
                    toast.error('Payment Method addition failed', { autoClose: 3000 });
                    console.error("Error sending data to the server:", err);
                });
        }
    };

    // reset form fields
    const resetForm = () => {
        setCardNickname('');
        setIsDefaultPayment(false);
    };

    // reset on modal close
    const handleModalClose = () => {
        resetForm();
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={handleModalClose} centered size='l'>
            <ModalHeader toggle={handleModalClose}>Add Payment Method</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="cardNickname">Card Nickname</Label>
                        <Input
                            type="text"
                            id="cardNickname"
                            value={cardNickname}
                            onChange={handleCardNicknameChange}
                            placeholder="e.g., My Primary Card"
                        />
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="checkbox"
                                id="defaultPaymentCheckbox"
                                checked={isDefaultPayment}
                                onChange={handleDefaultPaymentChange}
                            />
                            Set as default payment method
                        </Label>
                    </FormGroup>
                    <FormGroup className="card-input-wrapper form-control shadow-sm p-2 mb-4 bg-white rounded mt-3">
                        <CardElement options={CARD_ELEMENT_OPTIONS} />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button className='btn btn-danger' onClick={handleModalClose}>Cancel</Button>
                <Button className="btn btn-success" onClick={handleSubmit}>Save</Button>
            </ModalFooter>
        </Modal>
    );
}

export default AddPaymentMethodModal;