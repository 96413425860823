import { createSlice } from "@reduxjs/toolkit";

import {
  forgetPassword,
} from "./thunk";

export const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
};

const forgotPasswordSlice = createSlice({
  name: "forgotpwd",
  initialState,
  reducers: {
      userForgetPasswordSuccess(state, action) {
          state.forgetSuccessMsg = action.payload
      },
      userForgetPasswordError(state, action) {
          state.forgetError = action.payload
      },
  },
  extraReducers: (builder) => {
    builder.addCase(forgetPassword.fulfilled, (state, action) => {
      state.forgetSuccessMsg = action.payload.message || null;
    });
    builder.addCase(forgetPassword.rejected, (state, action) => {
      state.forgetError = action.error.message || null;
    });
  }
});

export const {
  userForgetPasswordSuccess,
  userForgetPasswordError
} = forgotPasswordSlice.actions

export default forgotPasswordSlice.reducer;
