import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Label } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import ServiceUsage from '../../Services/components/ServiceUsage';
import { getServicesByCustomerId as fetchServices } from '../../../slices/thunks';

const Usage = ({ customerId }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [selectedService, setSelectedService] = useState(null);

    // Load the first page of services on component mount
    useEffect(() => {
        dispatch(fetchServices({customerId})).then((response) => {
            if (response.payload && response.payload.data.length > 0) {
                setSelectedService(response.payload.data[0]);
            }
        });
    }, [dispatch]);

    const loadOptions = async (inputValue, callback) => {
        setLoading(true);
        dispatch(fetchServices({customerId, search: inputValue})).then((response) => {
            if (response.payload) {
                const options = response.payload.data.map(service => ({
                    label: service.service_name,
                    value: service
                }));
                callback(options);
                setLoading(false);
            }
        });
    };

    const handleServiceChange = (selectedOption) => {
        setSelectedService(selectedOption.value);
    };

    return (
        <Container>
            <Row className="mb-4">
                <Col md={12}>
                    <AsyncSelect
                        loadOptions={loadOptions}
                        onChange={handleServiceChange}
                        isLoading={loading}
                        defaultOptions
                        styles={{
                            menu: provided => ({ ...provided, zIndex: 2 })
                        }}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <ServiceUsage service={selectedService} />
                </Col>
            </Row>
        </Container>
    );
};

export default Usage;
