// components/PermissionForm.js
import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { addPermission as onCreatePermission, updatePermission as onUpdatePermission } from "../../../slices/thunks";

const PermissionForm = ({ initialPermission, onNextStep, onCancel }) => {
    const dispatch = useDispatch();
    const [permission, setPermission] = useState({
        name: '',
        description: ''
    });

    useEffect(() => {
        if (initialPermission) {
            setPermission(initialPermission);
        }
    }, [initialPermission]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPermission({ ...permission, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (initialPermission) {
            dispatch(onUpdatePermission(permission))
                .then(() => onNextStep());
        } else {
            dispatch(onCreatePermission(permission))
                .then(() => onNextStep());
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="permission_name">Permission Name</Label>
                <Input
                    type="text"
                    name="name"
                    id="permission_name"
                    value={permission.name}
                    onChange={handleInputChange}
                    required
                />
            </FormGroup>
            <FormGroup>
                <Label for="permission_description">Permission Description</Label>
                <Input
                    type="text"
                    name="description"
                    id="permission_description"
                    value={permission.description}
                    onChange={handleInputChange}
                />
            </FormGroup>
            <Button type="submit" color="primary">Submit</Button>
            <Button type="button" color="secondary" onClick={onCancel}>Cancel</Button>
        </Form>
    );
};

export default PermissionForm;
