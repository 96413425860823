import React, { useState, useEffect } from 'react';
import { Col, Container, Modal, Row, ModalBody, ModalFooter, ModalHeader, Button, Input } from 'reactstrap';
import TableCommon from '../../Components/Common/TableCommon';
import DeleteModal from '../../Components/Common/DeleteModal';
import PermissionForm from './components/PermissionForm';
import {
    deletePermission as onDeletePermission,
    updatePermission as onUpdatePermission,
    getPermissions as onGetPermissions,
    resetPermission as onResetPermissions,
} from "../../slices/thunks";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const ManagePermissions = () => {
    const dispatch = useDispatch();
    const { permissions } = useSelector(state => state.Permission);
    const [permissionList, setPermissionList] = useState([]);
    const [currentPermission, setCurrentPermission] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [isPermissionsFetched, setIsPermissionsFetched] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        itemsPerPage: 10,
        totalRecords: 0,
        totalPages: 0,
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'permission_id', direction: 'desc' });

    const toggleAddModal = () => setAddModal(!addModal);
    const toggleDeleteModal = () => setDeleteModal(!deleteModal);
    const toggleUpdateModal = () => setUpdateModal(!updateModal);

    useEffect(() => {
        if (!isPermissionsFetched) {
            fetchPermissions();
        }
    }, [dispatch, isPermissionsFetched]);

    const formatName = (name) => {
        // replace underscores with spaces and capitalize first letter of each word
        return name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };

    useEffect(() => {
        if (permissions && permissions.data && Array.isArray(permissions.data)) {
            setPermissionList(permissions.data.map(permission => ({
                id: permission.id,
                name: formatName(permission.name),
                description: permission.description,
                actions: (
                    <ul className="list-inline hstack gap-2 mb-0">
                        <li className="list-inline-item edit">
                            <Link
                                title={'Edit'}
                                className="text-primary d-inline-block edit-item-btn"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handlePermissionView(permission.permission_id);
                                }}
                            >
                                <i className="ri-pencil-fill fs-16"></i>
                            </Link>
                        </li>
                        <li className="list-inline-item">
                            <Link
                                title={'Delete'}
                                className="text-danger d-inline-block remove-item-btn"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handlePermissionDelete(permission.permission_id);
                                }}
                            >
                                <i className="ri-delete-bin-5-fill fs-16"></i>
                            </Link>
                        </li>
                    </ul>
                )
            })));
        }

        if (permissions && permissions.pagination) {
            setPagination({
                currentPage: permissions.pagination.page,
                itemsPerPage: permissions.pagination.per_page,
                totalRecords: permissions.pagination.total_records,
                totalPages: permissions.pagination.total_pages,
            });
        }
    }, [permissions]);

    const handlePermissionView = (id) => {
        setCurrentPermission(permissions.data.find(permission => permission.permission_id === id));
        toggleUpdateModal();
    };

    const handlePermissionDelete = (id) => {
        setCurrentPermission(permissions.data.find(permission => permission.permission_id === id));
        toggleDeleteModal();
    };

    const handlePermissionConfirmDelete = () => {
        dispatch(onDeletePermission(currentPermission.permission_id))
            .then(() => {
                toast.success('Permission deleted successfully', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(error => {
                console.log(error);
                toast.error('Failed to delete permission', { autoClose: 3000 });
            })
            .finally(() => fetchPermissions(pagination.currentPage, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm));

        setCurrentPermission(null);
        setDeleteModal(false);
    };

    const fetchPermissions = (page = 1, perPage = 10, sort = 'permission_id', sortOrder = 'desc', searchTerm = '') => {
        dispatch(onGetPermissions({ searchTerm, page, perPage, sort, sortOrder }))
            .then(() => {
                setIsPermissionsFetched(true);
                setSearchTerm(searchTerm);
                setSortConfig({ key: sort, direction: sortOrder });
            })
            .catch(error => {
                console.log(error);
                toast.error('Failed to fetch permissions', { autoClose: 3000 });
            });
    };

    useEffect(() => {
        fetchPermissions();
        return () => {
            dispatch(onResetPermissions());
        };
    }, []);

    document.title = "Permission List | OmniCRM";
    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <TableCommon
                            title="Permissions"
                            data={permissionList}
                            edit={handlePermissionView}
                            delete={handlePermissionDelete}
                            add={toggleAddModal}
                            pagination={pagination}
                            sortConfig={sortConfig}
                            fetchRecords={fetchPermissions}
                            onRowClick={handlePermissionView}
                        />
                    </Col>
                </Row>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handlePermissionConfirmDelete}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <Modal isOpen={updateModal} toggle={toggleUpdateModal} centered size='xl'>
                    <ModalHeader toggle={toggleUpdateModal}>Update Permission</ModalHeader>
                    <ModalBody>
                        <PermissionForm initialPermission={currentPermission} onNextStep={() => { toggleUpdateModal(); fetchPermissions(); }} onCancel={toggleUpdateModal} />
                    </ModalBody>
                </Modal>
                <Modal isOpen={addModal} toggle={toggleAddModal} centered size='xl'>
                    <ModalHeader toggle={toggleAddModal}>Add Permission</ModalHeader>
                    <ModalBody>
                        <PermissionForm onNextStep={() => { toggleAddModal(); fetchPermissions(); }} onCancel={toggleAddModal} />
                    </ModalBody>
                </Modal>
            </Container>
        </React.Fragment>
    );
}

export default ManagePermissions;
