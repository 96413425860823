import React from 'react';
import ActivitiesTab from '../../Customer/CustomerOverview/ActivitiesTab';
import CommunicationsData from '../../Customer/CustomerOverview/CommunicationList/CommunicationsData';
const ActivitiesComms = ({ customerId, type = null}) => {

    return (
        <React.Fragment>
            <ActivitiesTab customerId={customerId} type={type} />
            <CommunicationsData customerId={customerId} type={type} />
        </React.Fragment>
    );
}

export default ActivitiesComms;