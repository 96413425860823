import React from 'react';
import { Container, Row } from 'reactstrap';
import Widgets from './Widgets';
import CommunicationsData from './CommunicationsData';


const CommunicationList = ({ customerId = null, contacts = [], type = null }) => {
    document.title="Communication List | Omnitouch CRM";
    return (
        <React.Fragment>
            <div>
                <Container fluid>                    
                    <Row>
                        <Widgets />
                    </Row>
                    <CommunicationsData customerId={customerId} contacts={contacts} type={null}/>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CommunicationList;