import { createAsyncThunk } from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {
    getTransactions as getTransactionsApi,
    addNewTransaction as addNewTransactionApi,
    deleteTransaction as deleteTransactionApi,
    getTransactionsByCustomerId as getTransactionsByCustomerIdApi,
    getTransactionsByInvoiceId as getTransactionsByInvoiceIdApi,
    processJsonRPCEvent as processJsonRPCEventApi,
    getTransactionStats as getTransactionStatsApi,
} from "../../helpers/backend_helper";

export const getTransactions = createAsyncThunk("billing/getTransactions", async () => {
    try {
        const response = await getTransactionsApi();
        return response;
    } catch (error) {
        return error;
    }
});

export const addNewTransaction = createAsyncThunk("billing/addNewTransaction", async (data) => {
    try {
        const response = await addNewTransactionApi(data);
        return response;
    } catch (error) {
        return error;
    }
});

export const deleteTransaction = createAsyncThunk("billing/deleteTransaction", async (id) => {
    try {
        const response = await deleteTransactionApi(id);
        return response;
    } catch (error) {
        return error;
    }
});

export const getTransactionsByCustomerId = createAsyncThunk("billing/getTransactionsByCustomerId", async ({customerId, page = 1, perPage = 10, sort = 'transaction_id', sortOrder = 'desc', searchTerm = '', filters = {}}) => {
    try {
        const response = await getTransactionsByCustomerIdApi(customerId, page, perPage, sort, sortOrder, searchTerm, filters);
        return response;
    } catch (error) {
        return error;
    }
});

export const getTransactionsByInvoiceId = createAsyncThunk("billing/getTransactionsByInvoiceId", async (id) => {
    try {
        const response = await getTransactionsByInvoiceIdApi(id);
        return response;
    } catch (error) {
        return error;
    }
});

export const processJsonRPCEvent = createAsyncThunk("billing/processJsonRPCEvent", async (data) => {
    try {
        const response = await processJsonRPCEventApi(data);
        return response;
    } catch (error) {
        return error;
    }
});

export const getTransactionStats = createAsyncThunk("billing/getTransactionStats", async () => {
    try {
        const response = await getTransactionStatsApi();
        return response;
    } catch (error) {
        return error;
    }
});

export const resetTransactionStates = createAsyncThunk("billing/resetTransactionStates", async () => {
    return {};
});
