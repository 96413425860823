import React, { useState, useEffect } from 'react';
import { Col, Container, Modal, Row, ModalBody, ModalFooter, ModalHeader, Button, Input } from 'reactstrap';
import TableCommon from '../../Components/Common/TableCommon';
import DeleteModal from '../../Components/Common/DeleteModal';
import UserForm from './components/UserForm';
import Tags from '../../Components/Common/Tags';

// Import actions
import {
    deleteUser as onDeleteUser,
    updateUser as onUpdateUser,
    getUsers as onGetUsers,
    resetUser as onResetUsers,
    getUserById as onGetUserById,
    addUser as onAddUser,
    getRoles as onGetRoles,
    sendUserWelcomeEmail as onSendUserWelcomeEmail,
} from "../../slices/thunks";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const ManageUsers = () => {
    const dispatch = useDispatch();

    const {
        users
    } = useSelector(state => state.User);

    const [userList, setUserList] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [isUsersFetched, setIsUsersFetched] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        itemsPerPage: 10,
        totalRecords: 0,
        totalPages: 0,
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'user_id', direction: 'desc' });
    const [initiallyFetched, setInitiallyFetched] = useState(false);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        
    }, [dispatch]);

    const toggleAddModal = () => setAddModal(!addModal);
    const toggleDeleteModal = () => setDeleteModal(!deleteModal);
    const toggleUpdateModal = () => setUpdateModal(!updateModal);

    useEffect(() => {
        if (!isUsersFetched) {
            fetchUsers();
            dispatch(onGetRoles()).then((response) => {
                setRoles(response.data);
            });
        }
    }, [dispatch, isUsersFetched]);

    useEffect(() => {
        if (users && users.data && Array.isArray(users.data)) {
            setUserList(users.data.map(user => ({
                id: user.id,
                name: user.first_name + ' ' + user.last_name,
                email: user.email,
                phone: user.phone_number,
                actions: (
                    <ul className="list-inline hstack gap-2 mb-0">
                        <li className="list-inline-item edit">
                            <Link
                            title={'Edit'}
                            className="text-primary d-inline-block edit-item-btn"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleUserView(user.id);
                            }}
                            >
                            <i className="ri-pencil-fill fs-16"></i>
                            </Link>
                        </li>
                        <li className="list-inline-item">
                            <Link
                            title={'Delete'}
                            className="text-danger d-inline-block remove-item-btn"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleUserDelete(user.id);
                            }}
                            >
                            <i className="ri-delete-bin-5-fill fs-16"></i>
                            </Link>
                        </li>
                        { user.login_count == 0 && (
                            <li className="list-inline-item">
                                <Link
                                    title={'Send Welcome Email'}
                                    className="text-info d-inline-block send-item-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleUserSendWelcomeEmail(user.id);
                                    }}
                                >
                                    <i className="ri-mail-send-fill fs-16"></i>
                                </Link>
                            </li>
                        )}
                    </ul>
                )
            })));
        }

        if (users && users.pagination) {
            setPagination({
                currentPage: users.pagination.page,
                itemsPerPage: users.pagination.per_page,
                totalRecords: users.pagination.total_records,
                totalPages: users.pagination.total_pages,
            });
        }
    }, [users]);

    const handleUserView = (id) => {
        setCurrentUser(users.data.find(user => user.id === id));
        toggleUpdateModal();
    };

    const handleUserSendWelcomeEmail = (id) => {
        dispatch(onSendUserWelcomeEmail(id))
            .then(() => {
                toast.success('Welcome email sent successfully',
                    {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            })
            .catch(error => {
                console.log(error);
                toast.error('Failed to send welcome email', { autoClose: 3000 });
            });
    }

    const handleUserDelete = (id) => {
        setCurrentUser(users.data.find(user => user.id === id));
        toggleDeleteModal();
    };

    const handleUserConfirmDelete = () => {
        dispatch(onDeleteUser(currentUser.user_id))
            .then(() => {
                toast.success('User deleted successfully',
                    {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            })
            .catch(error => {
                console.log(error);
                toast.error('Failed to delete user', { autoClose: 3000 });
            })
            .finally(() => fetchUsers(pagination.currentPage, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm));

        // Reset the current user
        setCurrentUser(null);

        // Close the delete modal
        setDeleteModal(false);
    };

    const refetchUsers = (page = 1, perPage = 10, sort = 'user_id', sortOrder = 'desc', searchTerm = '') => {
        if (initiallyFetched) {
            fetchUsers(page, perPage, sort, sortOrder, searchTerm);
        }
    };

    const fetchUsers = (page = 1, perPage = 10, sort = 'user_id', sortOrder = 'desc', searchTerm = '') => {
        // Process sort key
        if (sort === 'name') {
            sort = 'first_name';
        } else if (sort === 'email') {
            sort = 'email';
        } else if (sort === 'phone_number') {
            sort = 'phone_number';
        }

        dispatch(onGetUsers({ searchTerm, page, perPage, sort, sortOrder }))
            .then(() => {
                setIsUsersFetched(true);
                setSearchTerm(searchTerm);
                setSortConfig({ key: sort, direction: sortOrder });
                setInitiallyFetched(true);
            })
            .catch(error => {
                console.log(error);
                toast.error('Failed to fetch users', { autoClose: 3000 });
            });
    }

    useEffect(() => {
        // This function is called once after the component mounts
    
        // Fetch users when the component mounts
        fetchUsers();
    
        // Return a function to be called when the component unmounts
        return () => {
            // Reset users when the component unmounts
            dispatch(onResetUsers());
        };
    }, []);
    
    document.title = "User List | OmniCRM";
    return (
        <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <TableCommon
                                title="Users"
                                data={userList}
                                edit={handleUserView}
                                delete={handleUserDelete}
                                add={toggleAddModal}
                                pagination={pagination}
                                sortConfig={sortConfig}
                                fetchRecords={refetchUsers}
                                onRowClick={handleUserView}
                            />
                        </Col>
                    </Row>
                    <DeleteModal
                        show={deleteModal}
                        onDeleteClick={handleUserConfirmDelete}
                        onCloseClick={() => setDeleteModal(false)}
                    />
                    <Modal isOpen={updateModal} toggle={toggleUpdateModal} centered size='xl'>
                        <ModalHeader toggle={toggleUpdateModal}>Update User</ModalHeader>
                        <ModalBody>
                            <UserForm initialUser={currentUser} roles={roles} onNextStep={() => {toggleUpdateModal(); refetchUsers();}} onCancel={toggleUpdateModal}/>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={addModal} toggle={toggleAddModal} centered size='xl'>
                        <ModalHeader toggle={toggleAddModal}>Add User</ModalHeader>
                        <ModalBody>
                            <UserForm roles={roles} onNextStep={() => {toggleAddModal(); refetchUsers();}} onCancel={toggleAddModal}/>
                        </ModalBody>
                    </Modal>
                </Container>
        </React.Fragment>
    )
}

export default ManageUsers;