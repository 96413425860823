import React, { useState } from 'react';
import { Nav, NavItem, NavLink, Container, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import TopUp from './components/TopUp';
import Usage from './components/Usage';

const TopupAndUsage = () => {
    const [activePage, setActivePage] = useState('topup');

    const handleNavigation = (page) => {
        setActivePage(page);
    };

    return (
        <div className="page-content-topup">
            <Container fluid>
                <Row className="justify-content-center navigation">
                    <Col xs="12" md="auto" className="text-center mb-3 mb-md-0">
                        <Nav pills className="justify-content-center">
                            <NavItem className="mx-2">
                                <NavLink
                                    className={classnames({ active: activePage === 'topup' })}
                                    onClick={() => handleNavigation('topup')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Topup
                                </NavLink>
                            </NavItem>
                            <span className="align-self-center d-none d-md-inline">|</span>
                            <NavItem className="mx-2">
                                <NavLink
                                    className={classnames({ active: activePage === 'usage' })}
                                    onClick={() => handleNavigation('usage')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Usage
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {activePage === 'topup' && <TopUp />}
                        {activePage === 'usage' && <Usage />}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default TopupAndUsage;
