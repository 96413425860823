import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createNewProvisioningJob as createNewProvisioningJobApi,
  updateProvisioningRecord as updateProvisioningRecordApi,
  createNewProvisioningRecord as createNewProvisioningRecordApi,
  getAllProvisioningJobs as getAllProvisioningJobsApi,
  getAvailablePlaybooks as getAvailablePlaybooksApi,
  getProvisioningStatus as getProvisioningStatusApi,
  createSimpleProvisionJob as createSimpleProvisionJobApi,
} from "../../helpers/backend_helper";

export const createNewProvisioningJob = createAsyncThunk("crm/createNewProvisioningJob", async (provisioningJob) => {
  try {
    const response = await createNewProvisioningJobApi(provisioningJob);
    return response;
  } catch (error) {
    return error;
  }
});

export const updateProvisioningRecord = createAsyncThunk("crm/updateProvisioningRecord", async (provisioningRecord) => {
  try {
    const response = await updateProvisioningRecordApi(provisioningRecord);
    return response;
  } catch (error) {
    return error;
  }
});

export const createNewProvisioningRecord = createAsyncThunk("crm/createNewProvisioningRecord", async (provisioningRecord) => {
  try {
    const response = await createNewProvisioningRecordApi(provisioningRecord);
    return response;
  } catch (error) {
    return error;
  }
});

export const getAllProvisioningJobs = createAsyncThunk("crm/getAllProvisioningJobs", async ({ page, perPage, sort, sortOrder, search, filters }) => {
  try {
    const response = await getAllProvisioningJobsApi(page, perPage, sort, sortOrder, search, filters);
    return response;
  } catch (error) {
    return error;
  }
});

export const getAvailablePlaybooks = createAsyncThunk("crm/getAvailablePlaybooks", async () => {
  try {
    const response = await getAvailablePlaybooksApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const getProvisioningStatus = createAsyncThunk("crm/getProvisioningStatus", async (provisioningId) => {
  try {
    const response = await getProvisioningStatusApi(provisioningId);
    return response;
  } catch (error) {
    return error;
  }
});

export const createSimpleProvisionJob = createAsyncThunk("crm/createSimpleProvisionJob", async ({serviceId, productId}) => {
  try {
    const response = await createSimpleProvisionJobApi(serviceId, productId);
    return response;
  } catch (error) {
    return error;
  }
});

export const resetProvisioningServices = createAsyncThunk("crm/resetProvisioningServices", async () => {
  return {};
});