import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

const Tags = ({ tags }) => {
  return (
    <span>
      {tags.map((tag, index) => {
        const value = typeof tag === 'string' ? tag : tag.value;
        const color = typeof tag === 'string' ? 'info' : tag.color;
        const link = typeof tag === 'string' ? null : tag.link ? tag.link : null;
        const isHexColor = /^#[0-9A-F]{6}$/i.test(color);
        const style = isHexColor ? { color: '#fff', backgroundColor: color } : null;
        return (
          <Badge key={index} className={`badge text-uppercase ${!isHexColor ? `bg-${color}-subtle text-${color}` : ''} mx-1`} style={style} href={link ? link : null}>
            {value}
          </Badge>
        );
      })}
    </span>
  );
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        color: PropTypes.string,
      }),
    ])
  ).isRequired,
};

export default Tags;