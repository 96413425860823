import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getUsers as getUsersApi,
  deleteUser as deleteUserApi,
  updateUser as updateUserApi,
  addUser as createUserApi,
  getUserById as getUserByIdApi,
  sendUserWelcomeEmail as sendUserWelcomeEmailApi,
} from "../../helpers/backend_helper";

export const getUsers = createAsyncThunk("user/getUsers", async ({searchTerm = '', page = 1, perPage = 10, sort = 'id', sortOrder = 'desc', filters = {}} = {}) => {
  try {
    const response = await getUsersApi(searchTerm, page, perPage, sort, sortOrder, filters);
    return response;
  } catch (error) {
    return error;
  }
});

export const addUser = createAsyncThunk("user/addUser", async (user) => {
  try {
    const response = await createUserApi(user);
    return response;
  } catch (error) {
    return error;
  }
});

export const updateUser = createAsyncThunk("user/updateUser", async (user) => {
  try {
    const response = await updateUserApi(user);
    return response;
  } catch (error) {
    return error;
  }
});

export const deleteUser = createAsyncThunk("user/deleteUser", async (userId) => {
  try {
    const response = await deleteUserApi(userId);
    return {response, userId};
  } catch (error) {
    return error;
  }
});

export const getUserById = createAsyncThunk("user/getUserById", async (userId) => {
  try {
    const response = await getUserByIdApi(userId);
    return response;
  } catch (error) {
    return error;
  }
});

export const sendUserWelcomeEmail = createAsyncThunk("user/sendUserWelcomeEmail", async (userId) => {
  try {
    const response = await sendUserWelcomeEmailApi(userId);
    return response;
  } catch (error) {
    return error;
  }
});

export const resetUser = createAsyncThunk("user/resetUser", async () => {
  return {};
});