import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Navigation from "./Navigation";
import Cart from "./Cart";
import DataSelection from "./DataSelection";
import BillingInfo from "./BillingInfo";
import Payment from "./Payment";
import { getUsage, createNewProvisioningJob, createPaymentIntent, getProvisioningStatus } from '../../../slices/thunks';

import defaultLogoSm from "../../../assets/images/omnitouch/DefaultLogoSm.png";
import defaultLogoDark from "../../../assets/images/omnitouch/DefaultLogoDark.png";
import defaultLogoLight from "../../../assets/images/omnitouch/DefaultLogoLight.png";

// Function to conditionally import user-uploaded images
const tryImport = (filename) => {
  try {
    return require(`../../../assets/images/omnitouch/${filename}`);
  } catch (err) {
    return null;
  }
};

// Attempt to import user-uploaded images
const userLogoSm = tryImport("logoSm.png");
const userLogoDark = tryImport("logoDark.png");
const userLogoLight = tryImport("logoLight.png");

import "../style.scss";

const getLogo = (userLogo, defaultLogo) => userLogo || defaultLogo;

const TopUp = () => {
  const dispatch = useDispatch();
  const [activeVerticalTab, setActiveVerticalTab] = useState(1);
  const [passedVerticalSteps, setPassedVerticalSteps] = useState([1]);
  const [days, setDays] = useState(1);
  const [price, setPrice] = useState(10);
  const [usage, setUsage] = useState({});
  const [clientSecret, setClientSecret] = useState('');
  const [handleStripePayment, setHandleStripePayment] = useState(null);
  const [billingInfo, setBillingInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [showStatus, setShowStatus] = useState(false);

  const handlePayment = () => {
    if (handleStripePayment) {
      handleStripePayment();
    }
  };

  useEffect(() => {
    dispatch(getUsage())
      .then((response) => {
        if (response) {
          setUsage(response?.payload || {});
        }
      });
  }, [dispatch]);

  const handleCreatePaymentIntent = async () => {
    try {
      const paymentIntent = await dispatch(createPaymentIntent({
        amount: price * days * 100,
        currency: process.env.REACT_APP_CURRENCY_CODE.toLowerCase(),
        description: 'Dongle top-up',
      })).unwrap();

      setClientSecret(paymentIntent);
    } catch (error) {
      console.error('Error creating payment intent:', error);
    }
  };

  const handlePaymentSuccess = async () => {
    setShowStatus(true);
    setStatusMessage('Processing Payment...');
    try {
      const response = await dispatch(createNewProvisioningJob({ days, price })).unwrap();
      if (response?.success) {
        setStatusMessage('Provisioning...');
        checkProvisioningStatus(response.data.provisioningId);
      } else {
        console.error("Provisioning failed");
        setStatusMessage('Provisioning Failed');
      }
    } catch (error) {
      console.error('Error creating provisioning job:', error);
      setStatusMessage('Provisioning Failed');
    }
  };

  const checkProvisioningStatus = async (provisioningId) => {
    try {
      const intervalId = setInterval(async () => {
        const response = await dispatch(getProvisioningStatus(provisioningId)).unwrap();
        if (response?.provisioning_status === 0) {
          setStatusMessage("Provisioning Successful");
          clearInterval(intervalId);
          setTimeout(() => setActiveVerticalTab(4), 2000);
        } else if (response?.provisioning_status === 2) {
          setStatusMessage("Provisioning Failed");
          clearInterval(intervalId);
        } else {
          setStatusMessage("Provisioning in Progress...");
        }
      }, 5000); // poll every 5 seconds
    } catch (error) {
      console.error('Error checking provisioning status:', error);
      setStatusMessage("Error checking provisioning status");
    }
  };

  const handleNextStep = (nextTab) => {
    if (nextTab === 2) {
      handleCreatePaymentIntent();
    }
    if (nextTab === 3 && !clientSecret) {
      return; // Ensure clientSecret is available before going to Payment step
    }
    if (nextTab === 4) {
      handlePayment();
    } else {
      toggleVerticalTab(nextTab);
    }
  };

  const toggleVerticalTab = (tab) => {
    if (activeVerticalTab !== tab) {
      const modifiedSteps = [...passedVerticalSteps, tab];
      if (tab >= 1 && tab <= 5) {
        setActiveVerticalTab(tab);
        setPassedVerticalSteps(modifiedSteps);
      }
    }
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="">
          <Col xl={12}>
            <Card className="" style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}>
              <CardHeader className="card-header-custom mb-3" style={{ backgroundColor: 'transparent', border: 'none' }}>
                <div className="header-content">
                  <Link to="#" className="logo logo-dark">
                    <span className="logo-sm">
                      <img src={getLogo(defaultLogoSm, defaultLogoDark)} className="header-logo" alt="" width={'200'} />
                    </span>
                    <span className="logo-lg">
                      <img src={getLogo(defaultLogoDark, defaultLogoDark)} className="header-logo" alt="" width={'200'} />
                    </span>
                  </Link>
                  <Link to="#" className="logo logo-light">
                    <span className="logo-sm">
                      <img src={getLogo(defaultLogoSm, defaultLogoLight)} className="header-logo" alt="" width={'200'} />
                    </span>
                    <span className="logo-lg">
                      <img src={getLogo(defaultLogoLight, defaultLogoLight)} className="header-logo" alt="" width={'200'} />
                    </span>
                  </Link>
                </div>
              </CardHeader>
              <CardBody className="mb-0 pb-0" style={{ backgroundColor: 'transparent', border: 'none' }}>
                <form className="vertical-navs-step form-steps">
                  <Row className="gy-5 gap-0">
                    <Col lg={3} className="equalize-height-col">
                      <Card className="rounded-5 equalize-height-col-item">
                        <CardBody>
                          <Nav className="flex-column custom-nav nav-pills">
                            <NavItem>
                              <NavLink
                                href="#"
                                className={classnames({ active: activeVerticalTab === 1, done: activeVerticalTab <= 5 && activeVerticalTab > 1 })}
                                onClick={() => handleNextStep(1)}
                              >
                                <span className="step-title me-2">
                                  <i className="ri-close-circle-fill step-icon me-2"></i>
                                  Step 1
                                </span>
                                Data Selection
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                href="#"
                                className={classnames({ active: activeVerticalTab === 2, done: activeVerticalTab <= 5 && activeVerticalTab > 2 })}
                                onClick={() => handleNextStep(2)}
                              >
                                <span className="step-title me-2">
                                  <i className="ri-close-circle-fill step-icon me-2"></i>
                                  Step 2
                                </span>
                                Billing Info
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                href="#"
                                className={classnames({ active: activeVerticalTab === 3, done: activeVerticalTab <= 5 && activeVerticalTab >= 3 })}
                                onClick={() => handleNextStep(3)}
                              >
                                <span className="step-title me-2">
                                  <i className="ri-close-circle-fill step-icon me-2"></i>
                                  Step 3
                                </span>
                                Payment
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                href="#"
                                className={classnames({ active: activeVerticalTab === 4, done: activeVerticalTab <= 5 && activeVerticalTab >= 4 })}
                                onClick={() => handleNextStep(4)}
                              >
                                <span className="step-title me-2">
                                  <i className="ri-close-circle-fill step-icon me-2"></i>
                                  Step 4
                                </span>
                                Finish
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={6} className="equalize-height-col">
                      <div>
                        <TabContent activeTab={activeVerticalTab}>
                          <TabPane tabId={1}>
                            <DataSelection days={days} setDays={setDays} price={price} total={price * days} />
                          </TabPane>
                          <TabPane tabId={2}>
                            <BillingInfo
                              activeVerticalTab={activeVerticalTab}
                              toggleVerticalTab={setActiveVerticalTab}
                              saveInfo={setBillingInfo}
                            />
                          </TabPane>
                          <TabPane tabId={3}>
                            {showStatus ? (
                              <div className="text-center">
                                <div className="spinner-border text-primary" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                                <p>{statusMessage}</p>
                              </div>
                            ) : (
                              <Payment
                                billingInfo={billingInfo}
                                resetOnPayment={() => setActiveVerticalTab(1)}
                                onPaymentSuccess={handlePaymentSuccess}
                                setHandleStripePayment={setHandleStripePayment}
                              />
                            )}
                          </TabPane>
                          <TabPane tabId={4}>
                            <Card className="rounded-5 equalize-height-col-item">
                              <CardBody>
                                <div className="text-center pt-4 pb-2">
                                  <div className="mb-4">
                                    <i className="bx bx-party display-4 text-success"></i>
                                  </div>
                                  <h5>Your Order is Completed!</h5>
                                  <p className="text-muted">
                                    You will receive an order confirmation email with details of your order.
                                  </p>
                                </div>
                              </CardBody>
                            </Card>
                          </TabPane>
                        </TabContent>
                      </div>
                    </Col>
                    <Col lg={3} className="equalize-height-col">
                      <Cart days={days} price={price} total={price * days} />
                    </Col>
                  </Row>
                </form>
              </CardBody>
              <CardFooter className="mt-1 pt-2" style={{ backgroundColor: 'transparent', border: 'none' }}>
                <Navigation
                  activeVerticalTab={activeVerticalTab}
                  toggleVerticalTab={setActiveVerticalTab}
                  handlePayment={handlePayment}
                />
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default TopUp;