import { createSlice } from "@reduxjs/toolkit";
import { 
  getSitesByCustomerId,
  addNewSite,
  updateSite,
  deleteSite,
  getSiteById
} from './thunk';
export const initialState = {
  sites: [],
  site: {},
  error: {},
};

const SiteSlice = createSlice({
  name: 'SiteSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getSitesByCustomerId.fulfilled, (state, action) => {
      state.sites = action.payload;
      state.isSiteCreated = false;
      state.isSiteSuccess = true;
    });
    builder.addCase(getSitesByCustomerId.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isSiteCreated = false;
      state.isSiteSuccess = false;
    });
    builder.addCase(addNewSite.fulfilled, (state, action) => {
      state.site = action.payload;
      state.isSiteCreated = true;
    });
    builder.addCase(addNewSite.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(getSiteById.fulfilled, (state, action) => {
      state.site = action.payload;
    });
    builder.addCase(getSiteById.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(updateSite.fulfilled, (state, action) => {
      state.site = action.payload;
      // update site in list
      state.sites = state.sites.map((site) =>
        site.site_id === action.payload.site_id ? action.payload : site
      );
    });
    builder.addCase(updateSite.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(deleteSite.fulfilled, (state, action) => {
      state.site = action.payload;
      // remove site from list
      state.sites = state.sites.filter((site) => site.site_id !== action.payload.siteId);
    });
    builder.addCase(deleteSite.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
  }
});

export default SiteSlice.reducer;