import axios from "axios";
import { api } from "../config";

// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).token : null;
if (token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

// intercepting to capture errors and handle token expiration
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  async function (error) {
    // Check for token expiration error
    if (error.response && error.response.status === 401 && error.response.data && error.response.data.message === "Token has expired. Please log in again.") {
      const originalRequest = error.config;
      if (!originalRequest._retry) { // Avoid infinite loop
        originalRequest._retry = true;
        const refreshToken = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).refresh_token : null;
        if (refreshToken) {
          try {
            // Attempt to refresh token
            const resetResponse = await axios.post(api.REFRESH_TOKEN_URL, {}, {
              headers: { "Authorization": "Bearer " + refreshToken }
            });
            if (resetResponse.status === 200) {
              // Update token in sessionStorage
              const newToken = resetResponse.token;
              sessionStorage.setItem("authUser", JSON.stringify({ ...JSON.parse(sessionStorage.getItem("authUser")), token: newToken }));
              // Update the authorization header
              axios.defaults.headers.common["Authorization"] = "Bearer " + newToken;
              // Retry the original request with the new token
              originalRequest.headers["Authorization"] = "Bearer " + newToken;
              return axios(originalRequest);
            }
          } catch (refreshError) {
            console.error("Unable to refresh token:", refreshError);
            // Redirect to login page
            window.location.href = "/login";
            return Promise.reject(refreshError);
          }
        }
      }
    } else if (error.response && error.response.status === 403) {
      return Promise.reject(error.response?.data?.message || "You are not authorized to perform this action");
    } else {
      // Existing error handling logic
      let message;
      switch (error.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        default:
          message = error.message || error;
      }
      return Promise.reject(message);
    }
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };

  /**
   * post given data to url with multipart form data
   */
  createFormData = (url, data) => {
    return axios.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  /**
   * patch given data to url with multipart form data
   */
  updateFormData = (url, data) => {
    return axios.patch(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  /**
   * put given data to url with multipart form data
   */
  updatePutFormData = (url, data) => {
    return axios.put(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };

  putWithAdditionalHeaders = (url, data, headers) => {
    return axios.put(url, data, {
      headers: {
        ...axios.defaults.headers,
        ...headers
      }
    });
  };

  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };