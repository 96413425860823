// components/RoleForm.js
import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { addRole as onCreateRole, updateRole as onUpdateRole } from "../../../slices/thunks";

const RoleForm = ({ initialRole, onNextStep, onCancel }) => {
    const dispatch = useDispatch();
    const [role, setRole] = useState({
        name: '',
        description: '',
        permissions: []
    });

    useEffect(() => {
        if (initialRole) {
            setRole(initialRole);
        }
    }, [initialRole]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRole({ ...role, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (initialRole) {
            dispatch(onUpdateRole(role))
                .then(() => onNextStep());
        } else {
            dispatch(onCreateRole(role))
                .then(() => onNextStep());
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="role_name">Role Name</Label>
                <Input
                    type="text"
                    name="name"
                    id="role_name"
                    value={role.name}
                    onChange={handleInputChange}
                    required
                />
            </FormGroup>
            <FormGroup>
                <Label for="role_description">Role Description</Label>
                <Input
                    type="text"
                    name="description"
                    id="role_description"
                    value={role.description}
                    onChange={handleInputChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for="role_permissions">Permissions</Label>
                <Input
                    type="text"
                    name="permissions"
                    id="role_permissions"
                    value={role.permissions}
                    onChange={handleInputChange}
                />
            </FormGroup>
            <Button type="submit" color="primary">Submit</Button>
            <Button type="button" color="secondary" onClick={onCancel}>Cancel</Button>
        </Form>
    );
};

export default RoleForm;
