import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Row, Col, Collapse } from 'reactstrap';
import UsageCard from './UsageCard';
import UsageProgress from './UsageProgress';
import { USAGE_KEYS, USAGE_MAP } from '../../../Components/constants/crm';
import { getServiceById } from '../../../slices/thunks';
import { useDispatch } from 'react-redux';
import Loader from '../../../Components/Common/Loader';
import './ServiceUsage.css';

const ServiceUsage = ({ service, dongle = false }) => {
    const dispatch = useDispatch();
    const [openIndex, setOpenIndex] = useState(null);
    const [currentService, setCurrentService] = useState(service);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setCurrentService(service);
    }, [service]);

    useEffect(() => {
        if (!service?.service_id) {
            return;
        }
        const intervalId = setInterval(async () => {
            dispatch(getServiceById(service?.service_id)).then((response) => {
                setCurrentService(response.payload);
                setLoading(false);
            }).catch((error) => {
                console.error(error);
            });
        }, 3000);

        return () => clearInterval(intervalId);
    }, [service?.service_id, dispatch]);

    const formatItemID = (dataType, itemId) => {
        let words = itemId.split('_');
        if ([USAGE_KEYS.DATA].includes(dataType) || [USAGE_KEYS.DATA_DONGLE].includes(dataType)) {
            if (!isNaN(words[words.length - 1])) {
                words[words.length - 1] = `${parseInt(words[words.length - 1]) / (1024 * 1024 * 1024)}`;
            }
        } else if ([USAGE_KEYS.VOICE].includes(dataType)) {
            if (!isNaN(words[words.length - 1])) {
                words[words.length - 1] = `${parseInt(words[words.length - 1]) / 60000000000}`;
            }
        }
        return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };

    const toggle = index => setOpenIndex(openIndex === index ? null : index);

    // modify usageMap to only show dongle data if dongle is true
    const usageMap = { ...USAGE_MAP };
    if (dongle) {
        delete usageMap[USAGE_KEYS.DATA];
        delete usageMap[USAGE_KEYS.VOICE];
        delete usageMap[USAGE_KEYS.SMS];
        delete usageMap[USAGE_KEYS.MONETARY];
    } else {
        delete usageMap[USAGE_KEYS.DATA_DONGLE];
    }

    return (
        <React.Fragment>
            {loading && <Loader className="loader-overlay" />}
            <div className={`service-usage-container ${loading ? 'blur' : ''}`}>
                <h4>Current Balances for {currentService?.service_name}</h4>
                <Row>
                    {Object.keys(usageMap).map((key, index) => (
                        <Col key={'uc-' + index} md={3} sm={'auto'}>
                            <UsageCard data={currentService?.cgrates?.BalanceMap?.[key] ?? []} dataType={key ?? null} moreInfoClick={() => toggle(index)} />
                        </Col>
                    ))}
                </Row>
                <hr />
                <h4>Current Usage</h4>
                {Object.keys(usageMap).map((key, index) => (
                    <div key={'up-' + index} onClick={() => toggle(index)}>
                        <UsageProgress data={currentService?.cgrates?.BalanceMap?.[key] || []} dataType={key} className='mb-0' isOpen={openIndex === index} />
                        <Collapse isOpen={openIndex === index} className='mt-0'>
                            <Card>
                                <CardBody>
                                    {currentService?.cgrates?.BalanceMap?.[key] && currentService?.cgrates.BalanceMap[key].map((item, itemIndex) => (
                                        <React.Fragment key={itemIndex}>
                                            <Card>
                                                <CardHeader>
                                                    <Row>
                                                        <Col className=" d-flex text-center align-items-center" md={4}>
                                                            {item.custom_Name_hr ?? formatItemID(key, item.ID)}
                                                        </Col>
                                                        <Col>
                                                            <UsageProgress data={item} dataType={key} simple={true} />
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row className=" d-flex text-center align-items-center">
                                                        <Col>Expires {item.custom_Expiration}</Col>
                                                        <Col>{item.custom_Description_String}</Col>
                                                        {item.custom_Destinations && <Col>Destinations: {item.custom_Destinations}</Col>}
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </React.Fragment>
                                    ))}
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

export default ServiceUsage;
