import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, CardFooter, Button } from 'reactstrap';
import TCsModal from './TCsModal';
import { Link } from 'react-router-dom';
import './common.css';

const backgroundColors = {
    'card-primary': [64, 81, 137],
    'card-success': [10, 179, 156],
};

// get Currency Symbol and Code from the environment
const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL || "$";
const currencyCode = process.env.REACT_APP_CURRENCY_CODE || "USD";

const CarouselItem = ({ item, theme = 'card-primary', darken = false, footerText = "Connect Now", index, totalItems = 10, action = null}) => {
    const brightness = darken ? -0.03 * (index / (totalItems / 10)) : 0.03 * (index / (totalItems / 10)); 
    const color = adjustBrightness(backgroundColors[theme], brightness);
    const colorStyle = {
        backgroundColor: `rgb(${color[0]}, ${color[1]}, ${color[2]})`
    };

    // Extract the Component from item
    const Component = item.component;

    const [TCsModalOpen, setTCsModalOpen] = useState(false);
    const toggleTCsModal = () => setTCsModalOpen(!TCsModalOpen);

    function adjustBrightness(rgb, percent) {
        // First, convert RGB to HSL
        let r = rgb[0] / 255, g = rgb[1] / 255, b = rgb[2] / 255;
        let max = Math.max(r, g, b), min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;
    
        if(max === min){
            h = s = 0; // achromatic
        } else {
            let d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch(max){
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }
    
        // Then, adjust lightness
        l = l + percent;
        l = Math.max(0, Math.min(1, l));
    
        // Finally, convert HSL back to RGB
        let r1, g1, b1;
        if(s === 0){
            r1 = g1 = b1 = l; // achromatic
        } else {
            let hue2rgb = function hue2rgb(p, q, t){
                if(t < 0) t += 1;
                if(t > 1) t -= 1;
                if(t < 1/6) return p + (q - p) * 6 * t;
                if(t < 1/2) return q;
                if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
                return p;
            };
            let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            let p = 2 * l - q;
            r1 = hue2rgb(p, q, h + 1/3);
            g1 = hue2rgb(p, q, h);
            b1 = hue2rgb(p, q, h - 1/3);
        }
    
        return [Math.round(r1 * 255), Math.round(g1 * 255), Math.round(b1 * 255)];
    }

    const handleCardAction = ((data) => {
        return () => {
            if( action ) {
                action(data);
            }
        }
    });

    return (
        <Card className={`card-custom-height ${theme}`} style={colorStyle}>
            <CardHeader className='text-center justify-content-center align-items-center' style={{minHeight: '10em'}}>
                <h5 className="card-title white-text pt-2">{item.title ?? item.product_name ?? 'CarouselItem'}</h5>
                <p className='mb-0'>{item.icon} {currencySymbol}{item.retail_cost}</p>
                <p className='mb-0'>{currencySymbol}{item.retail_setup_cost} Setup</p>
            </CardHeader>
            <CardBody className='d-flex flex-column' style={{ height: `${item.maxHeight}rem`, overflow: 'auto' }}>
                {Component ? Component : (
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                            <img src={item.avatar} alt="" className="avatar-sm rounded-circle" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                            <p className="card-text white-text"><span className="fw-medium">{item.title}</span> ({item.description}) started a new conversation.</p>
                        </div>
                    </div>
                )}
            </CardBody>
            <CardFooter className='mb-0 pb-1'>
                <div className="text-center justify-content-center">
                    {item.terms && (
                        <div>
                            T&Cs {' '}
                            <Link
                                className="d-inline-block text-white"
                                title='View Terms & Conditions'
                                onClick={(e) => {
                                    e => e.stopPropagation();
                                    toggleTCsModal();
                                }}
                            >
                                <i className="ri-eye-fill fs-16"></i>
                            </Link>
                        </div>
                    )}
                    <Button color="link" className="link-light fs-18 fw-bold" onClick={handleCardAction(item)}>{ footerText }</Button>
                </div>
            </CardFooter>
            <TCsModal isOpen={TCsModalOpen} toggle={toggleTCsModal} terms={item.terms} productTitle={item.title ?? item.product_name} />
        </Card>
    );
};

export default CarouselItem;