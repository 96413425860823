//Include Backend Helper File with needed methods
import {
  register,
  signup,
} from "../../../helpers/backend_helper";

// action
import {
  registerUserSuccessful,
  registerUserFailed,
  resetRegisterFlagChange,
  apiErrorChange,
  signupUser
} from "./reducer";

// Is user register successful then direct plot user in redux.
export const registerUser = (user) => async (dispatch) => {
  try {
    let response;

    response = await register(user);

    const data = await response;

    if (data.message === "success") {
      dispatch(registerUserSuccessful(data));
    } else {
      dispatch(registerUserFailed(data));
    }
  } catch (error) {
    dispatch(registerUserFailed(error));
  }
};

export const userSignUp = (formData) => async (dispatch) => {
  try {
    dispatch(signupUser());
    const response = await signup(formData);
    return response;
  } catch (error) {
    return error;
  }
};

export const resetRegisterFlag = () => {
  try {
    const response = resetRegisterFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};

export const apiError = () => {
  try {
    const response = apiErrorChange();
    return response;
  } catch (error) {
    return error;
  }
};