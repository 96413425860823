import { createAsyncThunk } from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {
    getStripeObjects as getStripeObjectsApi,
    addNewStripeObject as addNewStripeObjectApi,
    deleteStripeObject as deleteStripeObjectApi,
    getStripeObjectsByCustomerId as getStripeObjectsByCustomerIdApi,
    addStripePaymentForAnInvoice as addStripePaymentForAnInvoiceApi,
    chargeStripePayment as chargeStripePaymentApi,
    createPaymentIntent as createPaymentIntentApi,
} from "../../helpers/backend_helper";

export const getStripeObjects = createAsyncThunk("stripe/getStripeObjects", async () => {
    const response = await getStripeObjectsApi();
    return response;
});

export const addNewStripeObject = createAsyncThunk("stripe/addNewStripeObject", async (stripeObject) => {
    const response = await addNewStripeObjectApi(stripeObject);
    return response;
});

export const deleteStripeObject = createAsyncThunk("stripe/deleteStripeObject", async (stripeObjectId) => {
    const response = await deleteStripeObjectApi(stripeObjectId);
    return response;
});

export const getStripeObjectsByCustomerId = createAsyncThunk("stripe/getStripeObjectsByCustomerId", async (customerId) => {
    const response = await getStripeObjectsByCustomerIdApi(customerId);
    return response;
});

export const addStripePaymentForAnInvoice = createAsyncThunk("stripe/addStripePaymentForAnInvoice", async ({invoiceId, paymentId}) => {
    const response = await addStripePaymentForAnInvoiceApi(invoiceId, paymentId);
    return response;
});

export const chargeStripePayment = createAsyncThunk("stripe/chargeStripePayment", async (tokenId, stripePayment) => {
    const response = await chargeStripePaymentApi(tokenId, stripePayment);
    return response;
});

export const createPaymentIntent = createAsyncThunk("stripe/createPaymentIntent", async (data) => {
    const response = await createPaymentIntentApi(data);
    return response;
});

export const resetStripeStates = createAsyncThunk("stripe/resetStates", async () => {
    return {};
});
