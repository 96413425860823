import { createSlice } from "@reduxjs/toolkit";
import {
  getCommunicationById,
  getCommunicationsByCustomerId,
  addNewCommunication,
  sendInvoiceEmailToCustomer,
} from './thunk';
export const initialState = {
  communications: [],
  communication: {},
  isCommunicationCreated: false,
  error: {},
};

const CommunicationSlice = createSlice({
  name: 'CommunicationSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getCommunicationById.fulfilled, (state, { payload }) => {
      state.communication = payload;
    });
    builder.addCase(getCommunicationById.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(getCommunicationsByCustomerId.fulfilled, (state, { payload }) => {
      state.communications = payload;
    });
    builder.addCase(getCommunicationsByCustomerId.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(addNewCommunication.fulfilled, (state, { payload }) => {
      state.communication = payload;
      state.isCommunicationCreated = true;
    });
    builder.addCase(addNewCommunication.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(sendInvoiceEmailToCustomer.fulfilled, (state, { payload }) => {
      state.sentInvoiceEmailToCustomer = true;
    });
    builder.addCase(sendInvoiceEmailToCustomer.rejected, (state, { payload }) => {
      state.error = payload;
    });
  }
});

export default CommunicationSlice.reducer;