import React, { useEffect, useState } from "react";
import { Card, CardFooter, CardBody, CardHeader, Row, Col, Label, Input } from "reactstrap";

const BillingInfo = ({ saveInfo, activeVerticalTab, toggleVerticalTab }) => {

    const [billingInfo, setBillingInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
    });

    useEffect(() => {
        if (saveInfo) {
            saveInfo(billingInfo);
        }
    }, [billingInfo, saveInfo]);

    return (
        <React.Fragment>
            <Card className="rounded-5 equalize-height-col-item">
                <CardHeader className="py-3 pb-0 rounded-5">
                    <h5>Billing Info</h5>
                    <p className="text-muted fs-6">
                        Fill all information below
                    </p>
                </CardHeader>

                <CardBody>
                    <Row className="g-3">
                        <Col sm={6}>
                        <Label
                            htmlFor="firstName"
                            className="form-label"
                        >
                            First name
                        </Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="firstName"
                            placeholder="Enter First Name"
                            defaultValue=""
                        />
                        </Col>

                        <Col sm={6}>
                        <Label
                            htmlFor="lastName"
                            className="form-label"
                        >
                            Last name
                        </Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="lastName"
                            placeholder="Enter Last Name"
                            defaultValue=""
                        />
                        </Col>
                        <Col xs={12}>
                        <Label
                            htmlFor="email"
                            className="form-label"
                        >
                            Email <span className="text-muted">(For Receipt)</span>{" "}
                        </Label>
                        <Input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Enter Email"
                        />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default BillingInfo;