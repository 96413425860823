import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import TableCommon from "../../Components/Common/TableCommon";
import DeleteModal from "../../Components/Common/DeleteModal";
import PlanList from "../Product/PlanList";
import ServiceView from "./components/ServiceView";
import Addons from "../Product/Addons";
import Tags from "../../Components/Common/Tags";
import { SERVICE_STATUS_THEME_COLORS } from "../../Components/constants/crm";
import ServiceUsage from "./components/ServiceUsage";

//redux
import { useDispatch } from "react-redux";

// import actions
import {
  getServices as onGetServices,
  deleteService as onDeleteService,
  getCustomers as getCustomers,
} from "../../slices/thunks";

import Loader from "../../Components/Common/Loader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ServiceList = () => {
  const [activeTab, setActiveTab] = useState("1");

  const dispatch = useDispatch();

  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [serviceList, setServiceList] = useState([]);
  const [formattedServiceList, setFormattedServiceList] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 10,
    totalRecords: 0,
    totalPages: 0,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "service_id", direction: "desc" });
  const sortableColumns = [ 'service_id', 'customer_id', 'service_name', 'service_start', 'service_end', 'autorenew', 'service_type', 'retail_cost', 'wholesale_cost' ];
  const [filters, setFilters] = useState({ service_status: ['Active'] });

  const [addServiceModal, setAddServiceModal] = useState(false);
  const [editServiceModal, setEditServiceModal] = useState(false);
  const [addonModal, setAddonModal] = useState(false);
  const [usageModal, setUsageModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);

  const sortOptions = [
      { value: 'desc', label: 'Newest' },
      { value: 'asc', label: 'Oldest' },
  ];

  const filterOptions = {
    service_type: {
      options: [
        { value: 'mobile', label: 'Mobile' },
        { value: 'iptv', label: 'IPTV' },
        { value: 'internet', label: 'Internet' },
        { value: 'voip', label: 'VoIP' },
      ]
    },
    customer_id: {
      config: {
        type: 'search',
        loadOptions: (inputValue, callback) => {
          dispatch(getCustomers({ searchTerm: inputValue }))
            .then((response) => {
              const options = response.payload.data.map(customer => ({ value: customer.customer_id, label: customer.customer_name }));
              callback(options);
            });
        }
      },
    },
  };

  const fetchServices = (currentPage = pagination.currentPage, itemsPerPage = pagination.itemsPerPage, key = sortConfig.key, direction = sortConfig.direction, search = searchTerm, filtersData = filters) => {
    const sortKeyMapping = {
      service_start: 'service_active_date',
      service_end: 'service_deactivate_date',
      autorenew: 'auto_renew',
    };
    key = sortKeyMapping[key] || key;

    dispatch(onGetServices({ search, page: currentPage, perPage: itemsPerPage, sort: key, sortOrder: direction, filters: filtersData }))
      .then((response) => {
        setSortConfig({ key, direction });
        setSearchTerm(searchTerm);
        setServices(response?.payload?.data || []);
        if (response?.payload?.pagination) {
          setPagination({
            currentPage: response.payload.pagination.page,
            itemsPerPage: response.payload.pagination.per_page,
            totalRecords: response.payload.pagination.total_records,
            totalPages: response.payload.pagination.total_pages,
          });
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchServices();
  }, []);

  useEffect(() => {
    if (services) {
      setServiceList(services);
    }
  }, [services]);

  useEffect(() => {
    if (serviceList) {
      setFormattedServiceList(serviceList.map((service) => {
        return {
          id: service.service_id,
          service_id: service.service_id,
          customer_id: service.customer_id,
          service_name: service.service_name,
          service_start: service.service_active_date,
          service_end: service.service_deactivate_date,
          autorenew: service.auto_renew ? 'On' : 'Off',
          status: (
            <Tags tags={[{ value: service.service_status, color: SERVICE_STATUS_THEME_COLORS[service.service_status] ?? 'info' }]} />
          ),
          service_type: service.service_type,
          retail_cost: service.retail_cost,
          wholesale_cost: service.wholesale_cost,
          actions: (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddonClick(service);
                  }}
                  className="text-white d-inline-block btn btn-success btn-sm"
                >
                  <i className="ri-add-line">Add On</i>
                </Link>
              </li>
              { service.customer_id && (
                <li className="list-inline-item">
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUsageClick(service);
                    }}
                    className="text-white d-inline-block btn btn-success btn-sm"
                  >
                    <i className="ri-donut-chart-fill">Usage</i>
                  </Link>
                </li>
              )}
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleServiceEdit(service);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedService(service);
                    setDeleteModal(true);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          )
        };
      }));
    }
  }, [serviceList]);

  const handleAddonClick = (service) => {
    setSelectedService(service);
    setAddonModal(true);
  };

  const handleUsageClick = (service) => {
    setSelectedService(service);
    setUsageModal(true);
  };

  const handleServiceEdit = (service) => {
    setSelectedService(service);
    setEditServiceModal(true);
  };

  const handleServiceEditRow = (serviceId) => {
    setSelectedService(serviceList.find(service => service.service_id === serviceId));
    setEditServiceModal(true);
  };

  const handleDeleteServiceConfirm = () => {
    dispatch(onDeleteService(selectedService.service_id))
      .then(() => {
        toast.success("Service deleted successfully");
        fetchServices();
        setSelectedService(null);
        setDeleteModal(false);
      })
      .catch((error) => {
        toast.error('Failed to delete Service');
        console.error(error);
      });
  };

  const handleDeleteMultiple = () => {
    Promise.all(
      selectedCheckBoxDelete.map((element) => {
        return new Promise((resolve, reject) => {
          dispatch(onDeleteService(element));
          setTimeout(() => {
            toast.clearWaitingQueue();
            resolve();
          }, 3000);
        });
      })
    )
      .then(() => {
        toast.success("Services deleted successfully");
        fetchServices();
        setSelectedCheckBoxDelete([]);
        setDeleteModalMulti(false);
      })
      .catch((error) => {
        toast.error("Failed to delete services");
        console.error(error);
      });
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);

      // set filters based on tab
      let filtersData = null;
      if (['Active', 'Inactive', 'Suspended'].includes(type)) {
          filtersData = { service_status: [type] };
      }
      setFilters(filtersData);

      fetchServices(1, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm, filtersData);
    }
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: [value],
    }));
  };

  const applyFilters = () => {
    fetchServices();
  };

  const resetFilters = () => {
    // reset filters except service_status
    setFilters({ service_status: filters.service_status });
    fetchServices(pagination.currentPage, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm, { service_status: filters.service_status });
  };

  if (isLoading) {
    return <Loader />;
  }

  document.title = "Services | Omnitouch CRM";
  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => {
          handleDeleteServiceConfirm();
          setDeleteModal(false);
        }}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          handleDeleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Modal id="addonModal" isOpen={addonModal} toggle={() => { setSelectedService(null); setAddonModal(!addonModal); }} size={'xl'} centered>
        <ModalHeader toggle={() => { setSelectedService(null); setAddonModal(!addonModal); }}>
          Addons
        </ModalHeader>
        <ModalBody>
          <Addons customerId={selectedService?.customer_id} serviceId={selectedService?.service_id} service={selectedService} />
        </ModalBody>
      </Modal>
      <Modal id="usageModal" isOpen={usageModal} scrollable={true} toggle={() => { setSelectedService(null); setUsageModal(!usageModal); }} size={'xl'} centered>
        <ModalHeader toggle={() => { setSelectedService(null); setUsageModal(!usageModal); }}>
          Service Usage
        </ModalHeader>
        <ModalBody>
          <ServiceUsage service={selectedService} />
        </ModalBody>
      </Modal>

      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">Services</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      <button className="btn btn-success" onClick={() => setAddServiceModal(true)}>
                          <i className="ri-add-line me-1 align-bottom"></i> Add Service
                      </button>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success border-bottom border-grey"
                    role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1", 'text-success': activeTab === "1", 'text-dark': activeTab !== "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "Active");
                        }}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
                        Active Services
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "2", 'text-success': activeTab === "2", 'text-dark': activeTab !== "2" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("2", "Inactive");
                        }}
                        href="#"
                      >
                        <i className="ri-stop-circle-line me-1 align-bottom"></i>{" "}
                        Inactive Services
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "3", 'text-success': activeTab === "3", 'text-dark': activeTab !== "3" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("3", "Suspended");
                        }}
                        href="#"
                      >
                        <i className="ri-pause-circle-line me-1 align-bottom"></i>{" "}
                        Suspended Services
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "4", 'text-success': activeTab === "4", 'text-dark': activeTab !== "4" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("4", "All");
                        }}
                        href="#"
                      >
                        <i className="ri-service-fill me-1 align-bottom"></i>{" "}
                        All Services
                      </NavLink>
                    </NavItem>
                  </Nav>
                    <TableCommon
                      data={formattedServiceList}
                      title="Services"
                      noHeader={true}
                      searchPlaceholder="Search for Services, Service ID, Date etc."
                      sortConfig={sortConfig}
                      sortableColumns={sortableColumns}
                      pagination={pagination}
                      fetchRecords={fetchServices}
                      multiDelete={(ids) => { setSelectedCheckBoxDelete(ids); setDeleteModalMulti(true); }}
                      onRowClick={(id) => handleServiceEditRow(id)}
                      sortOptions={sortOptions}
                      noCard={true}
                      filters={{
                          filterOptions: filterOptions,
                          handleFilterChange: handleFilterChange,
                          applyFilters: applyFilters,
                          resetFilters: resetFilters
                      }}
                    />
                </div>
                <Modal id="showModal" isOpen={addServiceModal} toggle={() => { setAddServiceModal(!addServiceModal); fetchServices();}} size={'xl'} centered>
                  <ModalHeader className="bg-light p-3" toggle={() => { setAddServiceModal(!addServiceModal); fetchServices();}}>
                    Add Service to Customer:
                  </ModalHeader>
                  <ModalBody>
                    <PlanList />
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal id="editModal" isOpen={editServiceModal} toggle={() => { setEditServiceModal(!editServiceModal); }} size={'xl'} centered>
          <ModalHeader toggle={() => { setEditServiceModal(!editServiceModal); }}>
            Edit Service
          </ModalHeader>
          <ModalBody>
            <ServiceView service={selectedService} onClose={() => { setEditServiceModal(false); fetchServices(); }} />
          </ModalBody>
        </Modal>
      </Container>
    </div>
  );
};

export default ServiceList;