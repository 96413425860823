import { GoogleMap, Autocomplete, Marker } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { OpenLocationCode } from 'open-location-code';
import { Button } from "reactstrap";

import { useDispatch } from "react-redux";

//Import actions
import {
    addNewSite as onAddNewSite,
    updateSite as onUpdateSite
} from "../../../slices/thunks";

import { Col, Container, Row } from 'reactstrap';
import { toast } from "react-toastify";

const SiteEdit = (props) => {
    const dispatch = useDispatch();
    const { siteData } = props;

    const [searchResult, setSearchResult] = useState(null);
    let olc = new OpenLocationCode();

    const defaultMapCenter = {
        lat: 36.1483963,
        lng: -5.3604605,
    };

    const [mapCenter, setMapCenter] = useState(
        siteData?.latitude && siteData?.longitude 
            ? { lat: parseFloat(siteData.latitude), lng: parseFloat(siteData.longitude) } 
            : defaultMapCenter
    );

    // Use siteData for initial state if it exists
    const [address, setAddress] = useState({
        customer_id: siteData?.customer_id ?? props?.customer?.customer_id ?? null,
        site_id: siteData?.site_id ?? null,
        site_name: siteData?.site_name ?? '',
        address_line_1: siteData?.address_line_1 ?? '',
        address_line_2: siteData?.address_line_2 ?? '',
        city: siteData?.city ?? '',
        state: siteData?.state ?? '',
        country: siteData?.country ?? '',
        zip_code: siteData?.zip_code ?? '',
        plus_code: siteData?.plus_code ?? '',
        site_notes: siteData?.site_notes ?? '',
        latitude: siteData?.latitude ? parseFloat(siteData.latitude) : '',
        longitude: siteData?.longitude ? parseFloat(siteData.longitude) : '',
        google_maps_place_id: siteData?.google_maps_place_id ?? ''
    });

    const mapContainerStyle = {
        width: "100%",
        height: "50vh",
    };

    const [map, setMap] = useState(null);

    const onMapLoad = (map) => {
        setMap(map);
    };

    const onMapUnmount = (map) => {
        setMap(null);
    };

    const handlePlaceChanged = () => {
        if (searchResult) {
            const place = searchResult.getPlace();
            const resultLatitude = place.geometry.location.lat();
            const resultLongitude = place.geometry.location.lng();

            const addressComponents = place.address_components;

            const plusCode = olc.encode(resultLatitude, resultLongitude, 11);

            const getAddressComponent = (type) => {
                const component = addressComponents.find(component => component.types.includes(type));
                return component ? component.long_name : '';
            }

            const streetNumber = getAddressComponent('street_number');
            const route = getAddressComponent('route');

            setAddress({
                ...address,
                site_name: place.name,
                address_line_1: `${streetNumber} ${route}`,
                address_line_2: getAddressComponent('subpremise'),
                city: getAddressComponent('locality') ?? getAddressComponent('administrative_area_level_2'),
                state: getAddressComponent('administrative_area_level_1') ?? getAddressComponent('region') ?? getAddressComponent('locality'),
                country: getAddressComponent('country'),
                zip_code: getAddressComponent('postal_code'),
                plus_code: plusCode,
                latitude: resultLatitude,
                longitude: resultLongitude,
                site_notes: address.site_notes,
                google_maps_place_id: place.place_id
            });

            setMapCenter({ lat: resultLatitude, lng: resultLongitude });
        }
    }

    const handleInputChange = (event) => {
        setAddress({
            ...address,
            [event.target.name]: event.target.value
        });
    }

    const handleOnLoad = (autocomplete) => {
        setSearchResult(autocomplete);
    }

    const handleSiteSave = (event) => {
        event.preventDefault();

        // handle form submission
        if (siteData) {
            dispatch(onUpdateSite({...address, })); // update site if siteData exists
        } else {
            dispatch(onAddNewSite(address)); // add new site if siteData does not exist
        }

        toast.success("Site saved successfully!",
            {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        );

        // only call next pane if passed on as props
        if (props.onNextStep) {
            props.onNextStep();
        }
    }

    const handleSiteSaveAndAnother = (event) => {
        event.preventDefault();

        // handle form submission
        dispatch(onAddNewSite(address));

        handleClearAddress();
    }

    const handleClearAddress = () => {
        setAddress({
            ...address,
            site_name: '',
            address_line_1: '',
            address_line_2: '',
            city: '',
            state: '',
            country: '',
            zip_code: '',
            plus_code: '',
            latitude: '',
            longitude: '',
            site_notes: '',
            google_maps_place_id: ''
        });
    }

    const handleMarkerDragEnd = (e) => {
        const newPosition = e.latLng;
        const lat = newPosition.lat();
        const lng = newPosition.lng();
    
        const geocoder = new window.google.maps.Geocoder();
    
        geocoder.geocode({ location: newPosition }, (results, status) => {
            if (status === "OK") {
                if (results[0]) {
                    const place = results[0];
                    const addressComponents = place.address_components;
    
                    const getAddressComponent = (type) => {
                        const component = addressComponents.find(component => component.types.includes(type));
                        return component ? component.long_name : '';
                    }
    
                    const streetNumber = getAddressComponent('street_number');
                    const route = getAddressComponent('route');
                    const plusCode = olc.encode(lat, lng, 11);

    
                    setAddress({
                        ...address,
                        site_name: place.name ?? place.formatted_address ?? '',
                        address_line_1: `${streetNumber} ${route}`,
                        address_line_2: getAddressComponent('subpremise'),
                        city: getAddressComponent('locality') ?? getAddressComponent('administrative_area_level_2'),
                        state: getAddressComponent('administrative_area_level_1') ?? getAddressComponent('region') ?? getAddressComponent('locality'),
                        country: getAddressComponent('country'),
                        zip_code: getAddressComponent('postal_code'),
                        plus_code: plusCode,
                        latitude: lat,
                        longitude: lng,
                        site_notes: address.site_notes,
                        google_maps_place_id: place.place_id
                    });
    
                    setMapCenter({ lat, lng });
                } else {
                    window.alert("No results found");
                }
            } else {
                window.alert("Geocoder failed due to: " + status);
            }
        });
    }

    useEffect(() => {
        const style = document.createElement('style');
        style.id = 'autocomplete-style';
        style.type = 'text/css';
        style.innerText = '.pac-container { z-index: 1051 !important; }';
        document.head.appendChild(style);

        return () => {
            const styleElement = document.getElementById('autocomplete-style');
            if (styleElement) {
                styleElement.parentNode.removeChild(styleElement);
            }
        }
    }, []);

    document.title = "Customer Site | Omnitouch CRM";
    return (
        <React.Fragment>                
                <div>
                    <Container>
                        <Row>
                            <Col lg="12">
                                <Autocomplete
                                    onPlaceChanged={handlePlaceChanged}
                                    onLoad={handleOnLoad}
                                >
                                    <input
                                        className="form-control text-start rounded justify-content-center"
                                        type="text"
                                        id="name-7"
                                        name="Site Name"
                                        defaultValue={address.site_name}
                                        placeholder="Eg. Unit 5 Waterport Terraces Gibraltar, N Mole Rd, Gibraltar GX11 1AA, Gibraltar"
                                        style={{
                                            fontSize: 18,
                                            borderStyle: "solid",
                                            borderColor: "var(--vz-primary)",
                                            borderWidth: 2,
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                        }}
                                        // value={addressSearchInput}
                                        // onChange={e => setAddressSearchInput(e.target.value)}
                                    />
                                </Autocomplete>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                    <GoogleMap
                                        mapContainerStyle={mapContainerStyle}
                                        center={mapCenter}
                                        zoom={15}
                                        onLoad={onMapLoad}
                                        onUnmount={onMapUnmount}
                                    >
                                    {mapCenter && <Marker draggable={true} onDragEnd={handleMarkerDragEnd} position={mapCenter} />}

                                    <></>
                                    </GoogleMap>{" "}
                            </Col>
                            <Col lg="6">
                                <h4 className="card-title">Site Information</h4>
                                    <form>
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Site Name</label>
                                            <input type="text" className="form-control" id="name" name="site_name" value={address.site_name} onChange={handleInputChange} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="addressLine1" className="form-label">Address Line 1</label>
                                            <input type="text" className="form-control" id="addressLine1" name="address_line_1" value={address.address_line_1} onChange={handleInputChange} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="addressLine2" className="form-label">Address Line 2</label>
                                            <input type="text" className="form-control" id="addressLine2" name="address_line_2" value={address.address_line_2} onChange={handleInputChange} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="suburb" className="form-label">Suburb</label>
                                            <input type="text" className="form-control" id="suburb" name="city" value={address.city} onChange={handleInputChange} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="stateRegion" className="form-label">State/Region</label>
                                            <input type="text" className="form-control" id="stateRegion" name="state" value={address.state} onChange={handleInputChange} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="country" className="form-label">Country</label>
                                            <input type="text" className="form-control" id="country" name="country" value={address.country} onChange={handleInputChange} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="postCode" className="form-label">Post Code</label>
                                            <input type="text" className="form-control" id="postCode" name="zip_code" value={address.zip_code} onChange={handleInputChange} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="location" className="form-label">Location</label>
                                            <input type="text" className="form-control" id="location" name="plus_code" value={address.plus_code} onChange={handleInputChange} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="addressNote" className="form-label">Address Note</label>
                                            <textarea className="form-control" id="addressNote" name="site_notes" value={address.site_notes} onChange={handleInputChange} />
                                        </div>
                                    </form>
                            </Col>
                        </Row>
                        <Row style={{float: 'right'}} className="d-flex justify-content-start align-items-right gap-3 mt-4">
                            <Col xs={12} md="auto">
                                <Button
                                    type="button"
                                    className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                    onClick={handleSiteSave}
                                >
                                    <i className="ri-save-line label-icon align-middle fs-16 ms-2"></i>
                                    Save Site
                                </Button>
                            </Col>
                            <Col xs={12} md="auto" >
                                <Button
                                    type="button"
                                    className="btn btn-danger btn-label right ms-auto nexttab nexttab"
                                    onClick={props.onCancel} // call onCancel prop when Cancel button is clicked
                                >
                                    <i className="ri-close-line label-icon align-middle fs-16 ms-2"></i>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Container>                    
                </div>
        </React.Fragment>
    );
}

export default SiteEdit;